import React from "react";
import PropTypes from "prop-types";

const ArrowBottomIcon = ({
  width = "32",
  height = "29",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1015 1V20.325L22.5094 13.9315L16.1015 20.325L9.82373 14.0473"
        stroke="#2B8804"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 15.8408V27.9913H1V15.8408"
        stroke="#2B8804"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowBottomIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(ArrowBottomIcon);
