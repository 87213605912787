import React from "react";
import PropTypes from "prop-types";

const HomeIcon = ({
  width = "42",
  height = "36",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 42 36`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_358_850)">
        <path
          d="M35.76 17.39L20.64 5.46L5.53 17.39V19.6V34.71H20.64H35.76V19.6V17.39Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.79 16.4L20.64 0.5L0.5 16.4"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.37 20.83H15.93V34.72H25.37V20.83Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

HomeIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(HomeIcon);
