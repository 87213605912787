import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie-player";

const LottieFilePlayer = ({ data, width, height, classes, children, lottieStyles, speed = 0.5 }) => {
// children used for green arrow around Lottie files ->
  return (
    <div className={classes}>
        {children}
        <Lottie
            loop={false}
            autoPlay={false}
            speed={speed}
            animationData={data}
            play
            style={{ width, height, ...lottieStyles }}
      />
    </div>
  );
};

LottieFilePlayer.propTypes = {
  speed: PropTypes.number
};

export default React.memo(LottieFilePlayer);
