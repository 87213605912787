import React from "react";
import { useTranslation } from "react-i18next";

const ServiceQualityPointsGraphIcon = ({ classes, lang = "en" }) => {
  const { t, ready } = useTranslation();
  return (
    !!ready && (
      <svg
        className={classes}
        width="976"
        height="762"
        viewBox="-180 -40 910 710"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="GRAFICO">
          <line
            x1="280"
            y1="-40"
            x2="280"
            y2="680"
            stroke="#C4C4C4"
            strokeWidth="2"
          />
          <text
            fill="#2B8804"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="30"
            fontWeight="300"
          >
            <tspan x="187" y="630">
              2020
            </tspan>
          </text>
          <text
            id="25"
            fill="#2B8804"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="30"
            fontWeight="300"
          >
            <tspan x="301" y="630">
              2021
            </tspan>
          </text>

          <line
            id="Line 6"
            x1="70"
            y1="39.5"
            x2="70"
            y2="39.5"
            stroke="#45822D"
          >
            <animate
              attributeName="x2"
              from="70"
              to="480"
              begin="1s"
              dur="1s"
              fill="freeze"
            />
          </line>
          <line
            id="Line 9"
            x1="70"
            y1="213.5"
            x2="70"
            y2="213.5"
            stroke="#4FBA22"
          >
            <animate
              attributeName="x2"
              from="70"
              to="480"
              begin="1s"
              dur="1s"
              fill="freeze"
            />
          </line>
          <line id="Line 8" x1="70" y1="520" x2="70" y2="520" stroke="#323232">
            <animate
              attributeName="x2"
              from="70"
              to="480"
              begin="1s"
              dur="1s"
              fill="freeze"
            />
            <animate
              attributeName="y2"
              from="520"
              to="337"
              begin="1s"
              dur="1s"
              fill="freeze"
            />
          </line>
          <line id="Line 7" x1="75" y1="175" x2="75" y2="175" stroke="#395A2D">
            <animate
              attributeName="x2"
              from="75"
              to="490"
              begin="1s"
              dur="1s"
              fill="freeze"
            />
            <animate
              attributeName="y2"
              from="175"
              to="280"
              begin="1s"
              dur="1s"
              fill="freeze"
            />
          </line>
          <g id="Group 9">
            <circle
              id="Ellipse 1"
              cx="40"
              cy="40"
              r="40"
              fill="#45822D"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="1s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="25"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0em"
            >
              <tspan x="26.2889" y="49.3106">
                25
              </tspan>
            </text>
          </g>
          <g id="Group 11">
            <circle
              id="Ellipse 1_2"
              cx="40"
              cy="164"
              r="40"
              fill="#395A2D"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="1s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="22"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0em"
            >
              <tspan x="26.2889" y="170">
                22
              </tspan>
            </text>
          </g>
          <g id="Group 12">
            <circle
              id="Ellipse 1_3"
              cx="40"
              cy="214"
              r="40"
              fill="#4FBA22"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="1s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="21"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0em"
            >
              <tspan x="27.7813" y="223.311">
                21
              </tspan>
            </text>
          </g>
          <g id="Group 13">
            <circle
              id="Ellipse 1_4"
              cx="40"
              cy="529"
              r="40"
              fill="#323232"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="1s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="15"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0em"
            >
              <tspan x="26.2889" y="538.311">
                15
              </tspan>
            </text>
          </g>
          <g id="Group 14">
            <circle
              id="Ellipse 1_5"
              cx="515.016"
              cy="331"
              r="40"
              fill="#323232"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                begin="2s"
                dur="1s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="19"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0em"
            >
              <tspan x="501.305" y="340.311">
                19
              </tspan>
            </text>
            <text
              x="635"
              y="330"
              textAnchor="middle"
              fontSize="16"
              fontWeight="800"
              opacity="0"
              stroke="#000000"
              strokeWidth="1px"
              dy=".3em"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="2s"
                repeatCount="1"
                fill="freeze"
              />
              {t("ServiceQuality.ServiceQualityPointsGraphIcon.text4")}
            </text>
          </g>
          <g id="Group 15">
            <circle
              id="Ellipse 1_6"
              cx="515.016"
              cy="275"
              r="40"
              fill="#395A2D"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                begin="2s"
                dur="1s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="20"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0em"
            >
              <tspan x="501.305" y="287">
                20
              </tspan>
            </text>
            <text
              x={lang === "en" ? "640" : "627"}
              y="275"
              textAnchor="middle"
              fontSize="16"
              fontWeight="800"
              opacity="0"
              stroke="#000000"
              strokeWidth="1px"
              dy=".3em"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="2s"
                repeatCount="1"
                fill="freeze"
              />
              {t("ServiceQuality.ServiceQualityPointsGraphIcon.text3")}
            </text>
          </g>
          <g id="Group 16">
            <circle
              id="Ellipse 1_7"
              cx="515.016"
              cy="214"
              r="40"
              fill="#4FBA22"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                begin="2s"
                dur="1s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="21_2"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0em"
            >
              <tspan x="501.305" y="223.311">
                21
              </tspan>
            </text>
            <text
              x="620"
              y="213.311"
              textAnchor="middle"
              fontSize="16"
              fontWeight="800"
              opacity="0"
              stroke="#000000"
              strokeWidth="1px"
              dy=".3em"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="2s"
                repeatCount="1"
                fill="freeze"
              />
              {t("ServiceQuality.ServiceQualityPointsGraphIcon.text1")}
            </text>
          </g>
          <g id="Group 10">
            <circle
              id="Ellipse 1_8"
              cx="515.016"
              cy="40"
              r="40"
              fill="#45822D"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                begin="2s"
                dur="1s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="25_2"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0em"
            >
              <tspan x="501.305" y="49.3106">
                25
              </tspan>
            </text>
            <text
              x="650"
              y="40"
              textAnchor="middle"
              fontSize="16"
              fontWeight="800"
              opacity="0"
              stroke="#000000"
              strokeWidth="1px"
              dy=".3em"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="2s"
                repeatCount="1"
                fill="freeze"
              />
              {t("ServiceQuality.ServiceQualityPointsGraphIcon.text2")}
            </text>
          </g>
        </g>
      </svg>
    )
  );
};

export default React.memo(ServiceQualityPointsGraphIcon);
