import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextSection from "../../components/TextSection";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import InfoBox from "../../components/InfoBox";
import { ButtonTooltipIcon, LinkIcon } from "../../assets/icons";
import Tooltip from "../../components/Tooltip";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";
import ShowMore from "../../components/ShowMore";

const InvestmentInsurance = () => {
  const { t, ready } = useTranslation();

  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["investment-insurance"].areas}
            description={areas["investment-insurance"].description}
          />
          <TextSection
            customClasses={{ "mb-[29px] font line-height-h1-40": "h3" }}
            h3={t("InvestmentInsurance.TextSection1.title")}
            p4={t("InvestmentInsurance.TextSection1.paragraph")}
          />

          <InfoBox
            classes="my-16 height300-infoBox"
            table
            size={12}
            sizes={[2, 5, 5]}
          >
            <TextSection
              classes="pt-8 pr-4"
              customClasses={{ regular: "p1" }}
              p1={t("InvestmentInsurance.InfoBox1.TextSection1.text1")}
            />
            <TextSection
              customClasses={{
                "text-22px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              classes="px-4"
              h1={t("InvestmentInsurance.InfoBox1.TextSection2.text1")}
              p1={
                <>
                  <div>
                    {t("InvestmentInsurance.InfoBox1.TextSection2.text2")}
                  </div>
                  <Tooltip
                    classes="margin20-icon"
                    tooltipLink={""}
                    icon={<ButtonTooltipIcon width="19px" height="19px" />}
                  >
                    <TextSection
                      p5={t(
                        "InvestmentInsurance.InfoBox2.TextSection2.Tooltip1.TextSection1.text1"
                      )}
                    />
                  </Tooltip>
                </>
              }
            />
            <TextSection
              customClasses={{
                "text-22px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              classes="pl-4"
              h1={t("InvestmentInsurance.InfoBox1.TextSection3.text1")}
              p1={t("InvestmentInsurance.InfoBox1.TextSection3.text2")}
            />
          </InfoBox>

          <div className={"flex justify-end mt-[61px] mb-[115px]"}>
            <ShowMore
              iconPosition={"self-end pb-[7px]"}
              url={t("InvestmentInsurance.TextSection3.url")}
              title={t("InvestmentInsurance.TextSection3.text1")}
              width={432}
              height={86}
              target="_blank"
              icon={
                <a
                  href={t("InvestmentInsurance.TextSection3.url")}
                  target="_blank"
                >
                  <LinkIcon
                    classes="stroke-green hover:stroke-gray"
                    width="23px"
                    height="24px"
                  />
                </a>
              }
            />
          </div>

          <InfoBox
            classes="my-16 height273-infoBox"
            table
            size={12}
            sizes={[2, 5, 5]}
          >
            <TextSection
              classes="pt-2 pr-4"
              customClasses={{ regular: "p1" }}
              p1={t("InvestmentInsurance.InfoBox2.TextSection1.text1")}
            />
            <TextSection
              customClasses={{
                "text-22px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              classes="px-4"
              h1={t("InvestmentInsurance.InfoBox2.TextSection2.text1")}
              p1={
                <>
                  <div>
                    {t("InvestmentInsurance.InfoBox2.TextSection2.text2")}
                  </div>
                  <Tooltip
                    classes="margin50-icon"
                    tooltipLink={""}
                    icon={<ButtonTooltipIcon width="19px" height="19px" />}
                  >
                    <TextSection
                      p5={t(
                        "InvestmentInsurance.InfoBox2.TextSection2.Tooltip1.TextSection1.text1"
                      )}
                    />
                  </Tooltip>
                </>
              }
            />
            <TextSection
              customClasses={{
                "text-22px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              classes="pl-4"
              h1={t("InvestmentInsurance.InfoBox2.TextSection3.text1")}
              p1={t("InvestmentInsurance.InfoBox2.TextSection3.text2")}
            />
          </InfoBox>

          <div className={"flex justify-end mt-[67px] mb-[102px]"}>
            <ShowMore
              iconPosition={"self-end pb-[7px]"}
              url={t("InvestmentInsurance.TextSection4.url")}
              title={t("InvestmentInsurance.TextSection4.text1")}
              width={432}
              height={86}
              target="_blank"
              icon={
                <a
                  href={t("InvestmentInsurance.TextSection4.url")}
                  target="_blank"
                >
                  <LinkIcon
                    classes="stroke-green hover:stroke-gray"
                    width="23px"
                    height="24px"
                  />
                </a>
              }
            />
          </div>

          <TextSection
            customClasses={{ "mb-3": "h5", "mb-32": "p4" }}
            h5={t("InvestmentInsurance.TextSection2.caption")}
            p4={t("InvestmentInsurance.TextSection2.paragraph")}
          />
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

InvestmentInsurance.propTypes = {};

export default React.memo(InvestmentInsurance);
