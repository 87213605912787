import React from "react";
import PropTypes from "prop-types";

const GroupTooltipIcon3 = ({
  width = "79",
  height = "42",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 79 42`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_206_1389)">
      <path d="M19.7599 41.1201C23.4286 41.1201 26.4027 38.146 26.4027 34.4773C26.4027 30.8087 23.4286 27.8346 19.7599 27.8346C16.0912 27.8346 13.1172 30.8087 13.1172 34.4773C13.1172 38.146 16.0912 41.1201 19.7599 41.1201Z" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
      <path d="M63.1701 41.1201C66.8388 41.1201 69.8128 38.146 69.8128 34.4773C69.8128 30.8087 66.8388 27.8346 63.1701 27.8346C59.5014 27.8346 56.5273 30.8087 56.5273 34.4773C56.5273 38.146 59.5014 41.1201 63.1701 41.1201Z" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
      <path d="M14.7396 30.1242H0.289062V22.9134H11.8588L22.9648 8.13823L24.2285 3.55322H53.431V26.1189L50.2256 30.1242H24.7791" 
        stroke="white" 
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path d="M3.87109 22.9134L16.3045 6.06307V0.289795H20.8257L24.2283 3.5532" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
      <path d="M58.1503 30.1243H50.2266" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
      <path d="M69.6095 36.1062H75.7364L78.71 32.5936V30.1243H68.1836" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
      <path d="M26.1992 36.1062H56.7234" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
      <path d="M2.5625 30.1243L6.6664 36.1062H13.3207" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
      <path d="M64.9258 28.0723L65.0881 7.12964" 
        stroke="white" 
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path d="M78.7107 30.1242V18.1371L73.8359 7.12964H59.4375V28.9823" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
      <path d="M65.002 18.0792H78.7106" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_206_1389">
      <rect width="79" height="41.4099" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
};

GroupTooltipIcon3.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(GroupTooltipIcon3);

