import { useTranslation } from "react-i18next";
import {
  ALogo1,
  ALogo2,
  ALogo3,
  ALogo4,
  ALogo5,
  ALogo6,
} from "../assets/images";
import Tooltip from "./Tooltip";
import { LineIcon } from "../assets/icons";
import React, { useRef, useState } from "react";

const AwardLogos = () => {
  const { t } = useTranslation();
  const [selectedAward, setSelectedAward] = useState(null);
  const modalRef = useRef();
  const openModal = (award) => {
    setSelectedAward(award);
    modalRef.current.openModal();
  };

  const logos = [
    { name: "OscarBilancio", asset: ALogo1, height: "77px" },
    { name: "Abi", asset: ALogo2, height: "78px" },
    { name: "Aipsa", asset: ALogo3, height: "67px" },
    { name: "BrandonHall", asset: ALogo4, height: "55px" },
    { name: "Europe500", asset: ALogo5, height: "39px" },
    { name: "GreenBuilding", asset: ALogo6, height: "19px" },
  ];

  return (
    <>
      <Tooltip
        classes="ml-0"
        hideButton
        ref={modalRef}
        tooltipLink={t(`Home.Partners.${selectedAward}.Tooltip.url`)}
      >
        {selectedAward && (
          <>
            <p className={`text-base text-white mb-5`}>
              {t(`Home.Partners.${selectedAward}.Tooltip.text1`)}
            </p>
            <LineIcon width="79px" height="1px" />
            <p className={`text-xs mt-5 text-white`}>
              {t(`Home.Partners.${selectedAward}.Tooltip.text2`)}
            </p>
          </>
        )}
      </Tooltip>
      <div className="flex columns-5 overflow-x-scroll md:overflow-x-hidden md:flex-wrap justify-start  items-center gap-8 gap-y-12 my-8 md:my-16">
        {logos.map((logo, i) => {
          return (
            <img
              key={logo.name}
              className="w-auto h-full transition-all grayscale hover:grayscale-0 cursor-pointer"
              src={logo.asset}
              alt={logo.name}
              title={logo.name}
              height={logo.height}
              width={"100%"}
              onClick={() => openModal(logo.name)}
            ></img>
          );
        })}
      </div>
    </>
  );
};

export default AwardLogos;
