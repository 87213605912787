import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  MaterialityMatrix2020Icon,
  MaterialityMatrix2021Icon,
} from "../assets/icons";

const MaterialityMatrix = () => {
  const [selected, setSelected] = useState(2021);
  const { t, ready } = useTranslation();
  const { lang } = useParams();
  return (
    !!ready && (
      <div className="mt-20 w-full">
        <div
          className="mx-auto matrix"
          style={{
            marginLeft: "36px",
            overflowX: "scroll",
            width: "calc(100% - 36px)",
          }}
        >
          {selected === 2020 && (
            <MaterialityMatrix2020Icon
              width="1078px"
              height="644px"
              classes="origin-bottom-left scale-75 sm:scale-100 sm:origin-[unset]"
              lang={lang}
            />
          )}
          {selected === 2021 && (
            <MaterialityMatrix2021Icon
              width="1078px"
              height="644px"
              classes="origin-bottom-left scale-75 sm:scale-100 sm:origin-[unset]"
              lang={lang}
            />
          )}
        </div>

        <div
          className="mt-4 flex items-start"
          style={{ marginLeft: "36px", maxWidth: "1078px" }}
        >
          <div className="font line-height-h1-40 text-base grow my-0 ml-2 font-bold uppercase text-green">
            {t("Home.MaterialityMatrix.XLabel")}
          </div>
          <div
            className="font line-height-h1-40 text-base font-bold uppercase text-green"
            style={{
              position: "absolute",
              transformOrigin: "0 0",
              transform: "rotate(-90deg) translate(25px, -35px)",
            }}
          >
            {t("Home.MaterialityMatrix.YLabel")}
          </div>
          <div className="h-full hidden sm:block">
            <div className="flex items-center gap-3">
              <button
                type="button"
                style={{ minWidth: 145, fontSize: "12px", lineHeight: "16px" }}
                className={`font line-height-16  bg-white py-3 px-5 border border-2 border-green text-green font-bold hover:opacity-80 ${
                  selected === 2021 ? "opacity-50" : ""
                }`}
                onClick={() => setSelected(2020)}
              >
                {t("Home.MaterialityMatrix.Button2020Label")}
              </button>
              <button
                type="button"
                style={{ minWidth: 145, fontSize: "12px", lineHeight: "16px" }}
                className={`font line-height-16 text-size12 bg-white py-3 px-5 border border-2 border-green text-green font-bold hover:opacity-80 ${
                  selected === 2020 ? "opacity-50" : ""
                }`}
                onClick={() => setSelected(2021)}
              >
                {t("Home.MaterialityMatrix.Button2021Label")}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default React.memo(MaterialityMatrix);
