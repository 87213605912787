import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextSection from "../../components/TextSection";
import {
  DiversityInclusionPointsGraphIcon,
  ShowMoreIcon,
  GroupPeopleIcon,
} from "../../assets/icons";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import InfoBox from "../../components/InfoBox";
import ShowMore from "../../components/ShowMore";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";

const DiversityInclusion = () => {
  const { t, ready } = useTranslation();

  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["diversity-inclusion"].areas}
            description={areas["diversity-inclusion"].description}
          />
          <TextSection
            customClasses={{ "mb-[31px] font line-height-h1-40": "h3" }}            
            h3={t("DiversityInclusion.TextSection1.title")}
            p4={t("DiversityInclusion.TextSection1.paragraph")}
          />

          <TextSection
            classes="pt-16"
            h5={t("DiversityInclusion.TextSection2.caption")}
          />

          <InfoBox
            classes="pt-8 mb-20"
            height="335px"
            table
            size={3}
            sizes={[1, 2]}
          >
            <div className="grid grid-cols-1 gap-3 pt-4">
              <TextSection
                customClasses={{ "mb-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
                classes="pr-4"
                h1={t("DiversityInclusion.InfoBox1.TextSection1.text1")}
                p1={t("DiversityInclusion.InfoBox1.TextSection1.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-3 pt-4">
              <TextSection
                customClasses={{ "mt-0-imp mb-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
                classes="px-4 pt-4"
                h1={t("DiversityInclusion.InfoBox1.TextSection2.text1")}
                p1={t("DiversityInclusion.InfoBox1.TextSection2.text2")}
              />
            </div>
            {/* <TextSection
              classes="pl-4 pt-4"
            /> */}
          </InfoBox>

          <TextSection
            classes="pt-4"
            h5={t("DiversityInclusion.TextSection3.caption")}
          />
          <InfoBox classes="pt-8" height="335px" table>
            <div className="grid grid-cols-1 gap-3 pt-4">
              <TextSection
                customClasses={{ "mb-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
                classes="pr-4"
                h1={t("DiversityInclusion.InfoBox2.TextSection1.text1")}
                p1={t("DiversityInclusion.InfoBox2.TextSection1.text2")}
              />
              <TextSection
                classes="pr-4"
                p1={t("DiversityInclusion.InfoBox2.TextSection2.text1")}
              />
            </div>
            <TextSection
              customClasses={{ "mb-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              classes="px-4 pt-4"
              h1={t("DiversityInclusion.InfoBox2.TextSection3.text1")}
              p1={t("DiversityInclusion.InfoBox2.TextSection3.text2")}
            />
            <TextSection
              customClasses={{ "mb-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              classes="pl-4 pt-4"
              h1={t("DiversityInclusion.InfoBox2.TextSection4.text1")}
              p1={t("DiversityInclusion.InfoBox2.TextSection4.text2")}
            />
          </InfoBox>

          <TextSection
            customClasses={{ "mb-8": "h5" }}
            classes="pt-16"
            h5={t("DiversityInclusion.TextSection4.caption")}
            p4={t("DiversityInclusion.TextSection4.paragraph")}
          />
          <TextSection
            classes="pt-16"
            h6={t("DiversityInclusion.TextSection5.caption")}
          />

          <div className="mt-5 overflow-x-auto">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <div
                className="w-[5px] sm:w-[62px]"
                style={{                  
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 21,
                }}
              >
                <p
                  style={{
                    fontWeight: 300,
                    fontSize: "22px",
                    lineHeight: "30px",
                    color: "#2B8804",
                  }}
                >
                  {t("DiversityInclusion.ArtWork1.text1")}
                </p>
                <h1
                  className={
                    "font line-height-62-imp font.light-imp text-green text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl"
                  }
                >
                  {t("DiversityInclusion.ArtWork1.text2")}
                </h1>
              </div>              
              <div className="scale-[0.4] sm:scale-[0.6] md:scale-[0.6] lg:scale-[1]">
                <GroupPeopleIcon width="278px" height="265px" />
              </div>
              <div
                className="w-[5px] sm:w-[62px]"
                style={{                
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginBottom: 21,
                }}
              >
                <p
                  style={{
                    fontWeight: 300,
                    fontSize: "22px",
                    lineHeight: "30px",
                    color: "#2B8804",
                  }}
                >
                  {t("DiversityInclusion.ArtWork1.text3")}
                </p>
                <h1
                  className={
                    "font line-height-62-imp font.light-imp text-green text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl"
                  }
                >
                  {t("DiversityInclusion.ArtWork1.text4")}
                </h1>
              </div>
            </div>
            <div
              style={{ height: 0, width: "100%", border: "2px solid #C4C4C4" }}
            />
            <p
              style={{
                fontWeight: 300,
                fontSize: "22px",
                lineHeight: "30px",
                color: "#2B8804",
                marginTop: 17,
              }}
            >
              {t("DiversityInclusion.ArtWork1.text5")}
            </p>
          </div>

          <TextSection
            classes="pt-16 mb-2.5"
            h6={t("DiversityInclusion.TextSection6.caption")}
          />

          <div className="mt-28 mb-24 scale-90 lg:scale-[1]">
            <DiversityInclusionPointsGraphIcon classes="mx-auto w-full h-full lg:w-auto" />
          </div>

          {t("DiversityInclusion.TextSection7.text1") && (
            <div className={"flex justify-end mt-[28px] mb-[150px]"}>
              <ShowMore
                iconPosition={"self-end pb-[7px]"}
                url={t("DiversityInclusion.TextSection7.url")}
                title={t("DiversityInclusion.TextSection7.text1")}
                width={432}
                height={121}
                target="_blank"
                icon={
                  <a
                    href={t("DiversityInclusion.TextSection7.url")}
                    target="_blank"
                  >
                    <ShowMoreIcon
                      width="21px"
                      height="15px"
                      classes="stroke-green hover:stroke-gray"
                    />
                  </a>
                }
              />
            </div>
          )}
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

DiversityInclusion.propTypes = {};

export default React.memo(DiversityInclusion);
