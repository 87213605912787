import React from "react";
import PropTypes from "prop-types";

const GroupTooltipIcon7 = ({
  width = "46",
  height = "67",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 46 67`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_206_1438)">
      <path d="M0.230469 40.6387C0.230469 40.6387 22.5239 41.2998 23.2914 60.8886C23.2914 60.8886 21.7056 43.1815 45.534 41.1102C45.534 41.1102 48.2664 62.8674 23.7768 65.9742C23.7768 65.9742 1.26608 66.7278 0.230469 40.6387V40.6387Z" 
        stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.01953 37.9063V32.9132L15.9587 24.9103V41.7668" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.4023 38.9419V19.256L23.8746 11.3456V47.421" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M30.8418 41.7668V0.231201L44.6885 8.33119V37.9988" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_206_1438">
      <rect width="45.8583" height="66.21" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
};

GroupTooltipIcon7.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(GroupTooltipIcon7);

