import React from "react";
import PropTypes from "prop-types";

const ClimateChangeIcon = ({
  width = "33",
  height = "33",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 33 33`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_358_741)">
        <path
          d="M31.798 16.7549C31.798 25.344 24.815 32.3092 16.204 32.3092C-4.48899 31.4922 -4.47875 2.01754 16.204 1.2005C24.815 1.2005 31.798 8.16574 31.798 16.7549Z"

          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 4"
        />
        <path
          d="M16.204 27.264C19.6196 27.264 22.3884 22.5589 22.3884 16.7549C22.3884 10.9508 19.6196 6.24573 16.204 6.24573C12.7885 6.24573 10.0197 10.9508 10.0197 16.7549C10.0197 22.5589 12.7885 27.264 16.204 27.264Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.204 27.3661C22.0794 27.3661 26.8424 22.6153 26.8424 16.7549C26.8424 10.8944 22.0794 6.14359 16.204 6.14359C10.3286 6.14359 5.5657 10.8944 5.5657 16.7549C5.5657 22.6153 10.3286 27.3661 16.204 27.3661Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.12546 23.761C10.0811 22.2801 12.9787 21.3405 16.2143 21.3405C19.4498 21.3405 22.2655 22.2597 24.2109 23.6997"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2314 9.79984C22.286 11.2603 19.4088 12.1795 16.204 12.1795C12.9992 12.1795 10.122 11.2603 8.17664 9.79984"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5657 16.7549H26.8424"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.204 6.14359V27.264"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

ClimateChangeIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(ClimateChangeIcon);
