import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextSection from "../../components/TextSection";
import JobProtectionIcon from "../../assets/icons/JobProtectionIcon";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import InfoBox from "../../components/InfoBox";
import BarChart from "../../components/BarCharts/BarChart";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";

const JobProtection = () => {
  const { t, ready } = useTranslation();

  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["job-protection"].areas}
            description={areas["job-protection"].description}
          />
          <TextSection
            customClasses={{ "mb-[24px] font line-height-h1-40": "h3" }}
            h3={t("JobProtection.TextSection1.title")}
            p4={t("JobProtection.TextSection1.caption")}
          />

          <TextSection
            classes="mt-16 mb-16"
            h5={t("JobProtection.TextSection1.subCaption")}
          />

          <BarChart
            classes="mt-3.5 mb-20"
            right={({ classes }) => (
              <div
                className={`d-flex align-end ${classes || ""}`}
                style={{ width: 150 }}
              >
                <p className="text bold text-size22 text-black pl-10">
                  {t("JobProtection.BarChart1.text1")}
                </p>
              </div>
            )}
            options={{
              width: "100%",
              height: "350px",
              columns: {
                height: "251px",
                justifyContent: "center",
              },
              labels: {
                justifyContent: "center",
              },
            }}
            data={[
              {                
                topLabel: t("JobProtection.BarChart1.Values.val1"),                
                label: "2020",
                height: 152,
                color: "#29851B",
                style: { textAlign: "right" },
              },
              {
                topLabel: t("JobProtection.BarChart1.Values.val2"),                
                label: "2021",
                height: 212,
                color: "#2B7021",
                style: { textAlign: "right" },
              },
            ]}
          />

          <InfoBox
            classes="mt-1 mb-20"
            height="397px"
            table
            size={3}
            sizes={[1, 2]}
          >
            <div className="grid grid-cols-1 gap-3 mt-7">
              <TextSection
                customClasses={{ "my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1", "mb-5": "p1" }}
                classes="pr-4"
                h1={t("JobProtection.InfoBox1.TextSection1.text1")}
                p1={t("JobProtection.InfoBox1.TextSection1.text2")}
              />
              <TextSection
                customClasses={{ "my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
                classes="pr-4"
                h1={t("JobProtection.InfoBox1.TextSection2.text1")}
                p1={t("JobProtection.InfoBox1.TextSection2.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-3 mt-7">
              <TextSection
                customClasses={{ "marginTop0Imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
                classes="pl-4"
                h1={t("JobProtection.InfoBox1.TextSection3.text1")}
                p1={t("JobProtection.InfoBox1.TextSection3.text2")}
              />
            </div>
          </InfoBox>
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

JobProtection.propTypes = {};

export default React.memo(JobProtection);
