import React from "react";
import PropTypes from "prop-types";

const JobProtectionIcon = ({
  width = "25",
  height = "30",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 25 30`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_358_656)">
        <path
          d="M12.7015 28.6638C11.1162 28.6638 9.55139 28.077 8.05868 26.9034C6.57626 25.7504 5.29973 24.1754 4.43499 22.4974C4.17762 22.0032 3.94085 21.4679 3.75555 20.912C3.68349 20.9223 3.61142 20.9223 3.54966 20.9223C2.9011 20.9223 2.34519 20.5517 1.954 19.9752C1.62457 19.4708 1.40839 18.8016 1.40839 18.0501C1.40839 17.2986 1.62457 16.6295 1.954 16.125C2.03635 16.0118 2.12901 15.8882 2.24225 15.775C1.62457 15.1985 1.28485 14.5397 1.28485 13.8293V12.1513C1.28485 5.89222 6.44243 0.734642 12.7015 0.734642C18.9606 0.734642 24.1285 5.89222 24.1285 12.1513V13.8293C24.1285 14.55 23.7888 15.1985 23.1711 15.775C23.274 15.8882 23.3667 16.0118 23.4594 16.125C23.7888 16.6295 23.9844 17.3192 23.9844 18.0501C23.9844 18.781 23.7785 19.4708 23.4594 19.9752H23.4491C23.0785 20.5517 22.5122 20.9223 21.8637 20.9223C21.7916 20.9223 21.7299 20.9223 21.6578 20.912C21.4622 21.4679 21.2357 22.0032 20.9784 22.4974C20.1136 24.1754 18.8268 25.7504 17.3547 26.9034C15.862 28.077 14.2972 28.6638 12.7015 28.6638V28.6638Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.24213 15.8059C6.96733 18.8325 18.4046 18.8428 23.1298 15.8059"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.88196 13.7161C1.26428 14.2102 1.28487 14.5397 1.28487 13.819V12.141C1.28487 5.89222 6.44245 0.734642 12.7015 0.734642C18.9606 0.734642 24.1285 5.89222 24.1285 12.1513V13.8293C24.1285 14.55 24.0462 14.2205 23.4285 13.7264H1.88196V13.7161Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6192 0.837585V8.87764"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.74988 2.11411V6.65402"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.4885 2.11411V6.65402"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

JobProtectionIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(JobProtectionIcon);
