import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextSection from "../../components/TextSection";
import Covid19Icon from "../../assets/icons/Covid19Icon";
import ContainerWrapper from "../../components/ContainerWrapper";
import InfoBox from "../../components/InfoBox";
import Menu from "../../components/Menu";
import Tooltip from "../../components/Tooltip";
import { ButtonTooltipIcon } from "../../assets/icons";

const Covid19 = () => {
  const { t, ready } = useTranslation();

  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <TextSection
            customClasses={{ "flex items-start": "h3" }}
            classes="pt-16"
            h3={
              <>
                <span className="mb-[109px] text-size40 font line-height-h1-40">
                  {t("Covid19.TextSection1.title")}
                  <Tooltip
                    tag="span"
                    classes="ml-4"
                    tooltipLink={""}
                    icon={<ButtonTooltipIcon width="19px" height="19px" />}
                  >
                    <TextSection
                      p5={t("Covid19.TextSection1.Tooltip1.TextSection1.text1")}
                    />
                  </Tooltip>
                </span>
              </>
            }
            h5={t("Covid19.TextSection1.caption")}
          />

          <TextSection classes="pt-24" p1={t("Covid19.TextSection5.text1")} />
          <TextSection
            classes="pt-0"
            customClasses={{
              "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
              textBlack: "p4",
            }}
            h1={t("Covid19.TextSection6.text1")}
            p4={t("Covid19.TextSection6.text2")}
          />

          <InfoBox classes="pt-16 height300-infoBox" table>
            <div className="grid grid-cols-1 gap-0 md:mr-14">
              <TextSection
                classes="pb-0"
                customClasses={{ "my-0-imp": "p1" }}
                p1={t("Covid19.InfoBox1.Column1.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox1.Column1.TextSection2.text1")}
                p4={t("Covid19.InfoBox1.Column1.TextSection2.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-0 md:ml-14">
              <TextSection
                classes="pb-0"
                customClasses={{ "my-0-imp": "p1" }}
                p1={t("Covid19.InfoBox1.Column2.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox1.Column2.TextSection2.text1")}
                p4={t("Covid19.InfoBox1.Column2.TextSection2.text2")}
              />
            </div>
          </InfoBox>

          <InfoBox classes="pt-16 height300-infoBox" table>
            <div className="grid grid-cols-1 gap-0 md:mr-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox2.Column1.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox2.Column1.TextSection2.text1")}
                p4={t("Covid19.InfoBox2.Column1.TextSection2.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-0 md:ml-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox2.Column2.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox2.Column2.TextSection2.text1")}
                p4={t("Covid19.InfoBox2.Column2.TextSection2.text2")}
              />
            </div>
          </InfoBox>

          <InfoBox classes="pt-16 height282-infoBox" table>
            <div className="grid grid-cols-1 gap-0 md:mr-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox3.Column1.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox3.Column1.TextSection2.text1")}
                p4={t("Covid19.InfoBox3.Column1.TextSection2.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-0 md:ml-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox3.Column2.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox3.Column2.TextSection2.text1")}
                p4={t("Covid19.InfoBox3.Column2.TextSection2.text2")}
              />
            </div>
          </InfoBox>

          <TextSection classes="pt-16" h5={t("Covid19.TextSection2.caption")} />

          <TextSection classes="pt-10" p1={t("Covid19.TextSection7.text1")} />
          <TextSection
            classes="pt-0"
            customClasses={{
              "font line-height-82-imp mt-0-imp flex items-center text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
              textBlack: "p4",
            }}
            h1={
              <>
                <div>{t("Covid19.TextSection8.text1")}</div>
                <Tooltip
                  classes="ml-2"
                  tooltipLink={""}
                  icon={<ButtonTooltipIcon width="19px" height="19px" />}
                >
                  <TextSection
                    p5={t("Covid19.TextSection8.Tooltip1.TextSection1.text1")}
                  />
                </Tooltip>
              </>
            }
            p4={t("Covid19.TextSection8.text2")}
          />

          <InfoBox classes="pt-16 height292-infoBox" table>
            <div className="grid grid-cols-1 gap-0 md:mr-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox4.Column1.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox4.Column1.TextSection2.text1")}
                p4={t("Covid19.InfoBox4.Column1.TextSection2.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-0 md:ml-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox4.Column2.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp flex items-center text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                }}
                h1={
                  <>
                    <div>
                      {t("Covid19.InfoBox4.Column2.TextSection2.text1")}
                    </div>
                    <Tooltip
                      classes="ml-2"
                      tooltipLink={""}
                      icon={<ButtonTooltipIcon width="19px" height="19px" />}
                    >
                      <TextSection
                        p5={t(
                          "Covid19.InfoBox5.Column2.TextSection2.Tooltip1.TextSection1.text1"
                        )}
                      />
                    </Tooltip>
                  </>
                }
              />
            </div>
          </InfoBox>

          <InfoBox classes="pt-16 height300-infoBox" table>
            <div className="grid grid-cols-1 gap-0 md:mr-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox5.Column1.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox5.Column1.TextSection2.text1")}
                p4={t("Covid19.InfoBox5.Column1.TextSection2.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-0 md:ml-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox5.Column2.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp flex items-center text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={
                  <>
                    <div>
                      {t("Covid19.InfoBox5.Column2.TextSection2.text1")}
                    </div>
                    <Tooltip
                      classes="ml-2"
                      tooltipLink={""}
                      icon={<ButtonTooltipIcon width="19px" height="19px" />}
                    >
                      <TextSection
                        p5={t(
                          "Covid19.InfoBox5.Column2.TextSection2.Tooltip1.TextSection1.text1"
                        )}
                      />
                    </Tooltip>
                  </>
                }
                p4={t("Covid19.InfoBox5.Column2.TextSection2.text2")}
              />
            </div>
          </InfoBox>

          <InfoBox classes="pt-16 height379-infoBox" table>
            <div className="grid grid-cols-1 gap-0 md:mr-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox6.Column1.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp mt-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox6.Column1.TextSection2.text1")}
                p4={t("Covid19.InfoBox6.Column1.TextSection2.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-0 md:ml-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox6.Column2.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp mt-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox6.Column2.TextSection2.text1")}
                p4={t("Covid19.InfoBox6.Column2.TextSection2.text2")}
              />
            </div>
          </InfoBox>

          <TextSection classes="pt-16" h5={t("Covid19.TextSection3.text1")} />

          <TextSection classes="pt-16" p1={t("Covid19.TextSection9.text1")} />
          <TextSection
            classes="pt-0"
            customClasses={{
              "font line-height-82-imp mt-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
              textBlack: "p4",
            }}
            h1={t("Covid19.TextSection10.text1")}
            p4={t("Covid19.TextSection10.text2")}
          />

          <InfoBox classes="pt-16 height300-infoBox" table>
            <div className="grid grid-cols-1 gap-0 md:mr-14">
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox7.Column1.TextSection1.text1")}
                p4={t("Covid19.InfoBox7.Column1.TextSection1.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-0 md:ml-14">
              <TextSection
                classes="pb-0"
                p1={t("Covid19.InfoBox7.Column2.TextSection1.text1")}
              />
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox7.Column2.TextSection2.text1")}
                p4={t("Covid19.InfoBox7.Column2.TextSection2.text2")}
              />
            </div>
          </InfoBox>

          <InfoBox classes="pt-16 height300-infoBox" table>
            <div className="grid grid-cols-1 gap-0 md:mr-14">
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-25px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox8.Column1.TextSection1.text1")}
                p4={t("Covid19.InfoBox8.Column1.TextSection1.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-0 md:ml-14">
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-25px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox8.Column2.TextSection1.text1")}
                p4={t("Covid19.InfoBox8.Column2.TextSection1.text2")}
              />
            </div>
          </InfoBox>

          <TextSection
            classes="pt-16"
            h5={t("Covid19.TextSection11.text1")}
            p4={t("Covid19.TextSection11.text2")}
          />

          <InfoBox classes="pt-16 height235-infoBox" table>
            <div className="grid grid-cols-1 gap-0 md:mr-14">
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox9.Column1.TextSection1.text1")}
                p4={t("Covid19.InfoBox9.Column1.TextSection1.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-0 md:ml-14">
              <TextSection
                classes="pt-0"
                customClasses={{
                  "font line-height-82-imp my-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1",
                  textBlack: "p4",
                }}
                h1={t("Covid19.InfoBox9.Column2.TextSection1.text1")}
                p4={t("Covid19.InfoBox9.Column2.TextSection1.text2")}
              />
            </div>
          </InfoBox>

          <TextSection
            classes="pt-16 mb-20"
            customClasses={{ "font line-height-82-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1", textBlack: "p4" }}
            h1={t("Covid19.TextSection4.text2")}
            p4={t("Covid19.TextSection4.text3")}
          />
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

Covid19.propTypes = {};

export default React.memo(Covid19);
