import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { appendScript } from "./../utils";
import configuration from "./../configuration.json";

const Simulator = () => {
  const { lang } = useParams();
  const language = lang.toUpperCase();
  const otherLang = lang === "en" ? "it" : "en";
  const otherLanguage = otherLang.toUpperCase();

  useEffect(() => {
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        appendScript({ src: "/autoresizer.js" });
        return () => {
          if (window.autoResizer) {
            window.autoResizer.unregister();
          }
        };

      case configuration.menu.positioning.algorithms.Infer:
        appendScript({ src: "iframeResizer.min.js" });
        appendScript({ innerText: `iFrameResize({ log: true }, '#DCNF');` });

      default:
        break;
    }
  }, []);

  return (
    <Fragment>
      <head>
        <meta charSet="utf-8" content="text/html" httpEquiv="content-type" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />

        <script
          type="text/javascript"
          src="https://a1.adform.net/Serving/TrackPoint/?pm=1397378&amp;ADFPageName=Gruppo%20Intesa%20Sanpaolo&amp;ADFdivider=%7C&amp;ord=426732081184&amp;Set1=it-IT%7Cit-IT%7C2560x1440%7C24&amp;ADFtpmode=2&amp;itm=eyJic3oiOjF9&amp;loc=https%3A%2F%2Fgroup.intesasanpaolo.com%2Fit%2F"
        ></script>
        <script
          type="text/javascript"
          async=""
          charSet="utf-8"
          id="utag_3"
          src="//a1.adform.net/serving/scripts/trackpoint/async/"
        ></script>
        <script
          src="https://connect.facebook.net/en_US/sdk.js?hash=23773b809d3c02630aa5febf8bbd2890"
          async=""
          crossOrigin="anonymous"
        ></script>
        <script
          src="//tags.tiqcdn.com/utag/intesasanpaolo/sitoistituzionale/prod/utag.js?caburst=1652471201250"
          type="text/javascript"
          async=""
        ></script>
        <script src="//d172q3toj7w1md.cloudfront.net/common/discoveryWidget.v3.min.js"></script>

        <link
          rel="stylesheet"
          href="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all.css"
          type="text/css"
        />

        <link
          rel="stylesheet"
          href="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-image-zoom.css"
          type="text/css"
        />

        <title>Gruppo Intesa Sanpaolo</title>

        <meta
          name="description"
          content="Una banca al servizio dell'economia reale. Scopri di più su group.intesasanpaolo.com"
        />
        <meta name="googlebot" content="index,follow" />
        <meta name="robots" content="index,follow" />

        <meta name="ADFORM" content="SI" />

        <meta
          name="google-site-verification"
          content="VgfMaqW3wlr5l3lYldYlG0U9nkolzmb1x3jcJeG6b18"
        />

        <meta property="og:title" content="Gruppo Intesa Sanpaolo" />
        <meta
          property="og:description"
          content="Una banca al servizio dell'economia reale. Scopri di più su group.intesasanpaolo.com"
        />
        <meta
          property="og:image"
          content="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/la-forza-del-gruppo/banner/1920x1080_la_forza_del_gruppo.jpg"
        />
        <meta
          property="og:url"
          content="https://group.intesasanpaolo.com/it/"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Intesa Sanpaolo Group" />

        <meta name="twitter:title" content="Gruppo Intesa Sanpaolo" />
        <meta
          name="twitter:description"
          content="Una banca al servizio dell'economia reale. Scopri di più su group.intesasanpaolo.com"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <link
          rel="alternate"
          href="https://group.intesasanpaolo.com/it/"
          hrefLang="it"
        />

        <link
          rel="alternate"
          href="https://group.intesasanpaolo.com/en/"
          hrefLang="en"
        />

        <link rel="canonical" href="https://group.intesasanpaolo.com/it/" />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
        "@context": "http://schema.org",
        "@type": "ItemList",
        "itemListElement": [
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/",
            "name": "Home page",
            "position": "1"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/chi-siamo",
            "name": "Chi siamo",
            "position": "2"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/investor-relations",
            "name": "Investor Relations",
            "position": "3"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/sezione-editoriale/eventi-progetti",
            "name": "Eventi e Progetti",
            "position": "4"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/careers",
            "name": "Lavora con noi",
            "position": "5"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/sala-stampa",
            "name": "Newsroom",
            "position": "6"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/sostenibilita",
            "name": "Sostenibilità",
            "position": "7"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/sociale",
            "name": "Sociale",
            "position": "8"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/research",
            "name": "Studi e Ricerche",
            "position": "9"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/governance",
            "name": "Governance",
            "position": "10"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/sezione-editoriale/impegno",
            "name": "L'impegno",
            "position": "11"
          },
          {
            "@type": "SiteNavigationElement",
            "url": "https://group.intesasanpaolo.com/it/sezione-editoriale/forza-del-gruppo",
            "name": "La forza del gruppo",
            "position": "12"
          }
        ]
      }`,
          }}
        ></script>

        <link
          rel="icon"
          type="image/vnd.microsoft.icon"
          href="https://group.intesasanpaolo.com/etc/designs/portalgroup/favicon.ico"
        />
        <link
          rel="shortcut icon"
          type="image/vnd.microsoft.icon"
          href="https://group.intesasanpaolo.com/etc/designs/portalgroup/favicon.ico"
        />

        <script
          id="pageJson"
          dangerouslySetInnerHTML={{
            __html: `window.pageJson = {
        lang: "it",
        pageId: "7e97c7d7-71d0-4efb-989a-f1b98a1a8e7d",
        centroNotifiche: 0,
        subscribable: 0,
        recaptchaKey: "6LeeSrgUAAAAAM_q-R0zxc6yE-LDCTAQSmteFdA5",
        labelCentroNotifiche: 0,
      };`,
          }}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.paths = {
        centroNotificheAttivazioneCanale:
          "https://group.intesasanpaolo.com/it/centro-notifiche/attivazione-canale",
        centroNotificheDettaglioSottoscrizione:
          "https://group.intesasanpaolo.com/it/centro-notifiche",
        centroNotificheModificaProfilo:
          "https://group.intesasanpaolo.com/it/centro-notifiche/modifica-profilo",
        login: "https://group.intesasanpaolo.com/it/centro-notifiche/accedi",
        recuperaPassword:
          "https://group.intesasanpaolo.com/it/centro-notifiche/recupero-password",
        redirect:
          "https://group.intesasanpaolo.com/it/centro-notifiche/accedi/redirezione",
        searchPagDocAutocompleteURL:
          "https://group.intesasanpaolo.com/api/sdg/sidg0/gestoreutenti/v1/search",
        searchPagDocAutocompleteMethod: "POST",
        searchPagDocURL:
          "https://group.intesasanpaolo.com/api/sdg/sidg0/gestoreutenti/v1/search",
        searchPagDocMethod: "POST",
        searchPagDocResults:
          "https://group.intesasanpaolo.com/it/risultati-di-ricerca-gcs",
        searchComStaURL:
          "https://group.intesasanpaolo.com/api/sdg/sidg0/gestoreutenti/v1/search",
        searchComStaMethod: "POST",
        searchGCSSearchURL:
          "https://group.intesasanpaolo.com/api/sdg/sidg0/gestoreutenti/v1/search/search",
        searchGCSSuggestionsURL:
          "https://group.intesasanpaolo.com/api/sdg/sidg0/gestoreutenti/v1/search/suggest",
        searchGCSSearchApplicationId:
          "searchapplications/f2077830617d9541b802f712ec3471de",
        searchGCSSearchDataSource:
          "datasources/f2077830617d95410d97548abd7211a5",
        searchPagDocResultsGCS:
          "https://group.intesasanpaolo.com/it/risultati-di-ricerca-gcs",
        assembreaAzionisti:
          "https://group.intesasanpaolo.com/api/sdg/sidg0/richiedibilanci/v1/richiestaInformazioni",
      };`,
          }}
        ></script>

        <script
          dangerouslySetInnerHTML={{
            __html: `window.messagesJson = {
        "recuperaCodice.200.title": "Richiesta codice",
        "recuperaCodice.200.body": "Codice inviato con successo",
        "recuperaCodice.200.button": "Vai a Recupero Password",
        "recuperaCodice.401.title": "Richiesta codice",
        "recuperaCodice.401.body": "Username non trovato",
        "recuperaCodice.401.button": "Riprovare",
        "recuperaCodice.404.title": "Richiesta codice",
        "recuperaCodice.404.body": "Username non trovato",
        "recuperaCodice.404.button": "Riprovare",
        "recuperaCodice.500.title": "Errore interno del server",
        "recuperaCodice.500.body": "Si prega di riprovare pi\u00f9 tardi",
        "recuperaCodice.500.button": "OK",
        "modificaPassword.200.title": "Recupero Password",
        "modificaPassword.200.body": "Password recuperata con successo",
        "modificaPassword.200.button": "Vai alla login",
        "modificaPassword.401.title": "Recupero Password",
        "modificaPassword.401.body":
          "Controllare la validit\u00E0 dei dati inseriti",
        "modificaPassword.401.button": "Riprovare",
        "modificaPassword.500.title": "Errore interno del server",
        "modificaPassword.500.body": "Si prega di riprovare pi\u00f9 tardi",
        "modificaPassword.500.button": "OK",
        "richiediBilanci.200.title": "Richiesta bilanci",
        "richiediBilanci.200.body": "Email inviata correttamente",
        "richiediBilanci.200.button": "OK",
        "richiediBilanci.absentbalance.title": "Errore",
        "richiediBilanci.absentbalance.body": "Selezionare almeno un bilancio.",
        "richiediBilanci.absentbalance.button": "OK",
        "richiediBilanci.400.title": "Dati incompleti",
        "richiediBilanci.400.body": "Compilare tutti i campi obbligatori",
        "richiediBilanci.400.button": "OK",
        "richiediBilanci.500.title": "Errore interno del server",
        "richiediBilanci.500.body": "Si prega di riprovare pi\u00f9 tardi",
        "richiediBilanci.500.button": "OK",
        "sh.submit.200.title": "Inoltro richiesta",
        "sh.submit.200.body": "Richiesta inoltrata con successo",
        "sh.submit.200.button": "OK",
        "sh.modify.200.title": "Modifica richiesta",
        "sh.modify.200.body": "Richiesta modificata con successo",
        "sh.modify.200.button": "OK",
        "gestoreUtenti.crea.200.title": "Creazione utente",
        "gestoreUtenti.crea.200.body": "Utente creato con successo.",
        "gestoreUtenti.crea.200.button": "Vai alla Login",
        "gestoreUtenti.crea.400.title": "Dati incompleti",
        "gestoreUtenti.crea.400.body":
          "Uno dei campi obbligatori non \u00E8 stato compilato oppure Password e Conferma Password non combaciano",
        "gestoreUtenti.crea.400.button": "OK",
        "gestoreUtenti.crea.500.title": "Errore interno del server",
        "gestoreUtenti.crea.500.body": "Si prega di riprovare pi\u00f9 tardi",
        "gestoreUtenti.crea.500.button": "OK",
        "gestoreUtenti.modifica.200.title": "Modifica utente",
        "gestoreUtenti.modifica.200.body": "Utente modificato con successo",
        "gestoreUtenti.modifica.200.button": "OK",
        "gestoreUtenti.modifica.400.title": "Dati incompleti",
        "gestoreUtenti.modifica.400.body":
          "Uno dei campi obbligatori non \u00E8 stato compilato oppure Password e Conferma Password non combaciano",
        "gestoreUtenti.modifica.400.button": "OK",
        "gestoreUtenti.modifica.404.title": "Credenziali errate",
        "gestoreUtenti.modifica.404.body": "Username non trovato",
        "gestoreUtenti.modifica.404.button": "OK",
        "gestoreUtenti.modifica.500.title": "Errore interno del server",
        "gestoreUtenti.modifica.500.body":
          "Si prega di riprovare pi\u00f9 tardi",
        "gestoreUtenti.modifica.500.button": "OK",
        "gestoreUtenti.invioToken.200.title": "Invio codice",
        "gestoreUtenti.invioToken.200.body":
          "Il codice \u00E8 stato inviato correttamente e sar\u00E0 valido per le prossime 24 ore.",
        "gestoreUtenti.invioToken.200.button": "Ricarica pagina",
        "gestoreUtenti.invioToken.400.title": "Dati incompleti",
        "gestoreUtenti.invioToken.400.body":
          "Compilare tutti i campi obbligatori",
        "gestoreUtenti.invioToken.400.button": "OK",
        "gestoreUtenti.invioToken.404.title": "Credenziali errate",
        "gestoreUtenti.invioToken.404.body":
          "ID Utente o ID Contatto non trovato",
        "gestoreUtenti.invioToken.404.button": "OK",
        "gestoreUtenti.invioToken.500.title": "Errore interno del server",
        "gestoreUtenti.invioToken.500.body":
          "Si prega di riprovare pi\u00f9 tardi",
        "gestoreUtenti.invioToken.500.button": "OK",
        "gestoreUtenti.certificaContatto.200.title": "Invio codice",
        "gestoreUtenti.certificaContatto.200.body":
          "Il contatto \u00E8 stato verificato correttamente",
        "gestoreUtenti.certificaContatto.200.button": "OK",
        "gestoreUtenti.certificaContatto.400.title": "Dati incompleti",
        "gestoreUtenti.certificaContatto.400.body":
          "Uno dei campi obbligatori non \u00E8 stato compilato o il token \u00E8 errato",
        "gestoreUtenti.certificaContatto.400.button": "OK",
        "gestoreUtenti.certificaContatto.404.title": "Dati incompleti",
        "gestoreUtenti.certificaContatto.404.body":
          "ID Utente o ID Contatto non trovato",
        "gestoreUtenti.certificaContatto.404.button": "OK",
        "gestoreUtenti.certificaContatto.410.title": "Token scaduto",
        "gestoreUtenti.certificaContatto.410.body": "Richiedi un nuovo codice",
        "gestoreUtenti.certificaContatto.410.button": "Invia nuovo token",
        "gestoreUtenti.certificaContatto.500.title":
          "Errore interno del server",
        "gestoreUtenti.certificaContatto.500.body":
          "Si prega di riprovare pi\u00f9 tardi",
        "gestoreUtenti.certificaContatto.500.button": "OK",
        "gestoreUtenti.salvaSottoscrizioni.200.title":
          "Gestione sottoscrizioni",
        "gestoreUtenti.salvaSottoscrizioni.200.body":
          "Sottoscrizioni modificate correttamente.",
        "gestoreUtenti.salvaSottoscrizioni.200.button": "OK",
        "gestoreUtenti.salvaSottoscrizioni.400.title": "Dati incompleti",
        "gestoreUtenti.salvaSottoscrizioni.400.body":
          "Compilare tutti i campi obbligatori",
        "gestoreUtenti.salvaSottoscrizioni.400.button": "OK",
        "gestoreUtenti.salvaSottoscrizioni.404.title": "Credenziali errate",
        "gestoreUtenti.salvaSottoscrizioni.404.body": "ID Utente non trovato",
        "gestoreUtenti.salvaSottoscrizioni.404.button": "OK",
        "gestoreUtenti.salvaSottoscrizioni.500.title":
          "Errore interno del server",
        "gestoreUtenti.salvaSottoscrizioni.500.body":
          "Si prega di riprovare pi\u00f9 tardi",
        "gestoreUtenti.salvaSottoscrizioni.500.button": "OK",
        "gestoreUtenti.recuperaSottoscrizioni.400.title": "Dati incompleti",
        "gestoreUtenti.recuperaSottoscrizioni.400.body":
          "Compilare tutti i campi obbligatori",
        "gestoreUtenti.recuperaSottoscrizioni.400.button": "OK",
        "gestoreUtenti.recuperaSottoscrizioni.404.title": "Credenziali errate",
        "gestoreUtenti.recuperaSottoscrizioni.404.body":
          "ID Utente non trovato",
        "gestoreUtenti.recuperaSottoscrizioni.404.button": "OK",
        "gestoreUtenti.recuperaSottoscrizioni.500.title":
          "Errore interno del server",
        "gestoreUtenti.recuperaSottoscrizioni.500.body":
          "Si prega di riprovare pi\u00F9 tardi",
        "gestoreUtenti.recuperaSottoscrizioni.500.button": "OK",
        "gestoreUtenti.dettaglio.400.title": "Dati incompleti",
        "gestoreUtenti.dettaglio.400.body":
          "Compilare tutti i campi obbligatori",
        "gestoreUtenti.dettaglio.400.button": "OK",
        "gestoreUtenti.dettaglio.404.title": "Credenziali errate",
        "gestoreUtenti.dettaglio.404.body": "Utente non trovato",
        "gestoreUtenti.dettaglio.404.button": "OK",
        "gestoreUtenti.dettaglio.500.title": "Errore interno del server",
        "gestoreUtenti.dettaglio.500.body":
          "Si prega di riprovare pi\u00f9 tardi",
        "gestoreUtenti.dettaglio.500.button": "OK",
        "gestoreUtenti.dettaglio.cancella.200.title": "Eliminazione account",
        "gestoreUtenti.dettaglio.cancella.200.body":
          "Utente eliminato con successo",
        "gestoreUtenti.dettaglio.cancella.200.button": "Vai alla login",
        "gestoreUtenti.dettaglio.cancella.400.title": "Dati incompleti",
        "gestoreUtenti.dettaglio.cancella.400.body":
          "Compilare tutti i campi obbligatori",
        "gestoreUtenti.dettaglio.cancella.400.button": "OK",
        "gestoreUtenti.dettaglio.cancella.500.title":
          "Errore interno del server",
        "gestoreUtenti.dettaglio.cancella.500.body":
          "Cancellazione utente non riuscita",
        "gestoreUtenti.dettaglio.cancella.500.button": "OK",
        "gestoreUtenti.dettaglio.verificaStatoCanale.400.title":
          "Dati incompleti",
        "gestoreUtenti.dettaglio.verificaStatoCanale.400.body":
          "Compilare tutti i campi obbligatori",
        "gestoreUtenti.dettaglio.verificaStatoCanale.400.button": "OK",
        "gestoreUtenti.dettaglio.verificaStatoCanale.500.title":
          "Errore interno del server",
        "gestoreUtenti.dettaglio.verificaStatoCanale.500.body":
          "Si prega di riprovare pi\u00f9 tardi",
        "gestoreUtenti.dettaglio.verificaStatoCanale.500.button": "OK",
        "login.200.title": "Autenticazione",
        "login.200.body": "Login effettuato",
        "login.200.button": "OK",
        "login.401.title": "Credenziali errate",
        "login.401.body":
          "Se \u00E8 il tuo primo accesso alla nuova versione del sito o se hai dimenticato la password clicca sul link \u201CHai dimenticato la password?\u201D",
        "login.401.button": "OK",
        "login.404.title": "Credenziali errate",
        "login.404.body":
          "Se \u00E8 il tuo primo accesso alla nuova versione del sito o se hai dimenticato la password clicca sul link \u201CHai dimenticato la password?\u201D",
        "login.404.button": "OK",
        "login.500.title": "Errore interno del server",
        "login.500.body": "Si prega di riprovare pi\u00f9 tardi",
        "login.500.button": "OK",
        "areYouSure.title": "Attenzione",
        "areYouSure.body": "Sei sicuro di voler continuare?",
        "areYouSure.button.confirm": "S\u00cc",
        "areYouSure.button.cancel": "No",
        enableAlertTitle: "Attivazione Alert",
        enableAlertTitleAlreadySaved: "Sottoscrizione gi\u00e0 attiva",
        enableAlertDescr:
          "Vuoi ricevere una notifica ogni volta che la pagina viene aggiornata?",
        enableAlertDescrLogin:
          "Per impostare un alert sulla pagina, effettuare il log-in",
        enableAlertDescrAlreadySaved: "Vuoi gestire le tue preferenze?",
        enableAlertBtn: "ATTIVA ALERT",
        enableAlertBtnLogin: "ACCEDI ORA",
        enableAlertBtnAlreadySaved: "VAI AL CENTRO NOTIFICHE",
        sessionExpiredTitle: "Sessione scaduta",
        sessionExpiredDescr: "Si prega di effettuare nuovamente l'accesso",
        sessionExpiredBtn: "Torna alla login",
        deleteAccountTitle: "Eliminazione account",
        deleteAccountDescr: "Sei sicuro di voler eliminare il tuo account?",
        deleteAccountConfirmBtn: "S\u00ec, procedi",
        deleteAccountDeclineBtn: "No, annulla",
        "fiam.UOOO1.title": "Errore",
        "fiam.UOOO1.body": "Lo username inserito \u00e8 gi\u00e0 presente",
        "fiam.UOOO1.button": "Modifica",
        "fiam.UOOO2.title": "Errore",
        "fiam.UOOO2.body": "Email non valida",
        "fiam.UOOO2.button": "Modifica",
        "fiam.UOOO3.title": "Errore",
        "fiam.UOOO3.body": "Si prega di compilare tutti i campi obbligatori",
        "fiam.UOOO3.button": "OK",
        "fiam.UOOO5.title": "Errore",
        "fiam.UOOO5.body": "Errore di validazione per i canali abilitati",
        "fiam.UOOO5.button": "Modifica",
        "fiam.UOOO6.title": "Errore",
        "fiam.UOOO6.body":
          "Si prega di compilare correttamente il campo Lingua",
        "fiam.UOOO6.button": "OK",
        "fiam.UOOO7.title": "L'utente non \u00e8 stato ancora verificato",
        "fiam.UOOO7.body": "Si prega di riprovare pi\u00f9 tardi",
        "fiam.UOOO7.button": "OK",
        "fiam.500.title": "Errore interno del server",
        "fiam.500.body": "Si prega di riprovare pi\u00f9 tardi",
        "fiam.500.button": "OK",
        GCSAutosuggestionDefaultValue: [
          "Andamento titolo",
          "Azionariato",
          "Bilanci e relazioni",
          "Calendario finanziario",
          "Struttura organizzativa",
        ],
        "assembreaAzionisti.200.title": "Domanda",
        "assembreaAzionisti.200.body": "Dati inviati con successo",
        "assembreaAzionisti.200.button": "Scarica PDF",
        "assembreaAzionisti.404.title": "Domanda",
        "assembreaAzionisti.404.body": "Dati non inviati",
        "assembreaAzionisti.404.button": "Riprovare",
        "assembreaAzionisti.500.title": "Errore interno del server",
        "assembreaAzionisti.500.body": "Si prega di riprovare pi\u00f9 tardi",
        "assembreaAzionisti.500.button": "OK",
        "notificaDelega.200.title": "Notifica delega",
        "notificaDelega.200.body": "Dati inviati con successo",
        "notificaDelega.200.button": "Scarica PDF",
        "notificaDelega.404.title": "Notifica delega",
        "notificaDelega.404.body": "Dati non inviati",
        "notificaDelega.404.button": "Riprovare",
        "notificaDelega.500.title": "Errore interno del server",
        "notificaDelega.500.body": "Si prega di riprovare pi\u00f9 tardi",
        "notificaDelega.500.button": "OK",
      };`,
          }}
        ></script>

        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: `img[onload^="SVGInject("] {
        visibility: hidden;
      }`,
          }}
        ></style>
        <script
          type="text/javascript"
          async=""
          charSet="utf-8"
          id="utag_intesasanpaolo.sitoistituzionale_2"
          src="//tags.tiqcdn.com/utag/intesasanpaolo/sitoistituzionale/prod/utag.2.js?utv=ut4.47.202201071434"
        ></script>
        <script
          type="text/javascript"
          async=""
          charSet="utf-8"
          id="utag_intesasanpaolo.sitoistituzionale_3"
          src="//tags.tiqcdn.com/utag/intesasanpaolo/sitoistituzionale/prod/utag.3.js?utv=ut4.47.202201071434"
        ></script>
        <style
          type="text/css"
          {...{
            "data-fbcssmodules":
              "css:fb.css.base css:fb.css.dialog css:fb.css.iframewidget css:fb.css.customer_chat_plugin_iframe",
          }}
          dangerouslySetInnerHTML={{
            __html: `.fb_hidden {
        position: absolute;
        top: -10000px;
        z-index: 10001;
      }
      .fb_reposition {
        overflow: hidden;
        position: relative;
      }
      .fb_invisible {
        display: none;
      }
      .fb_reset {
        background: none;
        border: 0;
        border-spacing: 0;
        color: #000;
        cursor: auto;
        direction: ltr;
        font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
        font-size: 11px;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1;
        margin: 0;
        overflow: visible;
        padding: 0;
        text-align: left;
        text-decoration: none;
        text-indent: 0;
        text-shadow: none;
        text-transform: none;
        visibility: visible;
        white-space: normal;
        word-spacing: normal;
      }
      .fb_reset > div {
        overflow: hidden;
      }
      @keyframes fb_transform {
        from {
          opacity: 0;
          transform: scale(0.95);
        }
        to {
          opacity: 1;
          transform: scale(1);
        }
      }
      .fb_animate {
        animation: fb_transform 0.3s forwards;
      }
      .fb_dialog {
        background: rgba(82, 82, 82, 0.7);
        position: absolute;
        top: -10000px;
        z-index: 10001;
      }
      .fb_dialog_advanced {
        border-radius: 8px;
        padding: 10px;
      }
      .fb_dialog_content {
        background: #fff;
        color: #373737;
      }
      .fb_dialog_close_icon {
        background: url(https://static.xx.fbcdn.net/rsrc.php/v3/yq/r/IE9JII6Z1Ys.png)
          no-repeat scroll 0 0 transparent;
        cursor: pointer;
        display: block;
        height: 15px;
        position: absolute;
        right: 18px;
        top: 17px;
        width: 15px;
      }
      .fb_dialog_mobile .fb_dialog_close_icon {
        left: 5px;
        right: auto;
        top: 5px;
      }
      .fb_dialog_padding {
        background-color: transparent;
        position: absolute;
        width: 1px;
        z-index: -1;
      }
      .fb_dialog_close_icon:hover {
        background: url(https://static.xx.fbcdn.net/rsrc.php/v3/yq/r/IE9JII6Z1Ys.png)
          no-repeat scroll 0 -15px transparent;
      }
      .fb_dialog_close_icon:active {
        background: url(https://static.xx.fbcdn.net/rsrc.php/v3/yq/r/IE9JII6Z1Ys.png)
          no-repeat scroll 0 -30px transparent;
      }
      .fb_dialog_iframe {
        line-height: 0;
      }
      .fb_dialog_content .dialog_title {
        background: #6d84b4;
        border: 1px solid #365899;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        margin: 0;
      }
      .fb_dialog_content .dialog_title > span {
        background: url(https://static.xx.fbcdn.net/rsrc.php/v3/yd/r/Cou7n-nqK52.gif)
          no-repeat 5px 50%;
        float: left;
        padding: 5px 0 7px 26px;
      }
      body.fb_hidden {
        height: 100%;
        left: 0;
        margin: 0;
        overflow: visible;
        position: absolute;
        top: -10000px;
        transform: none;
        width: 100%;
      }
      .fb_dialog.fb_dialog_mobile.loading {
        background: url(https://static.xx.fbcdn.net/rsrc.php/v3/ya/r/3rhSv5V8j3o.gif)
          white no-repeat 50% 50%;
        min-height: 100%;
        min-width: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 10001;
      }
      .fb_dialog.fb_dialog_mobile.loading.centered {
        background: none;
        height: auto;
        min-height: initial;
        min-width: initial;
        width: auto;
      }
      .fb_dialog.fb_dialog_mobile.loading.centered #fb_dialog_loader_spinner {
        width: 100%;
      }
      .fb_dialog.fb_dialog_mobile.loading.centered .fb_dialog_content {
        background: none;
      }
      .loading.centered #fb_dialog_loader_close {
        clear: both;
        color: #fff;
        display: block;
        font-size: 18px;
        padding-top: 20px;
      }
      #fb-root #fb_dialog_ipad_overlay {
        background: rgba(0, 0, 0, 0.4);
        bottom: 0;
        left: 0;
        min-height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 10000;
      }
      #fb-root #fb_dialog_ipad_overlay.hidden {
        display: none;
      }
      .fb_dialog.fb_dialog_mobile.loading iframe {
        visibility: hidden;
      }
      .fb_dialog_mobile .fb_dialog_iframe {
        position: sticky;
        top: 0;
      }
      .fb_dialog_content .dialog_header {
        background: linear-gradient(from(#738aba), to(#2c4987));
        border-bottom: 1px solid;
        border-color: #043b87;
        box-shadow: white 0 1px 1px -1px inset;
        color: #fff;
        font: bold 14px Helvetica, sans-serif;
        text-overflow: ellipsis;
        text-shadow: rgba(0, 30, 84, 0.296875) 0 -1px 0;
        vertical-align: middle;
        white-space: nowrap;
      }
      .fb_dialog_content .dialog_header table {
        height: 43px;
        width: 100%;
      }
      .fb_dialog_content .dialog_header td.header_left {
        font-size: 12px;
        padding-left: 5px;
        vertical-align: middle;
        width: 60px;
      }
      .fb_dialog_content .dialog_header td.header_right {
        font-size: 12px;
        padding-right: 5px;
        vertical-align: middle;
        width: 60px;
      }
      .fb_dialog_content .touchable_button {
        background: linear-gradient(from(#4267b2), to(#2a4887));
        background-clip: padding-box;
        border: 1px solid #29487d;
        border-radius: 3px;
        display: inline-block;
        line-height: 18px;
        margin-top: 3px;
        max-width: 85px;
        padding: 4px 12px;
        position: relative;
      }
      .fb_dialog_content .dialog_header .touchable_button input {
        background: none;
        border: none;
        color: #fff;
        font: bold 12px Helvetica, sans-serif;
        margin: 2px -12px;
        padding: 2px 6px 3px 6px;
        text-shadow: rgba(0, 30, 84, 0.296875) 0 -1px 0;
      }
      .fb_dialog_content .dialog_header .header_center {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        text-align: center;
        vertical-align: middle;
      }
      .fb_dialog_content .dialog_content {
        background: url(https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/jKEcVPZFk-2.gif)
          no-repeat 50% 50%;
        border: 1px solid #4a4a4a;
        border-bottom: 0;
        border-top: 0;
        height: 150px;
      }
      .fb_dialog_content .dialog_footer {
        background: #f5f6f7;
        border: 1px solid #4a4a4a;
        border-top-color: #ccc;
        height: 40px;
      }
      #fb_dialog_loader_close {
        float: left;
      }
      .fb_dialog.fb_dialog_mobile .fb_dialog_close_icon {
        visibility: hidden;
      }
      #fb_dialog_loader_spinner {
        animation: rotateSpinner 1.2s linear infinite;
        background-color: transparent;
        background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yD/r/t-wz8gw1xG1.png);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
      }
      @keyframes rotateSpinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .fb_iframe_widget {
        display: inline-block;
        position: relative;
      }
      .fb_iframe_widget span {
        display: inline-block;
        position: relative;
        text-align: justify;
      }
      .fb_iframe_widget iframe {
        position: absolute;
      }
      .fb_iframe_widget_fluid_desktop,
      .fb_iframe_widget_fluid_desktop span,
      .fb_iframe_widget_fluid_desktop iframe {
        max-width: 100%;
      }
      .fb_iframe_widget_fluid_desktop iframe {
        min-width: 220px;
        position: relative;
      }
      .fb_iframe_widget_lift {
        z-index: 1;
      }
      .fb_iframe_widget_fluid {
        display: inline;
      }
      .fb_iframe_widget_fluid span {
        width: 100%;
      }
      .fb_mpn_mobile_landing_page_slide_out {
        animation-duration: 200ms;
        animation-name: fb_mpn_landing_page_slide_out;
        transition-timing-function: ease-in;
      }
      .fb_mpn_mobile_landing_page_slide_out_from_left {
        animation-duration: 200ms;
        animation-name: fb_mpn_landing_page_slide_out_from_left;
        transition-timing-function: ease-in;
      }
      .fb_mpn_mobile_landing_page_slide_up {
        animation-duration: 500ms;
        animation-name: fb_mpn_landing_page_slide_up;
        transition-timing-function: ease-in;
      }
      .fb_mpn_mobile_bounce_in {
        animation-duration: 300ms;
        animation-name: fb_mpn_bounce_in;
        transition-timing-function: ease-in;
      }
      .fb_mpn_mobile_bounce_out {
        animation-duration: 300ms;
        animation-name: fb_mpn_bounce_out;
        transition-timing-function: ease-in;
      }
      .fb_mpn_mobile_bounce_out_v2 {
        animation-duration: 300ms;
        animation-name: fb_mpn_fade_out;
        transition-timing-function: ease-in;
      }
      .fb_customer_chat_bounce_in_v2 {
        animation-duration: 300ms;
        animation-name: fb_bounce_in_v2;
        transition-timing-function: ease-in;
      }
      .fb_customer_chat_bounce_in_from_left {
        animation-duration: 300ms;
        animation-name: fb_bounce_in_from_left;
        transition-timing-function: ease-in;
      }
      .fb_customer_chat_bounce_out_v2 {
        animation-duration: 300ms;
        animation-name: fb_bounce_out_v2;
        transition-timing-function: ease-in;
      }
      .fb_customer_chat_bounce_out_from_left {
        animation-duration: 300ms;
        animation-name: fb_bounce_out_from_left;
        transition-timing-function: ease-in;
      }
      .fb_invisible_flow {
        display: inherit;
        height: 0;
        overflow-x: hidden;
        width: 0;
      }
      @keyframes fb_mpn_landing_page_slide_out {
        0% {
          margin: 0 12px;
          width: 100% - 24px;
        }
        60% {
          border-radius: 18px;
        }
        100% {
          border-radius: 50%;
          margin: 0 24px;
          width: 60px;
        }
      }
      @keyframes fb_mpn_landing_page_slide_out_from_left {
        0% {
          left: 12px;
          width: 100% - 24px;
        }
        60% {
          border-radius: 18px;
        }
        100% {
          border-radius: 50%;
          left: 12px;
          width: 60px;
        }
      }
      @keyframes fb_mpn_landing_page_slide_up {
        0% {
          bottom: 0;
          opacity: 0;
        }
        100% {
          bottom: 24px;
          opacity: 1;
        }
      }
      @keyframes fb_mpn_bounce_in {
        0% {
          opacity: 0.5;
          top: 100%;
        }
        100% {
          opacity: 1;
          top: 0;
        }
      }
      @keyframes fb_mpn_fade_out {
        0% {
          bottom: 30px;
          opacity: 1;
        }
        100% {
          bottom: 0;
          opacity: 0;
        }
      }
      @keyframes fb_mpn_bounce_out {
        0% {
          opacity: 1;
          top: 0;
        }
        100% {
          opacity: 0.5;
          top: 100%;
        }
      }
      @keyframes fb_bounce_in_v2 {
        0% {
          opacity: 0;
          transform: scale(0, 0);
          transform-origin: bottom right;
        }
        50% {
          transform: scale(1.03, 1.03);
          transform-origin: bottom right;
        }
        100% {
          opacity: 1;
          transform: scale(1, 1);
          transform-origin: bottom right;
        }
      }
      @keyframes fb_bounce_in_from_left {
        0% {
          opacity: 0;
          transform: scale(0, 0);
          transform-origin: bottom left;
        }
        50% {
          transform: scale(1.03, 1.03);
          transform-origin: bottom left;
        }
        100% {
          opacity: 1;
          transform: scale(1, 1);
          transform-origin: bottom left;
        }
      }
      @keyframes fb_bounce_out_v2 {
        0% {
          opacity: 1;
          transform: scale(1, 1);
          transform-origin: bottom right;
        }
        100% {
          opacity: 0;
          transform: scale(0, 0);
          transform-origin: bottom right;
        }
      }
      @keyframes fb_bounce_out_from_left {
        0% {
          opacity: 1;
          transform: scale(1, 1);
          transform-origin: bottom left;
        }
        100% {
          opacity: 0;
          transform: scale(0, 0);
          transform-origin: bottom left;
        }
      }
      @keyframes slideInFromBottom {
        0% {
          opacity: 0.1;
          transform: translateY(100%);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
      @keyframes slideInFromBottomDelay {
        0% {
          opacity: 0;
          transform: translateY(100%);
        }
        97% {
          opacity: 0;
          transform: translateY(100%);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }`,
          }}
        ></style>
        <script
          type="text/javascript"
          async=""
          charSet="utf-8"
          id="tiqapp"
          src="//tags.tiqcdn.com/utag/tiqapp/utag.v.js?a=intesasanpaolo/sitoistituzionale/202203180858&amp;cb=1652471201931"
        ></script>
        <style
          id="PDFTStyle"
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: `.qtip-content {
        font-family: "Times New Roman";
        font-size: 17px;
        line-height: 19px;
      }
      .qtip-max-width {
        max-width: 300px;
      }`,
          }}
        ></style>
      </head>
      <body
        {...{
          "data-aos-easing": "ease",
          "data-aos-duration": 1000,
          "data-aos-delay": 0,
          "data-hasqtip": 0,
          "data-new-gr-c-s-check-loaded": "14.1060.0",
          "data-gr-ext-installed": "",
        }}
      >
        <div className="cookie-banner">
          <div className="cookie-banner-container">
            <div className="cookie-banner-wrapper">
              <div className="container">
                <div id="cookie-chiudi" className="btn-close"></div>
                <h4 className="title-desc">Consenso all'uso di cookie</h4>
                <div className="show-mobile">
                  <p className="x-small-copy cookie-banner-text content-small">
                    Questo sito utilizza cookie tecnici e, previo consenso
                    dell’utente, cookie di profilazione al fine di inviare
                    comunicazioni pubblicitarie personalizzate e consente anche
                    l'invio di cookie di "terze parti" (impostati da un sito web
                    diverso da quello visitato). L'uso dei cookie ha la durata
                    di 1 anno.
                  </p>
                </div>
                <div className="show-desktop">
                  <p className="x-small-copy cookie-banner-text content-small">
                    Questo sito utilizza cookie tecnici e, previo consenso
                    dell’utente, cookie di profilazione al fine di inviare
                    comunicazioni pubblicitarie personalizzate e consente anche
                    l'invio di cookie di "terze parti" (impostati da un sito web
                    diverso da quello visitato).&nbsp;L'uso dei cookie ha la
                    durata di 1 anno.
                    <br />
                  </p>
                </div>
                <div className="cookie-warning show-mobile">
                  <div className="warning-icon">
                    <span className="circle">!</span>
                  </div>
                  <div className="warning-text">
                    <span>
                      <p>
                        Cliccando sulla [x] di chiusura del banner, non
                        acconsenti all’uso dei cookie di profilazione.
                        <br />
                        Non potremo, perciò, personalizzare la tua esperienza di
                        navigazione, né offrirti prodotti o servizi in linea con
                        le tue preferenze o i tuoi comportamenti online.
                      </p>
                    </span>
                  </div>
                </div>
                <div>
                  <p className="x-small-copy cookie-banner-text content-small">
                    È possibile consultare l'informativa estesa, prestare o meno
                    il consenso ai cookie o personalizzarne la configurazione e
                    modificare le proprie scelte in qualsiasi momento accedendo
                    alla sezione dedicata (
                    <a href="https://www.intesasanpaolo.com/it/common/footer/privacy.html">
                      Privacy&nbsp;
                    </a>
                    -&nbsp;
                    <a href="https://www.intesasanpaolo.com/it/common/footer/cookies.html">
                      Cookie policy
                    </a>
                    ).
                    <br />
                  </p>

                  <p className="x-small-copy cookie-banner-text content-small">
                    Per accettare tutti i cookie, clicca sul pulsante
                    “Acconsento”.
                  </p>
                </div>
                <div className="cookie-btn">
                  <div
                    id="cookie-denied"
                    className="cookie-btn--white-bor pg-btn-cookie"
                  >
                    <a
                      id="option-button"
                      href="https://www.intesasanpaolo.com/it/common/footer/cookies.html"
                      title="Non acconsento"
                    >
                      Più opzioni
                    </a>
                  </div>
                  <div
                    id="cookie-allowed"
                    className="cookie-btn--green pg-btn-cookie"
                  >
                    <a
                      href="#"
                      title="Acconsento"
                      id="agree-button"
                      onClick={() => false}
                    >
                      Acconsento
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <style
          dangerouslySetInnerHTML={{
            __html: `/* SVG Recoloring */
      .header-main svg.svg-injectable * {
        fill: #ffffff;
      }
      .icon-list svg.svg-injectable * {
        fill: #2b8804;
      }
      /* ToolBar */
      .toolbar-main .toolbar-box {
        background-color: #2b8804;
      }
      .toolbar-main .toolbar-box .toolbar-item {
        color: #ffffff;
      }
      .toolbar-main .toolbar-box .toolbar-item svg.svg-injectable * {
        fill: #ffffff;
      }
      /* HEADER */
      /* BACKGROUND HEADER TOP */
      .header-main .header-top {
        background-color: #323232;
      }
      /* COLOR FONT HEADER TOP */
      .header-top__main-level-link {
        color: #ffffff;
      }
      /* COLOR FONT NOTIFY HEADER TOP */
      .header-top__notify-item span {
        color: #ffffff;
      }
      /* COLOR FONT CONTACT HEADER TOP */
      .header-top__contact-item span {
        color: #ffffff;
      }
      /* COLOR FONT CONTACT HEADER TOP */
      .header-top__languages
        .dropdown-lang-wrapper
        .dropdown-lang-selected
        .dropdown-lang-item-selected {
        color: #ffffff;
      }
      /* BACKGROUND HEADER */
      .header-main .header {
        background-color: #2b8804;
      }
      /* COLOR FONT HEADER */
      .header-navbar .header-navbar-nav .header-navbar-item {
        color: #ffffff;
      }
      /* TITOLO */
      .g003-main .section-title-header {
        color: #2b8804;
      }
      /* DESCRIZIONE */
      .g003-main .g003-abstract p {
        color: #6f6f6f;
      }
      .customBackgroundColor {
        background-color: #f8f8f8;
      }
      .whiteBackgroundColor {
        background-color: #ffffff;
      }
      /* BACKGROUND FOOTER TOP */
      .footer-top-main {
        background-color: #6f6f6f;
      }
      /* COLOR FONT FOOTER TOP */
      .footer-top-main .footer-top .follow-us {
        color: #ffffff;
      }
      /* BACKGROUND FOOTER */
      .footer-center-main {
        background-color: #464646;
      }
      /* COLOR FONT FOOTER */
      .footer-title {
        color: #ffffff;
      }
      /* BACKGROUND ACCORDION MOBILE */
      .footer-center-main
        .footer
        .footer-column
        .title.active
        + .accordion-content {
        background-color: #323232;
      }
      /* COLOR FONT FOOTER */
      .footer-center-main .footer .footer-column .accordion-content li .item {
        color: #ffffff;
      }
      /* COLOR FONT FOOTER */
      .footer-center-main
        .footer
        .footer-column
        .accordion-content
        .dropdown-lang-wrapper {
        color: #ffffff;
      }
      /* BACKGROUND FOOTER LOWER */
      .footer-lower-main {
        background-color: #464646;
      }
      /* COLOR FONT FOOTER LOWER*/
      .footer-lower-main
        .footer-lower
        .footer-lower-wrapper
        .footer-lower-links
        a {
        color: #ffffff;
      }
      /* BACKGROUND FOOTER FINAL */
      .footer-final-main {
        background-color: #323232;
      }
      /* COLOR FONT FOOTER FINAL*/
      .footer-final-main .footer-final .footer-column-right .pmi {
        color: #ffffff;
      }`,
          }}
        ></style>

        <div id="fb-root" className="fb_reset">
          <div
            style={{
              position: "absolute",
              top: "-10000px",
              width: "0px",
              height: "0px",
            }}
          >
            <div></div>
          </div>
        </div>
        <script
          async=""
          defer=""
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&amp;version=v3.2"
        ></script>

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: `var utag_data = {};` }}
        ></script>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function (a, b, c, d) {
        a =
          "//tags.tiqcdn.com/utag/intesasanpaolo/sitoistituzionale/prod/utag.js?caburst=" +
          Date.now();
        b = document;
        c = "script";
        d = b.createElement(c);
        d.src = a;
        d.type = "text/java" + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })();`,
          }}
        ></script>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `document.addEventListener("DOMContentLoaded", function () {
        /* Force update date on specific pages */
        if (
          window.location.pathname.indexOf(
            "/it/investor-relations/comunicati-stampa-price-sensitive/storico-banca-intesa"
          ) > -1 &&
          document.querySelector(".text-date") != null
        ) {
          document.querySelector(".text-date").innerHTML =
            "Data ultimo aggiornamento 16 dicembre 2010 alle ore 17:15:42";
        } else if (
          window.location.pathname.indexOf(
            "/content/portalgroup/it/homepage/investor-relations/comunicati-stampa-price-sensitive/storico-banca-intesa"
          ) > -1 &&
          document.querySelector(".text-date") != null
        ) {
          document.querySelector(".text-date").innerHTML =
            "Data ultimo aggiornamento 16 dicembre 2010 alle ore 17:15:42";
        } else if (
          window.location.pathname.indexOf(
            "/en/investor-relations/press-releases/archive-banca-intesa"
          ) > -1 &&
          document.querySelector(".text-date") != null
        ) {
          document.querySelector(".text-date").innerHTML =
            "Last updated 16 December 2010 at 17:15:42";
        } else if (
          window.location.pathname.indexOf(
            "/content/portalgroup/en/homepage/investor-relations/press-releases/archive-banca-intesa"
          ) > -1 &&
          document.querySelector(".text-date") != null
        ) {
          document.querySelector(".text-date").innerHTML =
            "Last updated 16 December 2010 at 17:15:42";
        }
      });`,
          }}
        ></script>

        <div className="spinner-overlay">
          <div className="spinner">
            <div className="sk-circle1 sk-child"></div>
            <div className="sk-circle2 sk-child"></div>
            <div className="sk-circle3 sk-child"></div>
            <div className="sk-circle4 sk-child"></div>
            <div className="sk-circle5 sk-child"></div>
            <div className="sk-circle6 sk-child"></div>
            <div className="sk-circle7 sk-child"></div>
            <div className="sk-circle8 sk-child"></div>
            <div className="sk-circle9 sk-child"></div>
            <div className="sk-circle10 sk-child"></div>
            <div className="sk-circle11 sk-child"></div>
            <div className="sk-circle12 sk-child"></div>
            <div className="sk-circle13 sk-child"></div>
            <div className="sk-circle14 sk-child"></div>
            <div className="sk-circle15 sk-child"></div>
            <div className="sk-circle16 sk-child"></div>
            <div className="sk-circle17 sk-child"></div>
            <div className="sk-circle18 sk-child"></div>
            <div className="sk-circle19 sk-child"></div>
            <div className="sk-circle20 sk-child"></div>
            <div className="sk-circle21 sk-child"></div>
            <div className="sk-circle22 sk-child"></div>
            <div className="sk-circle23 sk-child"></div>
            <div className="sk-circle24 sk-child"></div>
          </div>
        </div>
        <div
          className="login-json hide"
          dangerouslySetInnerHTML={{
            __html: `{"clientID":"8f0f2457-784e-48e4-98d6-0415047ebc97","signature":"1b7d588a5acbce37f866186501ec2d14f26b16f94bbb11b0017b314f918129da","encryption":"d00eb0e03501a6b3d0ffac2db4d56565","keyID":"494d1aae-e754-42bc-1137-9a9628244ec6","user":"C1AAFC8C323DFDA567B3CD7D0E48C3DD","clientIDSh":"9e04155b-dc20-4ad8-b40b-5d4c665631f2","signatureSh":"1b7d588a5acbce37f866186501ec2d14f26b16f94bbb11b0017b314f918129da","encryptionSh":"d00eb0e03501a6b3d0ffac2db4d56565","keyIDSh":"72a8b4f5-7fbb-427b-9006-4baf6afba018","userSh":"C1AAFC8C323DFDA567B3CD7D0E48C3DD"}`,
          }}
        ></div>
        <iframe id="consent" style={{ display: "none" }}></iframe>
        <div className="page-header isp-default page">
          <header className="header-main">
            <div className="header-container">
              <div className="header-top" data-language-go="Vai a">
                <div className="container-fluid">
                  <div className="header-top__main">
                    <nav className="header-top__navigation">
                      <ul className="header-top__main-level">
                        <li className="header-top__main-level-item">
                          <div className="header-top-mobile-item">
                            <div>
                              <a
                                href="https://group.intesasanpaolo.com/it/chi-siamo"
                                target="_self"
                                className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                              >
                                CHI SIAMO
                              </a>
                            </div>
                            <a
                              href="https://group.intesasanpaolo.com/it/chi-siamo"
                              target="_self"
                              className="right-icon header-top__main-level-link main-level-link-has-child"
                            ></a>
                          </div>
                          <div className="header-top__sub-level-main">
                            <div className="header-top__selected">
                              <div className="header-top__selected-item">
                                <img
                                  className="svg-img"
                                  src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                  alt=""
                                />
                                <span></span>
                              </div>
                              <a
                                className="header-top__selected-link italic"
                                href="#"
                                title=""
                              ></a>
                            </div>
                            <ul className="header-top__sub-level-list">
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/chi-siamo/mission-valori-strategia"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Mission, Valori e Strategia
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/chi-siamo/profilo"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Profilo
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/chi-siamo/attivita"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Attività
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/chi-siamo/struttura-organizzativa"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Struttura organizzativa e Top management
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/chi-siamo/siti-societa-controllate"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Siti internet delle controllate
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/chi-siamo/azionariato"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Azionariato
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/chi-siamo/storia"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Storia
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/chi-siamo/marchio"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Marchio
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/chi-siamo/presenza-internazionale"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Presenza Internazionale
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <ul className="header-top__sub-level-list"></ul>
                          </div>
                        </li>
                        <li className="header-top__main-level-item">
                          <div className="header-top-mobile-item">
                            <div>
                              <a
                                href="https://group.intesasanpaolo.com/it/investor-relations"
                                target="_self"
                                className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                              >
                                INVESTOR RELATIONS
                              </a>
                            </div>
                            <a
                              href="https://group.intesasanpaolo.com/it/investor-relations"
                              target="_self"
                              className="right-icon header-top__main-level-link main-level-link-has-child"
                            ></a>
                          </div>
                          <div className="header-top__sub-level-main">
                            <div className="header-top__selected">
                              <div className="header-top__selected-item">
                                <img
                                  className="svg-img"
                                  src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                  alt=""
                                />
                                <span></span>
                              </div>
                              <a
                                className="header-top__selected-link italic"
                                href="#"
                                title=""
                              ></a>
                            </div>
                            <ul className="header-top__sub-level-list">
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/ir-policy"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      IR Policy
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/andamento-titolo"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Andamento titolo
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/news-teleborsa"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      News Teleborsa
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/bilanci-e-relazioni"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Bilanci e Relazioni
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/presentazioni"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Presentazioni
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/comunicati-stampa-price-sensitive"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Comunicati stampa
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/database-cifre-chiave"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Database cifre chiave
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/faq-finanziarie"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      FAQ finanziarie
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/rating"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Rating
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/azionariato"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Azionariato
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/azioni-dividendi/2021"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Azioni e dividendi
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/guida-azionista"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Guida dell'azionista
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/dialogo-con-gli-investitori"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Dialogo con gli investitori
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/Disclaimer/pag-conferma?PREV_URL=7e97c7d7-71d0-4efb-989a-f1b98a1a8e7d&amp;NEXT_URL=439424cf-d66b-4e80-bc85-ffeada2695a9"
                                      rel="nofollow"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Prospetti
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/analyst-coverage"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Analyst coverage
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/calendario-finanziario"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Calendario finanziario
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/sec-filing/form-20-f"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      SEC Filings
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/link-utili-di-approfondimento"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Link
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/investor-relations/contatti"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Contatti
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <ul className="header-top__sub-level-list"></ul>
                          </div>
                        </li>
                        <li className="header-top__main-level-item">
                          <div className="header-top-mobile-item">
                            <div>
                              <a
                                href="https://group.intesasanpaolo.com/it/governance"
                                target="_self"
                                className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                              >
                                GOVERNANCE
                              </a>
                            </div>
                            <a
                              href="https://group.intesasanpaolo.com/it/governance"
                              target="_self"
                              className="right-icon header-top__main-level-link main-level-link-has-child"
                            ></a>
                          </div>
                          <div className="header-top__sub-level-main">
                            <div className="header-top__selected">
                              <div className="header-top__selected-item">
                                <img
                                  className="svg-img"
                                  src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                  alt=""
                                />
                                <span></span>
                              </div>
                              <a
                                className="header-top__selected-link italic"
                                href="#"
                                title=""
                              ></a>
                            </div>
                            <ul className="header-top__sub-level-list">
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/azionariato"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Azionariato
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/assemblea-azionisti/2022"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Assemblea degli Azionisti
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/cda"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Consiglio di Amministrazione e Comitato
                                      per il Controllo sulla Gestione
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/comitati-cda"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Comitati del Consiglio di Amministrazione
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/direzioni-centrali"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Strutture Centrali
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/risk-management"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Risk Management
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/revisione-legale-conti"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Revisione legale dei conti
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/delibere-consiliari/2022"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Delibere consiliari
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/compensi-possessi-azionari"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Compensi e possessi azionari
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/internal-dealing/2022"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Internal Dealing
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/documenti-societari/2022"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Documenti societari
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/governance/dlgs-231-2001"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      D.Lgs 231/2001 e Anticorruzione
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <ul className="header-top__sub-level-list"></ul>
                          </div>
                        </li>
                        <li className="header-top__main-level-item">
                          <div className="header-top-mobile-item">
                            <div>
                              <a
                                href="https://group.intesasanpaolo.com/it/sostenibilita"
                                target="_self"
                                className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                              >
                                SOSTENIBILITÀ
                              </a>
                            </div>
                            <a
                              href="https://group.intesasanpaolo.com/it/sostenibilita"
                              target="_self"
                              className="right-icon header-top__main-level-link main-level-link-has-child"
                            ></a>
                          </div>
                          <div className="header-top__sub-level-main">
                            <div className="header-top__selected">
                              <div className="header-top__selected-item">
                                <img
                                  className="svg-img"
                                  src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                  alt=""
                                />
                                <span></span>
                              </div>
                              <a
                                className="header-top__selected-link italic"
                                href="#"
                                title=""
                              ></a>
                            </div>
                            <ul className="header-top__sub-level-list">
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/il-nostro-impegno"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Il nostro impegno per la sostenibilità
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sostenibilita/il-nostro-impegno"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/il-nostro-impegno/codice-etico --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/il-nostro-impegno/codice-etico"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Codice Etico
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/il-nostro-impegno/governo-della-sostenibilita/governance-aziendale --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/il-nostro-impegno/governo-della-sostenibilita/governance-aziendale"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Governo della sostenibilità
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/il-nostro-impegno/obiettivi/azionisti --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/il-nostro-impegno/obiettivi/azionisti"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Obiettivi
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/il-nostro-impegno/dialogo-stakeholder/coinvolgimento --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/il-nostro-impegno/dialogo-stakeholder/coinvolgimento"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Dialogo con gli stakeholder
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/il-nostro-impegno/relazioni-istituzionali/a-livello-nazionale --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/il-nostro-impegno/relazioni-istituzionali/a-livello-nazionale"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Relazioni istituzionali
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/il-nostro-impegno/diritti-umani --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/il-nostro-impegno/diritti-umani"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Diritti Umani
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/il-nostro-impegno/adesioni-partnership/standard-internazionali --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/il-nostro-impegno/adesioni-partnership/standard-internazionali"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Adesioni e Partnership
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/il-nostro-impegno/SDGs --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/il-nostro-impegno/SDGs"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Obiettivi di Sviluppo Sostenibile
                                            delle Nazioni Unite
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/dcnf"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Dichiarazione Consolidata Non Finanziaria
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sostenibilita/dcnf"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/dcnf/dichiarazione-consolidata-non-finanziaria-2021 --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/dcnf/dichiarazione-consolidata-non-finanziaria-2021"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Dichiarazione Consolidata Non
                                            Finanziaria 2021
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/dcnf/relazione-consolidata-non-finanziaria-al-30-giugno --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/dcnf/relazione-consolidata-non-finanziaria-al-30-giugno"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Relazione Consolidata Non
                                            Finanziaria al 30 giugno
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/dcnf/archivio-dcnf/2021 --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/dcnf/archivio-dcnf/2021"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Archivio - Dichiarazione Consolidata
                                            Non Finanziaria
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/dcnf/Indicatori-Non-Finanziari --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/dcnf/Indicatori-Non-Finanziari"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Indicatori Non Finanziari
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/tcfd-report/2021"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      TCFD Report
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/indici-di-sostenibilita"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Indici di sostenibilità
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/riconoscimenti/2022"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Riconoscimenti
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/azionisti"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Azionisti
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/clienti"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Clienti
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sostenibilita/clienti"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/clienti/relazione-clienti --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/clienti/relazione-clienti"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Relazione con il cliente
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/clienti/inclusione-finanziaria --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/clienti/inclusione-finanziaria"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Inclusione finanziaria
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/clienti/supporto-economia --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/clienti/supporto-economia"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Supporto all'economia
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/clienti/educazione-finanziaria --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/clienti/educazione-finanziaria"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Educazione finanziaria
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/clienti/investimenti-sostenibili --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/clienti/investimenti-sostenibili"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Investimenti sostenibili
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/clienti/impegno-verso-i-clienti---i-numeri --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/clienti/impegno-verso-i-clienti---i-numeri"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Impegno verso i clienti: i numeri
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/persone"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Persone
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sostenibilita/persone"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/persone/sviluppo-professionale --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/persone/sviluppo-professionale"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Sviluppo Professionale
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/persone/welfare-sicurezza --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/persone/welfare-sicurezza"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Welfare aziendale
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/persone/diversity --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/persone/diversity"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Diversity
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/persone/relazioni-industriali --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/persone/relazioni-industriali"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Relazioni industriali
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/persone/impegno-verso-le-persone--i-numeri --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/persone/impegno-verso-le-persone--i-numeri"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Impegno verso le persone: i numeri
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Ambiente e Climate Change
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/cambiamento-climatico --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/cambiamento-climatico"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Cambiamento climatico
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/prodotti-servizi-verdi --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/prodotti-servizi-verdi"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                          >
                                            Green Economy: prodotti, servizi
                                            verdi e Circular Economy
                                          </a>
                                        </div>
                                        <a
                                          href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/prodotti-servizi-verdi"
                                          target="_self"
                                          className="right-icon header-top__main-level-link main-level-link-has-child"
                                        ></a>
                                      </div>

                                      <div className="header-top__sub-level-main">
                                        <div className="header-top__selected">
                                          <div className="header-top__selected-item">
                                            <img
                                              className="svg-img"
                                              src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                              alt=""
                                            />
                                            <span></span>
                                          </div>
                                          <a
                                            className="header-top__selected-link italic"
                                            href="#"
                                            title=""
                                          ></a>
                                        </div>
                                        <ul className="header-top__sub-level-list">
                                          {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/prodotti-servizi-verdi/finanziamenti-e-servizi-per-la-green-economy --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/prodotti-servizi-verdi/finanziamenti-e-servizi-per-la-green-economy"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Finanziamenti e servizi per la
                                                  Green Economy
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/prodotti-servizi-verdi/supporto-alla-circular-economy --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/prodotti-servizi-verdi/supporto-alla-circular-economy"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Supporto alla Circular Economy
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/prodotti-servizi-verdi/investimenti-sostenibili-green --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/prodotti-servizi-verdi/investimenti-sostenibili-green"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Investimenti sostenibili green
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/prodotti-servizi-verdi/Green-bonds --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/prodotti-servizi-verdi/Green-bonds"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Green Bond
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>

                                        <ul className="header-top__sub-level-list"></ul>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/impatti-ambientali-diretti --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/impatti-ambientali-diretti"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                          >
                                            Impatti ambientali diretti
                                          </a>
                                        </div>
                                        <a
                                          href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/impatti-ambientali-diretti"
                                          target="_self"
                                          className="right-icon header-top__main-level-link main-level-link-has-child"
                                        ></a>
                                      </div>

                                      <div className="header-top__sub-level-main">
                                        <div className="header-top__selected">
                                          <div className="header-top__selected-item">
                                            <img
                                              className="svg-img"
                                              src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                              alt=""
                                            />
                                            <span></span>
                                          </div>
                                          <a
                                            className="header-top__selected-link italic"
                                            href="#"
                                            title=""
                                          ></a>
                                        </div>
                                        <ul className="header-top__sub-level-list">
                                          {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/impatti-ambientali-diretti/le-nostre-policy-ambientali --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/impatti-ambientali-diretti/le-nostre-policy-ambientali"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Le nostre policy ambientali
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/impatti-ambientali-diretti/emissioni-gas-a-effetto-serra/fattori-di-emissione --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/impatti-ambientali-diretti/emissioni-gas-a-effetto-serra/fattori-di-emissione"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Emissioni gas a effetto serra
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/impatti-ambientali-diretti/presidio-degli-impatti-ambientali --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/impatti-ambientali-diretti/presidio-degli-impatti-ambientali"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Presidio degli impatti
                                                  ambientali
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/impatti-ambientali-diretti/indicatori-ambientali --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/impatti-ambientali-diretti/indicatori-ambientali"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Indicatori ambientali
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>

                                        <ul className="header-top__sub-level-list"></ul>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/iniziative-e-gruppi-di-lavoro-internazionali/iniziative-internazionali --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/iniziative-e-gruppi-di-lavoro-internazionali/iniziative-internazionali"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Iniziative e gruppi di lavoro
                                            internazionali
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/ambiente/impegno-verso-l-ambiente--i-numeri --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/ambiente/impegno-verso-l-ambiente--i-numeri"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Impegno verso l'ambiente: i numeri
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/comunita"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Comunità
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sostenibilita/comunita"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/comunita/Contributo-alla-comunita/contributo-monetario --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/comunita/Contributo-alla-comunita/contributo-monetario"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Contributo alla comunità
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/comunita/donazioni-e-iniziative --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/comunita/donazioni-e-iniziative"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Donazioni e Iniziative
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/comunita/impegno-verso-la-comunita--i-numeri --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/comunita/impegno-verso-la-comunita--i-numeri"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Impegno verso la comunità: i numeri
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/fornitori"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Fornitori
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sostenibilita/fornitori"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/fornitori/portale-fornitori --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/fornitori/portale-fornitori"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Portale fornitori
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/fornitori/sostenibilita-strategie-acquisto --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/fornitori/sostenibilita-strategie-acquisto"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            La sostenibilità nelle strategie di
                                            acquisto
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/controllo-gestione-rischi"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Controllo e Gestione dei rischi
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sostenibilita/controllo-gestione-rischi"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/controllo-gestione-rischi/gestione-rischi-esg --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/controllo-gestione-rischi/gestione-rischi-esg"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Gestione rischi ESG
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/controllo-gestione-rischi/gestione-rischi-legati-al-climate-change --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/controllo-gestione-rischi/gestione-rischi-legati-al-climate-change"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Gestione rischi legati al Climate
                                            Change
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/controllo-gestione-rischi/presidio-rischi-esg-reputazionali --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/controllo-gestione-rischi/presidio-rischi-esg-reputazionali"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                          >
                                            Presidio dei rischi ESG e
                                            reputazionali
                                          </a>
                                        </div>
                                        <a
                                          href="https://group.intesasanpaolo.com/it/sostenibilita/controllo-gestione-rischi/presidio-rischi-esg-reputazionali"
                                          target="_self"
                                          className="right-icon header-top__main-level-link main-level-link-has-child"
                                        ></a>
                                      </div>

                                      <div className="header-top__sub-level-main">
                                        <div className="header-top__selected">
                                          <div className="header-top__selected-item">
                                            <img
                                              className="svg-img"
                                              src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                              alt=""
                                            />
                                            <span></span>
                                          </div>
                                          <a
                                            className="header-top__selected-link italic"
                                            href="#"
                                            title=""
                                          ></a>
                                        </div>
                                        <ul className="header-top__sub-level-list">
                                          {/** <!-- /content/portalgroup/it/homepage/sostenibilita/controllo-gestione-rischi/presidio-rischi-esg-reputazionali/equator-principles/uno-standard-di-riferimento --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sostenibilita/controllo-gestione-rischi/presidio-rischi-esg-reputazionali/equator-principles/uno-standard-di-riferimento"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Equator Principles
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sostenibilita/controllo-gestione-rischi/presidio-rischi-esg-reputazionali/progetti-equator-principles/2021 --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sostenibilita/controllo-gestione-rischi/presidio-rischi-esg-reputazionali/progetti-equator-principles/2021"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Progetti valutati secondo gli
                                                  Equator Principles
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>

                                        <ul className="header-top__sub-level-list"></ul>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/controllo-gestione-rischi/integrita-nella-condotta-aziendale---intesa-sanpaolo --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/controllo-gestione-rischi/integrita-nella-condotta-aziendale---intesa-sanpaolo"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Integrità nella condotta aziendale
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/news/2022"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      News
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/pubblicazioni"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Pubblicazioni
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sostenibilita/pubblicazioni"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/pubblicazioni/green-bond-report/2020 --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/pubblicazioni/green-bond-report/2020"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Green Bond Reports
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/pubblicazioni/quaderni-tematici/2018 --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/pubblicazioni/quaderni-tematici/2018"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Quaderni tematici
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sostenibilita/pubblicazioni/altre-pubblicazioni/2017 --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sostenibilita/pubblicazioni/altre-pubblicazioni/2017"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Altre pubblicazioni
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/risorse-utili/policy"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Policy e certificazioni
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sostenibilita/contatti"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Contatti
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <ul className="header-top__sub-level-list"></ul>
                          </div>
                        </li>
                        <li className="header-top__main-level-item">
                          <div className="header-top-mobile-item">
                            <div>
                              <a
                                href="https://group.intesasanpaolo.com/it/sociale"
                                target="_self"
                                className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                              >
                                SOCIALE
                              </a>
                            </div>
                            <a
                              href="https://group.intesasanpaolo.com/it/sociale"
                              target="_self"
                              className="right-icon header-top__main-level-link main-level-link-has-child"
                            ></a>
                          </div>
                          <div className="header-top__sub-level-main">
                            <div className="header-top__selected">
                              <div className="header-top__selected-item">
                                <img
                                  className="svg-img"
                                  src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                  alt=""
                                />
                                <span></span>
                              </div>
                              <a
                                className="header-top__selected-link italic"
                                href="#"
                                title=""
                              ></a>
                            </div>
                            <ul className="header-top__sub-level-list">
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-universita-e-scuola"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Iniziative per Università e Scuola
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-universita-e-scuola"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-universita-e-scuola/educazione --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-universita-e-scuola/educazione"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Educazione
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-universita-e-scuola/inclusione-allo-studio --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-universita-e-scuola/inclusione-allo-studio"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Inclusione e Orientamento
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-universita-e-scuola/ricerca-e-innovazione --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-universita-e-scuola/ricerca-e-innovazione"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Ricerca, Talento e Innovazione
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Iniziative per il sociale
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                          >
                                            Intesa Sanpaolo per i Bisognosi
                                          </a>
                                        </div>
                                        <a
                                          href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi"
                                          target="_self"
                                          className="right-icon header-top__main-level-link main-level-link-has-child"
                                        ></a>
                                      </div>

                                      <div className="header-top__sub-level-main">
                                        <div className="header-top__selected">
                                          <div className="header-top__selected-item">
                                            <img
                                              className="svg-img"
                                              src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                              alt=""
                                            />
                                            <span></span>
                                          </div>
                                          <a
                                            className="header-top__selected-link italic"
                                            href="#"
                                            title=""
                                          ></a>
                                        </div>
                                        <ul className="header-top__sub-level-list">
                                          {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi/pasti --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi/pasti"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Distribuzione e donazione
                                                  pasti
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi/posti-letto --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi/posti-letto"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Diritto ai posti letto
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi/raccolta-e-distribuzione-farmaci --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi/raccolta-e-distribuzione-farmaci"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Favorire la distribuzione
                                                  farmaci
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi/donazione-e-distribuzione-indumenti --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/intesa-sanpaolo-per-i-bisognosi/donazione-e-distribuzione-indumenti"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Donazione e distribuzione
                                                  indumenti
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>

                                        <ul className="header-top__sub-level-list"></ul>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/infanzia --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/infanzia"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Diritti dell'infanzia
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                          >
                                            Inclusione e coesione sociale
                                          </a>
                                        </div>
                                        <a
                                          href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale"
                                          target="_self"
                                          className="right-icon header-top__main-level-link main-level-link-has-child"
                                        ></a>
                                      </div>

                                      <div className="header-top__sub-level-main">
                                        <div className="header-top__selected">
                                          <div className="header-top__selected-item">
                                            <img
                                              className="svg-img"
                                              src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                              alt=""
                                            />
                                            <span></span>
                                          </div>
                                          <a
                                            className="header-top__selected-link italic"
                                            href="#"
                                            title=""
                                          ></a>
                                        </div>
                                        <ul className="header-top__sub-level-list">
                                          {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale/empowerment-femminile --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale/empowerment-femminile"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Empowerment femminile
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale/inserimento-lavorativo-persone-svantaggiate --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale/inserimento-lavorativo-persone-svantaggiate"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Inserimento lavorativo
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale/sostenibilita-sociale-e-ambientale --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale/sostenibilita-sociale-e-ambientale"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Sostenibilità sociale e
                                                  ambientale
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale/rigenerazione-beni-comuni --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale/rigenerazione-beni-comuni"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Rigenerazione dei beni comuni
                                                </a>
                                              </div>
                                            </div>
                                          </li>

                                          {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale/prevenzione-e-recupero-dipendenze --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/inclusione-e-coesione-sociale/prevenzione-e-recupero-dipendenze"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Prevenzione e recupero
                                                  dipendenze
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>

                                        <ul className="header-top__sub-level-list"></ul>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sociale/iniziative-per-il-sociale/interventi-covid-19 --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/iniziative-per-il-sociale/interventi-covid-19"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Interventi Covid-19
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sociale/fondo-di-beneficenza"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Fondo di Beneficenza
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/sociale/fondo-di-beneficenza"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/sociale/fondo-di-beneficenza/linee-guida --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/fondo-di-beneficenza/linee-guida"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Linee Guida
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sociale/fondo-di-beneficenza/monitoraggio-e-valutazione --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/fondo-di-beneficenza/monitoraggio-e-valutazione"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Monitoraggio e Valutazione
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sociale/fondo-di-beneficenza/come-richiedere-un-contributo --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/fondo-di-beneficenza/come-richiedere-un-contributo"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Come richiedere un contributo
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sociale/fondo-di-beneficenza/formulario-suggerimenti --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/fondo-di-beneficenza/formulario-suggerimenti"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Formulario: suggerimenti
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sociale/fondo-di-beneficenza/Pubblicazioni --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/fondo-di-beneficenza/Pubblicazioni"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Pubblicazioni e News
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/sociale/fondo-di-beneficenza/Testimonianze --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/sociale/fondo-di-beneficenza/Testimonianze"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Testimonianze
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sociale/enti-non-profit-costituiti-dalla-banca"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Enti non profit costituiti dalla Banca
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <ul className="header-top__sub-level-list"></ul>
                          </div>
                        </li>
                        <li className="header-top__main-level-item">
                          <div className="header-top-mobile-item">
                            <div>
                              <a
                                href="https://group.intesasanpaolo.com/it/research"
                                target="_self"
                                className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                              >
                                RESEARCH
                              </a>
                            </div>
                            <a
                              href="https://group.intesasanpaolo.com/it/research"
                              target="_self"
                              className="right-icon header-top__main-level-link main-level-link-has-child"
                            ></a>
                          </div>
                          <div className="header-top__sub-level-main">
                            <div className="header-top__selected">
                              <div className="header-top__selected-item">
                                <img
                                  className="svg-img"
                                  src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                  alt=""
                                />
                                <span></span>
                              </div>
                              <a
                                className="header-top__selected-link italic"
                                href="#"
                                title=""
                              ></a>
                            </div>
                            <ul className="header-top__sub-level-list">
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/research/research-in-primo-piano"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      In Primo Piano
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/research/esg-paper"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      ESG Papers
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/research/macroeconomic-analysis-new/macroeconomia"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Macroeconomic Analysis
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/research/industry---banking-research-new/Banking"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Industry &amp; Banking Research
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/research/equity---credit-research/equity"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Equity &amp; Credit Research
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/research/coordinamento-international-research-network"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      International Research Network
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/research/indici-comit"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Indici Comit
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/research/indici-comit"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/research/indici-comit/indici-azionari--last- --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/research/indici-comit/indici-azionari--last-"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Indici Azionari "Last"
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/research/indici-comit/indici-azionari--globali- --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/research/indici-comit/indici-azionari--globali-"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Indici Azionari "Globali"
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/research/indici-comit/indici-azionari--small-cap- --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/research/indici-comit/indici-azionari--small-cap-"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Indici Azionari "Small Cap"
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/research/indici-comit/indici-azionari--10-40- --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/research/indici-comit/indici-azionari--10-40-"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Indici Azionari "10/40"
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/research/indici-comit/serie-storiche --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/research/indici-comit/serie-storiche"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Serie Storiche
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/research/RegulatoryDisclosures"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Regulatory Disclosures
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/research/RegulatoryDisclosures"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/research/RegulatoryDisclosures/archivio-dei-conflitti-di-interesse --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/research/RegulatoryDisclosures/archivio-dei-conflitti-di-interesse"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Conflitti di Interesse del Gruppo
                                            ISP
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/research/RegulatoryDisclosures/archivio-raccomandazioni --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/research/RegulatoryDisclosures/archivio-raccomandazioni"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Raccomandazioni ultimi 12 mesi
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/research/RegulatoryDisclosures/archivio-storia-delle-raccomandazioni --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/research/RegulatoryDisclosures/archivio-storia-delle-raccomandazioni"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Grafici Storia delle Raccomandazioni
                                            12M
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/research/area-media"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Area Media
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <ul className="header-top__sub-level-list">
                              <li className="header-top__sub-level-title">
                                COMPRENDERE LA NOSTRA SOCIETÀ
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/research/consumi-indagini-di-mercato"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Ricerche Comportamentali
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className="header-top__main-level-item">
                          <div className="header-top-mobile-item">
                            <div>
                              <a
                                href="https://group.intesasanpaolo.com/it/sala-stampa"
                                target="_self"
                                className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                              >
                                NEWSROOM
                              </a>
                            </div>
                            <a
                              href="https://group.intesasanpaolo.com/it/sala-stampa"
                              target="_self"
                              className="right-icon header-top__main-level-link main-level-link-has-child"
                            ></a>
                          </div>
                          <div className="header-top__sub-level-main">
                            <div className="header-top__selected">
                              <div className="header-top__selected-item">
                                <img
                                  className="svg-img"
                                  src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                  alt=""
                                />
                                <span></span>
                              </div>
                              <a
                                className="header-top__selected-link italic"
                                href="#"
                                title=""
                              ></a>
                            </div>
                            <ul className="header-top__sub-level-list">
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sala-stampa/news"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      News
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sala-stampa/approfondimenti"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Approfondimenti
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sala-stampa/comunicati-stampa"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Comunicati stampa
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sala-stampa/interviste-e-dichiarazioni/dichiarazioni"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Dichiarazioni e interviste del CEO
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/sala-stampa/PressKit"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Press Kit
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <ul className="header-top__sub-level-list"></ul>
                          </div>
                        </li>
                        <li className="header-top__main-level-item">
                          <div className="header-top-mobile-item">
                            <div>
                              <a
                                href="https://group.intesasanpaolo.com/it/careers"
                                target="_self"
                                className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                              >
                                CAREERS
                              </a>
                            </div>
                            <a
                              href="https://group.intesasanpaolo.com/it/careers"
                              target="_self"
                              className="right-icon header-top__main-level-link main-level-link-has-child"
                            ></a>
                          </div>
                          <div className="header-top__sub-level-main">
                            <div className="header-top__selected">
                              <div className="header-top__selected-item">
                                <img
                                  className="svg-img"
                                  src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                  alt=""
                                />
                                <span></span>
                              </div>
                              <a
                                className="header-top__selected-link italic"
                                href="#"
                                title=""
                              ></a>
                            </div>
                            <ul className="header-top__sub-level-list">
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/careers/posizioni-aperte"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Posizioni aperte
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/careers/posizioni-aperte"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/careers/posizioni-aperte/business --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://jobs.intesasanpaolo.com/go/Business-Jobs_IT/1361201/"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Business
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/careers/posizioni-aperte/it --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://jobs.intesasanpaolo.com/go/IT-Jobs_IT/1361401/"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            IT
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/careers/posizioni-aperte/quants --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://jobs.intesasanpaolo.com/go/Quants-Jobs_IT/1361301/"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Quants
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/careers/posizioni-aperte/regulatory --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://jobs.intesasanpaolo.com/go/Regulatory-Jobs_IT/1361701/"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Regulatory
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/careers/posizioni-aperte/staff --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://jobs.intesasanpaolo.com/go/Staff-Jobs_IT/1361501/"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Staff
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/careers/posizioni-aperte/laureati-e-studenti --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/careers/posizioni-aperte/laureati-e-studenti"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                          >
                                            Diplomati, Laureati e Studenti
                                          </a>
                                        </div>
                                        <a
                                          href="https://group.intesasanpaolo.com/it/careers/posizioni-aperte/laureati-e-studenti"
                                          target="_self"
                                          className="right-icon header-top__main-level-link main-level-link-has-child"
                                        ></a>
                                      </div>

                                      <div className="header-top__sub-level-main">
                                        <div className="header-top__selected">
                                          <div className="header-top__selected-item">
                                            <img
                                              className="svg-img"
                                              src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                              alt=""
                                            />
                                            <span></span>
                                          </div>
                                          <a
                                            className="header-top__selected-link italic"
                                            href="#"
                                            title=""
                                          ></a>
                                        </div>
                                        <ul className="header-top__sub-level-list">
                                          {/** <!-- /content/portalgroup/it/homepage/careers/posizioni-aperte/laureati-e-studenti/diventa-consulente-finanziario --> */}

                                          <li className="header-top__main-level-item">
                                            <div className="header-top-mobile-item">
                                              <div>
                                                <a
                                                  href="https://group.intesasanpaolo.com/it/careers/posizioni-aperte/laureati-e-studenti/diventa-consulente-finanziario"
                                                  target="_self"
                                                  className="x-small-copy header-top__main-level-link background-link-animated"
                                                >
                                                  Entra nella consulenza
                                                  finanziaria
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>

                                        <ul className="header-top__sub-level-list"></ul>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/careers/posizioni-aperte/categorie-protette --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://jobs.intesasanpaolo.com/search/?createNewAlert=false&amp;q=categorie+tutelate&amp;locationsearch="
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Categorie protette
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/careers/posizioni-aperte/tutte-le-posizioni --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://jobs.intesasanpaolo.com/search/?q=&amp;locationsearch=&amp;locale=it_IT"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Tutte le posizioni
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/careers/lavorare-IT-department"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Lavorare nell'IT, Digital and Innovation
                                      Area
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/careers/vita-intesa-sanpaolo"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link main-level-link-has-child background-link-animated"
                                    >
                                      Vita in Intesa Sanpaolo
                                    </a>
                                  </div>

                                  <a
                                    href="https://group.intesasanpaolo.com/it/careers/vita-intesa-sanpaolo"
                                    target="_self"
                                    className="right-icon header-top__main-level-link main-level-link-has-child"
                                  ></a>
                                </div>

                                <div className="header-top__sub-level-main">
                                  <div className="header-top__selected">
                                    <div className="header-top__selected-item">
                                      <img
                                        className="svg-img"
                                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                        alt=""
                                      />
                                      <span></span>
                                    </div>
                                    <a
                                      className="header-top__selected-link italic"
                                      href="#"
                                      title=""
                                    ></a>
                                  </div>
                                  <ul className="header-top__sub-level-list">
                                    {/** <!-- /content/portalgroup/it/homepage/careers/vita-intesa-sanpaolo/valori --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/careers/vita-intesa-sanpaolo/valori"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Principi guida
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/careers/vita-intesa-sanpaolo/parita-di-genere --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/careers/vita-intesa-sanpaolo/parita-di-genere"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Donne Intesa Sanpaolo
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/careers/vita-intesa-sanpaolo/benefit --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/careers/vita-intesa-sanpaolo/benefit"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Benefit
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    {/** <!-- /content/portalgroup/it/homepage/careers/vita-intesa-sanpaolo/diversity --> */}

                                    <li className="header-top__main-level-item">
                                      <div className="header-top-mobile-item">
                                        <div>
                                          <a
                                            href="https://group.intesasanpaolo.com/it/careers/vita-intesa-sanpaolo/diversity"
                                            target="_self"
                                            className="x-small-copy header-top__main-level-link background-link-animated"
                                          >
                                            Diversity
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>

                                  <ul className="header-top__sub-level-list"></ul>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/careers/international-talent-program"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Internazionalità
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/careers/candidate-experience"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Candidate experience
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/careers/contatti"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      Contatti
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li className="header-top__main-level-item">
                                <div className="header-top-mobile-item">
                                  <div>
                                    <a
                                      href="https://group.intesasanpaolo.com/it/careers/faq"
                                      target="_self"
                                      className="x-small-copy header-top__main-level-link background-link-animated"
                                    >
                                      FAQ
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <ul className="header-top__sub-level-list"></ul>
                          </div>
                        </li>
                        <li className="header-top__main-level-item dropdown-wrapper">
                          <div className="header-top-mobile-item">
                            <div className="header-top-dropdown-head">
                              <a className="x-small-copy dropdown-title header-top__main-level-link main-level-link-has-child background-link-animated">
                                PRODOTTI E SERVIZI
                              </a>
                              <span className="down-arrow"></span>
                            </div>
                            <div className="dropdown-content">
                              <a
                                href="https://www.intesasanpaolo.com/it/persone-e-famiglie.html"
                                target="_blank"
                                rel="nofollow"
                                className="dropdown-content-links"
                              >
                                <span className="link-text">
                                  Persone e Famiglie
                                </span>
                                <span className="icon-external-link icon-link"></span>
                              </a>

                              <a
                                href="https://www.intesasanpaolo.com/it/business.html"
                                target="_blank"
                                rel="nofollow"
                                className="dropdown-content-links"
                              >
                                <span className="link-text">Business</span>
                                <span className="icon-external-link icon-link"></span>
                              </a>

                              <a
                                href="https://www.imi.intesasanpaolo.com/home/"
                                target="_blank"
                                rel="nofollow"
                                className="dropdown-content-links"
                              >
                                <span className="link-text">Corporate</span>
                                <span className="icon-external-link icon-link"></span>
                              </a>

                              <a
                                href="https://group.intesasanpaolo.com/it/chi-siamo/presenza-internazionale"
                                target="_self"
                                className="dropdown-content-links"
                              >
                                <span className="link-text">
                                  Presenza Internazionale
                                </span>
                                <span className="icon-narrow-right icon-link"></span>
                              </a>

                              <a
                                href="https://www.intesasanpaolo.com/it/persone-e-famiglie/tutti-i-giorni/intesa-sanpaolo-mobile/la-banca-nell-app/intesa-sanpaolo-mobile.html"
                                rel="nofollow"
                                target="_blank"
                                className="dropdown-content-links app-download"
                              >
                                <span className="link-text">
                                  App Intesa Sanpaolo Mobile
                                </span>
                                <img
                                  className="img-download"
                                  alt="App Intesa Sanpaolo Mobile"
                                  src="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/AppIntesa.png"
                                />
                              </a>
                            </div>
                            <a
                              href="#"
                              className="right-icon header-top__main-level-link main-level-link-active main-level-link-has-child"
                            ></a>
                          </div>
                          <div className="header-top__sub-level-main">
                            <div className="header-top__selected">
                              <div className="header-top__selected-item">
                                <img
                                  className="svg-img"
                                  src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-green.svg"
                                  alt=""
                                />
                                <span></span>
                              </div>
                              <a
                                className="header-top__selected-link italic"
                                href="#"
                                title=""
                              ></a>
                            </div>
                            <div className="dropdown-content">
                              <a
                                href="https://www.intesasanpaolo.com/it/persone-e-famiglie.html"
                                target="_blank"
                                rel="nofollow"
                                className="dropdown-content-links"
                              >
                                <span className="link-text">
                                  Persone e Famiglie
                                </span>
                                <span className="icon-external-link-gray icon-link"></span>
                              </a>

                              <a
                                href="https://www.intesasanpaolo.com/it/business.html"
                                target="_blank"
                                rel="nofollow"
                                className="dropdown-content-links"
                              >
                                <span className="link-text">Business</span>
                                <span className="icon-external-link-gray icon-link"></span>
                              </a>

                              <a
                                href="https://www.imi.intesasanpaolo.com/home/"
                                target="_blank"
                                rel="nofollow"
                                className="dropdown-content-links"
                              >
                                <span className="link-text">Corporate</span>
                                <span className="icon-external-link-gray icon-link"></span>
                              </a>

                              <a
                                href="https://group.intesasanpaolo.com/it/chi-siamo/presenza-internazionale"
                                target="_self"
                                className="dropdown-content-links"
                              >
                                <span className="link-text">
                                  Presenza Internazionale
                                </span>
                                <span className="icon-narrow-right-gray icon-link"></span>
                              </a>

                              <a
                                href="https://www.intesasanpaolo.com/it/persone-e-famiglie/tutti-i-giorni/intesa-sanpaolo-mobile/la-banca-nell-app/intesa-sanpaolo-mobile.html"
                                target="_blank"
                                rel="nofollow"
                                className="dropdown-content-links app-download"
                              >
                                <span className="link-text">
                                  App Intesa Sanpaolo Mobile
                                </span>
                                <img
                                  className="img-download"
                                  alt="App Intesa Sanpaolo Mobile"
                                  src="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/AppIntesa.png"
                                />
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="header-top__feature-items">
                        <a
                          className="small-copy header-top__notify-item"
                          onClick={() => window.goToLogin()}
                          href="#"
                        >
                          <div className="logged-circle"></div>
                          <div className="text-hover">
                            <span>NOTIFICHE</span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="48px"
                            height="48px"
                            viewBox="0 0 48 48"
                            version="1.1"
                            data-inject-url="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/notifiche.svg"
                            className="svg-injectable svg-img"
                          >
                            {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                            <title>atom/icon/notifiche</title>
                            <desc>Created with Sketch.</desc>
                            <defs>
                              <path
                                d="M10.1798245,37 L18.3414114,37 C19.1890453,37 19.9446205,37.5343248 20.2270912,38.3335078 C20.787202,39.9182071 22.2909517,41 24,41 C25.7090483,41 27.212798,39.9182071 27.7729088,38.3335078 C28.0553795,37.5343248 28.8109547,37 29.6585886,37 L37.8179589,37 L36.5466855,22.4251473 C35.7850774,15.927847 30.4009582,11 24,11 C17.5051437,11 12.0671383,16.0709296 11.4154237,22.6665042 L10.1798245,37 Z M18.0648485,8.11476168 C18.4927988,5.22079064 20.9870527,3 24,3 C27.0133569,3 29.5078793,5.2213944 29.9353258,8.11594179 C35.5741364,10.3197998 39.7714558,15.5798625 40.5255112,22.0184115 L41.9924261,38.8261083 C42.0944304,39.9948592 41.1731938,41 40,41 L30.930077,41 C29.5278474,43.4283101 26.9134268,45 24,45 C21.0865732,45 18.4721526,43.4283101 17.069923,41 L8,41 C6.82765335,41 5.9067028,39.9962447 6.0073899,38.8282298 L7.43250163,22.2981167 C8.07857753,15.7580383 12.3220897,10.3562539 18.0648485,8.11476168 Z"
                                id="path-1--inject-1"
                              ></path>
                            </defs>
                            <g
                              id="atom/icon/notifiche--inject-1"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <mask id="mask-2--inject-1" fill="white">
                                <use xlinkHref="#path-1--inject-1"></use>
                              </mask>
                              <use
                                id="Combined-Shape--inject-1"
                                fill="#fff"
                                xlinkHref="#path-1--inject-1"
                              ></use>
                            </g>
                          </svg>
                        </a>
                        <a
                          href="https://group.intesasanpaolo.com/it/pagine-footer/contatti"
                          target="_self"
                          className="small-copy header-top__contact-item"
                        >
                          <div className="text-hover">
                            <span>CONTATTI</span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/icone/40_icona_contatti.svg"
                            className="svg-injectable svg-img"
                          >
                            <defs>
                              <path
                                id="a--inject-2"
                                d="M8.03 10l15.769 15.77L39.569 10H8.029zM42 13.225L25.213 30.012a2 2 0 0 1-2.828 0L6 13.627V38h36V13.225zM4 6h40a2 2 0 0 1 2 2v32a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2z"
                              ></path>
                            </defs>
                            <g fill="none" fillRule="evenodd">
                              <mask id="b--inject-2" fill="#fff">
                                <use xlinkHref="#a--inject-2"></use>
                              </mask>
                              <use
                                fill="#FFF"
                                fillRule="nonzero"
                                xlinkHref="#a--inject-2"
                              ></use>
                              <g fill="#FFF" mask="url(#b--inject-2)">
                                <path d="M-16-16h80v80h-80z"></path>
                              </g>
                            </g>
                          </svg>
                        </a>

                        <div className="header-top__languages">
                          <div className="dropdown-lang-wrapper">
                            <div className="dropdown-lang-selected">
                              <span className="dropdown-lang-item-selected">
                                {language}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="48px"
                                height="48px"
                                viewBox="0 0 48 48"
                                version="1.1"
                                data-inject-url="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-down-white.svg"
                                className="svg-injectable svg-img"
                              >
                                <defs>
                                  <path
                                    d="M24,31.8416288 L40.4520854,11.7335244 C41.1515406,10.8786348 42.411586,10.7526303 43.2664756,11.4520854 C44.1213652,12.1515406 44.2473697,13.411586 43.5479146,14.2664756 L25.5479146,36.2664756 C24.7477061,37.2445081 23.2522939,37.2445081 22.4520854,36.2664756 L4.4520854,14.2664756 C3.75263025,13.411586 3.87863479,12.1515406 4.73352442,11.4520854 C5.58841405,10.7526303 6.84845945,10.8786348 7.5479146,11.7335244 L24,31.8416288 Z"
                                    id="path-1--inject-3"
                                  ></path>
                                </defs>
                                <g
                                  id="atom/icon/arrow-down--inject-3"
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <mask id="mask-2--inject-3" fill="white">
                                    <use xlinkHref="#path-1--inject-3"></use>
                                  </mask>
                                  <use
                                    id="Stato-normale--inject-3"
                                    fill="#FFF"
                                    xlinkHref="#path-1--inject-3"
                                  ></use>
                                </g>
                              </svg>
                            </div>

                            <div className="dropdown-lang-select-item">
                              <a
                                id={`lang-item-${otherLang}`}
                                className="dropdown-lang-item"
                                href={`///${otherLang}/simulator`}
                                target="_self"
                              >
                                {otherLanguage}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>

              <div className="header no-active-items" data-lang="it">
                <div className="container-fluid">
                  <nav className="header-navbar">
                    <button className="header-navbar-btn-toggler" type="button">
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                    <a
                      className="header-navbar-brand"
                      href="https://group.intesasanpaolo.com/it/"
                      target="_self"
                    >
                      <img
                        className="brand-big img-change"
                        alt="Intesa Sanpaolo"
                        onError={() => {
                          this.onError = null;
                          this.src =
                            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
                        }}
                        data-minwidth1280px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/loghi/logo_isp.svg"
                        data-minwidth1024px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/loghi/logo_isp.svg"
                        data-minwidth768px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/loghi/logo_isp.svg"
                        data-minwidth0px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/loghi/logo_isp.svg"
                        src="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/loghi/logo_isp.svg"
                        style={{ opacity: 1 }}
                      />
                      <img
                        className="brand-print img-change"
                        alt="Intesa Sanpaolo"
                        src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/logo/ISP_logo-01.png"
                        style={{ opacity: 1 }}
                      />
                    </a>
                    <div className="header-navbar-main">
                      <ul className="header-navbar-nav">
                        <li className="main-menu-item mobile-arrow">
                          <a
                            className="h4-copy header-navbar-item background-link-animated white-link single-item"
                            href="https://group.intesasanpaolo.com/it/sezione-editoriale/impegno"
                          >
                            L’ Impegno
                          </a>
                        </li>
                        <li className="main-menu-item mobile-arrow">
                          <a
                            className="h4-copy header-navbar-item background-link-animated white-link single-item"
                            href="https://group.intesasanpaolo.com/it/sezione-editoriale/forza-del-gruppo"
                          >
                            La Forza del Gruppo
                          </a>
                        </li>
                        <li className="main-menu-item">
                          <a
                            className="h4-copy header-navbar-item background-link-animated white-link"
                            href="https://group.intesasanpaolo.com/it/sezione-editoriale/eventi-progetti"
                            id="header-item-3"
                          >
                            Eventi e Progetti
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="header-nav-search">
                      <button
                        className="header-navbar-btn-search"
                        data-name="search-open"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="48px"
                          height="48px"
                          viewBox="0 0 48 48"
                          version="1.1"
                          data-inject-url="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/search.svg"
                          className="svg-injectable"
                        >
                          {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                          <title>atom/icon/search</title>
                          <desc>Created with Sketch.</desc>
                          <defs>
                            <path
                              d="M31.2476413,34.0539751 C28.1664087,36.5231376 24.2556742,38 20,38 C10.0588745,38 2,29.9411255 2,20 C2,10.0588745 10.0588745,2 20,2 C29.9411255,2 38,10.0588745 38,20 C38,24.2556742 36.5231376,28.1664087 34.0539751,31.2476413 C34.1194468,31.298532 34.1824832,31.3540561 34.2426407,31.4142136 L45.5563492,42.7279221 C46.3373978,43.5089706 46.3373978,44.7753006 45.5563492,45.5563492 C44.7753006,46.3373978 43.5089706,46.3373978 42.7279221,45.5563492 L31.4142136,34.2426407 C31.3540561,34.1824832 31.298532,34.1194468 31.2476413,34.0539751 Z M20,34 C27.7319865,34 34,27.7319865 34,20 C34,12.2680135 27.7319865,6 20,6 C12.2680135,6 6,12.2680135 6,20 C6,27.7319865 12.2680135,34 20,34 Z"
                              id="path-1--inject-4"
                            ></path>
                          </defs>
                          <g
                            id="atom/icon/search--inject-4"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <mask id="mask-2--inject-4" fill="white">
                              <use xlinkHref="#path-1--inject-4"></use>
                            </mask>
                            <use
                              id="Combined-Shape--inject-4"
                              fill="#FFF"
                              xlinkHref="#path-1--inject-4"
                            ></use>
                          </g>
                        </svg>
                      </button>
                      <button
                        className="header-navbar-btn-search hide"
                        type="submit"
                        data-name="search-close"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="48px"
                          height="48px"
                          viewBox="0 0 48 48"
                          version="1.1"
                          data-inject-url="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/close-menu.svg"
                          className="svg-injectable"
                        >
                          {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                          <title>atom/icon/close-menu</title>
                          <desc>Created with Sketch.</desc>
                          <defs>
                            <path
                              d="M21.3564113,24.1848385 L10.3861292,13.2145563 C9.51829743,12.3467246 9.44794576,11.0100429 10.2289943,10.2289943 C11.0100429,9.44794576 12.3467246,9.51829743 13.2145563,10.3861292 L24.1848385,21.3564113 L35.8414121,9.69983768 C36.7092439,8.83200592 38.0459255,8.76165426 38.8269741,9.54270284 C39.6080227,10.3237514 39.537671,11.660433 38.6698393,12.5282648 L27.0132656,24.1848385 L38.3561308,35.5277036 C39.2239625,36.3955354 39.2943142,37.732217 38.5132656,38.5132656 C37.732217,39.2943142 36.3955354,39.2239625 35.5277036,38.3561308 L24.1848385,27.0132656 L13.5282648,37.6698393 C12.660433,38.537671 11.3237514,38.6080227 10.5427028,37.8269741 C9.76165426,37.0459255 9.83200592,35.7092439 10.6998377,34.8414121 L21.3564113,24.1848385 Z"
                              id="path-1--inject-6"
                            ></path>
                          </defs>
                          <g
                            id="atom/icon/close-menu--inject-6"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <mask id="mask-2--inject-6" fill="white">
                              <use xlinkHref="#path-1--inject-6"></use>
                            </mask>
                            <use
                              id="Combined-Shape--inject-6"
                              fill="#0B0B0A"
                              xlinkHref="#path-1--inject-6"
                            ></use>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </nav>

                  <div className="header-search header-search-gcs">
                    <div className="search-field">
                      <input type="search" placeholder="Cerca" />

                      <button
                        className="btn-search"
                        type="submit"
                        data-name="search-button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="48px"
                          height="48px"
                          viewBox="0 0 48 48"
                          version="1.1"
                          data-inject-url="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/search.svg"
                          className="svg-injectable"
                        >
                          {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                          <title>atom/icon/search</title>
                          <desc>Created with Sketch.</desc>
                          <defs>
                            <path
                              d="M31.2476413,34.0539751 C28.1664087,36.5231376 24.2556742,38 20,38 C10.0588745,38 2,29.9411255 2,20 C2,10.0588745 10.0588745,2 20,2 C29.9411255,2 38,10.0588745 38,20 C38,24.2556742 36.5231376,28.1664087 34.0539751,31.2476413 C34.1194468,31.298532 34.1824832,31.3540561 34.2426407,31.4142136 L45.5563492,42.7279221 C46.3373978,43.5089706 46.3373978,44.7753006 45.5563492,45.5563492 C44.7753006,46.3373978 43.5089706,46.3373978 42.7279221,45.5563492 L31.4142136,34.2426407 C31.3540561,34.1824832 31.298532,34.1194468 31.2476413,34.0539751 Z M20,34 C27.7319865,34 34,27.7319865 34,20 C34,12.2680135 27.7319865,6 20,6 C12.2680135,6 6,12.2680135 6,20 C6,27.7319865 12.2680135,34 20,34 Z"
                              id="path-1--inject-5"
                            ></path>
                          </defs>
                          <g
                            id="atom/icon/search--inject-5"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <mask id="mask-2--inject-5" fill="white">
                              <use xlinkHref="#path-1--inject-5"></use>
                            </mask>
                            <use
                              id="Combined-Shape--inject-5"
                              fill="#FFF"
                              xlinkHref="#path-1--inject-5"
                            ></use>
                          </g>
                        </svg>
                      </button>
                      <button
                        className="btn-search hide"
                        type="submit"
                        data-name="search-cancel"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="48px"
                          height="48px"
                          viewBox="0 0 48 48"
                          version="1.1"
                          data-inject-url="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/close-menu.svg"
                          className="svg-injectable"
                        >
                          {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                          <title>atom/icon/close-menu</title>
                          <desc>Created with Sketch.</desc>
                          <defs>
                            <path
                              d="M21.3564113,24.1848385 L10.3861292,13.2145563 C9.51829743,12.3467246 9.44794576,11.0100429 10.2289943,10.2289943 C11.0100429,9.44794576 12.3467246,9.51829743 13.2145563,10.3861292 L24.1848385,21.3564113 L35.8414121,9.69983768 C36.7092439,8.83200592 38.0459255,8.76165426 38.8269741,9.54270284 C39.6080227,10.3237514 39.537671,11.660433 38.6698393,12.5282648 L27.0132656,24.1848385 L38.3561308,35.5277036 C39.2239625,36.3955354 39.2943142,37.732217 38.5132656,38.5132656 C37.732217,39.2943142 36.3955354,39.2239625 35.5277036,38.3561308 L24.1848385,27.0132656 L13.5282648,37.6698393 C12.660433,38.537671 11.3237514,38.6080227 10.5427028,37.8269741 C9.76165426,37.0459255 9.83200592,35.7092439 10.6998377,34.8414121 L21.3564113,24.1848385 Z"
                              id="path-1--inject-7"
                            ></path>
                          </defs>
                          <g
                            id="atom/icon/close-menu--inject-7"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <mask id="mask-2--inject-7" fill="white">
                              <use xlinkHref="#path-1--inject-7"></use>
                            </mask>
                            <use
                              id="Combined-Shape--inject-7"
                              fill="#0B0B0A"
                              xlinkHref="#path-1--inject-7"
                            ></use>
                          </g>
                        </svg>
                      </button>
                    </div>

                    <p className="search-text">
                      premi <b>Invio </b>per cercare o <b>ESC </b>per chiudere
                    </p>
                  </div>
                </div>
                <div className="submenu">
                  <div className="header-bottom-submenu">
                    <div className="container-menu">
                      <div
                        className="container-fluid"
                        style={{ maxWidth: "1410px" }}
                      >
                        <div className="container-block">
                          <div className="turn-back">
                            <img
                              className="svg-img"
                              src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-white.svg"
                              alt=""
                            />
                            <span className="btn-back"></span>
                          </div>
                          <div className="go-to-page">
                            <a href="#">
                              Vai a <span className="link-text"></span>
                            </a>
                          </div>
                          <div className="img-preview"></div>
                          <div className="block-menu">
                            <ul>
                              <div className="column"></div>
                              <div className="column"></div>
                              <div className="column"></div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="header-bottom-submenu">
                    <div className="container-menu">
                      <div
                        className="container-fluid"
                        style={{ maxWidth: "1410px" }}
                      >
                        <div className="container-block">
                          <div className="turn-back">
                            <img
                              className="svg-img"
                              src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-white.svg"
                              alt=""
                            />
                            <span className="btn-back"></span>
                          </div>
                          <div className="go-to-page">
                            <a href="#">
                              Vai a <span className="link-text"></span>
                            </a>
                          </div>
                          <div className="img-preview"></div>
                          <div className="block-menu">
                            <ul>
                              <div className="column"></div>

                              <div className="column"></div>

                              <div className="column"></div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="header-bottom-submenu"
                    content="header-item-3"
                  >
                    <div className="container-menu">
                      <div
                        className="container-fluid"
                        style={{ maxWidth: "1410px" }}
                      >
                        <div className="container-block">
                          <div className="turn-back">
                            <img
                              className="svg-img"
                              src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/arrow-left-white.svg"
                              alt=""
                            />
                            <span className="btn-back"></span>
                          </div>
                          <div className="go-to-page">
                            <a href="#">
                              Vai a <span className="link-text"></span>
                            </a>
                          </div>

                          <div className="img-preview"></div>
                          <div className="block-menu">
                            <ul>
                              <div className="column">
                                <li className="list-item">
                                  <a href="https://group.intesasanpaolo.com/it/sezione-editoriale/Cultura-e-tutela-patrimonio-artistico-italiano">
                                    <span className="underline">
                                      Per la Cultura
                                    </span>
                                  </a>
                                </li>

                                <li className="list-item">
                                  <a href="https://group.intesasanpaolo.com/it/sezione-editoriale/eventi-progetti/tutti-gli-eventi">
                                    <span className="underline">
                                      Gli eventi
                                    </span>
                                  </a>
                                </li>
                              </div>

                              <div className="column">
                                <li className="list-item">
                                  <a href="https://group.intesasanpaolo.com/it/sezione-editoriale/innovazione-e-nuovi-modelli-di-business">
                                    <span className="underline">
                                      Per l'Innovazione
                                    </span>
                                  </a>
                                </li>

                                <li className="list-item">
                                  <a href="https://group.intesasanpaolo.com/it/sezione-editoriale/eventi-progetti/tutti-i-progetti">
                                    <span className="underline">
                                      I progetti
                                    </span>
                                  </a>
                                </li>
                              </div>

                              <div className="column">
                                <li className="list-item">
                                  <a href="https://group.intesasanpaolo.com/it/sezione-editoriale/sport-per-tutti">
                                    <span className="underline">
                                      Per lo Sport
                                    </span>
                                  </a>
                                </li>

                                <li className="list-item">
                                  <a href="https://group.intesasanpaolo.com/it/sezione-editoriale/intesa-sanpaolo-on-air">
                                    <span className="underline">
                                      I nostri podcast
                                    </span>
                                  </a>
                                </li>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="header-progress">
                <div className="header-bar" style={{ width: "0%" }}></div>
              </div>

              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: `{
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://group.intesasanpaolo.com",
              "logo": "https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/loghi/logo_isp.svg"
            }`,
                }}
              ></script>
            </div>
          </header>
          <section>
            <div className="breadcrumb-main">
              <div className="container-fluid">
                <div className="breadcrumbs-container">
                  <div className="breadcrumbs-wrapper-left">
                    <div className="link-wrapper device-breadcrumb">
                      <a
                        title="Homepage"
                        className="simple-link x-small-copy green-color"
                        href="/it/"
                        target="_self"
                      >
                        Homepage
                      </a>
                    </div>

                    <div className="link-wrapper device-breadcrumb">
                      <a
                        title="Homepage"
                        className="simple-link x-small-copy green-color"
                        href="/it/"
                        target="_self"
                      >
                        Sostenibilit&agrave;
                      </a>
                    </div>

                    <div className="link-wrapper device-breadcrumb">
                      <a
                        title="Homepage"
                        className="simple-link x-small-copy green-color"
                        href="/it/"
                        target="_self"
                      >
                        Dichiarazione Consolidata Non Finanziaria
                      </a>
                    </div>

                    <span className="x-small-copy warm-grey-color current-bread-page">
                      DCNF 2021 Interattiva
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `{ "@context": "https://schema.org", "@type": "BreadcrumbList", "itemListElement": [{ "@type": "ListItem", "position": 1, "item": { "@id": "/it/", "name": "Homepage" } }, { "@type": "ListItem", "position": 2, "item": { "@id": "/it/research", "name": "Research" } }] }`,
              }}
            ></script>
          </section>
        </div>

        <div className="main-wrapper homepage-toolbar">
          <div
            className="toolbar-json hide"
            dangerouslySetInnerHTML={{
              __html: `{"toolbar":[{"label":"Aggiorna","url":"","key":"update-page"},{"label":"Stampa","url":"","key":"print-page"},{"label":"Calendario
        Finanziario","url":"https://group.intesasanpaolo.com/it/investor-relations/calendario-finanziario","key":"financial-calendar"},{"label":"Press
        kit","url":"https://group.intesasanpaolo.com/it/sala-stampa/PressKit","key":"press-kit"},{"label":"Invia
        CV","url":"https://www.intesasanpaolo.com/it/common/careers.html","key":"send-cv"}]}`,
            }}
          ></div>
          <div className="main-container-tool">
            <div
              className="title-and-abstract section"
              style={{ marginBottom: -10 }}
            >
              <iframe
                id="DCNF"
                frameBorder="0"
                scrolling="no"
                src={`${window.location.protocol}//${window.location.host}/${lang}/home`}
                style={{ width: "100%", height: "1px" }}
              />
            </div>
          </div>
        </div>

        <div className="page-footer isp-default page">
          <div>
            <section>
              <div className="main-footer">
                <div>
                  <div className="footer-top-main">
                    <div className="footer-top container-fluid">
                      <span className="small follow-us">Seguici anche su</span>
                      <a
                        href="https://www.facebook.com/intesasanpaolo"
                        rel="nofollow"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="48px"
                          height="48px"
                          viewBox="0 0 48 48"
                          version="1.1"
                          data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/icone/social_FB.svg"
                          className="svg-injectable img-social img-default"
                        >
                          {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                          <title>atom/icon/social_FB</title>
                          <desc>Created with Sketch.</desc>
                          <defs>
                            <path
                              d="M4,4 L44,4 L44,44 L4,44 L4,4 Z M28.3333333,24 L29.6666667,20 L25.6666667,20 L25.6666667,17.3333333 C25.6849099,16.604644 26.2713107,16.0182433 27,16 L29.6666667,16 L29.6666667,12 L25.6666667,12 C21.2773333,12 21.6666667,15.3733333 21.6666667,17.3333333 L21.6666667,20 L19,20 L19,24 L21.6666667,24 L21.6666667,36 L25.6666667,36 L25.6666667,24 L28.3333333,24 Z"
                              id="path-1--inject-10"
                            ></path>
                          </defs>
                          <g
                            id="atom/icon/social_FB--inject-10"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <mask id="mask-2--inject-10" fill="white">
                              <use xlinkHref="#path-1--inject-10"></use>
                            </mask>
                            <use
                              id="Combined-Shape--inject-10"
                              fill="#FFF"
                              xlinkHref="#path-1--inject-10"
                            ></use>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 48 48"
                          style={{ enableBbackground: "new 0 0 48 48" }}
                          xmlSpace="preserve"
                          data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/icone/social_FB_Roll_over.svg"
                          className="svg-injectable img-social img-hover"
                        >
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{
                              __html: `.st0 {
                          fillRule: evenodd;
                          clip-rule: evenodd;
                          fill: #ffffff;
                        }
                        .st1 {
                          fillRule: evenodd;
                          clip-rule: evenodd;
                        }`,
                            }}
                          ></style>
                          <rect
                            x="12.2"
                            y="8.6"
                            className="st0"
                            width="25"
                            height="32.4"
                          ></rect>
                          <path
                            className="st1"
                            d="M44,4v40H4V4H44z M29.7,12h-4c-4.4,0-4,3.4-4,5.3V20H19v4h2.7v12h4V24h2.7l1.3-4h-4v-2.7c0-0.7,0.6-1.3,1.3-1.3  l0,0h2.7V12z"
                          ></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.youtube.com/user/intesasanpaolo"
                        rel="nofollow"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="48px"
                          height="48px"
                          viewBox="0 0 48 48"
                          version="1.1"
                          data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/icone/social_YT.svg"
                          className="svg-injectable img-social img-default"
                        >
                          {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                          <title>atom/icon/social_YT</title>
                          <desc>Created with Sketch.</desc>
                          <defs>
                            <path
                              d="M4,4 L44,4 L44,44 L4,44 L4,4 Z M21.0010467,29.5163949 L21,20 L30.0493607,24.7746032 L21.0010467,29.5163949 Z M38.7,18.529924 C38.7,18.529924 38.4065625,16.4725641 37.5075,15.5665793 C36.3665625,14.3769494 35.0878125,14.3713511 34.501875,14.3023059 C30.30375,14 24.0065625,14 24.0065625,14 L23.9934375,14 C23.9934375,14 17.69625,14 13.498125,14.3023059 C12.91125,14.3713511 11.6334375,14.3769494 10.4915625,15.5665793 C9.5925,16.4725641 9.3,18.529924 9.3,18.529924 C9.3,18.529924 9,20.9465055 9,23.362154 L9,25.6275825 C9,28.044164 9.3,30.4598125 9.3,30.4598125 C9.3,30.4598125 9.5925,32.5171724 10.4915625,33.4231572 C11.6334375,34.6127871 13.1325,34.5754654 13.8,34.6995601 C16.2,34.9290887 24,35 24,35 C24,35 30.30375,34.9906696 34.501875,34.6883636 C35.0878125,34.6183854 36.3665625,34.6127871 37.5075,33.4231572 C38.4065625,32.5171724 38.7,30.4598125 38.7,30.4598125 C38.7,30.4598125 39,28.044164 39,25.6275825 L39,23.362154 C39,20.9465055 38.7,18.529924 38.7,18.529924 L38.7,18.529924 Z"
                              id="path-1--inject-12"
                            ></path>
                          </defs>
                          <g
                            id="atom/icon/social_YT--inject-12"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <mask id="mask-2--inject-12" fill="white">
                              <use xlinkHref="#path-1--inject-12"></use>
                            </mask>
                            <use
                              id="Combined-Shape--inject-12"
                              fill="#FFF"
                              xlinkHref="#path-1--inject-12"
                            ></use>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 48 48"
                          style={{ enableBackground: "new 0 0 48 48" }}
                          xmlSpace="preserve"
                          data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/icone/social_YT_Roll_over.svg"
                          className="svg-injectable img-social img-hover"
                        >
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{
                              __html: `.st0 {
                          fillRule: evenodd;
                          clip-rule: evenodd;
                          fill: #ffffff;
                        }
                        .st1 {
                          fillRule: evenodd;
                          clip-rule: evenodd;
                        }`,
                            }}
                          ></style>
                          <rect
                            x="6.3"
                            y="7.3"
                            className="st0"
                            width="35.5"
                            height="32.4"
                          ></rect>
                          <path
                            className="st1"
                            d="M4,4h40v40H4V4z M21,29.5l0-9.5l9,4.8L21,29.5z M38.7,18.5c0,0-0.3-2.1-1.2-3c-1.1-1.2-2.4-1.2-3-1.3  C30.3,14,24,14,24,14h0c0,0-6.3,0-10.5,0.3c-0.6,0.1-1.9,0.1-3,1.3c-0.9,0.9-1.2,3-1.2,3S9,20.9,9,23.4v2.3c0,2.4,0.3,4.8,0.3,4.8  s0.3,2.1,1.2,3c1.1,1.2,2.6,1.2,3.3,1.3C16.2,34.9,24,35,24,35s6.3,0,10.5-0.3c0.6-0.1,1.9-0.1,3-1.3c0.9-0.9,1.2-3,1.2-3  S39,28,39,25.6v-2.3C39,20.9,38.7,18.5,38.7,18.5L38.7,18.5z"
                          ></path>
                        </svg>
                      </a>
                      <a
                        href="https://twitter.com/intesasanpaolo"
                        rel="nofollow"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="48px"
                          height="48px"
                          viewBox="0 0 48 48"
                          version="1.1"
                          data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/icone/social_TW.svg"
                          className="svg-injectable img-social img-default"
                        >
                          {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                          <title>atom/icon/social_TW</title>
                          <desc>Created with Sketch.</desc>
                          <defs>
                            <path
                              d="M4,4 L44,4 L44,44 L4,44 L4,4 Z M31.953,19.9800923 C31.962,20.1500923 31.966,20.3300923 31.966,20.5100923 C31.9636872,23.5892641 30.7266378,26.5388947 28.5318306,28.6985534 C26.3370234,30.8582121 23.3678079,32.0474857 20.289,32.0000923 C18.0638902,32.0021471 15.8835978,31.37465 14,30.1900923 C14.319,30.2200923 14.647,30.2400923 14.978,30.2400923 C16.8224854,30.2429681 18.6159408,29.6345999 20.078,28.5100923 C18.3316439,28.4858521 16.792348,27.3579717 16.243,25.7000923 C16.4967527,25.7519912 16.7549977,25.7787871 17.014,25.7800923 C17.3791593,25.7797301 17.742774,25.732682 18.096,25.6400923 C16.1954747,25.2725242 14.8177826,23.6157793 14.803,21.6800923 C14.801167,21.663476 14.801167,21.6467086 14.803,21.6300923 C15.3746807,21.9398392 16.0110761,22.1110974 16.661,22.1300923 C15.5242771,21.3902798 14.8381488,20.1263593 14.837,18.7700923 C14.8353654,18.0558666 15.0267799,17.354474 15.391,16.7400923 C17.4937968,19.2700749 20.5620758,20.8011306 23.848,20.9600923 C23.7806609,20.6580816 23.7471214,20.349518 23.748,20.0400923 C23.7661573,17.7929655 25.6008497,15.9851222 27.848,16.0000923 C28.9774795,15.9971797 30.0590882,16.4559784 30.842,17.2700923 C31.7613552,17.0963114 32.6437055,16.7647533 33.45,16.2900923 C33.1427268,17.2310039 32.5027057,18.0274747 31.65,18.5300923 C32.4622333,18.4322382 33.2558915,18.2166435 34.006,17.8900923 C33.4521022,18.7038797 32.756765,19.4117486 31.953,19.9800923 Z"
                              id="path-1--inject-14"
                            ></path>
                          </defs>
                          <g
                            id="atom/icon/social_TW--inject-14"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <mask id="mask-2--inject-14" fill="white">
                              <use xlinkHref="#path-1--inject-14"></use>
                            </mask>
                            <use
                              id="Combined-Shape--inject-14"
                              fill="#FFF"
                              xlinkHref="#path-1--inject-14"
                            ></use>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 48 48"
                          style={{ enableBackground: "new 0 0 48 48" }}
                          xmlSpace="preserve"
                          data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/icone/social_TW_Roll_over.svg"
                          className="svg-injectable img-social img-hover"
                        >
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{
                              __html: `.st0 {
                          fillRule: evenodd;
                          clip-rule: evenodd;
                          fill: #ffffff;
                        }
                        .st1 {
                          fillRule: evenodd;
                          clip-rule: evenodd;
                        }`,
                            }}
                          ></style>
                          <rect
                            x="11.2"
                            y="7.6"
                            className="st0"
                            width="25"
                            height="32.4"
                          ></rect>
                          <path
                            className="st1"
                            d="M4,4h40v40H4V4z M32,20c0,0.2,0,0.3,0,0.5c0,3.1-1.2,6-3.4,8.2S23.4,32,20.3,32c-2.2,0-4.4-0.6-6.3-1.8  c0.3,0,0.6,0.1,1,0.1c1.8,0,3.6-0.6,5.1-1.7c-1.7,0-3.3-1.2-3.8-2.8c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0,0.7,0,1.1-0.1  c-1.9-0.4-3.3-2-3.3-4c0,0,0,0,0,0c0.6,0.3,1.2,0.5,1.9,0.5c-1.1-0.7-1.8-2-1.8-3.4c0-0.7,0.2-1.4,0.6-2c2.1,2.5,5.2,4.1,8.5,4.2  c-0.1-0.3-0.1-0.6-0.1-0.9c0-2.2,1.9-4.1,4.1-4c1.1,0,2.2,0.5,3,1.3c0.9-0.2,1.8-0.5,2.6-1c-0.3,0.9-0.9,1.7-1.8,2.2  c0.8-0.1,1.6-0.3,2.4-0.6C33.5,18.7,32.8,19.4,32,20z"
                          ></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/intesa-sanpaolo"
                        rel="nofollow"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="48px"
                          height="48px"
                          viewBox="0 0 48 48"
                          version="1.1"
                          data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/icone/social_IN.svg"
                          className="svg-injectable img-social img-default"
                        >
                          {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                          <title>atom/icon/social_IN</title>
                          <desc>Created with Sketch.</desc>
                          <defs>
                            <path
                              d="M4,4 L44,4 L44,44 L4,44 L4,4 Z M33.9941406,33.0855824 L33.9941406,26.2217993 C33.9941406,22.398373 31.8187932,20.6227953 29.0499556,20.6227953 C26.8181972,20.6227953 25.4196757,21.831724 25.1611255,22.6787887 L25.1611255,20.7414774 L20.7845756,20.7414774 C20.8409866,21.7700559 20.7845756,33.0855824 20.7845756,33.0855824 L25.1611255,33.0855824 L25.1611255,26.4056402 C25.1611255,26.0344677 25.143497,25.6632951 25.2563189,25.3968421 C25.5571774,24.654497 26.2035529,23.8842267 27.3658536,23.8842267 C28.8854236,23.8842267 29.5764578,25.0256696 29.5764578,26.6976913 L29.5764578,33.0855824 L33.9941406,33.0855824 Z M16.3586662,19.1974462 C17.8230006,19.1974462 18.7349777,18.2503163 18.7349777,17.0541866 C18.7067722,15.8359494 17.8230006,14.9144176 16.3856965,14.9144176 C14.9472172,14.9144176 14.0058594,15.8382765 14.0058594,17.0576772 C14.0058594,18.2538069 14.9201869,19.1974462 16.3304608,19.1974462 L16.3586662,19.1974462 Z M14.5100323,20.7414774 L14.5100323,33.0855824 L18.4059138,33.0855824 L18.4059138,20.7414774 L14.5100323,20.7414774 Z"
                              id="path-1--inject-16"
                            ></path>
                          </defs>
                          <g
                            id="atom/icon/social_IN--inject-16"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <mask id="mask-2--inject-16" fill="white">
                              <use xlinkHref="#path-1--inject-16"></use>
                            </mask>
                            <use
                              id="Combined-Shape--inject-16"
                              fill="#FFF"
                              xlinkHref="#path-1--inject-16"
                            ></use>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 48 48"
                          style={{ enableBackground: "new 0 0 48 48" }}
                          xmlSpace="preserve"
                          data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/icone/social_IN_Roll_over.svg"
                          className="svg-injectable img-social img-hover"
                        >
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{
                              __html: `.st0 {
                          fillRule: evenodd;
                          clip-rule: evenodd;
                          fill: #ffffff;
                        }
                        .st1 {
                          fillRule: evenodd;
                          clip-rule: evenodd;
                        }`,
                            }}
                          ></style>
                          <rect
                            x="11.2"
                            y="8.6"
                            className="st0"
                            width="25"
                            height="32.4"
                          ></rect>
                          <path
                            className="st1"
                            d="M44,4v40H4V4H44z M29,20.6c-2.1,0-3.5,1.1-3.9,2l0,0.1v-1.9h-4.4c0.1,1,0,11.5,0,12.3l0,0h4.4v-6.7  c0-0.4,0-0.7,0.1-1c0.3-0.7,0.9-1.5,2.1-1.5c1.5,0,2.2,1.1,2.2,2.6l0,0.2v6.4H34v-6.9C34,22.4,31.8,20.6,29,20.6z M18.4,20.7h-3.9  v12.3h3.9V20.7z M16.4,14.9c-1.4,0-2.4,0.9-2.4,2.1c0,1.1,0.8,2.1,2.1,2.1l0.2,0h0c1.5,0,2.4-0.9,2.4-2.1  C18.7,15.8,17.8,14.9,16.4,14.9z"
                          ></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.instagram.com/intesasanpaolo"
                        rel="nofollow"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="48px"
                          height="48px"
                          viewBox="0 0 48 48"
                          version="1.1"
                          data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/icone/social_Instagram.svg"
                          className="svg-injectable img-social img-default"
                        >
                          {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                          <title>atom/icon/social_Instagram</title>
                          <desc>Created with Sketch.</desc>
                          <defs>
                            <path
                              d="M4,4 L44,4 L44,44 L4,44 L4,4 Z M31.0190648,19.3466405 C29.8909726,19.3466405 28.9747761,18.430444 28.9747761,17.3043699 C28.9747761,16.1762777 29.8909726,15.2600812 31.0190648,15.2600812 C32.1451389,15.2600812 33.0633535,16.1762777 33.0633535,17.3043699 C33.0633535,18.430444 32.1451389,19.3466405 31.0190648,19.3466405 M24,30 C20.6919026,30 18,27.3100724 18,24 C18,20.6919026 20.6919026,18 24,18 C27.3080974,18 30,20.6919026 30,24 C30,27.3100724 27.3080974,30 24,30 M32.4857217,12 L15.5142783,12 C13.5769464,12 12,13.5769464 12,15.5163614 L12,32.4857217 C12,34.4230536 13.5769464,36 15.5142783,36 L32.4857217,36 C34.4230536,36 36,34.4230536 36,32.4857217 L36,15.5163614 C36,13.5769464 34.4230536,12 32.4857217,12 M24,20 C21.7943292,20 20,21.796022 20,24 C20,26.2056708 21.7943292,28 24,28 C26.2056708,28 28,26.2056708 28,24 C28,21.796022 26.2056708,20 24,20"
                              id="path-1--inject-18"
                            ></path>
                          </defs>
                          <g
                            id="atom/icon/social_Instagram--inject-18"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <mask id="mask-2--inject-18" fill="white">
                              <use xlinkHref="#path-1--inject-18"></use>
                            </mask>
                            <use
                              id="Combined-Shape--inject-18"
                              fill="#FFF"
                              xlinkHref="#path-1--inject-18"
                            ></use>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 48 48"
                          style={{ enableBackground: "new 0 0 48 48" }}
                          xmlSpace="preserve"
                          data-inject-url="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/immagini-cross/icone/icone/social_IG_Roll_over.svg"
                          className="svg-injectable img-social img-hover"
                        >
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{
                              __html: `.st0 {
                          fill: #ffffff;
                        }`,
                            }}
                          ></style>
                          <rect x="4" y="4" width="40" height="40"></rect>
                          <path
                            className="st0"
                            d="M32.5,12h-17c-1.9,0-3.5,1.6-3.5,3.5v17c0,1.9,1.6,3.5,3.5,3.5h17c1.9,0,3.5-1.6,3.5-3.5v-17  C36,13.6,34.4,12,32.5,12z M24,30c-3.3,0-6-2.7-6-6c0-3.3,2.7-6,6-6s6,2.7,6,6C30,27.3,27.3,30,24,30z M31,19.3c-1.1,0-2-0.9-2-2  c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C33.1,18.4,32.1,19.3,31,19.3z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>

                  <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                      __html: `{
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "name": "Intesa Sanpaolo",
                  "url": "https://group.intesasanpaolo.com",
                  "sameAs": [
                    "https://www.facebook.com/intesasanpaolo",
                    "https://www.youtube.com/user/intesasanpaolo",
                    "https://twitter.com/intesasanpaolo",
                    "https://www.linkedin.com/company/intesa-sanpaolo",
                    "https://www.instagram.com/intesasanpaolo"
                  ]
                }`,
                    }}
                  ></script>
                </div>
                <div>
                  <div className="footer-center-main">
                    <nav className="footer accordion container-fluid">
                      <section className="footer-column">
                        <div className="footer-title title">
                          <span className="h6-copy">I Valori</span>
                        </div>
                        <div className="accordion-content">
                          <ul>
                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/sezione-editoriale/forza-del-gruppo"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                La Forza del Gruppo
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/sezione-editoriale/impegno"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                L' Impegno
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/sezione-editoriale/eventi-progetti"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                Eventi e Progetti
                              </a>
                            </li>
                          </ul>
                        </div>
                      </section>
                      <section className="footer-column">
                        <div className="footer-title title">
                          <span className="h6-copy">Il Gruppo</span>
                        </div>
                        <div className="accordion-content">
                          <ul>
                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/chi-siamo"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                Chi Siamo
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/investor-relations"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                Investor Relations
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/governance"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                Governance
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/sostenibilita"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                Sostenibilità
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/sociale"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                Sociale
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/research"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                Research
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/sala-stampa"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                Newsroom
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/careers"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                Careers
                              </a>
                            </li>
                          </ul>
                        </div>
                      </section>
                      <section className="footer-column">
                        <div className="footer-title title">
                          <span className="h6-copy">Link Utili</span>
                        </div>

                        <ul className="accordion-content">
                          <li>
                            <a
                              href="https://group.intesasanpaolo.com/it/sezione-editoriale/intesa-sanpaolo-on-air"
                              target="_self"
                              className="item small-copy background-link-animated white-link"
                            >
                              Intesa Sanpaolo On Air
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://group.intesasanpaolo.com/it/sezione-editoriale/grattacielo-intesa-sanpaolo"
                              rel="nofollow"
                              target="_blank"
                              className="item small-copy background-link-animated white-link"
                            >
                              Il grattacielo
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://www.intesasanpaolo.com/it/persone-e-famiglie.html"
                              rel="nofollow"
                              target="_blank"
                              className="item small-copy background-link-animated white-link"
                            >
                              Persone e Famiglie
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://www.intesasanpaolo.com/it/business.html"
                              rel="nofollow"
                              target="_blank"
                              className="item small-copy background-link-animated white-link"
                            >
                              Business
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://www.imi.intesasanpaolo.com/home"
                              rel="nofollow"
                              target="_blank"
                              className="item small-copy background-link-animated white-link"
                            >
                              Corporate
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://www.imi.intesasanpaolo.com/home/La-nostra-offerta/Financial-Institutions.html"
                              rel="nofollow"
                              target="_blank"
                              className="item small-copy background-link-animated white-link"
                            >
                              Financial Institutions
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://www.imi.intesasanpaolo.com/home/La-nostra-offerta/Corporate-ed-Enti-Pubblici.html"
                              rel="nofollow"
                              target="_blank"
                              className="item small-copy background-link-animated white-link"
                            >
                              Public Finance
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://group.intesasanpaolo.com/it/chi-siamo/storia"
                              target="_self"
                              className="item small-copy background-link-animated white-link"
                            >
                              Storico UBI Banca
                            </a>
                          </li>
                        </ul>
                      </section>
                      <section className="footer-column">
                        <div className="footer-title title">
                          <span className="h6-copy">Area Utente</span>
                        </div>
                        <div className="accordion-content">
                          <ul>
                            <li>
                              <a
                                href="https://group.intesasanpaolo.com/it/pagine-footer/contatti"
                                target="_self"
                                className="item small-copy background-link-animated white-link"
                              >
                                Contatti
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() => goToLogin()}
                                href="#"
                                className="item small-copy background-link-animated white-link"
                              >
                                Notifiche
                              </a>
                            </li>
                          </ul>
                          <div className="dropdown-lang-wrapper">
                            <span className="title">Lingua</span>

                            <div className="dropdown-lang-selected">
                              <span className="dropdown-lang-item-selected small-copy">
                                Italiano
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="48px"
                                height="48px"
                                viewBox="0 0 48 48"
                                version="1.1"
                                data-inject-url="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all/images/icons/dropdown-arrow-close.svg"
                                className="svg-injectable svg-img"
                              >
                                {/** <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
                                <title>atom/icon/dropdown-arrow-close</title>
                                <desc>Created with Sketch.</desc>
                                <defs>
                                  <path
                                    d="M38.0040095,6.00400951 L38.0040095,30.0040095 C38.0040095,31.108579 37.108579,32.0040095 36.0040095,32.0040095 L12.0040095,32.0040095 C10.2221998,32.0040095 9.32986622,29.8497257 10.5897959,28.5897959 L34.5897959,4.58979595 C35.8497257,3.32986622 38.0040095,4.22219981 38.0040095,6.00400951 Z"
                                    id="path-1--inject-20"
                                  ></path>
                                </defs>
                                <g
                                  id="atom/icon/dropdown-arrow-close--inject-20"
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <mask id="mask-2--inject-20" fill="#cccccc">
                                    <use xlinkHref="#path-1--inject-20"></use>
                                  </mask>
                                  <use
                                    id="Rectangle--inject-20"
                                    fill="#cccccc"
                                    transform="translate(24.002005, 18.002005) rotate(45.000000) translate(-24.002005, -18.002005) "
                                    xlinkHref="#path-1--inject-20"
                                  ></use>
                                </g>
                              </svg>
                            </div>

                            <div className="dropdown-lang-select-item">
                              <a
                                id="lang-item-EN"
                                className="dropdown-lang-item x-small-copy"
                                href="https://group.intesasanpaolo.com/en/"
                                target="_self"
                              >
                                Inglese
                              </a>

                              <a
                                id="lang-item-IT"
                                className="dropdown-lang-item x-small-copy"
                                href="https://group.intesasanpaolo.com/it/"
                                rel="nofollow"
                                target="_blank"
                              >
                                Italiano
                              </a>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="footer-column other-links">
                        <div className="footer-title title">
                          <span className="h6-copy">Altri Link</span>
                        </div>
                        <div className="accordion-content">
                          <ul>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="https://portalefornitori.intesasanpaolo.com/scriptPfor0/PortaleFornitori/fo/Generale/index.do"
                                target="_blank"
                                rel="nofollow"
                              >
                                Fornitori
                              </a>
                            </li>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="https://group.intesasanpaolo.com/it/pagine-footer/glossario"
                                target="_self"
                              >
                                Glossario
                              </a>
                            </li>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="https://group.intesasanpaolo.com/it/pagine-footer/psd"
                                target="_self"
                              >
                                PSD
                              </a>
                            </li>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="https://group.intesasanpaolo.com/content/dam/portalgroup/repository-documenti/public/Contenuti/RISORSE/Documenti%20PDF/SSM/CNT-05-0000000275100.pdf"
                                target="_blank"
                                rel="nofollow"
                              >
                                SSM
                              </a>
                            </li>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="https://group.intesasanpaolo.com/it/pagine-footer/cert-e-cyber-security"
                                target="_self"
                              >
                                CSIRT - CERT
                              </a>
                            </li>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="https://www.intesasanpaolo.com/it/common/footer/trasparenza.html"
                                target="_blank"
                                rel="nofollow"
                              >
                                Trasparenza
                              </a>
                            </li>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="/it/pagine-footer/dati-sociali"
                                target="_self"
                              >
                                Dati Sociali
                              </a>
                            </li>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="https://www.intesasanpaolo.com/it/common/footer/privacy.html"
                                target="_blank"
                                rel="nofollow"
                              >
                                Privacy
                              </a>
                            </li>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="https://www.intesasanpaolo.com/it/common/footer/cookies.html"
                                target="_blank"
                                rel="nofollow"
                              >
                                Cookies
                              </a>
                            </li>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="https://group.intesasanpaolo.com/it/pagine-footer/disclaimer"
                                target="_self"
                              >
                                Disclamer
                              </a>
                            </li>
                            <li>
                              <a
                                className="background-link-animated white-link item small-copy"
                                href="https://group.intesasanpaolo.com/it/pagine-footer/aml-cft"
                                target="_self"
                              >
                                AML - CFT
                              </a>
                            </li>
                          </ul>
                        </div>
                      </section>
                    </nav>
                  </div>
                </div>
                <div>
                  <div className="footer-lower-main">
                    <nav className="footer-lower container-fluid">
                      <div className="footer-lower-wrapper">
                        <div className="footer-lower-links">
                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://portalefornitori.intesasanpaolo.com/scriptPfor0/PortaleFornitori/fo/Generale/index.do"
                            rel="nofollow"
                            target="_blank"
                          >
                            Fornitori
                          </a>

                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://group.intesasanpaolo.com/it/pagine-footer/glossario"
                            target="_self"
                          >
                            Glossario
                          </a>

                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://group.intesasanpaolo.com/it/pagine-footer/psd"
                            target="_self"
                          >
                            PSD
                          </a>

                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://group.intesasanpaolo.com/content/dam/portalgroup/repository-documenti/public/Contenuti/RISORSE/Documenti%20PDF/SSM/CNT-05-0000000275100.pdf"
                            rel="nofollow"
                            target="_blank"
                          >
                            SSM
                          </a>

                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://group.intesasanpaolo.com/it/pagine-footer/cert-e-cyber-security"
                            target="_self"
                          >
                            CSIRT - CERT
                          </a>

                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://www.intesasanpaolo.com/it/common/footer/trasparenza.html"
                            rel="nofollow"
                            target="_blank"
                          >
                            Trasparenza
                          </a>

                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://group.intesasanpaolo.com/it/pagine-footer/dati-sociali"
                            target="_self"
                          >
                            Dati Sociali
                          </a>

                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://www.intesasanpaolo.com/it/common/footer/privacy.html"
                            rel="nofollow"
                            target="_blank"
                          >
                            Privacy
                          </a>

                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://www.intesasanpaolo.com/it/common/footer/cookies.html"
                            rel="nofollow"
                            target="_blank"
                          >
                            Cookies
                          </a>

                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://group.intesasanpaolo.com/it/pagine-footer/disclaimer"
                            target="_self"
                          >
                            Disclamer
                          </a>

                          <a
                            className="background-link-animated white-link x-small-copy"
                            href="https://group.intesasanpaolo.com/it/pagine-footer/aml-cft"
                            target="_self"
                          >
                            AML - CFT
                          </a>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                <div>
                  <div className="footer-final-main">
                    <div className="footer-final container-fluid">
                      <div className="footer-column-left">
                        <a
                          href="https://group.intesasanpaolo.com/it/"
                          target="_self"
                        >
                          <img
                            className="img-change"
                            data-minwidth1280px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/loghi/logo-isp.png"
                            data-minwidth1024px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/loghi/logo-isp.png"
                            data-minwidth768px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/loghi/logo-isp.png"
                            data-minwidth0px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/loghi/logo-isp.png"
                            src="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/loghi/logo-isp.png"
                            alt="Intesa Sanpaolo"
                            style={{ opacity: 1 }}
                          />
                        </a>
                      </div>
                      <div className="footer-column-right">
                        <div className="group">
                          <a
                            href="https://www.intesasanpaolo.com/it/common/footer/trasparenza.html"
                            rel="nofollow"
                            target="_blank"
                          >
                            <img
                              className="img-change"
                              data-minwidth1280px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/footer/trasparenza.png"
                              data-minwidth1024px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/footer/trasparenza.png"
                              data-minwidth768px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/footer/trasparenza.png"
                              data-minwidth0px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/footer/trasparenza.png"
                              src="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/footer/trasparenza.png"
                              alt="Trasparenza"
                              style={{ opacity: 1 }}
                            />
                          </a>
                        </div>

                        <div className="fondo">
                          <a
                            href="http://www.fondidigaranzia.it/"
                            rel="nofollow"
                            target="_blank"
                          >
                            <img
                              className="img-change"
                              data-minwidth1280px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/footer/fondo di garanzia.png"
                              data-minwidth1024px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/footer/fondo di garanzia.png"
                              data-minwidth768px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/footer/fondo di garanzia.png"
                              data-minwidth0px="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/footer/fondo di garanzia.png"
                              src="https://group.intesasanpaolo.com/content/dam/portalgroup/nuove-immagini/comunicazione-e-immagine/footer/fondo di garanzia.png"
                              alt="Fondo di garanzia"
                              style={{ opacity: 1 }}
                            />
                          </a>
                        </div>
                        <div className="pmi">
                          <span>
                            per le PMI del Ministero dello Sviluppo Economico
                            (Legge 662/96 )
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-printable">
                    <div className="container-fluid">
                      <div className="wrapper-footer-pages">
                        <p className="small-copy grey-color">
                          Copyright 2019 © Intesa Sanpaolo
                        </p>
                        <p className="small-copy grey-color">
                          Stampa del 13/5/2022 alle 21:52:54
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <script
          type="text/javascript"
          src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-all.js"
        ></script>

        <script
          type="text/javascript"
          src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-jquery.js"
        ></script>
        <script
          type="text/javascript"
          src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-disclaimer.js"
        ></script>

        <script
          type="text/javascript"
          src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-tealium.js"
        ></script>

        <script
          type="text/javascript"
          src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-last-podcast.js"
        ></script>
        <script
          type="text/javascript"
          src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-last-event-project.js"
        ></script>

        <script
          type="text/javascript"
          src="https://group.intesasanpaolo.com/etc/designs/portalgroup/clientlib-image-zoom.js"
        ></script>
      </body>
    </Fragment>
  );
};

export default React.memo(Simulator);
