import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import TextSection from "../../components/TextSection";
import ContainerWrapper from "../../components/ContainerWrapper";
import CardImage from "../../components/CardImage";
import MaterialityMatrix from "../../components/MaterialityMatrix";
import {
  DirittiUmani,
  EmergenzaCovid,
  HomeCover,
  HomeVideo1Bg,
  HomeVideo2Bg,
} from "../../assets/images";
import { ArrowRightIcon } from "../../assets/icons";
import { PlayIcon } from "../../assets/icons";
import Modal from "react-modal";
import Menu from "../../components/Menu";
import PartnerLogos from "../../components/PartnerLogos";
import AwardLogos from "../../components/AwardLogos";
import { Retry, iFrameResizerListener } from "./../../utils";
import configuration from "./../../configuration.json";

const Home = () => {
  const modalStyle = {
    overlay: { backgroundColor: "rgba(0, 0, 0, 0.55)", zIndex: 100 },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      height: "586px",
      width: "70%",
      border: "0px",
      transitionProperty: "top",
      transitionDuration: "0.5s",
    },
  };

  const ref = useRef(true);
  const { t, ready } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const openUrl = (url) => window.open(url);

  const [showVideo, setShowVideo] = useState(null);
  const [heroImgHeight, setHeroImgHeight] = useState(1300);
  const [videoModalStyles, setVideoModalStyles] = useState(modalStyle);

  const setVideoLayout = (sizes) => {
    const { elements, members } = sizes;
    const ispHeader = elements["page-header"]?.[0];
    const ispBreadcrumb = elements["header-main"]?.[0];

    const offset = members.visualViewport?.height / 2; //- (maxHeight) / 2;
    const position =
      members.visualViewport?.height +
      members?.scrollY +
      2 -
      (ispHeader?.clientHeight + ispBreadcrumb?.clientHeight) -
      offset;

    if (ref.current) {
      if (isNaN(position)) {
        setVideoModalStyles(modalStyle);
      } else {
        setVideoModalStyles({
          ...modalStyle,
          content: { ...modalStyle.content, top: position },
        });
      }
    }
  };

  const setHeroImgLayout = (sizes) => {
    const { elements, members } = sizes;
    const ispHeader = elements["page-header"]?.[0];
    const ispBreadcrumb = elements["header-main"]?.[0];

    const fullHeight =
      members.visualViewport?.height -
      (ispHeader?.clientHeight + ispBreadcrumb?.clientHeight);

    if (isNaN(fullHeight)) return;

    if (ref.current) {
      setHeroImgHeight(fullHeight);
    }
  };

  function openModal(url) {
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        retryVideo.start({
          condition: () => window.autoResizer,
          onSuccess: () => {
            window.autoResizer.addOnParentSizesResponse(setVideoLayout);
            window.autoResizer.requestParentSizes({
              byClassNames: ["page-header", "header-main"],
              byMemberNames: ["visualViewport", "scrollY"],
            });
          },
          onFailure: () =>
            console.debug("autoResizer not found in Home index.jsx:89"),
        });
        break;

      case configuration.menu.positioning.algorithms.Infer:
        iFrameResizerListener.listen();
        break;
    }
    if (ref.current) {
      setShowVideo(url);
    }
  }

  useEffect(() => {
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        retryPage.start({
          condition: () => window.autoResizer,
          onSuccess: () => {
            window.autoResizer.addOnParentSizesResponse(setHeroImgLayout);
            window.autoResizer.requestParentSizes({
              byClassNames: ["page-header", "header-main"],
              byMemberNames: ["visualViewport", "scrollY"],
            });
          },
          onFailure: () =>
            console.log("autoResizer not found in Home index.jsx:114"),
        });
        break;

      case configuration.menu.positioning.algorithms.Infer:
        iFrameResizerListener.listen();
        break;
    }
    return () => {
      ref.current = false;
    };
  }, []);

  const trigger = () => {
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        retryTrigger.start({
          condition: () => window.autoResizer,
          onSuccess: () => window.autoResizer.trigger(),
          onFailure: () =>
            console.debug("autoResizer not found in Home index.jsx:150"),
        });
        break;

      case configuration.menu.positioning.algorithms.Infer:
        iFrameResizerListener.listen();
        break;
    }
  };

  const retryTrigger = new Retry("Home:Trigger");
  const retryPage = new Retry("Home:Page");
  const retryVideo = new Retry("Home:Video");

  const setAllSizes = (sizes) => {
    setVideoLayout(sizes);
    setHeroImgLayout(sizes);
  };

  useEffect(() => {
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        retryTrigger.register();
        retryPage.register();
        retryVideo.register();
        trigger();
        return () => {
          retryTrigger.unregister();
          retryPage.unregister();
          retryVideo.unregister();
        };

      default:
        iFrameResizerListener.register("Home", setAllSizes);
        trigger();
        return () => {
          iFrameResizerListener.unregister("Home");
        };
    }
  }, []);

  useEffect(() => {
    trigger();
  }, [location]);

  const setUrl = (url) => {
    if (ref.current) {
      setShowVideo(url);
    }
  };

  return (
    !!ready && (
      <>
        {showVideo && (
          <Modal
            isOpen={!!showVideo}
            onRequestClose={() => openModal(null)}
            style={videoModalStyles}
            ariaHideApp={false}
          >
            <iframe
              width="100%"
              height="100%"
              src={`${showVideo}?controls=0`}
              title="YouTube video player"
              frameBorder="0"
              controls="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Modal>
        )}
        <div style={{ height: `calc(${heroImgHeight}px - 0.5rem)` }}>
          <CardImage
            id="heroImg"
            backgroundImage={`url(${HomeCover})`}
            classes="h-full w-full bg-cover bg-no-repeat bg-center"
          >
            <div className="flex justify-center items-center px-16 flex-col h-full w-full text-center bg-overlay text-white">
              <h3 className="hero title mb-4">{t("Home.Cover.title")}</h3>
              <p className="hero subtitle mb-12">{t("Home.Cover.caption")}</p>
              <a href={t("Generic.DownloadSection1.link")} download>
                <button
                  type="button"
                  className="transition-colors duration-500 hover:bg-[#0B0B0A] font regular line-height-22 w-full inline-block px-5 py-3 md:px-8 md:py-6 w-auto text-white text-base leading-normal uppercase bg-green"
                >
                  {t("Home.Cover.button")}
                </button>
              </a>
            </div>
          </CardImage>
        </div>

        <ContainerWrapper>
          <section className="columns-1 mb-9 md:mb-4 mt-10 w-full">
            <div
              className="relative group w-full md:min-w-0 cursor-pointer group h-[350px] lg:h-[399px] h-auto overflow-hidden"
              onClick={() => setUrl(t("Home.Video1.url"))}
            >
              <CardImage
                backgroundImage={`url(${HomeVideo1Bg})`}
                overridingStyle={{backgroundPosition: 'right'}}
                classes="h-full w-full aspect-video bg-cover bg-no-repeat scale-[1] ease-in group-hover:scale-110 duration-200 overflow-hidden"
              />
              <div className="absolute top-0 bottom-0 right-0 left-0 backgroundGradient">
                <div className="flex items-start  justify-between p-[21px] flex-col h-full w-full text-white">
                  <div className="w-[41px] h-[49px] lg:h-[60px] lg:w-[60px] h-auto flex justify-end self-end">
                    <PlayIcon
                      width="100%"
                      height="100%"
                      classes="stroke-white group-hover:stroke-green"
                    />
                  </div>
                  <div className="flex-initial w-[270px] lg:w-[430px] p-[15px]">
                    <p className="font line-height-h1-40 text-xs font-bold mb-4 uppercase">
                      {t("Home.Video1.title")}
                    </p>
                    <span className="font line-height-35-imp text-size26  w-9/12 mb-4 lg:mb-2 font-light link-underline hover:link-underline-white">
                      {t("Home.Video1.subtitle")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="px-4">
            <TextSection
              customClasses={{
                "font lg:line-height-30-imp lg:mb-6": "h3",
                "font line-height-30-imp font-light": "h3 p1",
                "mb-1.5 lg:mb-8": "p1",
              }}
              classes={`pt-16 lg:mr-[20%]`}
              h3={t("Home.TextSection1.title")}
              p1={t("Home.TextSection1.caption")}
              p4={t("Home.TextSection1.paragraph")}
            />
          </div>

          <MaterialityMatrix />
          <section className="md:grid grid-cols-[7fr_5fr] grid-rows-1 gap-x-8 mt-9 md:mt-20 md:gap-8 md:mb-8">
            <div
              className="relative group w-full md:min-w-0 cursor-pointer group h-[350px] lg:h-[610px] h-auto overflow-hidden mb-5"
              onClick={() => openUrl(t("Home.CardImage1.link"))}
            >
              <CardImage
                backgroundImage={`url(${DirittiUmani})`}
                classes="w-full h-full md:min-w-0 aspect-video bg-cover bg-no-repeat scale-[1] ease-in group-hover:scale-110 duration-200 overflow-hidden"
              />
              <div className="absolute top-0 bottom-0 right-0 left-0 flex items-end justify-between p-[21px] flex-col h-full w-full text-white bg-overlay">
                <div className="w-[40px] lg:w-[60px] h-auto flex justify-end self-end">
                  <ArrowRightIcon
                    width="30px"
                    height="30px"
                    classes="stroke-white group-hover:stroke-green"
                  />
                </div>
                <div className="p-[15px]">
                  <p className="font lg:line-height-h1-40 text-xs font-bold mb-4 uppercase">
                    {t("Home.CardImage1.title")}
                  </p>
                  <span className="font lg:line-height-35-imp lg:text-size26 mb-6 font-light link-underline hover:link-underline-white">
                    {t("Home.CardImage1.paragraph")}
                  </span>
                </div>
              </div>
            </div>

            <div
              className="relative group w-full md:min-w-0 cursor-pointer group h-[350px] lg:h-[610px] h-auto overflow-hidden mb-5"
              onClick={() => navigate(t("Home.CardImage2.link"))}
            >
              <CardImage
                backgroundImage={`url(${EmergenzaCovid})`}
                classes="h-full w-full aspect-video bg-cover bg-no-repeat scale-[1] ease-in group-hover:scale-110 duration-200 overflow-hidden"
              />
              <div className="absolute top-0 bottom-0 right-0 left-0">
                <div className="flex items-end  justify-between p-[21px] flex-col h-full w-full text-white bg-overlay">
                  <div className="w-[40px] lg:w-[60px] h-auto flex justify-end self-end">
                    <ArrowRightIcon
                      width="30px"
                      height="30px"
                      classes="stroke-white group-hover:stroke-green"
                    />
                  </div>
                  <div className="p-[15px]">
                    <p className="font lg:line-height-h1-40 text-xs font-bold mb-4 uppercase">
                      {t("Home.CardImage2.title")}
                    </p>
                    <span className="font lg:line-height-35-imp lg:text-size26 mb-6 font-light link-underline hover:link-underline-white">
                      {t("Home.CardImage2.paragraph")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="columns-1 mb-9 md:mb-20">
            <div
              className="relative group w-full md:min-w-0 cursor-pointer group h-[350px] lg:h-[613px] h-auto overflow-hidden"
              onClick={() => setUrl(t("Home.Video2.url"))}
            >
              <CardImage
                backgroundImage={`url(${HomeVideo2Bg})`}
                classes="h-full w-full aspect-video bg-cover bg-no-repeat scale-[1] ease-in group-hover:scale-110 duration-200 overflow-hidden"
              />
              <div className="absolute top-0 bottom-0 right-0 left-0">
                <div className="flex items-start  justify-between p-[21px] flex-col h-full w-full text-white bg-overlay">
                  <div className="w-[41px] h-[49px] lg:h-[60px] lg:w-[60px] h-auto flex justify-end self-end">
                    <PlayIcon
                      width="100%"
                      height="100%"
                      classes="stroke-white group-hover:stroke-green"
                    />
                  </div>
                  <div className="flex-initial p-[15px]">
                    <p className="font line-height-h1-40 text-xs font-bold mb-4 uppercase">
                      {t("Home.Video2.title")}
                    </p>
                    <h4 className="font line-height-35-imp text-size26  mb-4 lg:mb-2 font-light link-underline hover:link-underline-white">
                      {t("Home.Video2.subtitle")}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="px-4">
            <TextSection
              customClasses={{ "mb-8": "h3", "font-light": "h3" }}
              classes="pt-16 mr-12 lg:mr-[20%]"
              h3={t("Home.TextSection2.title")}
              p4={t("Home.TextSection2.paragraph")}
            />
            <PartnerLogos />
            <TextSection
              customClasses={{ "font line-height-22-imp light": "p4" }}
              p4={t("Home.TextSection3.paragraph")}
            />
            <AwardLogos />
          </div>
        </ContainerWrapper>

        <Menu />
      </>
    )
  );
};

Home.propTypes = {};

export default React.memo(Home);
