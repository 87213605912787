import React from "react";
import PropTypes from "prop-types";

const LinkIcon = ({
  width = "23",
  height = "24",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 23 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.23 3.56006H1V22.1301H19.51V12.7901"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.84 11.89L21.74 1H14.52H21.74V8.07"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LinkIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
  color: PropTypes.string,
};
export default React.memo(LinkIcon);
