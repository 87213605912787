import React from "react";
import PropTypes from "prop-types";

const PlayIcon = ({
  width = "49",
  height = "41",
  classes = "stroke-white",
}) => {
    return (
        <svg
            className={classes}
            style={{ width, height }}
            viewBox={`0 0 49 41`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_1601_3430)">
                <rect x="5.01428" y="1.93427" width="38.8829" height="29.9086" rx="5" strokeWidth="2"/>
                <path d="M30.9371 16.39L19.9686 8.91144V10.4072V23.8686L30.9371 16.39Z" strokeWidth="2"
                      strokeLinejoin="round"/>
            </g>
            <defs>
                <filter id="filter0_d_1601_3430" x="0.0142822" y="0.934265" width="48.8829" height="39.9086"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1601_3430"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1601_3430" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
};

PlayIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    classes: PropTypes.string,
};
export default React.memo(PlayIcon);
