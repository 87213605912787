import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextSection from "../../components/TextSection";
import {
  CybersecurityIcon,
  ButtonTooltipIcon,
  ShowMoreIcon,
} from "../../assets/icons";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import Timeline from "../../components/Timeline";
import {
  TimelineIcon5,
  TimelineIcon6,
  TimelineIcon7,
  TimelineIcon8,
} from "../../assets/images";
import InfoBox from "../../components/InfoBox";
import Tooltip from "../../components/Tooltip";
import ShowMore from "../../components/ShowMore";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";

const TimelineItems = [
  {
    Icon: TimelineIcon5,
    title: "",
    titleClasses: "",
    left: {
      title: "InnovationTransformation.Timeline1.leftTitle",
      caption: "",
    },
    right: {
      title: "",
      caption: "InnovationTransformation.Timeline1.rightCaption",
    },
    timelineTop: { sx: "", dx: "" },
    timelineBottom: { sx: "", dx: "" },
  },
  {
    Icon: TimelineIcon6,
    title: "",
    titleClasses: "",
    left: {
      title: "InnovationTransformation.Timeline2.leftTitle",
      caption: "InnovationTransformation.Timeline2.leftCaption",
    },
    right: {
      title: "",
      caption: "InnovationTransformation.Timeline2.rightCaption",
    },
    timelineTop: { sx: "", dx: "" },
    timelineBottom: { sx: "", dx: "" },
  },
  {
    Icon: TimelineIcon7,
    title: "",
    titleClasses: "",
    left: {
      title: "InnovationTransformation.Timeline3.leftTitle",
      caption: "InnovationTransformation.Timeline3.leftCaption",
    },
    right: {
      title: "",
      caption: "InnovationTransformation.Timeline3.rightCaption",
    },
    timelineTop: { sx: "", dx: "" },
    timelineBottom: { sx: "", dx: "" },
  },
  {
    Icon: TimelineIcon8,
    title: "",
    titleClasses: "",
    left: {
      title: "InnovationTransformation.Timeline4.leftTitle",
      caption: "",
    },
    right: {
      title: "",
      caption: "InnovationTransformation.Timeline4.rightCaption",
    },
    timelineTop: { sx: "", dx: "" },
    timelineBottom: { sx: "", dx: "" },
  },
];

const InnovationTransformation = () => {
  const { t, ready } = useTranslation();

  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["innovation-transformation"].areas}
            description={areas["innovation-transformation"].description}
          />
          <TextSection
            customClasses={{
              "mb-[64px] font line-height-h1-40 ": "h3",
              "mb-4": "h5",
            }}
            h3={t("InnovationTransformation.TextSection1.title")}
            h5={t("InnovationTransformation.TextSection1.caption")}
            p4={`${t("InnovationTransformation.TextSection1.paragraph")}\n\n${t(
              "InnovationTransformation.TextSection1.subParagraph"
            )}`}
          />
          <Timeline
            TimelineItems={TimelineItems}
            classes="pt-16 pb-10 sm:pb-16 lg:pb-20"
            lineClasses="h-[710px] sm:h-[880px] md:h-[1000px]"
          ></Timeline>

          <TextSection
            classes="pt-0 lg:pt-20"
            h5={t("InnovationTransformation.TextSection2.caption")}
            p4={t("InnovationTransformation.TextSection2.paragraph")}
          />

          <InfoBox classes="my-16" height="281px" table>
            <TextSection
              customClasses={{ "text-25px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              h1={t("InnovationTransformation.InfoBox1.TextSection1.text1")}
              p1={t("InnovationTransformation.InfoBox1.TextSection1.text2")}
            />
            <TextSection
              customClasses={{ "text-25px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1", "mb-20": "p1" }}
              h1={t("InnovationTransformation.InfoBox1.TextSection2.text1")}
              p1={
                <>
                  <div>
                    {t("InnovationTransformation.InfoBox1.TextSection2.text2")}
                  </div>
                  <Tooltip
                    classes="margin50-icon"
                    tooltipLink={""}
                    icon={<ButtonTooltipIcon width="19px" height="19px" />}
                  >
                    <TextSection
                      p5={t(
                        "InnovationTransformation.InfoBox1.TextSection2.Tooltip1.TextSection1.text1"
                      )}
                    />
                  </Tooltip>
                </>
              }
            />
            <TextSection
              customClasses={{ "text-25px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              h1={t("InnovationTransformation.InfoBox1.TextSection3.text1")}
              p1={t("InnovationTransformation.InfoBox1.TextSection3.text2")}
            />
          </InfoBox>

          <InfoBox
            classes="my-16 h-[290px] lg:h-[340px]"
            table
            size={3}
            sizes={[1, 2]}
          >
            <TextSection
              customClasses={{ "text-25px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              h1={t("InnovationTransformation.InfoBox2.TextSection1.text1")}
              p1={
                <>
                  <div>
                    {t("InnovationTransformation.InfoBox2.TextSection1.text2")}
                  </div>
                  <Tooltip
                    classes="margin30-icon"
                    tooltipLink={""}
                    icon={<ButtonTooltipIcon width="19px" height="19px" />}
                  >
                    <TextSection
                      p5={t(
                        "InnovationTransformation.InfoBox1.TextSection2.Tooltip1.TextSection1.text1"
                      )}
                    />
                  </Tooltip>
                </>
              }
            />
            <TextSection
              customClasses={{ "text-25px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              h1={t("InnovationTransformation.InfoBox2.TextSection2.text1")}
              p1={
                <>
                  <div>
                    {t("InnovationTransformation.InfoBox2.TextSection2.text2")}
                  </div>
                  <Tooltip
                    classes="margin50-icon"
                    tooltipLink={""}
                    icon={<ButtonTooltipIcon width="19px" height="19px" />}
                  >
                    <TextSection
                      p5={t(
                        "InnovationTransformation.InfoBox2.TextSection2.Tooltip1.TextSection1.text1"
                      )}
                    />
                    <div
                      className="mt-[75px]  grid grid-cols-1 md:grid-cols-4 gap-4 sm:gap-1 overflow-x-auto"
                      style={{ minWidth: 500 }}
                    >
                        <TextSection
                          customClasses={{ "w-[200px] sm:w-[300px] md:w-9/12": "p5" }}
                          p5={t(
                            "InnovationTransformation.InfoBox2.TextSection2.Tooltip1.InfoBox1.TextSection1.text1"
                          )}
                        />
                        <TextSection
                          customClasses={{ "w-[200px] sm:w-[300px] md:w-9/12": "p5" }}
                          p5={t(
                            "InnovationTransformation.InfoBox2.TextSection2.Tooltip1.InfoBox1.TextSection2.text1"
                          )}
                        />
                        <TextSection
                          customClasses={{ "w-[200px] sm:w-[300px] md:w-9/12": "p5" }}
                          p5={t(
                            "InnovationTransformation.InfoBox2.TextSection2.Tooltip1.InfoBox1.TextSection3.text1"
                          )}
                        />
                        <TextSection
                          customClasses={{ "w-[200px] sm:w-[300px] md:w-9/12": "p5" }}
                          p5={t(
                            "InnovationTransformation.InfoBox2.TextSection2.Tooltip1.InfoBox1.TextSection4.text1"
                          )}
                        />
                    </div>
                  </Tooltip>
                </>
              }
            />
          </InfoBox>

          <TextSection
            customClasses={{ "mb-5": "h5" }}
            classes="pt-16"
            h5={t("InnovationTransformation.TextSection3.caption")}
            p4={t("InnovationTransformation.TextSection3.text2")}
          />
          <InfoBox classes="my-5" height="215px" size={3} sizes={[1, 2]}>
            <TextSection
              customClasses={{ "text-25px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              h1={t("InnovationTransformation.InfoBox3.TextSection1.text1")}
              p1={t("InnovationTransformation.InfoBox3.TextSection1.text2")}
            />
            <TextSection
              customClasses={{ "text-25px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              h1={t("InnovationTransformation.InfoBox3.TextSection2.text1")}
              p1={t("InnovationTransformation.InfoBox3.TextSection2.text2")}
            />
          </InfoBox>

          {t("InnovationTransformation.TextSection4.text1") && (
            <div className={"flex justify-end mt-[92px] mb-[168px]"}>
              <ShowMore
                iconPosition={"self-end pb-[7px]"}
                url={t("InnovationTransformation.TextSection4.url")}
                title={t("InnovationTransformation.TextSection4.text1")}
                width={432}
                height={121}
                target="_blank"
                icon={
                  <a
                    href={t("InnovationTransformation.TextSection4.url")}
                    target="_blank"
                  >
                    <ShowMoreIcon
                      width="21px"
                      height="15px"
                      classes="stroke-green hover:stroke-gray"
                    />
                  </a>
                }
              />
            </div>
          )}
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

InnovationTransformation.propTypes = {};

export default React.memo(InnovationTransformation);
