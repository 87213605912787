import React from "react";
import {
  SolidityIcon,
  IntegrityIcon,
  SatisfactionIcon,
  CybersecurityIcon,
  FinancialInclusionIcon,
  InvestmentIcon,
  JobProtectionIcon,
  EnhancementIcon,
  GreenTransitionIcon,
  HealthIcon,
  Covid19Icon,
  HomeIcon,
  ClimateChangeIcon,
  CommunitySupportIcon,
} from "./assets/icons";
import {
  CircleGroup,
  CittaSostenibili,
  GreenEnergy,
  Istruzione,
  LavoroDignitoso,
  LottaCambiamentoClimatico,
  PaceGiustizia,
  ParitaGenere,
  RidurreDisuguaglianze,
  SaluteBenessere,
} from "./assets/icons/Areas";
import InnovationTransformation from "./containers/InnovationTransformation";
import Family from "./assets/icons/Areas/Family";
import IndustriaInnovazione from "./assets/icons/Areas/IndustriaInnovazione";
import ConsumoResponsabile from "./assets/icons/Areas/ConsumoResponsabile";
import uniqid from "uniqid";
import configuration from "./configuration.json";

const menuItems = [
  {
    id: "item-home",
    title: "Home.Menu.label",
    icon: HomeIcon,
    iconProps: {
      width: "38px",
      height: "32px",
    },
    url: "Home.Menu.url",
  },
  {
    id: "item-group-value",
    title: "GroupValue.Menu.label",
    icon: SolidityIcon,
    iconProps: {
      width: "33px",
      height: "32px",
    },
    url: "GroupValue.Menu.url",
  },
  {
    id: "item-integrity",
    title: "IntegrityPage.Menu.label",
    icon: IntegrityIcon,
    iconProps: {
      width: "36px",
      height: "37px",
    },
    url: "IntegrityPage.Menu.url",
  },
  {
    id: "item-service-quality",
    title: "ServiceQuality.Menu.label",
    icon: SatisfactionIcon,
    iconProps: {
      width: "36px",
      height: "42px",
    },
    url: "ServiceQuality.Menu.url",
  },
  {
    id: "item-innovation-transformation",
    title: "InnovationTransformation.Menu.label",
    icon: CybersecurityIcon,
    iconProps: {
      width: "40px",
      height: "41px",
    },
    url: "InnovationTransformation.Menu.url",
  },
  {
    id: "item-financial-inclusion",
    title: "FinancialInclusion.Menu.label",
    icon: FinancialInclusionIcon,
    iconProps: {
      width: "36px",
      height: "36px",
    },
    url: "FinancialInclusion.Menu.url",
  },
  {
    id: "item-investment-insurance",
    title: "InvestmentInsurance.Menu.label",
    icon: InvestmentIcon,
    iconProps: {
      width: "32px",
      height: "36px",
    },
    url: "InvestmentInsurance.Menu.url",
  },
  {
    id: "item-community-support",
    title: "CommunitySupport.Menu.label",
    icon: CommunitySupportIcon,
    iconProps: {
      width: "32px",
      height: "30px",
    },
    url: "CommunitySupport.Menu.url",
  },
  {
    id: "item-job-protection",
    title: "JobProtection.Menu.label",
    icon: JobProtectionIcon,
    iconProps: {
      width: "26px",
      height: "32px",
    },
    url: "JobProtection.Menu.url",
  },
  {
    id: "item-diversity-inclusion",
    title: "DiversityInclusion.Menu.label",
    icon: EnhancementIcon,
    iconProps: {
      width: "35px",
      height: "34px",
    },
    url: "DiversityInclusion.Menu.url",
  },
  {
    id: "item-health-safety",
    title: "HealthSafety.Menu.label",
    icon: HealthIcon,
    iconProps: {
      width: "27px",
      height: "41px",
    },
    url: "HealthSafety.Menu.url",
  },
  {
    id: "item-green-transition",
    title: "GreenTransition.Menu.label",
    icon: GreenTransitionIcon,
    iconProps: {
      width: "31px",
      height: "31px",
    },
    url: "GreenTransition.Menu.url",
  },
  {
    id: "item-climate-change",
    title: "ClimateChange.Menu.label",
    icon: ClimateChangeIcon,
    iconProps: {
      width: "36px",
      height: "36px",
    },
    url: "ClimateChange.Menu.url",
  },
  // ,
  // {
  //   id: "item-covid19",
  //   title: "Covid19.Menu.label",
  //   icon: Covid19Icon,
  //   iconProps: {
  //     width: "31px",
  //     height: "31px",
  //   },
  //   url: "Covid19.Menu.url",
  // },
];

const areas = {
  "group-value": {
    areas: [
      {
        id: "8",
        name: "Lavoro dignitoso e crescita economica",
        icon: LavoroDignitoso,
      },
    ],
    description: "Generic.SDG.8",
  },
  integrity: {
    areas: [
      {
        id: "16",
        name: "SDG 16: Pace, Giustizia e Istituzioni Forti",
        icon: PaceGiustizia,
      },
    ],
    description: "Generic.SDG.16",
  },
  "service-quality": {
    areas: [{ id: "3", name: "Salute e Benessere", icon: SaluteBenessere }],
    description: "Generic.SDG.3",
  },
  "innovation-transformation": {
    areas: [
      {
        id: "9",
        name: "Industria, Innovazione e Infrastrutture",
        icon: IndustriaInnovazione,
      },
      { id: "17", name: "Partnership per gli obiettivi", icon: CircleGroup },
    ],
    description: "Generic.SDG.9",
  },
  "financial-inclusion": {
    areas: [
      { id: "1", name: "Sconfiggere la povertà", icon: Family },
      { id: "3", name: "Salute e Benessere", icon: SaluteBenessere },
      { id: "4", name: "Istruzione di qualità", icon: Istruzione },
      { id: "5", name: "Parità di genere", icon: ParitaGenere },
      {
        id: "8",
        name: "Lavoro dignitoso e crescita economica",
        icon: LavoroDignitoso,
      },
      {
        id: "9",
        name: "Industria, Innovazione e Infrastrutture",
        icon: IndustriaInnovazione,
      },
      {
        id: "11",
        name: "Città e comunità sostenibili",
        icon: CittaSostenibili,
      },
      { id: "17", name: "Partnership per gli obiettivi", icon: CircleGroup },
    ],
    description: "Generic.SDG.mainGoals",
  },
  "investment-insurance": {
    areas: [
      {
        id: "8",
        name: "Lavoro dignitoso e crescita economica",
        icon: LavoroDignitoso,
      },
      {
        id: "13",
        name: "Lotta al cambiamento climatico",
        icon: LottaCambiamentoClimatico,
      },
      { id: "17", name: "Partnership per gli obiettivi", icon: CircleGroup },
    ],
    description: "Generic.SDG.mainGoals",
  },
  "community-support": {
    areas: [
      { id: "1", name: "Sconfiggere la povertà", icon: Family },
      { id: "3", name: "Salute e Benessere", icon: SaluteBenessere },
      { id: "4", name: "Istruzione di qualità", icon: Istruzione },
      {
        id: "11",
        name: "Città e comunità sostenibili",
        icon: CittaSostenibili,
      },
      { id: "17", name: "Partnership per gli obiettivi", icon: CircleGroup },
    ],
    description: "Generic.SDG.mainGoals",
  },
  "job-protection": {
    areas: [
      {
        id: "8",
        name: "Lavoro dignitoso e crescita economica",
        icon: LavoroDignitoso,
      },
    ],
    description: "Generic.SDG.8",
  },
  "diversity-inclusion": {
    areas: [
      { id: "4", name: "Istruzione di qualità", icon: Istruzione },
      { id: "5", name: "Parità di genere", icon: ParitaGenere },
      {
        id: "8",
        name: "Lavoro dignitoso e crescita economica",
        icon: LavoroDignitoso,
      },
      {
        id: "10",
        name: "Ridurre le disuguaglianze",
        icon: RidurreDisuguaglianze,
      },
      { id: "17", name: "Partnership per gli obiettivi", icon: CircleGroup },
    ],
    description: "Generic.SDG.mainGoals",
  },
  "health-safety": {
    areas: [
      { id: "3", name: "Salute e Benessere", icon: SaluteBenessere },
      {
        id: "8",
        name: "Lavoro dignitoso e crescita economica",
        icon: LavoroDignitoso,
      },
    ],
    description: "Generic.SDG.mainGoals",
  },
  "green-transition": {
    areas: [
      { id: "7", name: "Energia pulita e accessibile", icon: GreenEnergy },
      {
        id: "12",
        name: "Consumo e produzione responsabili",
        icon: ConsumoResponsabile,
      },
      {
        id: "13",
        name: "Lotta al cambiamento climatico",
        icon: LottaCambiamentoClimatico,
      },
      { id: "17", name: "Partnership per gli obiettivi", icon: CircleGroup },
    ],
    description: "Generic.SDG.mainGoals",
  },
  "climate-change": {
    areas: [
      { id: "7", name: "Energia pulita e accessibile", icon: GreenEnergy },
      {
        id: "13",
        name: "Lotta al cambiamento climatico",
        icon: LottaCambiamentoClimatico,
      },
      { id: "17", name: "Partnership per gli obiettivi", icon: CircleGroup },
    ],
    description: "Generic.SDG.mainGoals",
  },
};

const appendScript = ({
  src,
  innerText,
  async = false,
  defer = false,
  charSet,
}) => {
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.src = src;
  s.innerText = innerText;
  if (async) s.async = true;
  if (defer) s.defer = true;
  if (charSet) s.charSet = charSet;
  document.body.append(s);
};

/**
 *
 * @param {*} data
 * clientHeight: 1272
 * clientWidth: 865
 * documentHeight: 1272
 * documentWidth: 848
 * iframeHeight: 5405
 * iframeWidth: 796
 * offsetLeft: 20
 * offsetTop: 84
 * scrollLeft: 0
 * scrollTop: 13
 * windowHeight: 1272
 * windowWidth: 865
 * @returns
 */
const inferSizes = (data) => {
  const { browsers } = configuration.menu.positioning.parameters.Infer;
  const { scrollTop, offsetTop, clientHeight, clientWidth } = data;

  const matches = browsers.Fallback.thresholds.filter(
    (t) => clientWidth < t.width && scrollTop < t.scrollY
  );
  const ordered = matches.sort((t1, t2) =>
    t1.width - t2.width === 0 ? t1.scrollY - t2.scrollY : t1.width - t2.width
  );
  let threshold = ordered.find((_) => true);
  if (!threshold) {
    // Use default when no match found:
    threshold = { header: 0 };
  }

  return {
    elements: {
      "page-header": [{ clientHeight: threshold.header }],
      "header-main": [{ clientHeight: 0 }],
    },
    members: {
      visualViewport: {
        height: clientHeight,
      },
      scrollY: scrollTop,
    },
  };
};

const supportsOrientationChange = "onorientationchange" in window;
const orientationEvent = supportsOrientationChange
  ? "orientationchange" // Mobile
  : "resize"; // Desktop

class AutoResizerRegistrar {
  register = (handler) => {
    window.addEventListener("load", handler);
    window.addEventListener(orientationEvent, handler);
    window.addEventListener("scroll", handler);
  };

  unregister = (handler) => {
    window.removeEventListener("load", handler);
    window.removeEventListener(orientationEvent, handler);
    window.removeEventListener("scroll", handler);
  };
}
// Singleton work-around:
const autoResizerRegistrar = (window.autoResizerRegistrar =
  new AutoResizerRegistrar());

class Retry {
  constructor(name, useUniqueId = false, delay = 100, maxRetries = 16) {
    this.name = name + (useUniqueId ? ":" + uniqid() : "");
    this.delay = delay;
    this.maxRetries = maxRetries;
  }

  register = () => {
    window[this.name] = [];
  };

  running = () => {
    return window[this.name] && window[this.name].length > 0;
  };

  queueable = () => {
    return window[this.name] && window[this.name].length < this.maxRetries;
  };

  start = ({
    condition = () => true,
    onSuccess = () => {},
    onRetry,
    onFailure = () => {},
  }) => {
    if (typeof condition !== "function") {
      console.debug(
        "Retry: condition is not a function. Cannot test",
        condition
      );
      return;
    }
    if (condition()) {
      if (typeof onSuccess === "function") {
        onSuccess();
        clear();
      } else {
        console.debug(
          "Retry: onSuccess is not a function. Cannot start",
          onSuccess
        );
      }
    } else {
      if (typeof onRetry === "function") {
        if (this.queueable()) {
          window[this.name].push(setTimeout(onRetry, this.delay));
        } else {
          if (typeof onFailure === "function") {
            onFailure();
          } else {
            console.debug(
              "Retry: onFailure is not a function. Cannot fail",
              onFailure
            );
          }
        }
      } else {
        console.debug(
          "Retry: onRetry is not a function. Cannot retry",
          onRetry
        );
      }
    }
  };

  clear = () => {
    if (this.running()) {
      window[this.name].map((t) => clearTimeout(t));
    }
  };

  unregister = () => {
    this.clear();
    delete window[this.name];
  };
}

class IFrameResizerListener {
  constructor() {
    this.callbacks = {};
    this.listening = false;
    this.loading = false;
  }

  listen = () => {
    if (!this.listening) {
      if ("parentIFrame" in window) {
        window.parentIFrame.getPageInfo(this.onPageInfoReceived);
        this.listening = true;
        this.loading = false;
        console.debug(
          "IFrameResizerWrapper: start listening on parent changes"
        );
      } else {
        if (!this.loading) {
          // Use the handy event callback
          document.addEventListener(
            "DOMContentLoaded",
            this.onDocumentLoaded,
            false
          );
          // A fallback to window.onload, that will always work
          window.addEventListener("load", this.listen, false);

          this.loading = true;
        } else {
          console.debug("IFrameResizerWrapper: waiting for script load ...");
        }
      }
    }
  };

  scrollParentToTop = () => {
    if ("parentIFrame" in window) {
      window.parentIFrame.scrollTo(0, 0);
    }
  };

  onDocumentLoaded = () => {
    console.debug("IFrameResizerWrapper: document loaded");
    this.listen(); // ensure listening
  };

  onPageInfoReceived = (data) => {
    const sizes = inferSizes(data);
    Object.values(this.callbacks || {}).forEach((callback) => callback(sizes));
  };

  register = (name, onParentPageChanged) => {
    if (typeof onParentPageChanged === "function") {
      this.callbacks[name] = onParentPageChanged;
      this.listen(); // ensure listening
    } else {
      console.debug(
        "IFrameResizerWrapper: onParentPageChanged is not a function. Cannot register handler"
      );
    }
  };

  unregister = (name) => {
    delete this.callbacks[name];
  };

  reset = () => {
    window.removeEventListener("load", this.listen);
    this.callbacks = {};
    if ("parentIFrame" in window) {
      window.parentIFrame.getPageInfo(false);
      console.debug("IFrameResizerWrapper: stop listening on parent changes");
    }
    this.listening = false;
  };
}

// Singleton work-around:
const iFrameResizerListener = (window.iFrameResizerListener =
  new IFrameResizerListener());

export {
  menuItems,
  areas,
  appendScript,
  autoResizerRegistrar,
  Retry,
  iFrameResizerListener,
};
