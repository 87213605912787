import React from "react";
import PropTypes from "prop-types";

const DownloadPdfIcon = ({
  width = "32",
  height = "29",
  classes = "stroke-green"
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M16.1019 1V20.325L22.5099 13.9315L16.1019 20.325L9.82422 14.0473"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M31 15.8408V27.9913H1V15.8408" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>   
     </svg>   
  );
};

DownloadPdfIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(DownloadPdfIcon);
