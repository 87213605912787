import React from "react";
import PropTypes from "prop-types";

const ShowMore = ({
  title,
  width = 432,
  height = 121,
  icon,
  url,
  download = false,
  target,
  classes,
  iconPosition,
}) => {  
  return (
    <>
      {title && (
        <div
          style={{
            classes,
            width,
            height,
          }}
          className="p-[30px] flex items-center bg-gray100 justify-between break-all"
        >
          <a 
            download={download }          
            href={url}
            target={target}
            className="float-left "
          >
            <span className="font line-height-30-imp text-size20 font-normal text-green link link-underline link-underline-green w-9/12">{title}</span>
          </a>
          <div className={`float-right ${iconPosition}`}>{icon}</div>
        </div>
      )}
    </>
  );
};

ShowMore.propTypes = {
  //title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]), 
   title:  PropTypes.string,
  classes: PropTypes.string,
  icon: PropTypes.element,
  url: PropTypes.string,
  download: PropTypes.bool,
  target: PropTypes.string,
};

export default React.memo(ShowMore);
