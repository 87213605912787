import { useTranslation } from "react-i18next";
import TextSection from "./TextSection";
import { ButtonTooltipIcon, LineIcon } from "../assets/icons";
import Tooltip from "./Tooltip";

const InfoCard = ({
  children,
  title,
  buttonTitle,
  buttonLink,
  wrapperClass = "bg-white lg:mr-48 my-14 py-16 pl-16 w-3/5",
}) => {
  const { t } = useTranslation();

  return (
    <section className={wrapperClass}>
      <TextSection
        h5={
          <>
            <span>{title}</span>
            <LineIcon classes="mt-8 stroke-gray" width="51px" height="1px" />
          </>
        }
      />
      {children}

      {buttonTitle && (
        <a href={buttonLink} target="_blank">
          <button className="transition-all duration-500 hover:bg-[#0B0B0A] font text-size12 regular line-height-16-imp px-5 py-3 md:px-8 md:py-6 w-auto text-white uppercase bg-green mt-[54px]">
            {buttonTitle}
          </button>
        </a>
      )}
    </section>
  );
};

export default InfoCard;
