import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextSection from "../../components/TextSection";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import InfoBox from "../../components/InfoBox";
import Tooltip from "../../components/Tooltip";
import BarChart from "../../components/BarCharts/BarChart";
import { Animated } from "react-animated-css";
import { useInView } from "react-intersection-observer";
import { ButtonTooltipIcon, DownloadPdfIcon } from "../../assets/icons";
import ShowMore from "../../components/ShowMore";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";

const ClimateChange = () => {
  const { t, ready } = useTranslation();
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["climate-change"].areas}
            description={areas["climate-change"].description}
          />
          <TextSection
            customClasses={{ "mb-[14px] font line-height-h1-40": "h3" }}
            h3={t("ClimateChange.TextSection1.title")}
            p4={t("ClimateChange.TextSection1.paragraph")}
          />

          <div className={"flex justify-end mt-[35px] mb-[40px]"}>
     
          <ShowMore
              iconPosition={"self-end pb-[7px]"}              
              url="/pdf/TCFD Report_2021.11.pdf"
              title={t("ClimateChange.TextSection5.text1")}
              width={432}
              height={121}
              download              
              icon={
                <a href="/pdf/TCFD Report_2021.11.pdf" download>               
                  <DownloadPdfIcon width="32px" height="29px" classes="stroke-green hover:stroke-gray"/>
                </a>
              }
            />
          </div>

          <TextSection
            customClasses={{ "flex items-center mb-10": "h5" }}
            classes="pt-16"
            h5={t("ClimateChange.TextSection2.caption")}
          />

          <div
            className="w-full relative h-[410px] sm:h-[510px]"
            style={{ height: 400 }}
            ref={ref}
          >
            <div className="w-full absolute" style={{ zIndex: 10 }}>
              <Animated
                animationIn="fadeIn"
                animationInDelay={1000}
                isVisible={inView}
                // animateOnMount={false}
              >
                <div className="chart" style={{ marginTop: 40 }}>
                  <div className="d-flex">
                    <div
                      style={{
                        height: 28,
                        width: "calc(100% - 46px)",
                        borderTop: "2px solid #C4C4C4",
                        borderBottom: "2px solid #C4C4C4",
                      }}
                    ></div>
                    <div style={{ width: 42, marginLeft: 8, marginTop: 2 }}>
                      <span>4%</span>
                    </div>
                  </div>
                </div>
              </Animated>
            </div>

            <div className="w-full absolute" style={{ zIndex: 20 }}>
              <BarChart
                classes=""
                right={({ classes }) => (
                  <div
                    className={`d-flex col content-end ${classes || ""}`}
                    style={{ marginBottom: 14 }}
                  >
                    <div className="my-auto"></div>
                    <div className="d-flex col content-end ml-4 sm:ml-12">
                      <Tooltip
                        classes="ml-2 mb-2.5"
                        icon={<ButtonTooltipIcon width="19px" height="19px" />}
                      >
                        <TextSection
                          p5={t(
                            "ClimateChange.BarChart1.Tooltip1.TextSection1.text1"
                          )}
                        />
                        <TextSection
                          p5={t(
                            "ClimateChange.BarChart1.Tooltip1.TextSection1.text2"
                          )}
                        />
                        <TextSection
                          p5={t(
                            "ClimateChange.BarChart1.Tooltip1.TextSection1.text3"
                          )}
                        />
                      </Tooltip>
                      <p className="text bold text-size22 text-black">
                        {t("ClimateChange.BarChart1.text1")}
                      </p>
                    </div>
                  </div>
                )}
                options={{
                  width: "100%",
                  height: "290px",
                  columns: {
                    height: "297px",
                    justifyContent: "center",
                  },
                  labels: {
                    justifyContent: "center",
                  },
                }}
                data={[
                  {
                    topLabel: t("ClimateChange.BarChart1.Values.val1"),
                    label: "2020*",
                    height: 254,
                    color: "#29851B",
                    style: { textAlign: "right" },
                  },
                  {
                    topLabel: t("ClimateChange.BarChart1.Values.val2"),
                    topLabelStyle: { marginBottom: 33, marginLeft: 18 },
                    label: "2021",
                    height: 228,
                    color: "#2B7021",
                    style: { textAlign: "right" },
                  },
                ]}
              />
            </div>
          </div>

          <TextSection
            classes=""
            p4={t("ClimateChange.TextSection3.paragraph")}
          />
          <TextSection
            classes="pt-16"
            h5={t("ClimateChange.TextSection4.caption")}
          />

          <InfoBox
            classes="mt-8 mb-14"
            table
            size={3}
            sizes={[1, 2]}
            height="202px"
          >
            <div className="grid grid-cols-1 gap-3 pt-4">
              <TextSection
                customClasses={{ "text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":"h1"}}
                h1={t("ClimateChange.InfoBox1.TextSection1.text1")}
                p1={t("ClimateChange.InfoBox1.TextSection1.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-3 pt-4">
              <TextSection
                customClasses={{ "text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":"h1"}}
                h1={t("ClimateChange.InfoBox1.TextSection2.text1")}
                p1={t("ClimateChange.InfoBox1.TextSection2.text2")}
              />
            </div>
          </InfoBox>
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

ClimateChange.propTypes = {};

export default React.memo(ClimateChange);
