import React from "react";
import PropTypes from "prop-types";

const RidurreDisuguaglianze = ({
  width = "55",
  height = "50",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox="0 0 56 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M54.7426 0H0.0566406V50H54.7426V0Z" fill="#DD0979"/>
      <path d="M32.0838 26.162H22.2646C22.2505 26.1621 22.2365 26.1651 22.2235 26.1706C22.2105 26.1762 22.1988 26.1843 22.1889 26.1944C22.179 26.2045 22.1712 26.2164 22.1659 26.2295C22.1607 26.2426 22.1581 26.2567 22.1582 26.2708V28.9738C22.1579 28.988 22.1604 29.0022 22.1656 29.0154C22.1708 29.0287 22.1785 29.0408 22.1884 29.051C22.1983 29.0612 22.2101 29.0694 22.2232 29.075C22.2363 29.0807 22.2504 29.0837 22.2646 29.0838H32.0838C32.0982 29.0838 32.1125 29.081 32.1257 29.0754C32.139 29.0699 32.1511 29.0618 32.1612 29.0515C32.1713 29.0413 32.1793 29.0291 32.1847 29.0158C32.1901 29.0025 32.1928 28.9882 32.1926 28.9738V26.272C32.1926 26.2432 32.1812 26.2155 32.1608 26.1951C32.1404 26.1747 32.1127 26.1632 32.0838 26.1632" fill="white"/>
      <path d="M32.0839 20.4222H22.2646C22.2504 20.4224 22.2365 20.4253 22.2235 20.4309C22.2105 20.4365 22.1987 20.4445 22.1889 20.4546C22.179 20.4647 22.1712 20.4767 22.1659 20.4898C22.1607 20.5029 22.158 20.5169 22.1582 20.531V23.2279C22.158 23.2421 22.1607 23.2561 22.1659 23.2692C22.1712 23.2823 22.179 23.2943 22.1889 23.3044C22.1987 23.3145 22.2105 23.3225 22.2235 23.3281C22.2365 23.3336 22.2504 23.3366 22.2646 23.3367H32.0839C32.0982 23.3369 32.1124 23.3342 32.1257 23.3288C32.139 23.3234 32.1511 23.3154 32.1612 23.3052C32.1713 23.2951 32.1793 23.283 32.1847 23.2698C32.1902 23.2565 32.1929 23.2423 32.1927 23.2279V20.531C32.1927 20.5022 32.1812 20.4745 32.1608 20.4541C32.1404 20.4337 32.1127 20.4222 32.0839 20.4222Z" fill="white"/>
      <path d="M31.9453 16.3427L27.2325 12.0333C27.2167 12.019 27.1963 12.0111 27.175 12.0111C27.1538 12.0111 27.1333 12.019 27.1176 12.0333L22.4035 16.3427C22.3939 16.3534 22.3876 16.3667 22.3855 16.381C22.3835 16.3952 22.3856 16.4098 22.3917 16.4228C22.3979 16.4359 22.4077 16.4468 22.42 16.4543C22.4323 16.4618 22.4466 16.4655 22.461 16.4649H31.8879C31.9022 16.4655 31.9165 16.4618 31.9288 16.4543C31.9411 16.4468 31.951 16.4359 31.9571 16.4228C31.9632 16.4098 31.9654 16.3952 31.9633 16.381C31.9612 16.3667 31.9549 16.3534 31.9453 16.3427Z" fill="white"/>
      <path d="M36.3718 29.1132L41.0859 24.8038C41.0934 24.7972 41.0994 24.7891 41.1035 24.7801C41.1077 24.771 41.1098 24.7612 41.1098 24.7512C41.1098 24.7412 41.1077 24.7314 41.1035 24.7223C41.0994 24.7132 41.0934 24.7052 41.0859 24.6986L36.3718 20.388C36.3605 20.3775 36.3464 20.3703 36.3312 20.3673C36.316 20.3643 36.3003 20.3656 36.2858 20.3711C36.2713 20.3765 36.2586 20.3859 36.2492 20.3982C36.2398 20.4105 36.234 20.4252 36.2324 20.4406V29.0606C36.234 29.076 36.2398 29.0907 36.2492 29.103C36.2586 29.1152 36.2713 29.1246 36.2858 29.1301C36.3003 29.1356 36.316 29.1369 36.3312 29.1339C36.3464 29.1309 36.3605 29.1237 36.3718 29.1132Z" fill="white"/>
      <path d="M22.4035 33.1585L27.1176 37.4691C27.1337 37.4826 27.154 37.4901 27.175 37.4901C27.196 37.4901 27.2164 37.4826 27.2325 37.4691L31.9453 33.1585C31.9549 33.1478 31.9612 33.1344 31.9633 33.1202C31.9654 33.1059 31.9632 33.0914 31.9571 33.0783C31.951 33.0653 31.9411 33.0544 31.9288 33.0469C31.9165 33.0394 31.9022 33.0357 31.8879 33.0362H22.461C22.4466 33.0357 22.4323 33.0394 22.42 33.0469C22.4077 33.0544 22.3979 33.0653 22.3917 33.0783C22.3856 33.0914 22.3835 33.1059 22.3855 33.1202C22.3876 33.1344 22.3939 33.1478 22.4035 33.1585Z" fill="white"/>
      <path d="M17.9777 20.388L13.2661 24.695C13.2586 24.7015 13.2526 24.7096 13.2485 24.7187C13.2443 24.7277 13.2422 24.7376 13.2422 24.7476C13.2422 24.7575 13.2443 24.7674 13.2485 24.7764C13.2526 24.7855 13.2586 24.7936 13.2661 24.8001L17.979 29.1095C17.9903 29.1201 18.0043 29.1273 18.0195 29.1302C18.0347 29.1332 18.0505 29.1319 18.065 29.1265C18.0794 29.121 18.0921 29.1116 18.1015 29.0993C18.111 29.087 18.1168 29.0724 18.1183 29.057V20.4406C18.117 20.425 18.1112 20.41 18.1017 20.3975C18.0922 20.385 18.0793 20.3755 18.0646 20.37C18.0499 20.3644 18.0339 20.3632 18.0186 20.3664C18.0032 20.3696 17.989 20.3771 17.9777 20.388Z" fill="white"/>
    </svg>
  );
};

RidurreDisuguaglianze.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};

export default React.memo(RidurreDisuguaglianze);
