import { useEffect } from "react";
import { useLocation } from "react-router";
import { Retry } from "../utils";
import configuration from "../configuration.json";

const ScrollToTop = (props) => {
  const { smoothly } = props;
  const location = useLocation();

  const scrolling = {
    top: 0,
    left: 0,
    behavior: smoothly ? "smooth" : "auto",
  };

  const autoScrollParent = () => {
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        retry.start({
          condition: () => window.autoResizer,
          onSuccess: () => window.autoResizer.parentScrollTo(scrolling),
          onFailure: () =>
            console.debug("autoResizer not found in ScrollToTop.jsx:24"),
        });
        break;

      default:
        break;
    }
  };

  const retry = new Retry("ScrollToTop");

  const disableScrollRestoration = () => {
    if ("scrollRestoration" in window.history) {
      // Back off, browser, I got this...
      window.history.scrollRestoration = "manual";
    }
  };

  useEffect(() => {
    retry.register();
    disableScrollRestoration();
    window.scrollTo(scrolling);
    disableScrollRestoration();
    autoScrollParent();

    return () => {
      retry.unregister();
    };
  }, [location]);

  return null;
};

export default ScrollToTop;
