import React from "react";
import PropTypes from "prop-types";

const ContainerWrapper = ({ margin = "12%", classes, children }) => {
  return (
    <section
      className={`${
        classes || ""
      } w-full md:w-[76%] px-4 lg:px-0 mx-auto max-w-[1440px] pb-12`}
    >
      {children}
    </section>
  );
};

ContainerWrapper.propTypes = {
  classes: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.node,
};

export default React.memo(ContainerWrapper);
