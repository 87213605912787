import React from "react";
import PropTypes from "prop-types";

const GroupTooltipIcon6 = ({
  width = "59",
  height = "59",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 59 59`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <g clip-path="url(#clip0_206_1439)">
    <path d="M8.97461 19.0604C12.8359 11.7129 20.5896 6.69678 29.5269 6.69678C31.5377 6.69678 33.4864 6.95297 35.3473 7.42947" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.8549 49.4801C10.9851 45.4989 6.36914 38.1053 6.36914 29.6511C6.36914 27.1354 6.7775 24.717 7.53219 22.4574" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M49.3344 41.5537C45.2715 48.1787 37.9211 52.6056 29.5265 52.6056C26.9678 52.6056 24.5073 52.1957 22.207 51.4374" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M43.0078 10.9802C48.8696 15.1458 52.6895 21.9553 52.6895 29.6512C52.6895 32.213 52.2657 34.6776 51.4851 36.9781" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M30.0378 0.256226L35.7238 7.49609L28.4199 13.1322" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M0.257812 27.7144L7.56174 22.0782L13.2477 29.3181" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M27.727 58.7438L22.041 51.5039L29.3449 45.8678" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M58.7419 31.7365L51.4431 37.3727L45.752 30.1328" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M29.418 20.3669C29.418 20.3669 25.3086 29.5743 25.3499 29.559C25.3913 29.5436 33.6515 29.559 33.6515 29.559L29.5007 39.3556" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_206_1439">
    <rect width="59" height="59" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
};

GroupTooltipIcon6.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(GroupTooltipIcon6);

