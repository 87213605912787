import React from "react";
import PropTypes from "prop-types";

const FinancialInclusionIcon = ({
  width = "34",
  height = "34",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 34 34`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_707_365)">
        <path
          d="M31.7677 10.3492C32.4518 11.7423 32.9369 13.2597 33.1981 14.8518"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.02338 30.3622C9.29207 31.2826 10.6976 32.0164 12.215 32.5388"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.3623 26.7676C31.2827 25.4989 32.0166 24.0934 32.539 22.5759"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.42869 8.02328C3.50827 9.29197 2.77442 10.6975 2.25201 12.2149"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.939 33.1981C21.5311 32.9493 23.0361 32.4518 24.4416 31.7677"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.576 2.25201C24.081 2.77442 25.4989 3.50827 26.7676 4.42869"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3492 3.02316C11.7423 2.33906 13.2598 1.85397 14.8518 1.59277"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.02316 24.4292C2.33906 23.0361 1.85397 21.5187 1.59277 19.9266"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.42865 8.02329C5.12519 8.68251 6.07048 9.08053 7.10285 9.08053C9.25465 9.08053 11.0084 7.32676 11.0084 5.17496C11.0084 4.37892 10.7721 3.64507 10.3616 3.02316"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.1252 17.3892C4.93863 17.8992 4.64011 18.3842 4.22966 18.7947C3.49581 19.5286 2.55051 19.9017 1.59277 19.939"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.12522 17.3892C5.63519 16.0086 5.33667 14.3792 4.22968 13.2722C3.66996 12.7124 2.97343 12.3517 2.25201 12.2025"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.02313 24.4292C3.64504 24.0187 4.37889 23.7824 5.17493 23.7824C7.32673 23.7824 9.0805 25.5362 9.0805 27.688C9.0805 28.7203 8.68248 29.6532 8.02326 30.3622"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3892 29.6656C17.8992 29.8522 18.3843 30.1507 18.7947 30.5612C19.5286 31.295 19.9017 32.2403 19.939 33.1981"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3891 29.6656C16.0085 29.1557 14.3791 29.4542 13.2721 30.5612C12.7124 31.1209 12.3517 31.8174 12.2024 32.5388"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.3622 26.7675C29.6656 26.1083 28.7203 25.7103 27.688 25.7103C25.5362 25.7103 23.7824 27.4641 23.7824 29.6159C23.7824 30.4119 24.0187 31.1458 24.4292 31.7677"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.1981 14.8518C32.2403 14.8767 31.295 15.2623 30.5612 15.9961C30.1507 16.4066 29.8522 16.8917 29.6656 17.4016"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.6656 17.3892C29.1557 18.7698 29.4542 20.3992 30.5612 21.5062C31.1209 22.0659 31.8174 22.4266 32.5388 22.5759"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.7676 4.42868C26.1083 5.12522 25.7103 6.07051 25.7103 7.10288C25.7103 9.25468 27.4641 11.0085 29.6159 11.0085C30.4119 11.0085 31.1458 10.7721 31.7677 10.3617"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5759 2.25198C22.4267 2.9734 22.0784 3.66993 21.5062 4.22965C20.3868 5.34908 18.7699 5.64759 17.3892 5.12519"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8518 1.59277C14.8767 2.55051 15.2623 3.49581 15.9961 4.22966C16.4066 4.64011 16.8917 4.93863 17.4016 5.1252"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.001 15.5981H17.3892"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0134 19.1927H17.3892"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.5435 10.6477C21.0833 10.5482 20.6107 10.4985 20.1256 10.4985C16.3195 10.4985 13.2349 13.5831 13.2349 17.3892C13.2349 21.1953 16.3195 24.2799 20.1256 24.2799C20.6107 24.2799 21.0833 24.2302 21.5435 24.1307"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

FinancialInclusionIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(FinancialInclusionIcon);
