import React from "react";
import PropTypes from "prop-types";

const CybersecurityIcon = ({
  width = "39",
  height = "41",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 39 41`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_707_442)">
        <path
          d="M23.5802 18.5055C23.3344 18.415 23.0758 18.3633 22.7912 18.3633H16.2983C15.962 18.3633 15.6516 18.4279 15.3671 18.5573"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.367 18.5573C14.5004 18.9194 13.8925 19.7731 13.8925 20.7819V25.477C13.8925 26.8092 14.966 27.8827 16.2983 27.8827H22.7911C24.1234 27.8827 25.1969 26.8092 25.1969 25.477V20.7819C25.1969 19.7343 24.5114 18.8418 23.5672 18.5185"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.5801 18.5055C23.6965 18.1563 23.7612 17.8071 23.7612 17.4191V16.7336C23.7612 14.7676 22.1703 13.1767 20.2043 13.1767H18.7298C16.7639 13.1767 15.173 14.7676 15.173 16.7336V17.4191C15.173 17.82 15.2506 18.1951 15.367 18.5443"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.4671 21.7908V24.7915"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.34797 10.0337C8.39089 10.0337 9.23634 9.18826 9.23634 8.14535C9.23634 7.10243 8.39089 6.25697 7.34797 6.25697C6.30505 6.25697 5.45959 7.10243 5.45959 8.14535C5.45959 9.18826 6.30505 10.0337 7.34797 10.0337Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8715 10.0337C16.9144 10.0337 17.7598 9.18826 17.7598 8.14535C17.7598 7.10243 16.9144 6.25697 15.8715 6.25697C14.8285 6.25697 13.9831 7.10243 13.9831 8.14535C13.9831 9.18826 14.8285 10.0337 15.8715 10.0337Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8715 1.10922V6.25698"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.56232 22.1141C3.60524 22.1141 4.45069 21.2687 4.45069 20.2258C4.45069 19.1828 3.60524 18.3374 2.56232 18.3374C1.5194 18.3374 0.67395 19.1828 0.67395 20.2258C0.67395 21.2687 1.5194 22.1141 2.56232 22.1141Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.61143 20.2258H4.45074"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0111 10.0337C24.054 10.0337 24.8994 9.18826 24.8994 8.14535C24.8994 7.10243 24.054 6.25697 23.0111 6.25697C21.9681 6.25697 21.1227 7.10243 21.1227 8.14535C21.1227 9.18826 21.9681 10.0337 23.0111 10.0337Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.011 1.10922V6.25698"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.41258 10.1243V13.9657C7.41258 13.9657 7.07629 16.1127 9.32682 16.1127C11.5773 16.1127 10.9953 16.1127 10.9953 16.1127"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.444 10.0337C32.487 10.0337 33.3324 9.18826 33.3324 8.14535C33.3324 7.10243 32.487 6.25697 31.444 6.25697C30.4011 6.25697 29.5557 7.10243 29.5557 8.14535C29.5557 9.18826 30.4011 10.0337 31.444 10.0337Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.3794 10.1243V13.9657C31.3794 13.9657 31.7157 16.1127 29.4651 16.1127C27.2146 16.1127 27.7966 16.1127 27.7966 16.1127"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.34797 34.2204C8.39089 34.2204 9.23634 33.375 9.23634 32.332C9.23634 31.2891 8.39089 30.4437 7.34797 30.4437C6.30505 30.4437 5.45959 31.2891 5.45959 32.332C5.45959 33.375 6.30505 34.2204 7.34797 34.2204Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8715 34.2204C16.9144 34.2204 17.7598 33.375 17.7598 32.332C17.7598 31.2891 16.9144 30.4437 15.8715 30.4437C14.8285 30.4437 13.9831 31.2891 13.9831 32.332C13.9831 33.375 14.8285 34.2204 15.8715 34.2204Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8715 39.3682V34.2204"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0111 34.2204C24.054 34.2204 24.8994 33.375 24.8994 32.332C24.8994 31.2891 24.054 30.4437 23.0111 30.4437C21.9681 30.4437 21.1227 31.2891 21.1227 32.332C21.1227 33.375 21.9681 34.2204 23.0111 34.2204Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.011 39.3682V34.2204"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.41258 30.3661V26.5246C7.41258 26.5246 7.07629 24.3776 9.32682 24.3776C11.5773 24.3776 10.9953 24.3776 10.9953 24.3776"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.444 34.2204C32.487 34.2204 33.3324 33.375 33.3324 32.332C33.3324 31.2891 32.487 30.4437 31.444 30.4437C30.4011 30.4437 29.5557 31.2891 29.5557 32.332C29.5557 33.375 30.4011 34.2204 31.444 34.2204Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.3794 30.3661V26.5246C31.3794 26.5246 31.7157 24.3776 29.4651 24.3776C27.2146 24.3776 27.7966 24.3776 27.7966 24.3776"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0859 20.2258H10.8789"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.3073 22.1141C37.3502 22.1141 38.1956 21.2687 38.1956 20.2258C38.1956 19.1828 37.3502 18.3374 36.3073 18.3374C35.2643 18.3374 34.4189 19.1828 34.4189 20.2258C34.4189 21.2687 35.2643 22.1141 36.3073 22.1141Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2711 20.2258H34.4188"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.7836 20.2258H27.9906"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

CybersecurityIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(CybersecurityIcon);
