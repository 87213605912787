import React from "react";
import PropTypes from "prop-types";

const ShowMoreIcon = ({
  width = "21",
  height = "15",
  classes = "stroke-green",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M1 7.55936H20L13.6999 1L20 7.55936L13.8278 14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

ShowMoreIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(ShowMoreIcon);
