import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { menuItems } from "../../utils";
import configuration from "../../configuration.json";

const MaterialityMatrix2021Icon = ({
  width = "1078px",
  height = "644px",
  classes = "stroke-white",
  lang = "en",
}) => {
  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  const menuItemUrl = (id) => {
    const menuItem = menuItems.find((item) => item.id === id);
    return t(menuItem?.url ?? "item-home");
  };

  const onClick = (e) => {
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        setTimeout(() => {
          if (window.autoResizer) {
            window.autoResizer.onChildResized(e);
          } else {
            console.error(
              "autoResizer not found in MaterialityMatrix2020Icon.jsx:33"
            );
          }
        }, 500);
        break;

      default:
        break;
    }
  };

  switch (lang) {
    case "it":
      return (
        <svg
          className={classes}
          style={{ width, height }}
          viewBox="0 0 1078 644"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <style>
              {`
            a:link,
            a:visited,
            a:link g,
            a:visited g {
              cursor: pointer;
            }

            a:hover text,
            a:active text {
              fill: #2b8804; /* Even for text, SVG uses fill over color */
            }`}
            </style>
          </defs>

          <g id="matrice 2021">
            <rect
              id="Rectangle 48"
              x="768"
              y="663"
              width="310"
              height="43"
              fill="white"
            />
            <rect
              id="Rectangle 49"
              x="92"
              width="978"
              height="616"
              fill="white"
            />
            <g id="b">
              <g id="c">
                <g id="Group">
                  <g id="Group_2">
                    <path
                      id="Vector"
                      d="M6.60855 1.76941V637.405"
                      stroke="#323231"
                    />
                    <path
                      id="Vector_2"
                      d="M0 7.07781L1.20888 8.20382L6.60855 2.33246L12.0082 8.20382L13.2171 7.07781L6.60855 0L0 7.07781Z"
                      fill="#323231"
                    />
                  </g>
                  <path
                    id="Vector_3"
                    d="M1059.06 637.405H6.60855"
                    stroke="#323231"
                  />
                  <g id="Group_3">
                    <path
                      id="Vector_4"
                      d="M1076.31 637.405H6.60855"
                      stroke="#323231"
                    />
                    <path
                      id="Vector_5"
                      d="M1070.91 630.81L1069.86 632.016L1075.66 637.405L1069.86 642.794L1070.91 644L1078 637.405L1070.91 630.81Z"
                      fill="#323231"
                    />
                  </g>
                  <path
                    id="Vector_6"
                    opacity="0.11"
                    d="M1059.06 486.519H6.60855"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_7"
                    opacity="0.11"
                    d="M1059.06 335.633H6.60855"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_8"
                    opacity="0.11"
                    d="M1059.06 184.747H6.60855"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_9"
                    opacity="0.11"
                    d="M1059.06 33.8608H6.60855"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_10"
                    opacity="0.11"
                    d="M181.735 33.8608V637.405"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_11"
                    opacity="0.11"
                    d="M357.91 33.8608V637.405"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_12"
                    opacity="0.11"
                    d="M534.084 33.8608V637.405"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_13"
                    opacity="0.11"
                    d="M710.178 33.8608V637.405"
                    stroke="#323231"
                  />

                  <Link
                    to={menuItemUrl("item-investment-insurance")}
                    onClick={onClick}
                  >
                    <g id="INVESTIMENTI">
                      <text
                        id="Investimenti"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="146.055" y="399.315">
                          Investimenti
                        </tspan>
                      </text>
                      <text
                        id="e assicurazione"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="126.86" y="412.103">
                          {" "}
                          e assicurazione
                        </tspan>
                      </text>
                      <text
                        id="sostenibili"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="157.403" y="424.979">
                          sostenibili
                        </tspan>
                      </text>
                      <g id="Group_4">
                        <path
                          id="Vector_14"
                          d="M251.609 423.703C224.61 406.572 232.508 383.891 232.508 383.891C242.099 384.051 249.594 379.145 251.609 377.698C253.623 379.145 261.199 384.051 270.709 383.891C270.709 383.891 278.607 406.572 251.609 423.703Z"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_15"
                          d="M244.516 400.62C244.678 400.7 250.561 406.652 250.561 406.652L262.408 394.507"
                          stroke="#9C0139"
                        />
                      </g>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-diversity-inclusion")}
                    onClick={onClick}
                  >
                    <g id="INVESTIMENTI_2">
                      <text
                        id="Attrazione, valorizzazione"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="112.993" y="476.96">
                          Attrazione, valorizzazione
                        </tspan>
                      </text>
                      <text
                        id="diversity e inclusion"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="141.848" y="488.835">
                          {" "}
                          diversity e inclusion
                        </tspan>
                      </text>
                      <text
                        id="delle persone del Gruppo"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="113.712" y="500.711">
                          delle persone del Gruppo
                        </tspan>
                      </text>
                      <g id="Group_5">
                        <g id="Group_6">
                          <path
                            id="Vector_16"
                            d="M295.048 464.722C297.585 464.722 299.641 462.67 299.641 460.138C299.641 457.606 297.585 455.553 295.048 455.553C292.511 455.553 290.454 457.606 290.454 460.138C290.454 462.67 292.511 464.722 295.048 464.722Z"
                            stroke="#F8008B"
                          />
                          <path
                            id="Vector_17"
                            d="M288.842 466.894C290.535 465.527 292.711 464.722 295.048 464.722C300.609 464.722 305.122 469.226 305.122 474.776C305.122 477.109 304.316 479.2 303.026 480.969"
                            stroke="#F8008B"
                          />
                        </g>
                        <g id="Group_7">
                          <path
                            id="Vector_18"
                            d="M273.61 480.326C276.147 480.326 278.204 478.273 278.204 475.741C278.204 473.209 276.147 471.157 273.61 471.157C271.073 471.157 269.017 473.209 269.017 475.741C269.017 478.273 271.073 480.326 273.61 480.326Z"
                            stroke="#F8008B"
                          />
                          <path
                            id="Vector_19"
                            d="M278.043 483.623C277.317 481.612 277.237 479.28 277.962 477.028C279.655 471.72 285.377 468.824 290.696 470.594C292.872 471.317 294.725 472.685 295.934 474.454"
                            stroke="#F8008B"
                          />
                        </g>
                        <g id="Group_8">
                          <path
                            id="Vector_20"
                            d="M281.75 505.42C284.287 505.42 286.344 503.367 286.344 500.836C286.344 498.304 284.287 496.251 281.75 496.251C279.213 496.251 277.156 498.304 277.156 500.836C277.156 503.367 279.213 505.42 281.75 505.42Z"
                            stroke="#F8008B"
                          />
                          <path
                            id="Vector_21"
                            d="M290.776 499.066C288.6 499.146 286.424 498.503 284.49 497.136C279.977 493.838 279.01 487.565 282.234 483.06C283.604 481.211 285.457 479.924 287.553 479.28"
                            stroke="#F8008B"
                          />
                        </g>
                        <g id="Group_9">
                          <path
                            id="Vector_22"
                            d="M308.265 505.42C310.802 505.42 312.859 503.367 312.859 500.836C312.859 498.304 310.802 496.251 308.265 496.251C305.728 496.251 303.671 498.304 303.671 500.836C303.671 503.367 305.728 505.42 308.265 505.42Z"
                            stroke="#F8008B"
                          />
                          <path
                            id="Vector_23"
                            d="M309.313 491.747C308.748 493.838 307.459 495.768 305.525 497.136C301.012 500.433 294.725 499.388 291.421 494.884C290.051 493.034 289.406 490.862 289.487 488.69"
                            stroke="#F8008B"
                          />
                        </g>
                        <g id="Group_10">
                          <path
                            id="Vector_24"
                            d="M316.405 480.326C318.942 480.326 320.998 478.273 320.998 475.741C320.998 473.209 318.942 471.157 316.405 471.157C313.868 471.157 311.811 473.209 311.811 475.741C311.811 478.273 313.868 480.326 316.405 480.326Z"
                            stroke="#F8008B"
                          />
                          <path
                            id="Vector_25"
                            d="M308.104 471.881C309.877 473.087 311.327 474.937 312.053 477.109C313.745 482.417 310.844 488.127 305.605 489.816C303.429 490.54 301.092 490.46 299.077 489.736"
                            stroke="#F8008B"
                          />
                        </g>
                      </g>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-climate-change")}
                    onClick={onClick}
                  >
                    <g id="CLIMA">
                      <text
                        id="Cambiamento"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="215.544" y="585.664">
                          Cambiamento
                        </tspan>
                      </text>
                      <text
                        id="climatico"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="243.829" y="597.539">
                          climatico
                        </tspan>
                      </text>
                      <g id="Group_11">
                        <path
                          id="Vector_26"
                          d="M335.021 611.265C350.377 611.265 362.826 598.842 362.826 583.517C362.826 568.192 350.377 555.769 335.021 555.769C319.665 555.769 307.217 568.192 307.217 583.517C307.217 598.842 319.665 611.265 335.021 611.265Z"
                          stroke="#3B7834"
                          strokeDasharray="2 2"
                        />
                        <g id="Group_12">
                          <path
                            id="Vector_27"
                            d="M335.021 602.257C341.119 602.257 346.062 593.867 346.062 583.517C346.062 573.167 341.119 564.777 335.021 564.777C328.924 564.777 323.98 573.167 323.98 583.517C323.98 593.867 328.924 602.257 335.021 602.257Z"
                            stroke="#3B7834"
                          />
                          <path
                            id="Vector_28"
                            d="M335.021 602.418C345.481 602.418 353.961 593.955 353.961 583.517C353.961 573.078 345.481 564.616 335.021 564.616C324.562 564.616 316.082 573.078 316.082 583.517C316.082 593.955 324.562 602.418 335.021 602.418Z"
                            stroke="#3B7834"
                          />
                          <path
                            id="Vector_29"
                            d="M320.676 595.983C324.141 593.329 329.299 591.64 335.102 591.64C340.905 591.64 345.901 593.249 349.367 595.822"
                            stroke="#3B7834"
                          />
                          <path
                            id="Vector_30"
                            d="M349.367 571.05C345.901 573.624 340.743 575.313 335.021 575.313C329.299 575.313 324.222 573.705 320.676 571.05"
                            stroke="#3B7834"
                          />
                          <path
                            id="Vector_31"
                            d="M316.082 583.517H353.961"
                            stroke="#3B7834"
                          />
                          <path
                            id="Vector_32"
                            d="M335.021 564.535V602.176"
                            stroke="#3B7834"
                          />
                        </g>
                      </g>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-job-protection")}
                    onClick={onClick}
                  >
                    <g id="Group 82">
                      <text
                        id="Tutela"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="781.504" y="588.404">
                          Tutela
                        </tspan>
                      </text>
                      <text
                        id="del lavoro"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="781.504" y="601.193">
                          del lavoro
                        </tspan>
                      </text>
                      <g id="Group_13">
                        <path
                          id="Vector_33"
                          d="M751.441 611.104C748.62 611.104 745.88 610.059 743.22 607.968C740.641 605.957 738.304 603.142 736.773 600.166C736.289 599.281 735.886 598.316 735.564 597.351C735.403 597.351 735.322 597.351 735.161 597.351C734.033 597.351 732.985 596.707 732.34 595.662C731.776 594.777 731.373 593.571 731.373 592.284C731.373 590.997 731.776 589.791 732.34 588.906C732.502 588.665 732.663 588.504 732.824 588.262C731.776 587.217 731.132 586.091 731.132 584.804V581.828C731.132 570.729 740.238 561.64 751.36 561.64C762.482 561.64 771.589 570.729 771.589 581.828V584.804C771.589 586.091 770.944 587.217 769.896 588.262C770.058 588.423 770.219 588.665 770.38 588.906C770.944 589.791 771.347 590.997 771.347 592.284C771.347 593.571 771.025 594.777 770.38 595.662C769.735 596.707 768.768 597.351 767.559 597.351C767.398 597.351 767.317 597.351 767.237 597.351C766.914 598.316 766.511 599.281 766.028 600.166C764.497 603.142 762.24 605.957 759.581 607.968C757.082 610.139 754.261 611.104 751.441 611.104Z"
                          stroke="#9C0139"
                        />
                        <g id="Group_14">
                          <path
                            id="Vector_34"
                            d="M732.985 588.423C741.367 593.812 761.595 593.812 769.977 588.423"
                            stroke="#9C0139"
                          />
                          <path
                            id="Vector_35"
                            d="M732.34 584.724C731.293 585.608 731.293 586.171 731.293 584.884V581.909C731.293 570.809 740.4 561.721 751.521 561.721C762.643 561.721 771.75 570.809 771.75 581.909V584.884C771.75 586.171 771.589 585.608 770.541 584.724H732.34Z"
                            stroke="#9C0139"
                          />
                          <path
                            id="Vector_36"
                            d="M751.36 561.962V576.198"
                            stroke="#9C0139"
                          />
                          <path
                            id="Vector_37"
                            d="M742.737 564.214V572.257"
                            stroke="#9C0139"
                          />
                          <path
                            id="Vector_38"
                            d="M759.984 564.214V572.257"
                            stroke="#9C0139"
                          />
                        </g>
                      </g>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-community-support")}
                    onClick={onClick}
                  >
                    <g id="COMUNITA">
                      <text
                        id="Supporto"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="439.253" y="521.72">
                          Supporto
                        </tspan>
                      </text>
                      <text
                        id="alla comunit&#195;&#160;"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="439.253" y="533.596">
                          alla comunit&#xe0;
                        </tspan>
                      </text>
                      <g id="Group_15">
                        <path
                          id="Vector_39"
                          d="M403.847 510.728C404.572 508.154 406.265 506.707 408.924 506.626C412.47 506.465 416.097 506.465 419.643 506.626C423.109 506.787 425.043 509.039 425.123 512.498C425.123 515.956 425.123 519.415 425.123 522.873C425.123 524.884 424.398 526.331 422.867 527.618C422.383 528.021 421.98 529.066 422.061 529.71C422.625 533.892 423.27 538.074 423.914 542.337"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_40"
                          d="M376.446 542.417C376.446 538.396 376.446 534.455 376.446 530.514C376.446 529.307 376.365 528.503 374.995 527.94C372.9 527.055 371.933 525.206 371.933 522.953C371.933 519.495 371.933 516.037 371.933 512.578C371.933 509.12 373.947 506.787 377.574 506.626C381.362 506.465 385.15 506.465 388.938 506.626C391.355 506.707 393.289 507.833 394.257 510.406"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_41"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M382.651 504.374C379.75 504.374 377.413 502.122 377.332 499.227C377.332 496.251 379.669 493.838 382.571 493.838C385.472 493.838 387.89 496.251 387.89 499.227C387.89 502.042 385.472 504.374 382.651 504.374Z"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_42"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M398.609 520.702C396.03 520.702 393.934 518.691 393.934 516.117C393.934 513.463 395.949 511.372 398.609 511.372C401.268 511.372 403.363 513.543 403.283 516.117C403.202 518.691 401.187 520.702 398.609 520.702Z"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_43"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M413.438 504.374C410.536 504.374 408.199 502.122 408.118 499.227C408.118 496.251 410.456 493.838 413.357 493.838C416.339 493.838 418.676 496.251 418.676 499.227C418.676 502.042 416.339 504.374 413.438 504.374Z"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_44"
                          d="M382.651 528.825V542.417"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_45"
                          d="M387.89 516.037V521.345"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_46"
                          d="M408.118 516.037V521.425"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_47"
                          d="M376.446 542.096V516.519"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_48"
                          d="M422.061 529.71V516.117"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_49"
                          d="M422.061 523.436H411.1"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_50"
                          d="M402.396 531.72V542.82H402.88C403.766 541.774 404.734 540.809 405.459 539.602C406.104 538.557 406.91 537.35 406.99 536.144C407.232 533.168 407.071 530.192 407.071 527.216C407.071 524.482 405.298 522.632 402.558 522.551C401.107 522.471 399.576 522.471 398.125 522.471C396.674 522.471 395.143 522.471 393.692 522.551C390.952 522.632 389.26 524.562 389.179 527.216C389.099 530.192 389.018 533.168 389.26 536.144C389.341 537.35 390.066 538.557 390.791 539.602C391.516 540.728 392.484 541.774 393.37 542.82H393.934V531.72"
                          stroke="#FF9900"
                        />
                      </g>
                    </g>
                  </Link>
                  <path
                    id="Vector_51"
                    d="M550.122 50.6707H295.773C271.354 50.6707 251.528 71.1802 251.528 96.596V369.735"
                    stroke="#9C0139"
                  />
                  <path
                    id="Vector_52"
                    d="M622.655 18.338H994.104C1016.11 18.338 1034 36.1934 1034 58.1507V384.534"
                    stroke="#9C0139"
                  />
                  <path
                    id="Vector_53"
                    d="M671.735 50.7511H983.466C994.346 50.7511 1003.13 59.5179 1003.13 70.3759V302.898"
                    stroke="#DA8C02"
                    strokeMiterlimit="10"
                  />
                  <path
                    id="Vector_54"
                    d="M687.209 83.5664H954.13C962.431 83.5664 969.201 90.9659 969.201 100.054V207.589"
                    stroke="#FF0123"
                  />
                  <path
                    id="Vector_55"
                    d="M554.151 117.266H328.171C309.877 117.266 295.048 132.387 295.048 151.047V448.556"
                    stroke="#F8008B"
                  />
                  <path
                    id="Vector_56"
                    d="M649.895 50.6707H582.036C569.867 50.6707 559.954 61.3678 559.954 74.6387V201.878"
                    stroke="#FF9900"
                  />
                  <path
                    id="Vector_57"
                    d="M649.331 83.8076H616.771C609.679 83.8076 605.488 86.7835 605.488 98.2045V198.742"
                    stroke="#FF6200"
                  />
                  <path
                    id="Vector_58"
                    d="M519.336 148.634H363.793C347.916 148.634 335.021 161.664 335.021 177.749V544.911"
                    stroke="#3B7834"
                  />
                  <path
                    id="Vector_59"
                    d="M585.099 302.898H430.765C413.035 302.898 398.609 318.26 398.609 337.161V501.318"
                    stroke="#FF9900"
                  />
                  <path
                    id="Vector_60"
                    d="M641.836 305.23H732.582C743.14 305.23 751.763 314.078 751.924 325.016L751.36 553.034"
                    stroke="#9C0139"
                  />
                  <path
                    id="Vector_61"
                    d="M658.518 272.254H757.485C772.475 272.254 784.645 285.203 784.645 301.209V444.776"
                    stroke="#019F3C"
                  />
                  <path
                    id="Vector_62"
                    d="M664.16 85.0142V131.663"
                    stroke="#00568F"
                  />
                  <path
                    id="Vector_63"
                    d="M519.336 154.425C523.43 154.425 526.75 151.112 526.75 147.025C526.75 142.939 523.43 139.626 519.336 139.626C515.241 139.626 511.921 142.939 511.921 147.025C511.921 151.112 515.241 154.425 519.336 154.425Z"
                    fill="#3B7834"
                  />
                  <path
                    id="Vector_64"
                    d="M585.099 310.298C589.194 310.298 592.513 306.985 592.513 302.898C592.513 298.811 589.194 295.498 585.099 295.498C581.004 295.498 577.684 298.811 577.684 302.898C577.684 306.985 581.004 310.298 585.099 310.298Z"
                    fill="#FF9900"
                  />
                  <path
                    id="Vector_65"
                    d="M643.609 312.63C647.703 312.63 651.023 309.317 651.023 305.23C651.023 301.144 647.703 297.831 643.609 297.831C639.514 297.831 636.194 301.144 636.194 305.23C636.194 309.317 639.514 312.63 643.609 312.63Z"
                    fill="#9C0139"
                  />
                  <path
                    id="Vector_66"
                    d="M658.518 279.654C662.613 279.654 665.933 276.341 665.933 272.254C665.933 268.168 662.613 264.855 658.518 264.855C654.423 264.855 651.104 268.168 651.104 272.254C651.104 276.341 654.423 279.654 658.518 279.654Z"
                    fill="#019F3C"
                  />
                  <path
                    id="Vector_67"
                    d="M554.151 124.666C558.246 124.666 561.566 121.353 561.566 117.266C561.566 113.18 558.246 109.867 554.151 109.867C550.056 109.867 546.737 113.18 546.737 117.266C546.737 121.353 550.056 124.666 554.151 124.666Z"
                    fill="#F8008B"
                  />
                  <path
                    id="Vector_68"
                    d="M550.122 58.4724C554.217 58.4724 557.536 55.1595 557.536 51.0729C557.536 46.9862 554.217 43.6733 550.122 43.6733C546.027 43.6733 542.707 46.9862 542.707 51.0729C542.707 55.1595 546.027 58.4724 550.122 58.4724Z"
                    fill="#9C0139"
                  />
                  <path
                    id="Vector_69"
                    d="M622.655 25.7375C626.749 25.7375 630.069 22.4246 630.069 18.338C630.069 14.2514 626.749 10.9385 622.655 10.9385C618.56 10.9385 615.24 14.2514 615.24 18.338C615.24 22.4246 618.56 25.7375 622.655 25.7375Z"
                    fill="#9C0139"
                  />
                  <path
                    id="Vector_70"
                    d="M649.895 58.0702C653.99 58.0702 657.309 54.7573 657.309 50.6706C657.309 46.584 653.99 43.2711 649.895 43.2711C645.8 43.2711 642.48 46.584 642.48 50.6706C642.48 54.7573 645.8 58.0702 649.895 58.0702Z"
                    fill="#FF9900"
                  />
                  <path
                    id="Vector_71"
                    d="M665.852 58.0702C669.947 58.0702 673.266 54.7573 673.266 50.6706C673.266 46.584 669.947 43.2711 665.852 43.2711C661.757 43.2711 658.438 46.584 658.438 50.6706C658.438 54.7573 661.757 58.0702 665.852 58.0702Z"
                    fill="#DA8C02"
                  />
                  <path
                    id="Vector_72"
                    d="M687.209 90.9659C691.304 90.9659 694.623 87.653 694.623 83.5664C694.623 79.4798 691.304 76.1669 687.209 76.1669C683.114 76.1669 679.794 79.4798 679.794 83.5664C679.794 87.653 683.114 90.9659 687.209 90.9659Z"
                    fill="#FF0123"
                  />
                  <path
                    id="Vector_73"
                    d="M664.724 91.2072C668.819 91.2072 672.138 87.8944 672.138 83.8077C672.138 79.7211 668.819 76.4082 664.724 76.4082C660.629 76.4082 657.309 79.7211 657.309 83.8077C657.309 87.8944 660.629 91.2072 664.724 91.2072Z"
                    fill="#00568F"
                  />
                  <path
                    id="Vector_74"
                    d="M649.895 91.2072C653.99 91.2072 657.309 87.8944 657.309 83.8077C657.309 79.7211 653.99 76.4082 649.895 76.4082C645.8 76.4082 642.48 79.7211 642.48 83.8077C642.48 87.8944 645.8 91.2072 649.895 91.2072Z"
                    fill="#FF6200"
                  />

                  <Link
                    to={menuItemUrl("item-service-quality")}
                    onClick={onClick}
                  >
                    <g id="QUALITA SERVIZIO">
                      <g id="Group_16">
                        <path
                          id="Vector_75"
                          d="M561.888 216.919L552.942 226.168C552.942 226.168 548.51 221.664 548.349 221.664"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_76"
                          d="M542.788 231.959L533.6 246.999L542.627 247.241L546.979 255.203L555.521 241.208"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_77"
                          d="M552.701 236.544L555.521 241.208"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_78"
                          d="M567.933 231.396L577.523 246.999L568.416 247.241L564.064 255.203L555.521 241.208"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_79"
                          d="M552.701 236.543L550.122 237.911L547.14 232.602L542.788 231.959"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_80"
                          d="M552.701 236.544L555.521 235.096L558.423 236.463"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_81"
                          d="M567.933 231.396L563.742 232.281L561.002 237.589L558.423 236.463"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_82"
                          d="M567.933 231.396L569.625 231.074L568.658 225.123L572.768 220.618L568.416 216.436L569.141 210.404L563.178 209.519L560.196 204.211L554.796 207.026L549.316 204.533L546.576 209.921L540.692 211.128L541.659 217.08L537.549 221.503L541.821 225.766L541.176 231.718L542.788 231.959"
                          stroke="#FF9900"
                        />
                      </g>
                      <text
                        id="Qualit&#195;&#160; del servizio"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="416.561" y="223.927">
                          Qualit&#xe0; del servizio
                        </tspan>
                      </text>
                      <text
                        id="e soddisfazione"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="435.361" y="236.803">
                          e soddisfazione
                        </tspan>
                      </text>
                      <text
                        id="del cliente"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="464.256" y="248.678">
                          del cliente
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-innovation-transformation")}
                    onClick={onClick}
                  >
                    <g id="INNOVAZIONE">
                      <g id="Group_17">
                        <g id="Group_18">
                          <path
                            id="Vector_83"
                            d="M617.577 227.294C617.174 227.133 616.771 227.053 616.368 227.053H606.456C605.972 227.053 605.488 227.133 605.005 227.374"
                            stroke="#FF6200"
                          />
                          <path
                            id="Vector_84"
                            d="M605.086 227.375C603.796 227.938 602.829 229.224 602.829 230.753V237.911C602.829 239.922 604.441 241.611 606.536 241.611H616.449C618.464 241.611 620.156 240.002 620.156 237.911V230.753C620.156 229.144 619.109 227.777 617.658 227.294"
                            stroke="#FF6200"
                          />
                          <path
                            id="Vector_85"
                            d="M617.577 227.294C617.738 226.811 617.819 226.248 617.819 225.685V224.64C617.819 221.664 615.401 219.171 612.339 219.171H610.082C607.1 219.171 604.602 221.584 604.602 224.64V225.685C604.602 226.329 604.763 226.892 604.924 227.374"
                            stroke="#FF6200"
                          />
                        </g>
                        <path
                          id="Vector_86"
                          d="M611.372 232.281V236.865"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_87"
                          d="M592.836 214.345C594.438 214.345 595.737 213.049 595.737 211.449C595.737 209.85 594.438 208.554 592.836 208.554C591.233 208.554 589.934 209.85 589.934 211.449C589.934 213.049 591.233 214.345 592.836 214.345Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_88"
                          d="M605.811 214.345C607.413 214.345 608.712 213.049 608.712 211.449C608.712 209.85 607.413 208.554 605.811 208.554C604.209 208.554 602.91 209.85 602.91 211.449C602.91 213.049 604.209 214.345 605.811 214.345Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_89"
                          d="M605.811 200.752V208.634"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_90"
                          d="M585.502 232.844C587.104 232.844 588.403 231.547 588.403 229.948C588.403 228.349 587.104 227.053 585.502 227.053C583.899 227.053 582.6 228.349 582.6 229.948C582.6 231.547 583.899 232.844 585.502 232.844Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_91"
                          d="M596.301 229.948H588.403"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_92"
                          d="M616.771 214.345C618.374 214.345 619.673 213.049 619.673 211.449C619.673 209.85 618.374 208.554 616.771 208.554C615.169 208.554 613.87 209.85 613.87 211.449C613.87 213.049 615.169 214.345 616.771 214.345Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_93"
                          d="M616.771 200.752V208.634"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_94"
                          d="M592.916 214.506V220.377C592.916 220.377 592.433 223.675 595.817 223.675C599.202 223.675 598.396 223.675 598.396 223.675"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_95"
                          d="M629.666 214.345C631.268 214.345 632.568 213.049 632.568 211.449C632.568 209.85 631.268 208.554 629.666 208.554C628.064 208.554 626.765 209.85 626.765 211.449C626.765 213.049 628.064 214.345 629.666 214.345Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_96"
                          d="M629.585 214.506V220.377C629.585 220.377 630.069 223.675 626.684 223.675C623.299 223.675 624.105 223.675 624.105 223.675"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_97"
                          d="M592.836 251.262C594.438 251.262 595.737 249.966 595.737 248.367C595.737 246.768 594.438 245.471 592.836 245.471C591.233 245.471 589.934 246.768 589.934 248.367C589.934 249.966 591.233 251.262 592.836 251.262Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_98"
                          d="M605.811 251.262C607.413 251.262 608.712 249.966 608.712 248.367C608.712 246.768 607.413 245.471 605.811 245.471C604.209 245.471 602.91 246.768 602.91 248.367C602.91 249.966 604.209 251.262 605.811 251.262Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_99"
                          d="M605.811 259.144V251.262"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_100"
                          d="M616.771 251.262C618.374 251.262 619.673 249.966 619.673 248.367C619.673 246.768 618.374 245.471 616.771 245.471C615.169 245.471 613.87 246.768 613.87 248.367C613.87 249.966 615.169 251.262 616.771 251.262Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_101"
                          d="M616.771 259.144V251.262"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_102"
                          d="M592.916 245.391V239.519C592.916 239.519 592.433 236.222 595.817 236.222C599.202 236.222 598.396 236.222 598.396 236.222"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_103"
                          d="M629.666 251.262C631.268 251.262 632.568 249.966 632.568 248.367C632.568 246.768 631.268 245.471 629.666 245.471C628.064 245.471 626.765 246.768 626.765 248.367C626.765 249.966 628.064 251.262 629.666 251.262Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_104"
                          d="M629.585 245.391V239.519C629.585 239.519 630.069 236.222 626.684 236.222C623.299 236.222 624.105 236.222 624.105 236.222"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_105"
                          d="M598.559 229.948H598.235"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_106"
                          d="M637.081 232.844C638.683 232.844 639.982 231.547 639.982 229.948C639.982 228.349 638.683 227.053 637.081 227.053C635.478 227.053 634.179 228.349 634.179 229.948C634.179 231.547 635.478 232.844 637.081 232.844Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_107"
                          d="M626.362 229.948H634.179"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_108"
                          d="M624.025 229.948H624.349"
                          stroke="#FF6200"
                        />
                      </g>
                      <text
                        id="Innovazione"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="648.813" y="223.928">
                          Innovazione
                        </tspan>
                      </text>
                      <text
                        id="trasformazione digitale"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="648.813" y="235.803">
                          trasformazione digitale
                        </tspan>
                      </text>
                      <text
                        id="cybersecurity"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="648.813" y="247.678">
                          cybersecurity
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link to={menuItemUrl("item-integrity")} onClick={onClick}>
                    <g id="INTEGRITA">
                      <g id="Group_19">
                        <path
                          id="Vector_109"
                          d="M664.16 182.414C672.661 182.414 679.553 171.395 679.553 157.803C679.553 144.21 672.661 133.191 664.16 133.191C655.658 133.191 648.767 144.21 648.767 157.803C648.767 171.395 655.658 182.414 664.16 182.414Z"
                          stroke="#00568F"
                        />
                        <path
                          id="Vector_110"
                          d="M685.25 170.497C689.643 163.233 683.752 151.651 672.092 144.626C660.432 137.601 647.418 137.795 643.025 145.058C638.631 152.322 644.522 163.904 656.182 170.929C667.842 177.954 680.856 177.76 685.25 170.497Z"
                          stroke="#00568F"
                        />
                        <path
                          id="Vector_111"
                          d="M672.119 171.08C683.779 164.056 689.67 152.473 685.277 145.21C680.883 137.946 667.869 137.753 656.209 144.777C644.549 151.802 638.658 163.385 643.052 170.648C647.445 177.911 660.459 178.105 672.119 171.08Z"
                          stroke="#00568F"
                        />
                        <path
                          id="Vector_112"
                          d="M664.16 164.961C677.699 157.481 671.896 152.173 671.896 152.173C668.109 148.714 664.16 152.494 664.16 152.494C664.16 152.494 660.211 148.634 656.423 152.173C656.503 152.173 650.701 157.481 664.16 164.961Z"
                          stroke="#00568F"
                        />
                      </g>
                      <text
                        id="Integrit&#195;&#160; nella"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="697.314" y="159.984">
                          Integrit&#xe0; nella
                        </tspan>
                      </text>
                      <text
                        id="condotta aziendale"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="697.314" y="171.859">
                          condotta aziendale
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-financial-inclusion")}
                    onClick={onClick}
                  >
                    <g id="Group 78">
                      <g id="Group_20">
                        <g id="Group_21">
                          <path
                            id="Vector_113"
                            d="M986.85 227.375C987.817 229.385 988.543 231.477 988.865 233.729"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_114"
                            d="M953.324 255.605C955.097 256.892 957.112 257.938 959.207 258.662"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_115"
                            d="M984.835 250.538C986.125 248.769 987.173 246.758 987.898 244.667"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_116"
                            d="M948.247 224.077C946.957 225.846 945.91 227.857 945.184 229.948"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_117"
                            d="M970.168 259.627C972.424 259.225 974.52 258.581 976.534 257.616"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_118"
                            d="M973.875 215.954C976.051 216.677 977.985 217.723 979.758 219.01"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_119"
                            d="M956.628 217.08C958.562 216.114 960.739 215.391 962.995 215.069"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_120"
                            d="M946.232 247.241C945.265 245.23 944.539 243.139 944.217 240.887"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_121"
                            d="M948.247 224.077C949.214 224.962 950.584 225.605 952.035 225.605C955.097 225.605 957.515 223.112 957.515 220.136C957.515 219.01 957.192 217.964 956.628 217.08"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_122"
                            d="M949.214 237.348C948.972 238.072 948.488 238.715 947.924 239.359C946.877 240.404 945.587 240.887 944.217 240.967"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_123"
                            d="M949.214 237.348C949.939 235.418 949.536 233.085 947.924 231.557C947.118 230.753 946.151 230.27 945.104 230.029"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_124"
                            d="M946.232 247.241C947.118 246.678 948.166 246.356 949.294 246.356C952.357 246.356 954.775 248.849 954.775 251.825C954.775 253.273 954.211 254.64 953.324 255.605"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_125"
                            d="M966.541 254.64C967.267 254.881 967.911 255.364 968.556 255.927C969.604 256.973 970.087 258.259 970.168 259.627"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_126"
                            d="M966.541 254.64C964.607 253.916 962.27 254.318 960.738 255.927C959.933 256.731 959.449 257.697 959.207 258.742"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_127"
                            d="M984.835 250.538C983.868 249.654 982.498 249.01 981.048 249.01C977.985 249.01 975.568 251.503 975.568 254.479C975.568 255.605 975.89 256.651 976.454 257.536"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_128"
                            d="M988.865 233.729C987.495 233.729 986.206 234.292 985.158 235.337C984.594 235.9 984.191 236.624 983.869 237.348"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_129"
                            d="M983.869 237.348C983.143 239.278 983.546 241.611 985.158 243.139C985.964 243.943 986.931 244.426 987.979 244.667"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_130"
                            d="M979.758 219.01C978.791 219.975 978.308 221.342 978.308 222.79C978.308 225.846 980.806 228.259 983.788 228.259C984.916 228.259 985.964 227.938 986.85 227.375"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_131"
                            d="M973.875 215.954C973.633 216.999 973.15 217.964 972.344 218.769C970.813 220.377 968.475 220.779 966.541 220.056"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_132"
                            d="M962.915 215.069C962.995 216.436 963.479 217.723 964.526 218.769C965.091 219.332 965.816 219.734 966.541 220.055"
                            stroke="#FF0123"
                          />
                        </g>
                        <g id="Group_22">
                          <path
                            id="Vector_133"
                            d="M956.064 234.774H966.541"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_134"
                            d="M956.145 239.841H966.541"
                            stroke="#FF0123"
                          />
                        </g>
                        <path
                          id="Vector_135"
                          d="M972.424 227.777C971.78 227.616 971.135 227.535 970.41 227.535C965.01 227.535 960.658 231.879 960.658 237.267C960.658 242.656 965.01 246.999 970.41 246.999C971.054 246.999 971.78 246.919 972.424 246.758"
                          stroke="#FF0123"
                        />
                      </g>
                      <text
                        id="Inclusione finanziaria"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="806.873" y="233.062">
                          Inclusione finanziaria
                        </tspan>
                      </text>
                      <text
                        id="e sostegno al"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="850.002" y="245.851">
                          e sostegno al
                        </tspan>
                      </text>
                      <text
                        id="tessuto produttivo"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="821.916" y="257.726">
                          tessuto produttivo
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-green-transition")}
                    onClick={onClick}
                  >
                    <g id="Group 79">
                      <g id="Group_23">
                        <g id="Group_24">
                          <path
                            id="Vector_136"
                            d="M982.337 338.528C982.337 340.459 983.869 341.987 985.803 341.987C987.737 341.987 989.268 340.459 989.268 338.528C989.268 336.598 987.737 335.07 985.803 335.07C983.869 335.07 982.337 336.598 982.337 338.528Z"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_137"
                            d="M989.913 328.957C992.492 328.957 994.587 326.866 994.587 324.292C994.587 321.719 992.492 319.627 989.913 319.627C987.334 319.627 985.239 321.719 985.239 324.292C985.239 326.866 987.334 328.957 989.913 328.957Z"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_138"
                            d="M1002 327.429C1002.24 326.464 1002.4 325.338 1002.4 324.292C1002.4 317.858 997.166 312.63 990.719 312.63C984.271 312.63 979.033 317.858 979.033 324.292C979.033 327.429 980.242 330.244 982.257 332.335"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_139"
                            d="M982.257 332.335C979.355 333.542 977.34 336.437 977.34 339.735C977.34 344.158 980.967 347.778 985.4 347.778C989.832 347.778 993.459 344.158 993.459 339.735"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_140"
                            d="M998.697 344.078C997.005 345.043 996.441 347.215 997.408 348.823C998.375 350.512 1000.55 351.075 1002.16 350.11C1003.86 349.145 1004.42 346.973 1003.45 345.365C1002.49 343.676 1000.31 343.113 998.697 344.078Z"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_141"
                            d="M1013.04 340.459C1015.3 339.172 1016.02 336.276 1014.74 334.105C1013.45 331.853 1010.54 331.129 1008.37 332.416C1006.11 333.703 1005.39 336.598 1006.68 338.77C1007.97 341.022 1010.87 341.746 1013.04 340.459Z"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_142"
                            d="M1002 327.429C1002.73 326.705 1003.45 326.142 1004.34 325.579C1009.9 322.362 1017.07 324.212 1020.38 329.762C1023.68 335.311 1021.75 342.469 1016.19 345.767C1013.45 347.376 1010.38 347.697 1007.56 347.054"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_143"
                            d="M995.554 341.183C991.686 343.435 990.396 348.341 992.653 352.121C994.91 355.982 999.826 357.269 1003.61 355.016C1006.51 353.327 1007.97 350.191 1007.56 347.054"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                        </g>
                        <path
                          id="Vector_144"
                          d="M996.279 337.563L1006.68 319.627"
                          stroke="#DA8C02"
                          strokeMiterlimit="10"
                        />
                        <path
                          id="Vector_145"
                          d="M1006.68 319.627L1003.94 311.102"
                          stroke="#DA8C02"
                          strokeMiterlimit="10"
                        />
                        <path
                          id="Vector_146"
                          d="M1015.38 317.375L1006.68 319.627"
                          stroke="#DA8C02"
                          strokeMiterlimit="10"
                        />
                      </g>
                      <text
                        id="Transizione verso"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="861.188" y="329.891">
                          Transizione verso
                        </tspan>
                      </text>
                      <text
                        id="un&#226;&#128;&#153;economia sostenibile"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="824.212" y="342.679">
                          un&#x2019;economia sostenibile
                        </tspan>
                      </text>
                      <text
                        id="green e circular"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="870.555" y="354.555">
                          green e circular
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-health-safety")}
                    onClick={onClick}
                  >
                    <g id="Group 81">
                      <g id="Group_25">
                        <g id="Group_26">
                          <path
                            id="Vector_147"
                            d="M767.72 490.459C767.72 500.031 775.457 507.752 785.048 507.752C785.451 507.752 785.854 507.752 786.257 507.671"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_148"
                            d="M774.49 484.186C776.827 484.427 779.326 485.955 781.099 487.403C782.872 488.851 784 491.586 785.37 496.974"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_149"
                            d="M773.12 461.505V461.586V461.667"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_150"
                            d="M780.857 461.344C780.776 459.333 779.084 457.725 776.988 457.725C774.893 457.725 773.281 459.333 773.12 461.344"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_151"
                            d="M773.12 461.507C773.12 461.507 773.12 461.426 773.12 461.344"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_152"
                            d="M780.857 461.587C780.857 461.506 780.857 461.425 780.857 461.344"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_153"
                            d="M788.513 457.081C788.513 457.001 788.513 456.921 788.513 456.84C788.513 454.749 786.821 452.979 784.645 452.979C782.469 452.979 780.776 454.669 780.776 456.84C780.776 456.921 780.776 457.001 780.776 457.081"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_154"
                            d="M795.686 457.081C795.363 455.392 793.913 454.105 792.14 454.105C790.367 454.105 788.916 455.392 788.594 457.081"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_155"
                            d="M795.686 457.081C795.686 457.323 795.766 457.483 795.766 457.725"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_156"
                            d="M788.513 457.725C788.513 457.483 788.513 457.323 788.594 457.081"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_157"
                            d="M774.168 476.867C774.087 475.982 773.765 475.178 773.12 474.615"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_158"
                            d="M785.37 483.301C785.37 480.969 783.516 479.119 781.179 479.119C778.842 479.119 776.989 480.969 776.989 483.301C776.989 484.025 777.069 484.508 777.391 485.071"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_159"
                            d="M773.12 461.667V461.505"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_160"
                            d="M773.12 474.535V461.666"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_161"
                            d="M773.202 461.344H773.12V461.507"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_162"
                            d="M780.857 457.081V461.344"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_163"
                            d="M780.857 474.535V473.65"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_164"
                            d="M780.857 461.587V461.344"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_165"
                            d="M780.857 473.65V461.585"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_166"
                            d="M795.766 457.725V469.468"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_167"
                            d="M795.766 457.725V457.081H795.686"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_168"
                            d="M788.596 457.081H788.513"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_169"
                            d="M788.513 457.725V457.081"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_170"
                            d="M788.513 474.535V473.489"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_171"
                            d="M788.513 457.725V473.489"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_172"
                            d="M795.766 469.468V474.535"
                            stroke="#019F3C"
                          />
                        </g>
                        <path
                          id="Vector_173"
                          d="M786.337 507.672C795.363 507.028 802.456 499.548 802.456 490.46C802.456 489.173 802.294 487.886 802.053 486.68L801.811 485.393V469.468C801.811 467.859 800.441 466.492 798.829 466.492C797.217 466.492 795.847 467.859 795.847 469.468"
                          stroke="#019F3C"
                        />
                        <path
                          id="Vector_174"
                          d="M768.043 493.838L766.995 482.497V476.867C767.156 475.017 768.688 473.489 770.541 473.489C771.508 473.489 772.395 473.891 773.12 474.535"
                          stroke="#019F3C"
                        />
                        <path
                          id="Vector_175"
                          d="M774.49 484.186V482.578L774.248 476.787V476.867"
                          stroke="#019F3C"
                        />
                        <path
                          id="Vector_176"
                          d="M785.37 497.055C790.528 490.54 793.671 486.84 793.671 483.543C793.671 480.245 791.898 479.36 789.883 479.2C787.385 478.958 785.37 480.889 785.37 483.382"
                          stroke="#019F3C"
                        />
                      </g>
                      <text
                        id="Salute, sicurezza"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="813.533" y="475.133">
                          Salute, sicurezza
                        </tspan>
                      </text>
                      <text
                        id="e benessere delle"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="813.533" y="488.009">
                          e benessere delle
                        </tspan>
                      </text>
                      <text
                        id="persone del Gruppo"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="813.533" y="499.797">
                          persone del Gruppo
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link to={menuItemUrl("item-group-value")} onClick={onClick}>
                    <g id="Group 80">
                      <text
                        id="Valore e solidit&#195;&#160;"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="911.11" y="413.017">
                          Valore e solidit&#xe0;
                        </tspan>
                      </text>
                      <text
                        id="del Gruppo"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="937.224" y="425.806">
                          del Gruppo
                        </tspan>
                      </text>
                      <g id="Group_27">
                        <path
                          id="Vector_177"
                          d="M1048.34 397.564C1048.34 398.368 1048.99 399.011 1049.79 399.011C1050.84 399.011 1051.65 398.207 1051.65 397.161C1051.65 395.875 1050.6 394.829 1049.39 394.829C1047.78 394.829 1046.49 396.116 1046.49 397.725C1046.49 399.735 1048.1 401.344 1050.12 401.344C1052.61 401.344 1054.63 399.333 1054.63 396.84C1054.63 393.783 1052.13 391.21 1048.99 391.21H1019.17C1016.11 391.21 1013.53 393.703 1013.53 396.84C1013.53 399.333 1015.54 401.344 1018.04 401.344C1020.05 401.344 1021.67 399.735 1021.67 397.725C1021.67 396.116 1020.38 394.829 1018.76 394.829C1017.48 394.829 1016.51 395.875 1016.51 397.161C1016.51 398.207 1017.31 399.011 1018.36 399.011C1019.17 399.011 1019.81 398.368 1019.81 397.564"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_178"
                          d="M1046.49 398.127V420.808H1021.59V398.127"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_179"
                          d="M1050.36 425.875H1018.36L1021.59 420.808H1046.49L1050.36 425.875Z"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_180"
                          d="M1054.06 425.875H1014.41V430.861H1054.06V425.875Z"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_181"
                          d="M1027.95 420.808V402.55"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_182"
                          d="M1034.24 420.808V402.55"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_183"
                          d="M1040.61 420.808V402.55"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_184"
                          d="M1024.73 398.127H1043.35"
                          stroke="#9C0139"
                        />
                      </g>
                    </g>
                  </Link>
                </g>
              </g>
            </g>
            <g id="CTA SMALL VAR">
              <rect
                id="Rectangle 30"
                x="934.5"
                y="664.5"
                width="142"
                height="40"
                stroke="#2B8804"
                strokeWidth="3"
              />
              <text
                id="scarica la dcnf 2021"
                fill="#2B8804"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Open Sans"
                fontSize="12"
                fontWeight="bold"
                letterSpacing="0em"
              >
                <tspan x="991.338" y="687.97">
                  2021
                </tspan>
              </text>
            </g>
            <g id="CTA SMALL VAR_2" opacity="0.5">
              <rect
                id="Rectangle 30_2"
                x="769.5"
                y="664.5"
                width="142"
                height="40"
                stroke="#2B8804"
                strokeWidth="3"
              />
              <text
                id="scarica la dcnf 2021_2"
                fill="#2B8804"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Open Sans"
                fontSize="12"
                fontWeight="bold"
                letterSpacing="0em"
              >
                <tspan x="806.082" y="687.97">
                  2020 + 2021
                </tspan>
              </text>
            </g>
          </g>
        </svg>
      );

    default:
      return (
        <svg
          className={classes}
          style={{ width, height }}
          viewBox="0 0 1078 644"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <style>
              {`
            a:link,
            a:visited,
            a:link g,
            a:visited g {
              cursor: pointer;
            }

            a:hover text,
            a:active text {
              fill: #2b8804; /* Even for text, SVG uses fill over color */
            }`}
            </style>
          </defs>

          <g id="matrice 2021">
            <rect
              id="Rectangle 48"
              x="768"
              y="663"
              width="310"
              height="43"
              fill="white"
            />
            <rect
              id="Rectangle 49"
              x="92"
              width="978"
              height="616"
              fill="white"
            />
            <g id="b">
              <g id="c">
                <g id="Group">
                  <g id="Group_2">
                    <path
                      id="Vector"
                      d="M6.60855 1.76941V637.405"
                      stroke="#323231"
                    />
                    <path
                      id="Vector_2"
                      d="M0 7.07781L1.20888 8.20382L6.60855 2.33246L12.0082 8.20382L13.2171 7.07781L6.60855 0L0 7.07781Z"
                      fill="#323231"
                    />
                  </g>
                  <path
                    id="Vector_3"
                    d="M1059.06 637.405H6.60855"
                    stroke="#323231"
                  />
                  <g id="Group_3">
                    <path
                      id="Vector_4"
                      d="M1076.31 637.405H6.60855"
                      stroke="#323231"
                    />
                    <path
                      id="Vector_5"
                      d="M1070.91 630.81L1069.86 632.016L1075.66 637.405L1069.86 642.794L1070.91 644L1078 637.405L1070.91 630.81Z"
                      fill="#323231"
                    />
                  </g>
                  <path
                    id="Vector_6"
                    opacity="0.11"
                    d="M1059.06 486.519H6.60855"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_7"
                    opacity="0.11"
                    d="M1059.06 335.633H6.60855"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_8"
                    opacity="0.11"
                    d="M1059.06 184.747H6.60855"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_9"
                    opacity="0.11"
                    d="M1059.06 33.8608H6.60855"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_10"
                    opacity="0.11"
                    d="M181.735 33.8608V637.405"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_11"
                    opacity="0.11"
                    d="M357.91 33.8608V637.405"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_12"
                    opacity="0.11"
                    d="M534.084 33.8608V637.405"
                    stroke="#323231"
                  />
                  <path
                    id="Vector_13"
                    opacity="0.11"
                    d="M710.178 33.8608V637.405"
                    stroke="#323231"
                  />

                  <Link
                    to={menuItemUrl("item-investment-insurance")}
                    onClick={onClick}
                  >
                    <g id="INVESTIMENTI">
                      <text
                        id="Investimenti"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="146.055" y="399.315">
                          Sustainable
                        </tspan>
                      </text>
                      <text
                        id="e assicurazione"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="147.86" y="412.103">
                          investment
                        </tspan>
                      </text>
                      <text
                        id="sostenibili"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="133.403" y="423.979">
                          and insurance
                        </tspan>
                      </text>
                      <g id="Group_4">
                        <path
                          id="Vector_14"
                          d="M251.609 423.703C224.61 406.572 232.508 383.891 232.508 383.891C242.099 384.051 249.594 379.145 251.609 377.698C253.623 379.145 261.199 384.051 270.709 383.891C270.709 383.891 278.607 406.572 251.609 423.703Z"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_15"
                          d="M244.516 400.62C244.678 400.7 250.561 406.652 250.561 406.652L262.408 394.507"
                          stroke="#9C0139"
                        />
                      </g>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-diversity-inclusion")}
                    onClick={onClick}
                  >
                    <g id="INVESTIMENTI_2">
                      <text
                        id="Attrazione, valorizzazione"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="110.993" y="476.96">
                          Retention, enhancement
                        </tspan>
                      </text>
                      <text
                        id="diversity e inclusion"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="122.848" y="488.835">
                          diversity and inclusion
                        </tspan>
                      </text>
                      <text
                        id="delle persone del Gruppo"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="125.712" y="500.711">
                          of the Group's people
                        </tspan>
                      </text>
                      <g id="Group_5">
                        <g id="Group_6">
                          <path
                            id="Vector_16"
                            d="M295.048 464.722C297.585 464.722 299.641 462.67 299.641 460.138C299.641 457.606 297.585 455.553 295.048 455.553C292.511 455.553 290.454 457.606 290.454 460.138C290.454 462.67 292.511 464.722 295.048 464.722Z"
                            stroke="#F8008B"
                          />
                          <path
                            id="Vector_17"
                            d="M288.842 466.894C290.535 465.527 292.711 464.722 295.048 464.722C300.609 464.722 305.122 469.226 305.122 474.776C305.122 477.109 304.316 479.2 303.026 480.969"
                            stroke="#F8008B"
                          />
                        </g>
                        <g id="Group_7">
                          <path
                            id="Vector_18"
                            d="M273.61 480.326C276.147 480.326 278.204 478.273 278.204 475.741C278.204 473.209 276.147 471.157 273.61 471.157C271.073 471.157 269.017 473.209 269.017 475.741C269.017 478.273 271.073 480.326 273.61 480.326Z"
                            stroke="#F8008B"
                          />
                          <path
                            id="Vector_19"
                            d="M278.043 483.623C277.317 481.612 277.237 479.28 277.962 477.028C279.655 471.72 285.377 468.824 290.696 470.594C292.872 471.317 294.725 472.685 295.934 474.454"
                            stroke="#F8008B"
                          />
                        </g>
                        <g id="Group_8">
                          <path
                            id="Vector_20"
                            d="M281.75 505.42C284.287 505.42 286.344 503.367 286.344 500.836C286.344 498.304 284.287 496.251 281.75 496.251C279.213 496.251 277.156 498.304 277.156 500.836C277.156 503.367 279.213 505.42 281.75 505.42Z"
                            stroke="#F8008B"
                          />
                          <path
                            id="Vector_21"
                            d="M290.776 499.066C288.6 499.146 286.424 498.503 284.49 497.136C279.977 493.838 279.01 487.565 282.234 483.06C283.604 481.211 285.457 479.924 287.553 479.28"
                            stroke="#F8008B"
                          />
                        </g>
                        <g id="Group_9">
                          <path
                            id="Vector_22"
                            d="M308.265 505.42C310.802 505.42 312.859 503.367 312.859 500.836C312.859 498.304 310.802 496.251 308.265 496.251C305.728 496.251 303.671 498.304 303.671 500.836C303.671 503.367 305.728 505.42 308.265 505.42Z"
                            stroke="#F8008B"
                          />
                          <path
                            id="Vector_23"
                            d="M309.313 491.747C308.748 493.838 307.459 495.768 305.525 497.136C301.012 500.433 294.725 499.388 291.421 494.884C290.051 493.034 289.406 490.862 289.487 488.69"
                            stroke="#F8008B"
                          />
                        </g>
                        <g id="Group_10">
                          <path
                            id="Vector_24"
                            d="M316.405 480.326C318.942 480.326 320.998 478.273 320.998 475.741C320.998 473.209 318.942 471.157 316.405 471.157C313.868 471.157 311.811 473.209 311.811 475.741C311.811 478.273 313.868 480.326 316.405 480.326Z"
                            stroke="#F8008B"
                          />
                          <path
                            id="Vector_25"
                            d="M308.104 471.881C309.877 473.087 311.327 474.937 312.053 477.109C313.745 482.417 310.844 488.127 305.605 489.816C303.429 490.54 301.092 490.46 299.077 489.736"
                            stroke="#F8008B"
                          />
                        </g>
                      </g>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-climate-change")}
                    onClick={onClick}
                  >
                    <g id="CLIMA">
                      <text
                        id="Cambiamento"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="246.544" y="585.664">
                          Climate
                        </tspan>
                      </text>
                      <text
                        id="climatico"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="247.829" y="597.539">
                          change
                        </tspan>
                      </text>
                      <g id="Group_11">
                        <path
                          id="Vector_26"
                          d="M335.021 611.265C350.377 611.265 362.826 598.842 362.826 583.517C362.826 568.192 350.377 555.769 335.021 555.769C319.665 555.769 307.217 568.192 307.217 583.517C307.217 598.842 319.665 611.265 335.021 611.265Z"
                          stroke="#3B7834"
                          strokeDasharray="2 2"
                        />
                        <g id="Group_12">
                          <path
                            id="Vector_27"
                            d="M335.021 602.257C341.119 602.257 346.062 593.867 346.062 583.517C346.062 573.167 341.119 564.777 335.021 564.777C328.924 564.777 323.98 573.167 323.98 583.517C323.98 593.867 328.924 602.257 335.021 602.257Z"
                            stroke="#3B7834"
                          />
                          <path
                            id="Vector_28"
                            d="M335.021 602.418C345.481 602.418 353.961 593.955 353.961 583.517C353.961 573.078 345.481 564.616 335.021 564.616C324.562 564.616 316.082 573.078 316.082 583.517C316.082 593.955 324.562 602.418 335.021 602.418Z"
                            stroke="#3B7834"
                          />
                          <path
                            id="Vector_29"
                            d="M320.676 595.983C324.141 593.329 329.299 591.64 335.102 591.64C340.905 591.64 345.901 593.249 349.367 595.822"
                            stroke="#3B7834"
                          />
                          <path
                            id="Vector_30"
                            d="M349.367 571.05C345.901 573.624 340.743 575.313 335.021 575.313C329.299 575.313 324.222 573.705 320.676 571.05"
                            stroke="#3B7834"
                          />
                          <path
                            id="Vector_31"
                            d="M316.082 583.517H353.961"
                            stroke="#3B7834"
                          />
                          <path
                            id="Vector_32"
                            d="M335.021 564.535V602.176"
                            stroke="#3B7834"
                          />
                        </g>
                      </g>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-job-protection")}
                    onClick={onClick}
                  >
                    <g id="Group 82">
                      <text
                        id="Tutela"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="781.504" y="588.404">
                          Employment
                        </tspan>
                      </text>
                      <text
                        id="del lavoro"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="781.504" y="601.193">
                          protection
                        </tspan>
                      </text>
                      <g id="Group_13">
                        <path
                          id="Vector_33"
                          d="M751.441 611.104C748.62 611.104 745.88 610.059 743.22 607.968C740.641 605.957 738.304 603.142 736.773 600.166C736.289 599.281 735.886 598.316 735.564 597.351C735.403 597.351 735.322 597.351 735.161 597.351C734.033 597.351 732.985 596.707 732.34 595.662C731.776 594.777 731.373 593.571 731.373 592.284C731.373 590.997 731.776 589.791 732.34 588.906C732.502 588.665 732.663 588.504 732.824 588.262C731.776 587.217 731.132 586.091 731.132 584.804V581.828C731.132 570.729 740.238 561.64 751.36 561.64C762.482 561.64 771.589 570.729 771.589 581.828V584.804C771.589 586.091 770.944 587.217 769.896 588.262C770.058 588.423 770.219 588.665 770.38 588.906C770.944 589.791 771.347 590.997 771.347 592.284C771.347 593.571 771.025 594.777 770.38 595.662C769.735 596.707 768.768 597.351 767.559 597.351C767.398 597.351 767.317 597.351 767.237 597.351C766.914 598.316 766.511 599.281 766.028 600.166C764.497 603.142 762.24 605.957 759.581 607.968C757.082 610.139 754.261 611.104 751.441 611.104Z"
                          stroke="#9C0139"
                        />
                        <g id="Group_14">
                          <path
                            id="Vector_34"
                            d="M732.985 588.423C741.367 593.812 761.595 593.812 769.977 588.423"
                            stroke="#9C0139"
                          />
                          <path
                            id="Vector_35"
                            d="M732.34 584.724C731.293 585.608 731.293 586.171 731.293 584.884V581.909C731.293 570.809 740.4 561.721 751.521 561.721C762.643 561.721 771.75 570.809 771.75 581.909V584.884C771.75 586.171 771.589 585.608 770.541 584.724H732.34Z"
                            stroke="#9C0139"
                          />
                          <path
                            id="Vector_36"
                            d="M751.36 561.962V576.198"
                            stroke="#9C0139"
                          />
                          <path
                            id="Vector_37"
                            d="M742.737 564.214V572.257"
                            stroke="#9C0139"
                          />
                          <path
                            id="Vector_38"
                            d="M759.984 564.214V572.257"
                            stroke="#9C0139"
                          />
                        </g>
                      </g>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-community-support")}
                    onClick={onClick}
                  >
                    <g id="COMUNITA">
                      <text
                        id="Supporto"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="439.253" y="521.72">
                          Community
                        </tspan>
                      </text>
                      <text
                        id="alla comunit&#195;&#160;"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="439.253" y="533.596">
                          support
                        </tspan>
                      </text>
                      <g id="Group_15">
                        <path
                          id="Vector_39"
                          d="M403.847 510.728C404.572 508.154 406.265 506.707 408.924 506.626C412.47 506.465 416.097 506.465 419.643 506.626C423.109 506.787 425.043 509.039 425.123 512.498C425.123 515.956 425.123 519.415 425.123 522.873C425.123 524.884 424.398 526.331 422.867 527.618C422.383 528.021 421.98 529.066 422.061 529.71C422.625 533.892 423.27 538.074 423.914 542.337"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_40"
                          d="M376.446 542.417C376.446 538.396 376.446 534.455 376.446 530.514C376.446 529.307 376.365 528.503 374.995 527.94C372.9 527.055 371.933 525.206 371.933 522.953C371.933 519.495 371.933 516.037 371.933 512.578C371.933 509.12 373.947 506.787 377.574 506.626C381.362 506.465 385.15 506.465 388.938 506.626C391.355 506.707 393.289 507.833 394.257 510.406"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_41"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M382.651 504.374C379.75 504.374 377.413 502.122 377.332 499.227C377.332 496.251 379.669 493.838 382.571 493.838C385.472 493.838 387.89 496.251 387.89 499.227C387.89 502.042 385.472 504.374 382.651 504.374Z"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_42"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M398.609 520.702C396.03 520.702 393.934 518.691 393.934 516.117C393.934 513.463 395.949 511.372 398.609 511.372C401.268 511.372 403.363 513.543 403.283 516.117C403.202 518.691 401.187 520.702 398.609 520.702Z"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_43"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M413.438 504.374C410.536 504.374 408.199 502.122 408.118 499.227C408.118 496.251 410.456 493.838 413.357 493.838C416.339 493.838 418.676 496.251 418.676 499.227C418.676 502.042 416.339 504.374 413.438 504.374Z"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_44"
                          d="M382.651 528.825V542.417"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_45"
                          d="M387.89 516.037V521.345"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_46"
                          d="M408.118 516.037V521.425"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_47"
                          d="M376.446 542.096V516.519"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_48"
                          d="M422.061 529.71V516.117"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_49"
                          d="M422.061 523.436H411.1"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_50"
                          d="M402.396 531.72V542.82H402.88C403.766 541.774 404.734 540.809 405.459 539.602C406.104 538.557 406.91 537.35 406.99 536.144C407.232 533.168 407.071 530.192 407.071 527.216C407.071 524.482 405.298 522.632 402.558 522.551C401.107 522.471 399.576 522.471 398.125 522.471C396.674 522.471 395.143 522.471 393.692 522.551C390.952 522.632 389.26 524.562 389.179 527.216C389.099 530.192 389.018 533.168 389.26 536.144C389.341 537.35 390.066 538.557 390.791 539.602C391.516 540.728 392.484 541.774 393.37 542.82H393.934V531.72"
                          stroke="#FF9900"
                        />
                      </g>
                    </g>
                  </Link>
                  <path
                    id="Vector_51"
                    d="M550.122 50.6707H295.773C271.354 50.6707 251.528 71.1802 251.528 96.596V369.735"
                    stroke="#9C0139"
                  />
                  <path
                    id="Vector_52"
                    d="M622.655 18.338H994.104C1016.11 18.338 1034 36.1934 1034 58.1507V384.534"
                    stroke="#9C0139"
                  />
                  <path
                    id="Vector_53"
                    d="M671.735 50.7511H983.466C994.346 50.7511 1003.13 59.5179 1003.13 70.3759V302.898"
                    stroke="#DA8C02"
                    strokeMiterlimit="10"
                  />
                  <path
                    id="Vector_54"
                    d="M687.209 83.5664H954.13C962.431 83.5664 969.201 90.9659 969.201 100.054V207.589"
                    stroke="#FF0123"
                  />
                  <path
                    id="Vector_55"
                    d="M554.151 117.266H328.171C309.877 117.266 295.048 132.387 295.048 151.047V448.556"
                    stroke="#F8008B"
                  />
                  <path
                    id="Vector_56"
                    d="M649.895 50.6707H582.036C569.867 50.6707 559.954 61.3678 559.954 74.6387V201.878"
                    stroke="#FF9900"
                  />
                  <path
                    id="Vector_57"
                    d="M649.331 83.8076H616.771C609.679 83.8076 605.488 86.7835 605.488 98.2045V198.742"
                    stroke="#FF6200"
                  />
                  <path
                    id="Vector_58"
                    d="M519.336 148.634H363.793C347.916 148.634 335.021 161.664 335.021 177.749V544.911"
                    stroke="#3B7834"
                  />
                  <path
                    id="Vector_59"
                    d="M585.099 302.898H430.765C413.035 302.898 398.609 318.26 398.609 337.161V501.318"
                    stroke="#FF9900"
                  />
                  <path
                    id="Vector_60"
                    d="M641.836 305.23H732.582C743.14 305.23 751.763 314.078 751.924 325.016L751.36 553.034"
                    stroke="#9C0139"
                  />
                  <path
                    id="Vector_61"
                    d="M658.518 272.254H757.485C772.475 272.254 784.645 285.203 784.645 301.209V444.776"
                    stroke="#019F3C"
                  />
                  <path
                    id="Vector_62"
                    d="M664.16 85.0142V131.663"
                    stroke="#00568F"
                  />
                  <path
                    id="Vector_63"
                    d="M519.336 154.425C523.43 154.425 526.75 151.112 526.75 147.025C526.75 142.939 523.43 139.626 519.336 139.626C515.241 139.626 511.921 142.939 511.921 147.025C511.921 151.112 515.241 154.425 519.336 154.425Z"
                    fill="#3B7834"
                  />
                  <path
                    id="Vector_64"
                    d="M585.099 310.298C589.194 310.298 592.513 306.985 592.513 302.898C592.513 298.811 589.194 295.498 585.099 295.498C581.004 295.498 577.684 298.811 577.684 302.898C577.684 306.985 581.004 310.298 585.099 310.298Z"
                    fill="#FF9900"
                  />
                  <path
                    id="Vector_65"
                    d="M643.609 312.63C647.703 312.63 651.023 309.317 651.023 305.23C651.023 301.144 647.703 297.831 643.609 297.831C639.514 297.831 636.194 301.144 636.194 305.23C636.194 309.317 639.514 312.63 643.609 312.63Z"
                    fill="#9C0139"
                  />
                  <path
                    id="Vector_66"
                    d="M658.518 279.654C662.613 279.654 665.933 276.341 665.933 272.254C665.933 268.168 662.613 264.855 658.518 264.855C654.423 264.855 651.104 268.168 651.104 272.254C651.104 276.341 654.423 279.654 658.518 279.654Z"
                    fill="#019F3C"
                  />
                  <path
                    id="Vector_67"
                    d="M554.151 124.666C558.246 124.666 561.566 121.353 561.566 117.266C561.566 113.18 558.246 109.867 554.151 109.867C550.056 109.867 546.737 113.18 546.737 117.266C546.737 121.353 550.056 124.666 554.151 124.666Z"
                    fill="#F8008B"
                  />
                  <path
                    id="Vector_68"
                    d="M550.122 58.4724C554.217 58.4724 557.536 55.1595 557.536 51.0729C557.536 46.9862 554.217 43.6733 550.122 43.6733C546.027 43.6733 542.707 46.9862 542.707 51.0729C542.707 55.1595 546.027 58.4724 550.122 58.4724Z"
                    fill="#9C0139"
                  />
                  <path
                    id="Vector_69"
                    d="M622.655 25.7375C626.749 25.7375 630.069 22.4246 630.069 18.338C630.069 14.2514 626.749 10.9385 622.655 10.9385C618.56 10.9385 615.24 14.2514 615.24 18.338C615.24 22.4246 618.56 25.7375 622.655 25.7375Z"
                    fill="#9C0139"
                  />
                  <path
                    id="Vector_70"
                    d="M649.895 58.0702C653.99 58.0702 657.309 54.7573 657.309 50.6706C657.309 46.584 653.99 43.2711 649.895 43.2711C645.8 43.2711 642.48 46.584 642.48 50.6706C642.48 54.7573 645.8 58.0702 649.895 58.0702Z"
                    fill="#FF9900"
                  />
                  <path
                    id="Vector_71"
                    d="M665.852 58.0702C669.947 58.0702 673.266 54.7573 673.266 50.6706C673.266 46.584 669.947 43.2711 665.852 43.2711C661.757 43.2711 658.438 46.584 658.438 50.6706C658.438 54.7573 661.757 58.0702 665.852 58.0702Z"
                    fill="#DA8C02"
                  />
                  <path
                    id="Vector_72"
                    d="M687.209 90.9659C691.304 90.9659 694.623 87.653 694.623 83.5664C694.623 79.4798 691.304 76.1669 687.209 76.1669C683.114 76.1669 679.794 79.4798 679.794 83.5664C679.794 87.653 683.114 90.9659 687.209 90.9659Z"
                    fill="#FF0123"
                  />
                  <path
                    id="Vector_73"
                    d="M664.724 91.2072C668.819 91.2072 672.138 87.8944 672.138 83.8077C672.138 79.7211 668.819 76.4082 664.724 76.4082C660.629 76.4082 657.309 79.7211 657.309 83.8077C657.309 87.8944 660.629 91.2072 664.724 91.2072Z"
                    fill="#00568F"
                  />
                  <path
                    id="Vector_74"
                    d="M649.895 91.2072C653.99 91.2072 657.309 87.8944 657.309 83.8077C657.309 79.7211 653.99 76.4082 649.895 76.4082C645.8 76.4082 642.48 79.7211 642.48 83.8077C642.48 87.8944 645.8 91.2072 649.895 91.2072Z"
                    fill="#FF6200"
                  />

                  <Link
                    to={menuItemUrl("item-service-quality")}
                    onClick={onClick}
                  >
                    <g id="QUALITA SERVIZIO">
                      <g id="Group_16">
                        <path
                          id="Vector_75"
                          d="M561.888 216.919L552.942 226.168C552.942 226.168 548.51 221.664 548.349 221.664"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_76"
                          d="M542.788 231.959L533.6 246.999L542.627 247.241L546.979 255.203L555.521 241.208"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_77"
                          d="M552.701 236.544L555.521 241.208"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_78"
                          d="M567.933 231.396L577.523 246.999L568.416 247.241L564.064 255.203L555.521 241.208"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_79"
                          d="M552.701 236.543L550.122 237.911L547.14 232.602L542.788 231.959"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_80"
                          d="M552.701 236.544L555.521 235.096L558.423 236.463"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_81"
                          d="M567.933 231.396L563.742 232.281L561.002 237.589L558.423 236.463"
                          stroke="#FF9900"
                        />
                        <path
                          id="Vector_82"
                          d="M567.933 231.396L569.625 231.074L568.658 225.123L572.768 220.618L568.416 216.436L569.141 210.404L563.178 209.519L560.196 204.211L554.796 207.026L549.316 204.533L546.576 209.921L540.692 211.128L541.659 217.08L537.549 221.503L541.821 225.766L541.176 231.718L542.788 231.959"
                          stroke="#FF9900"
                        />
                      </g>
                      <text
                        id="Qualit&#195;&#160; del servizio"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="419.561" y="223.927">
                          Quality of service
                        </tspan>
                      </text>
                      <text
                        id="e soddisfazione"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="434.361" y="235.803">
                          and customers
                        </tspan>
                      </text>
                      <text
                        id="del cliente"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="453.256" y="247.678">
                          satisfaction
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-innovation-transformation")}
                    onClick={onClick}
                  >
                    <g id="INNOVAZIONE">
                      <g id="Group_17">
                        <g id="Group_18">
                          <path
                            id="Vector_83"
                            d="M617.577 227.294C617.174 227.133 616.771 227.053 616.368 227.053H606.456C605.972 227.053 605.488 227.133 605.005 227.374"
                            stroke="#FF6200"
                          />
                          <path
                            id="Vector_84"
                            d="M605.086 227.375C603.796 227.938 602.829 229.224 602.829 230.753V237.911C602.829 239.922 604.441 241.611 606.536 241.611H616.449C618.464 241.611 620.156 240.002 620.156 237.911V230.753C620.156 229.144 619.109 227.777 617.658 227.294"
                            stroke="#FF6200"
                          />
                          <path
                            id="Vector_85"
                            d="M617.577 227.294C617.738 226.811 617.819 226.248 617.819 225.685V224.64C617.819 221.664 615.401 219.171 612.339 219.171H610.082C607.1 219.171 604.602 221.584 604.602 224.64V225.685C604.602 226.329 604.763 226.892 604.924 227.374"
                            stroke="#FF6200"
                          />
                        </g>
                        <path
                          id="Vector_86"
                          d="M611.372 232.281V236.865"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_87"
                          d="M592.836 214.345C594.438 214.345 595.737 213.049 595.737 211.449C595.737 209.85 594.438 208.554 592.836 208.554C591.233 208.554 589.934 209.85 589.934 211.449C589.934 213.049 591.233 214.345 592.836 214.345Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_88"
                          d="M605.811 214.345C607.413 214.345 608.712 213.049 608.712 211.449C608.712 209.85 607.413 208.554 605.811 208.554C604.209 208.554 602.91 209.85 602.91 211.449C602.91 213.049 604.209 214.345 605.811 214.345Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_89"
                          d="M605.811 200.752V208.634"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_90"
                          d="M585.502 232.844C587.104 232.844 588.403 231.547 588.403 229.948C588.403 228.349 587.104 227.053 585.502 227.053C583.899 227.053 582.6 228.349 582.6 229.948C582.6 231.547 583.899 232.844 585.502 232.844Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_91"
                          d="M596.301 229.948H588.403"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_92"
                          d="M616.771 214.345C618.374 214.345 619.673 213.049 619.673 211.449C619.673 209.85 618.374 208.554 616.771 208.554C615.169 208.554 613.87 209.85 613.87 211.449C613.87 213.049 615.169 214.345 616.771 214.345Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_93"
                          d="M616.771 200.752V208.634"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_94"
                          d="M592.916 214.506V220.377C592.916 220.377 592.433 223.675 595.817 223.675C599.202 223.675 598.396 223.675 598.396 223.675"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_95"
                          d="M629.666 214.345C631.268 214.345 632.568 213.049 632.568 211.449C632.568 209.85 631.268 208.554 629.666 208.554C628.064 208.554 626.765 209.85 626.765 211.449C626.765 213.049 628.064 214.345 629.666 214.345Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_96"
                          d="M629.585 214.506V220.377C629.585 220.377 630.069 223.675 626.684 223.675C623.299 223.675 624.105 223.675 624.105 223.675"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_97"
                          d="M592.836 251.262C594.438 251.262 595.737 249.966 595.737 248.367C595.737 246.768 594.438 245.471 592.836 245.471C591.233 245.471 589.934 246.768 589.934 248.367C589.934 249.966 591.233 251.262 592.836 251.262Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_98"
                          d="M605.811 251.262C607.413 251.262 608.712 249.966 608.712 248.367C608.712 246.768 607.413 245.471 605.811 245.471C604.209 245.471 602.91 246.768 602.91 248.367C602.91 249.966 604.209 251.262 605.811 251.262Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_99"
                          d="M605.811 259.144V251.262"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_100"
                          d="M616.771 251.262C618.374 251.262 619.673 249.966 619.673 248.367C619.673 246.768 618.374 245.471 616.771 245.471C615.169 245.471 613.87 246.768 613.87 248.367C613.87 249.966 615.169 251.262 616.771 251.262Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_101"
                          d="M616.771 259.144V251.262"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_102"
                          d="M592.916 245.391V239.519C592.916 239.519 592.433 236.222 595.817 236.222C599.202 236.222 598.396 236.222 598.396 236.222"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_103"
                          d="M629.666 251.262C631.268 251.262 632.568 249.966 632.568 248.367C632.568 246.768 631.268 245.471 629.666 245.471C628.064 245.471 626.765 246.768 626.765 248.367C626.765 249.966 628.064 251.262 629.666 251.262Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_104"
                          d="M629.585 245.391V239.519C629.585 239.519 630.069 236.222 626.684 236.222C623.299 236.222 624.105 236.222 624.105 236.222"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_105"
                          d="M598.559 229.948H598.235"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_106"
                          d="M637.081 232.844C638.683 232.844 639.982 231.547 639.982 229.948C639.982 228.349 638.683 227.053 637.081 227.053C635.478 227.053 634.179 228.349 634.179 229.948C634.179 231.547 635.478 232.844 637.081 232.844Z"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_107"
                          d="M626.362 229.948H634.179"
                          stroke="#FF6200"
                        />
                        <path
                          id="Vector_108"
                          d="M624.025 229.948H624.349"
                          stroke="#FF6200"
                        />
                      </g>
                      <text
                        id="Innovazione"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="648.813" y="223.928">
                          Innovation
                        </tspan>
                      </text>
                      <text
                        id="trasformazione digitale"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="648.813" y="235.803">
                          digital transformation and
                        </tspan>
                      </text>
                      <text
                        id="cybersecurity"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="648.813" y="247.678">
                          cybersecurity
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link to={menuItemUrl("item-integrity")} onClick={onClick}>
                    <g id="INTEGRITA">
                      <g id="Group_19">
                        <path
                          id="Vector_109"
                          d="M664.16 182.414C672.661 182.414 679.553 171.395 679.553 157.803C679.553 144.21 672.661 133.191 664.16 133.191C655.658 133.191 648.767 144.21 648.767 157.803C648.767 171.395 655.658 182.414 664.16 182.414Z"
                          stroke="#00568F"
                        />
                        <path
                          id="Vector_110"
                          d="M685.25 170.497C689.643 163.233 683.752 151.651 672.092 144.626C660.432 137.601 647.418 137.795 643.025 145.058C638.631 152.322 644.522 163.904 656.182 170.929C667.842 177.954 680.856 177.76 685.25 170.497Z"
                          stroke="#00568F"
                        />
                        <path
                          id="Vector_111"
                          d="M672.119 171.08C683.779 164.056 689.67 152.473 685.277 145.21C680.883 137.946 667.869 137.753 656.209 144.777C644.549 151.802 638.658 163.385 643.052 170.648C647.445 177.911 660.459 178.105 672.119 171.08Z"
                          stroke="#00568F"
                        />
                        <path
                          id="Vector_112"
                          d="M664.16 164.961C677.699 157.481 671.896 152.173 671.896 152.173C668.109 148.714 664.16 152.494 664.16 152.494C664.16 152.494 660.211 148.634 656.423 152.173C656.503 152.173 650.701 157.481 664.16 164.961Z"
                          stroke="#00568F"
                        />
                      </g>
                      <text
                        id="Integrit&#195;&#160; nella"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="697.314" y="159.984">
                          Integrity
                        </tspan>
                      </text>
                      <text
                        id="condotta aziendale"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="697.314" y="171.859">
                          in corporate conduct
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-financial-inclusion")}
                    onClick={onClick}
                  >
                    <g id="Group 78">
                      <g id="Group_20">
                        <g id="Group_21">
                          <path
                            id="Vector_113"
                            d="M986.85 227.375C987.817 229.385 988.543 231.477 988.865 233.729"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_114"
                            d="M953.324 255.605C955.097 256.892 957.112 257.938 959.207 258.662"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_115"
                            d="M984.835 250.538C986.125 248.769 987.173 246.758 987.898 244.667"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_116"
                            d="M948.247 224.077C946.957 225.846 945.91 227.857 945.184 229.948"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_117"
                            d="M970.168 259.627C972.424 259.225 974.52 258.581 976.534 257.616"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_118"
                            d="M973.875 215.954C976.051 216.677 977.985 217.723 979.758 219.01"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_119"
                            d="M956.628 217.08C958.562 216.114 960.739 215.391 962.995 215.069"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_120"
                            d="M946.232 247.241C945.265 245.23 944.539 243.139 944.217 240.887"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_121"
                            d="M948.247 224.077C949.214 224.962 950.584 225.605 952.035 225.605C955.097 225.605 957.515 223.112 957.515 220.136C957.515 219.01 957.192 217.964 956.628 217.08"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_122"
                            d="M949.214 237.348C948.972 238.072 948.488 238.715 947.924 239.359C946.877 240.404 945.587 240.887 944.217 240.967"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_123"
                            d="M949.214 237.348C949.939 235.418 949.536 233.085 947.924 231.557C947.118 230.753 946.151 230.27 945.104 230.029"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_124"
                            d="M946.232 247.241C947.118 246.678 948.166 246.356 949.294 246.356C952.357 246.356 954.775 248.849 954.775 251.825C954.775 253.273 954.211 254.64 953.324 255.605"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_125"
                            d="M966.541 254.64C967.267 254.881 967.911 255.364 968.556 255.927C969.604 256.973 970.087 258.259 970.168 259.627"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_126"
                            d="M966.541 254.64C964.607 253.916 962.27 254.318 960.738 255.927C959.933 256.731 959.449 257.697 959.207 258.742"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_127"
                            d="M984.835 250.538C983.868 249.654 982.498 249.01 981.048 249.01C977.985 249.01 975.568 251.503 975.568 254.479C975.568 255.605 975.89 256.651 976.454 257.536"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_128"
                            d="M988.865 233.729C987.495 233.729 986.206 234.292 985.158 235.337C984.594 235.9 984.191 236.624 983.869 237.348"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_129"
                            d="M983.869 237.348C983.143 239.278 983.546 241.611 985.158 243.139C985.964 243.943 986.931 244.426 987.979 244.667"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_130"
                            d="M979.758 219.01C978.791 219.975 978.308 221.342 978.308 222.79C978.308 225.846 980.806 228.259 983.788 228.259C984.916 228.259 985.964 227.938 986.85 227.375"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_131"
                            d="M973.875 215.954C973.633 216.999 973.15 217.964 972.344 218.769C970.813 220.377 968.475 220.779 966.541 220.056"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_132"
                            d="M962.915 215.069C962.995 216.436 963.479 217.723 964.526 218.769C965.091 219.332 965.816 219.734 966.541 220.055"
                            stroke="#FF0123"
                          />
                        </g>
                        <g id="Group_22">
                          <path
                            id="Vector_133"
                            d="M956.064 234.774H966.541"
                            stroke="#FF0123"
                          />
                          <path
                            id="Vector_134"
                            d="M956.145 239.841H966.541"
                            stroke="#FF0123"
                          />
                        </g>
                        <path
                          id="Vector_135"
                          d="M972.424 227.777C971.78 227.616 971.135 227.535 970.41 227.535C965.01 227.535 960.658 231.879 960.658 237.267C960.658 242.656 965.01 246.999 970.41 246.999C971.054 246.999 971.78 246.919 972.424 246.758"
                          stroke="#FF0123"
                        />
                      </g>
                      <text
                        id="Inclusione finanziaria"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="820.873" y="233.062">
                          Financial inclusion
                        </tspan>
                      </text>
                      <text
                        id="e sostegno al"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="837.002" y="245.851">
                          and supporting
                        </tspan>
                      </text>
                      <text
                        id="tessuto produttivo"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="859.916" y="258.726">
                          production
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-green-transition")}
                    onClick={onClick}
                  >
                    <g id="Group 79">
                      <g id="Group_23">
                        <g id="Group_24">
                          <path
                            id="Vector_136"
                            d="M982.337 338.528C982.337 340.459 983.869 341.987 985.803 341.987C987.737 341.987 989.268 340.459 989.268 338.528C989.268 336.598 987.737 335.07 985.803 335.07C983.869 335.07 982.337 336.598 982.337 338.528Z"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_137"
                            d="M989.913 328.957C992.492 328.957 994.587 326.866 994.587 324.292C994.587 321.719 992.492 319.627 989.913 319.627C987.334 319.627 985.239 321.719 985.239 324.292C985.239 326.866 987.334 328.957 989.913 328.957Z"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_138"
                            d="M1002 327.429C1002.24 326.464 1002.4 325.338 1002.4 324.292C1002.4 317.858 997.166 312.63 990.719 312.63C984.271 312.63 979.033 317.858 979.033 324.292C979.033 327.429 980.242 330.244 982.257 332.335"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_139"
                            d="M982.257 332.335C979.355 333.542 977.34 336.437 977.34 339.735C977.34 344.158 980.967 347.778 985.4 347.778C989.832 347.778 993.459 344.158 993.459 339.735"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_140"
                            d="M998.697 344.078C997.005 345.043 996.441 347.215 997.408 348.823C998.375 350.512 1000.55 351.075 1002.16 350.11C1003.86 349.145 1004.42 346.973 1003.45 345.365C1002.49 343.676 1000.31 343.113 998.697 344.078Z"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_141"
                            d="M1013.04 340.459C1015.3 339.172 1016.02 336.276 1014.74 334.105C1013.45 331.853 1010.54 331.129 1008.37 332.416C1006.11 333.703 1005.39 336.598 1006.68 338.77C1007.97 341.022 1010.87 341.746 1013.04 340.459Z"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_142"
                            d="M1002 327.429C1002.73 326.705 1003.45 326.142 1004.34 325.579C1009.9 322.362 1017.07 324.212 1020.38 329.762C1023.68 335.311 1021.75 342.469 1016.19 345.767C1013.45 347.376 1010.38 347.697 1007.56 347.054"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                          <path
                            id="Vector_143"
                            d="M995.554 341.183C991.686 343.435 990.396 348.341 992.653 352.121C994.91 355.982 999.826 357.269 1003.61 355.016C1006.51 353.327 1007.97 350.191 1007.56 347.054"
                            stroke="#DA8C02"
                            strokeMiterlimit="10"
                          />
                        </g>
                        <path
                          id="Vector_144"
                          d="M996.279 337.563L1006.68 319.627"
                          stroke="#DA8C02"
                          strokeMiterlimit="10"
                        />
                        <path
                          id="Vector_145"
                          d="M1006.68 319.627L1003.94 311.102"
                          stroke="#DA8C02"
                          strokeMiterlimit="10"
                        />
                        <path
                          id="Vector_146"
                          d="M1015.38 317.375L1006.68 319.627"
                          stroke="#DA8C02"
                          strokeMiterlimit="10"
                        />
                      </g>
                      <text
                        id="Transizione verso"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="886.188" y="329.891">
                          Transition to
                        </tspan>
                      </text>
                      <text
                        id="un&#226;&#128;&#153;economia sostenibile"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="822.212" y="342.679">
                          a sustainable, green and
                        </tspan>
                      </text>
                      <text
                        id="green e circular"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="862.000" y="354.555">
                          circular economy
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link
                    to={menuItemUrl("item-health-safety")}
                    onClick={onClick}
                  >
                    <g id="Group 81">
                      <g id="Group_25">
                        <g id="Group_26">
                          <path
                            id="Vector_147"
                            d="M767.72 490.459C767.72 500.031 775.457 507.752 785.048 507.752C785.451 507.752 785.854 507.752 786.257 507.671"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_148"
                            d="M774.49 484.186C776.827 484.427 779.326 485.955 781.099 487.403C782.872 488.851 784 491.586 785.37 496.974"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_149"
                            d="M773.12 461.505V461.586V461.667"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_150"
                            d="M780.857 461.344C780.776 459.333 779.084 457.725 776.988 457.725C774.893 457.725 773.281 459.333 773.12 461.344"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_151"
                            d="M773.12 461.507C773.12 461.507 773.12 461.426 773.12 461.344"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_152"
                            d="M780.857 461.587C780.857 461.506 780.857 461.425 780.857 461.344"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_153"
                            d="M788.513 457.081C788.513 457.001 788.513 456.921 788.513 456.84C788.513 454.749 786.821 452.979 784.645 452.979C782.469 452.979 780.776 454.669 780.776 456.84C780.776 456.921 780.776 457.001 780.776 457.081"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_154"
                            d="M795.686 457.081C795.363 455.392 793.913 454.105 792.14 454.105C790.367 454.105 788.916 455.392 788.594 457.081"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_155"
                            d="M795.686 457.081C795.686 457.323 795.766 457.483 795.766 457.725"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_156"
                            d="M788.513 457.725C788.513 457.483 788.513 457.323 788.594 457.081"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_157"
                            d="M774.168 476.867C774.087 475.982 773.765 475.178 773.12 474.615"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_158"
                            d="M785.37 483.301C785.37 480.969 783.516 479.119 781.179 479.119C778.842 479.119 776.989 480.969 776.989 483.301C776.989 484.025 777.069 484.508 777.391 485.071"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_159"
                            d="M773.12 461.667V461.505"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_160"
                            d="M773.12 474.535V461.666"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_161"
                            d="M773.202 461.344H773.12V461.507"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_162"
                            d="M780.857 457.081V461.344"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_163"
                            d="M780.857 474.535V473.65"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_164"
                            d="M780.857 461.587V461.344"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_165"
                            d="M780.857 473.65V461.585"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_166"
                            d="M795.766 457.725V469.468"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_167"
                            d="M795.766 457.725V457.081H795.686"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_168"
                            d="M788.596 457.081H788.513"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_169"
                            d="M788.513 457.725V457.081"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_170"
                            d="M788.513 474.535V473.489"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_171"
                            d="M788.513 457.725V473.489"
                            stroke="#019F3C"
                          />
                          <path
                            id="Vector_172"
                            d="M795.766 469.468V474.535"
                            stroke="#019F3C"
                          />
                        </g>
                        <path
                          id="Vector_173"
                          d="M786.337 507.672C795.363 507.028 802.456 499.548 802.456 490.46C802.456 489.173 802.294 487.886 802.053 486.68L801.811 485.393V469.468C801.811 467.859 800.441 466.492 798.829 466.492C797.217 466.492 795.847 467.859 795.847 469.468"
                          stroke="#019F3C"
                        />
                        <path
                          id="Vector_174"
                          d="M768.043 493.838L766.995 482.497V476.867C767.156 475.017 768.688 473.489 770.541 473.489C771.508 473.489 772.395 473.891 773.12 474.535"
                          stroke="#019F3C"
                        />
                        <path
                          id="Vector_175"
                          d="M774.49 484.186V482.578L774.248 476.787V476.867"
                          stroke="#019F3C"
                        />
                        <path
                          id="Vector_176"
                          d="M785.37 497.055C790.528 490.54 793.671 486.84 793.671 483.543C793.671 480.245 791.898 479.36 789.883 479.2C787.385 478.958 785.37 480.889 785.37 483.382"
                          stroke="#019F3C"
                        />
                      </g>
                      <text
                        id="Salute, sicurezza"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="813.533" y="475.133">
                          Health, safety
                        </tspan>
                      </text>
                      <text
                        id="e benessere delle"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="813.533" y="487.009">
                          and well-being of
                        </tspan>
                      </text>
                      <text
                        id="persone del Gruppo"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="813.533" y="499.797">
                          the Group’s people
                        </tspan>
                      </text>
                    </g>
                  </Link>

                  <Link to={menuItemUrl("item-group-value")} onClick={onClick}>
                    <g id="Group 80">
                      <text
                        id="Valore e solidit&#195;&#160;"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="911.11" y="413.017">
                          Group value and
                        </tspan>
                      </text>
                      <text
                        id="del Gruppo"
                        fill="#323231"
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Open Sans"
                        fontSize="12"
                        fontWeight="600"
                        letterSpacing="0em"
                      >
                        <tspan x="962.224" y="425.806">
                          solidity
                        </tspan>
                      </text>
                      <g id="Group_27">
                        <path
                          id="Vector_177"
                          d="M1048.34 397.564C1048.34 398.368 1048.99 399.011 1049.79 399.011C1050.84 399.011 1051.65 398.207 1051.65 397.161C1051.65 395.875 1050.6 394.829 1049.39 394.829C1047.78 394.829 1046.49 396.116 1046.49 397.725C1046.49 399.735 1048.1 401.344 1050.12 401.344C1052.61 401.344 1054.63 399.333 1054.63 396.84C1054.63 393.783 1052.13 391.21 1048.99 391.21H1019.17C1016.11 391.21 1013.53 393.703 1013.53 396.84C1013.53 399.333 1015.54 401.344 1018.04 401.344C1020.05 401.344 1021.67 399.735 1021.67 397.725C1021.67 396.116 1020.38 394.829 1018.76 394.829C1017.48 394.829 1016.51 395.875 1016.51 397.161C1016.51 398.207 1017.31 399.011 1018.36 399.011C1019.17 399.011 1019.81 398.368 1019.81 397.564"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_178"
                          d="M1046.49 398.127V420.808H1021.59V398.127"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_179"
                          d="M1050.36 425.875H1018.36L1021.59 420.808H1046.49L1050.36 425.875Z"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_180"
                          d="M1054.06 425.875H1014.41V430.861H1054.06V425.875Z"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_181"
                          d="M1027.95 420.808V402.55"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_182"
                          d="M1034.24 420.808V402.55"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_183"
                          d="M1040.61 420.808V402.55"
                          stroke="#9C0139"
                        />
                        <path
                          id="Vector_184"
                          d="M1024.73 398.127H1043.35"
                          stroke="#9C0139"
                        />
                      </g>
                    </g>
                  </Link>
                </g>
              </g>
            </g>
            <g id="CTA SMALL VAR">
              <rect
                id="Rectangle 30"
                x="934.5"
                y="664.5"
                width="142"
                height="40"
                stroke="#2B8804"
                strokeWidth="3"
              />
              <text
                id="scarica la dcnf 2021"
                fill="#2B8804"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Open Sans"
                fontSize="12"
                fontWeight="bold"
                letterSpacing="0em"
              >
                <tspan x="991.338" y="687.97">
                  2021
                </tspan>
              </text>
            </g>
            <g id="CTA SMALL VAR_2" opacity="0.5">
              <rect
                id="Rectangle 30_2"
                x="769.5"
                y="664.5"
                width="142"
                height="40"
                stroke="#2B8804"
                strokeWidth="3"
              />
              <text
                id="scarica la dcnf 2021_2"
                fill="#2B8804"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Open Sans"
                fontSize="12"
                fontWeight="bold"
                letterSpacing="0em"
              >
                <tspan x="806.082" y="687.97">
                  2020 + 2021
                </tspan>
              </text>
            </g>
          </g>
        </svg>
      );
  }
};

MaterialityMatrix2021Icon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(MaterialityMatrix2021Icon);
