import React from "react";
import PropTypes from "prop-types";

const IndustriaInnovazione = ({
  width = "54",
  height = "50",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox="0 0 54 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M54 0H0V50H54V0Z" fill="#EC6411"/>
        <path d="M40.1376 33.0082L34.3745 36.0877V29.9262L40.1376 26.8454V33.0082ZM21.1002 36.0877V30.3932L26.4239 33.2404L21.1002 36.0877ZM20.225 36.0877L14.4631 33.0082V26.8454L20.225 29.9262V36.0877ZM26.4239 19.0469L21.1002 21.893V16.2009L26.4239 19.0469ZM33.4993 21.893L28.1756 19.0469L33.4993 16.2009V21.893ZM28.1756 26.1425L33.9363 23.0641L39.6994 26.1425L33.9363 29.2183L28.1756 26.1425ZM26.8621 25.4407L21.5384 22.5959L26.8621 19.7487V25.4407ZM33.0611 22.5959L27.7374 25.4407V19.7487L33.0611 22.5959ZM26.8621 32.5375L21.5384 29.6914L26.8621 26.8454V32.5375ZM40.7943 25.7953L34.3745 22.3649V15.4992C34.3734 15.426 34.3524 15.3546 34.3139 15.2927C34.2753 15.2308 34.2207 15.1808 34.156 15.1483L27.5164 11.603C27.4488 11.5674 27.3736 11.5488 27.2973 11.5488C27.2211 11.5488 27.1459 11.5674 27.0782 11.603L20.4387 15.1483C20.374 15.1808 20.3194 15.2308 20.2808 15.2927C20.2423 15.3546 20.2213 15.426 20.2202 15.4992V22.3612L13.8064 25.7953C13.7418 25.828 13.6874 25.878 13.6489 25.9399C13.6103 26.0017 13.5893 26.073 13.5879 26.1461V33.2404C13.5893 33.3135 13.6103 33.3849 13.6489 33.4467C13.6874 33.5085 13.7418 33.5585 13.8064 33.5913L20.4459 37.1366C20.5139 37.1719 20.5892 37.1903 20.6656 37.1904C20.7416 37.1901 20.8165 37.1717 20.8841 37.1366L27.3028 33.7062L33.7202 37.1366C33.7881 37.1716 33.8631 37.1899 33.9393 37.1899C34.0155 37.1899 34.0905 37.1716 34.1584 37.1366L40.7979 33.5913C40.8625 33.5585 40.917 33.5085 40.9555 33.4467C40.994 33.3849 41.0151 33.3135 41.0164 33.2404V26.1425C41.0151 26.0694 40.994 25.998 40.9555 25.9362C40.917 25.8744 40.8625 25.8243 40.7979 25.7916" fill="white"/>
    </svg>
  );
};

IndustriaInnovazione.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};

export default React.memo(IndustriaInnovazione);
