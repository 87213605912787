import React from "react";
import PropTypes from "prop-types";

const CircleGroup = ({
  width = "55",
  height = "50",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox="0 0 55 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.0897 0.0805511H0V49.9988H54.0897V0.0805511Z"
        fill="#E2071F"
      />
      <path
        d="M7.10123 24.8023L5.3877 31.6506"
        stroke="white"
        strokeWidth="1.31"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M41.751 16.8432C41.751 16.0792 42.4264 15.4604 43.2588 15.4604C44.0913 15.4604 44.7643 16.0792 44.7643 16.8432C44.7643 17.6073 44.0901 18.2249 43.2588 18.2249C42.4276 18.2249 41.751 17.6 41.751 16.8432Z"
        fill="white"
      />
      <path
        d="M45.0184 18.7521C46.0407 18.8364 46.8439 19.6248 46.8439 20.5829V24.4142C46.8306 24.5603 46.7633 24.6962 46.6552 24.7951C46.5471 24.8941 46.4061 24.9489 46.2598 24.9489C46.1134 24.9489 45.9724 24.8941 45.8643 24.7951C45.7562 24.6962 45.6889 24.5603 45.6756 24.4142V21.1187C45.6809 21.0798 45.6772 21.0401 45.6648 21.0028C45.6525 20.9655 45.6318 20.9315 45.6043 20.9034C45.5768 20.8754 45.5432 20.854 45.5062 20.841C45.4692 20.828 45.4298 20.8236 45.3908 20.8283C45.2204 20.8283 45.1851 20.9942 45.1791 21.0992V30.961C45.1791 31.3846 44.8054 31.73 44.3405 31.73C43.8756 31.73 43.4996 31.3846 43.4996 30.961V25.2868C43.5011 25.2552 43.4956 25.2236 43.4837 25.1943C43.4717 25.165 43.4535 25.1386 43.4303 25.1171C43.4071 25.0956 43.3795 25.0794 43.3494 25.0697C43.3194 25.0601 43.2875 25.0571 43.2562 25.061C43.2249 25.0575 43.1933 25.0608 43.1634 25.0706C43.1335 25.0805 43.1061 25.0966 43.083 25.1181C43.0599 25.1395 43.0417 25.1657 43.0295 25.1948C43.0174 25.2239 43.0117 25.2553 43.0128 25.2868V30.9696C43.0128 31.3931 42.6392 31.7385 42.1743 31.7385C41.7094 31.7385 41.3345 31.3931 41.3345 30.9696V21.1078C41.3285 21.0028 41.2919 20.8368 41.124 20.8368C41.085 20.8319 41.0454 20.8361 41.0083 20.849C40.9712 20.862 40.9375 20.8833 40.91 20.9114C40.8824 20.9395 40.8617 20.9736 40.8495 21.0111C40.8372 21.0485 40.8337 21.0883 40.8392 21.1273V24.4227C40.8259 24.5688 40.7586 24.7047 40.6505 24.8037C40.5424 24.9026 40.4014 24.9575 40.2551 24.9575C40.1087 24.9575 39.9677 24.9026 39.8596 24.8037C39.7515 24.7047 39.6842 24.5688 39.6709 24.4227V20.5866C39.6709 19.6248 40.4729 18.8364 41.4964 18.7558C41.5331 18.7503 41.5701 18.7474 41.6072 18.7473H44.9077C44.9484 18.7471 44.9891 18.75 45.0294 18.7558"
        fill="white"
      />
      <path
        d="M32.7887 17.5621C32.7887 16.8396 32.151 16.2525 31.3624 16.2525C30.5738 16.2525 29.9385 16.8432 29.9385 17.5621C29.9385 18.281 30.5774 18.8717 31.366 18.8717C32.1546 18.8717 32.7924 18.2859 32.7924 17.5621"
        fill="white"
      />
      <path
        d="M28.4861 20.5268L27.2995 24.0663C27.2995 24.0663 27.0889 24.7156 27.6889 24.878C28.2889 25.0403 28.4678 24.4068 28.4678 24.4068L29.4244 21.4507C29.4244 21.4507 29.4767 21.2237 29.6678 21.2725C29.8588 21.3213 29.7895 21.5654 29.7895 21.5654L28.1124 27.1054H29.6605V31.0721C29.6788 31.2571 29.765 31.4287 29.9024 31.5536C30.0399 31.6784 30.2186 31.7475 30.404 31.7475C30.5895 31.7475 30.7682 31.6784 30.9057 31.5536C31.0431 31.4287 31.1293 31.2571 31.1476 31.0721V27.1054H31.5699V31.0721C31.5882 31.2571 31.6745 31.4287 31.8119 31.5536C31.9493 31.6784 32.1281 31.7475 32.3135 31.7475C32.4989 31.7475 32.6777 31.6784 32.8151 31.5536C32.9526 31.4287 33.0388 31.2571 33.0571 31.0721V27.1054H34.6051L32.9439 21.5667C32.9439 21.5667 32.8733 21.3226 33.0595 21.2737C33.2457 21.2249 33.3029 21.4519 33.3029 21.4519L34.2546 24.4105C34.2546 24.4105 34.4372 25.0439 35.0335 24.8816C35.6299 24.7193 35.423 24.07 35.423 24.07L34.2364 20.5304C34.2364 20.5304 33.8129 19.5064 32.8733 19.5064H29.8491C28.9108 19.5064 28.4861 20.5304 28.4861 20.5304"
        fill="white"
      />
      <path
        d="M24.2403 17.5621C24.2403 16.8396 23.6026 16.2525 22.814 16.2525C22.0254 16.2525 21.3877 16.8396 21.3877 17.5621C21.3877 18.2847 22.0254 18.8717 22.814 18.8717C23.6026 18.8717 24.2403 18.2859 24.2403 17.5621Z"
        fill="white"
      />
      <path
        d="M19.9384 20.5268L18.7531 24.0663C18.7531 24.0663 18.5413 24.7156 19.1413 24.878C19.7413 25.0403 19.9202 24.4068 19.9202 24.4068L20.8767 21.4507C20.8767 21.4507 20.9291 21.2237 21.1201 21.2725C21.3112 21.3213 21.2418 21.5654 21.2418 21.5654L19.577 27.1042H21.1153V31.0709C21.1336 31.2559 21.2198 31.4275 21.3572 31.5524C21.4947 31.6772 21.6734 31.7463 21.8588 31.7463C22.0443 31.7463 22.223 31.6772 22.3605 31.5524C22.4979 31.4275 22.5841 31.2559 22.6024 31.0709V27.1042H23.0247V31.0709C23.043 31.2559 23.1293 31.4275 23.2667 31.5524C23.4041 31.6772 23.5829 31.7463 23.7683 31.7463C23.9537 31.7463 24.1325 31.6772 24.2699 31.5524C24.4074 31.4275 24.4936 31.2559 24.5119 31.0709V27.1042H26.0612L24.3975 21.5667C24.3975 21.5667 24.3269 21.3226 24.5119 21.2737C24.6969 21.2249 24.7553 21.4519 24.7553 21.4519L25.707 24.4105C25.707 24.4105 25.8883 25.0439 26.4859 24.8816C27.0834 24.7193 26.8753 24.07 26.8753 24.07L25.6888 20.5304C25.6888 20.5304 25.2652 19.5064 24.3269 19.5064H21.2978C20.3595 19.5064 19.9335 20.5304 19.9335 20.5304"
        fill="white"
      />
      <path
        d="M37.5813 24.8865C38.2385 24.8865 38.7691 24.3983 38.7691 23.7966C38.7691 23.1948 38.2385 22.7079 37.5813 22.7079C36.9242 22.7079 36.3887 23.19 36.3887 23.8002C36.3887 24.4105 36.9193 24.8902 37.5765 24.8902"
        fill="white"
      />
      <path
        d="M34.7328 28.2698C34.7076 28.3145 34.6921 28.364 34.6872 28.4152C34.6823 28.4663 34.6882 28.5179 34.7045 28.5666C34.7208 28.6152 34.7472 28.66 34.7818 28.6978C34.8165 28.7356 34.8587 28.7657 34.9057 28.7861C34.9948 28.825 35.0947 28.8311 35.1878 28.8031C35.281 28.7752 35.3612 28.7151 35.4144 28.6335L36.0606 26.7454C36.0606 26.7454 36.1726 26.6233 36.1726 26.7454V31.1905C36.1726 31.3524 36.2367 31.5076 36.3508 31.622C36.4649 31.7365 36.6197 31.8008 36.7811 31.8008C36.9424 31.8008 37.0972 31.7365 37.2113 31.622C37.3254 31.5076 37.3896 31.3524 37.3896 31.1905V29.4403C37.3896 29.4403 37.3543 29.1962 37.5721 29.1962C37.79 29.1962 37.7571 29.4403 37.7571 29.4403V31.1942C37.7571 31.356 37.8212 31.5112 37.9353 31.6257C38.0494 31.7401 38.2042 31.8044 38.3656 31.8044C38.527 31.8044 38.6818 31.7401 38.7959 31.6257C38.91 31.5112 38.9741 31.356 38.9741 31.1942V26.749C38.9741 26.6184 39.0495 26.7124 39.0495 26.7124L39.5558 28.0049C39.5558 28.0049 39.7749 28.5835 39.843 28.6079C39.9513 28.6516 40.0719 28.6528 40.181 28.6115C40.2901 28.5701 40.3797 28.4891 40.4321 28.3845C40.462 28.3205 40.4733 28.2493 40.4646 28.1792C40.456 28.109 40.4279 28.0427 40.3834 27.9879C40.3688 27.9451 39.6264 26.2791 39.5388 26.0436C39.271 25.3259 38.7842 25.332 38.3145 25.3198C37.8447 25.3076 37.5636 25.3198 37.5636 25.3198C37.5636 25.3198 37.2642 25.3088 36.6435 25.3259C36.0229 25.343 35.8294 25.6347 35.1466 27.2458C35.0493 27.4777 34.7195 28.2283 34.7146 28.2735L34.7328 28.2698Z"
        fill="white"
      />
      <path
        d="M11.496 16.8432C11.496 16.0792 10.8193 15.4604 9.98688 15.4604C9.15445 15.4604 8.48145 16.0731 8.48145 16.8432C8.48145 17.6134 9.15566 18.2249 9.98688 18.2249C10.8181 18.2249 11.496 17.6061 11.496 16.8432Z"
        fill="white"
      />
      <path
        d="M8.22687 18.7521C7.20459 18.8364 6.40137 19.6248 6.40137 20.5829V24.4142C6.413 24.5617 6.47965 24.6994 6.58804 24.7998C6.69642 24.9003 6.83857 24.9561 6.98614 24.9561C7.13371 24.9561 7.27586 24.9003 7.38424 24.7998C7.49262 24.6994 7.55927 24.5617 7.57091 24.4142V21.1187C7.56561 21.0798 7.56929 21.0401 7.58166 21.0028C7.59402 20.9655 7.61474 20.9315 7.64224 20.9034C7.66973 20.8754 7.70326 20.854 7.74026 20.841C7.77726 20.828 7.81675 20.8236 7.85569 20.8283C8.02607 20.8283 8.06136 20.9942 8.06866 21.0992V30.961C8.06866 31.3846 8.44228 31.73 8.90718 31.73C9.37207 31.73 9.74569 31.3846 9.74569 30.961V25.2868C9.74441 25.2552 9.74999 25.2237 9.76203 25.1945C9.77407 25.1653 9.79229 25.1391 9.81543 25.1176C9.83857 25.0961 9.86608 25.0799 9.89607 25.0702C9.92605 25.0604 9.95779 25.0573 9.98909 25.061C10.0204 25.0575 10.052 25.0608 10.0819 25.0706C10.1118 25.0805 10.1392 25.0966 10.1623 25.1181C10.1854 25.1395 10.2036 25.1657 10.2157 25.1948C10.2278 25.2239 10.2336 25.2553 10.2325 25.2868V30.9696C10.2325 31.3931 10.6073 31.7385 11.071 31.7385C11.5347 31.7385 11.9107 31.3931 11.9107 30.9696V21.1078C11.9107 21.0028 11.9533 20.8368 12.1225 20.8368C12.1614 20.8318 12.201 20.836 12.2381 20.849C12.2752 20.8619 12.3087 20.8833 12.3362 20.9114C12.3637 20.9395 12.3843 20.9737 12.3964 21.0111C12.4085 21.0486 12.4118 21.0883 12.4061 21.1273V24.4227C12.4177 24.5702 12.4843 24.708 12.5927 24.8084C12.7011 24.9088 12.8433 24.9646 12.9908 24.9646C13.1384 24.9646 13.2805 24.9088 13.3889 24.8084C13.4973 24.708 13.564 24.5702 13.5756 24.4227V20.5866C13.5756 19.6248 12.7736 18.8364 11.7501 18.7558C11.7134 18.7502 11.6764 18.7474 11.6393 18.7473H8.34249C8.30177 18.7471 8.26109 18.75 8.22079 18.7558"
        fill="white"
      />
      <path
        d="M14.2883 24.3153C14.2465 24.2576 14.1916 24.2106 14.1281 24.1784C14.0646 24.1461 13.9944 24.1295 13.9232 24.1298C13.8138 24.1251 13.7069 24.1641 13.626 24.2382C13.545 24.3123 13.4966 24.4155 13.4912 24.5252C13.4973 24.585 13.5112 24.6436 13.5326 24.6998L14.7253 26.6868C14.7682 26.768 14.7935 26.8574 14.7995 26.9492V31.221C14.8128 31.3672 14.8801 31.503 14.9882 31.602C15.0963 31.7009 15.2373 31.7558 15.3837 31.7558C15.53 31.7558 15.671 31.7009 15.7791 31.602C15.8872 31.503 15.9545 31.3672 15.9678 31.221V29.5367C15.9678 29.5367 15.935 29.2926 16.1455 29.2926C16.356 29.2926 16.322 29.5367 16.322 29.5367V31.221C16.3336 31.3689 16.4004 31.5069 16.509 31.6075C16.6176 31.7082 16.7601 31.7641 16.9079 31.7641C17.0558 31.7641 17.1983 31.7082 17.3069 31.6075C17.4155 31.5069 17.4823 31.3689 17.4939 31.221V26.9492C17.4998 26.8576 17.5247 26.7682 17.567 26.6868L18.6732 24.856C18.7295 24.752 18.7747 24.6423 18.8083 24.5289C18.8061 24.4742 18.7932 24.4205 18.7703 24.3708C18.7474 24.3212 18.7149 24.2765 18.6748 24.2395C18.6347 24.2024 18.5876 24.1736 18.5364 24.1548C18.4852 24.136 18.4308 24.1275 18.3763 24.1298C18.305 24.1287 18.2345 24.145 18.1709 24.1773C18.1072 24.2096 18.0524 24.257 18.0112 24.3153C17.9771 24.3421 17.4221 25.2478 17.2396 25.4138C17.1928 25.4639 17.1363 25.5039 17.0735 25.5312C17.0107 25.5585 16.9429 25.5725 16.8745 25.5724H15.4311C15.3627 25.5724 15.295 25.5583 15.2322 25.531C15.1694 25.5037 15.1129 25.4638 15.066 25.4138C14.881 25.2465 14.3358 24.3409 14.2932 24.3153H14.2883Z"
        fill="white"
      />
      <path
        d="M16.1505 24.8865C16.8076 24.8865 17.337 24.3983 17.337 23.7966C17.337 23.1948 16.8076 22.7079 16.1505 22.7079C15.4933 22.7079 14.9688 23.19 14.9688 23.8002C14.9688 24.4105 15.4994 24.8902 16.1565 24.8902"
        fill="white"
      />
    </svg>
  );
};

CircleGroup.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};

export default React.memo(CircleGroup);
