import React from "react";
import PropTypes from "prop-types";

/**
 * Sample:
 * customClasses = {
 *    "uppercase": ["h1", "h2"],
 *    "reverse lowercase": ["h3", "p5"]
 * };
 *
 * @param {*} param0
 * @returns
 */
const TextSection = ({
  classes,
  customClasses,
  icon,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p1,
  p2,
  p3,
  p4,
  p5,
}) => {
  const getClasses = (el) => {
    const keys = Object.keys(customClasses || {}).filter((key) =>
      customClasses[key].includes(el)
    );
    return keys.join(" ");
  };
  return (
    <div className={classes}>
      {icon && <div>{icon}</div>}
      {h1 && (
        <h1
          className={`font line-height-62 light text-size60 text-green ${getClasses(
            "h1"
          )}`}
        >
          {h1}
        </h1>
      )}
      {h2 && (
        <h2
          className={`font line-height-62 light text-size60 text-gray ${getClasses(
            "h2"
          )}`}
        >
          {h2}
        </h2>
      )}
      {h3 && (
        <h3
          className={`font light  text-xl lg:text-size40 lg:line-height-h1-40 text-green ${getClasses(
            "h3"
          )}`}
        >
          {h3}
        </h3>
      )}
      {h4 && (
        <h4
          className={`font light line-height-h1-40 text-size40 text-gray ${getClasses(
            "h4"
          )}`}
        >
          {h4}
        </h4>
      )}
      {h5 && (
        <h5
          className={`font light line-height-42 text-size32 text-green ${getClasses(
            "h5"
          )}`}
        >
          {h5}
        </h5>
      )}

      {h6 && (
        <h6
          className={`font light line-height-42 text-size32 text-gray ${getClasses(
            "h6"
          )}`}
        >
          {h6}
        </h6>
      )}
      {p1 && (
        <p
          className={`font light lg:line-height-30 lg:text-size22 text-green lg:mt-2 ${getClasses(
            "p1"
          )}`}
        >
          {p1}
        </p>
      )}
      {p2 && (
        <p
          className={`font light line-height-30 text-size22 text-gray mt-2 ${getClasses(
            "p2"
          )}`}
        >
          {p2}
        </p>
      )}
      {p3 && (
        <p
          className={`font normal line-height-28 text-green mt-2 ${getClasses(
            "p3"
          )}`}
        >
          {p3}
        </p>
      )}
      {p4 && (
        <p
          className={`font text-base normal line-height-28 text-gray mt-2 ${getClasses(
            "p4"
          )}`}
        >
          {p4}
        </p>
      )}
      {p5 && (
        <p
          className={`font text-base normal line-height-28 text-white mt-2 mb-10 ${getClasses(
            "p5"
          )}`}
        >
          {p5}
        </p>
      )}
    </div>
  );
};

TextSection.propTypes = {
  classes: PropTypes.string,
  customClasses: PropTypes.object,
  icon: PropTypes.element,
  h1: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  h2: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  h3: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  h4: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  h5: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  h6: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  p1: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  p2: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  p3: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  p4: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  p5: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default React.memo(TextSection);
