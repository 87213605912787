import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import Tooltip from "../../components/Tooltip";
import TextSection from "../../components/TextSection";
import ShowMore from "../../components/ShowMore";
import { ShowMoreIcon } from "../../assets/icons";
import i18next from "i18next";

import {
  SatisfactionIcon,
  ButtonTooltipIcon,
  ServiceQualityPointsGraphIcon,
} from "../../assets/icons";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";

const ServiceQuality = () => {
  const { t, ready } = useTranslation();
  const { lang } = useParams();
  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["service-quality"].areas}
            description={areas["service-quality"].description}
          />
          <TextSection
            customClasses={{ "mb-[20px] font line-height-h1-40": "h3" }}
            h3={t("ServiceQuality.TextSection1.title")}
            p4={t("ServiceQuality.TextSection1.paragraph")}
          />

          <TextSection
            customClasses={{
              "flex items-center justify-center": ["h5", "p4"],
              "font bold mt-2 font-black": "p4",
            }}
            classes="pt-8 sm:pt-16"
            h5={
              <>
                <div>{t("ServiceQuality.TextSection2.title")}</div>
                <Tooltip
                  classes="ml-2"
                  tooltipLink={""}
                  icon={<ButtonTooltipIcon width="19px" height="19px" />}
                >
                  <TextSection
                    p5={t(
                      "ServiceQuality.TextSection2.Tooltip1.TextSection1.text1"
                    )}
                  />
                </Tooltip>
              </>
            }
            p4={t("ServiceQuality.TextSection2.paragraph")}
          />
          <TextSection
            customClasses={{ "flex justify-center text-opacity-70": "p2" }}
            classes="pt-2 sm:pt-4 md:pt-8 lg:pt-16 mb-0.5"
            p2={t("ServiceQuality.TextSection3.paragraph")}
          />

          <div className="mt-8 xs:mt-20 lg:mt-32 mb-6 lg:mb-12 flex">
            <ServiceQualityPointsGraphIcon classes="mx-auto" lang={lang} />
          </div>

          {t("ServiceQuality.TextSection5.text1") && (
            <div className={"flex justify-end mt-[65px] mb-[121px]"}>
              <ShowMore
                iconPosition={"self-end pb-[7px]"}
                url={t("ServiceQuality.TextSection5.url")}
                title={t("ServiceQuality.TextSection5.text1")}
                width={432}
                height={121}
                target="_blank"
                icon={
                  <a
                    href={t("ServiceQuality.TextSection5.url")}
                    target="_blank"
                  >
                    <ShowMoreIcon
                      width="21px"
                      height="15px"
                      classes="stroke-green hover:stroke-gray"
                    />
                  </a>
                }
              />
            </div>
          )}
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

ServiceQuality.propTypes = {};

export default React.memo(ServiceQuality);
