import React from "react";
import PropTypes from "prop-types";

const IntegrityIcon = ({
  width = "33",
  height = "34",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 33 34`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_358_583)">
        <path
          d="M16.5 32.9178C22.4226 32.9178 27.2239 25.6938 27.2239 16.7826C27.2239 7.87136 22.4226 0.647377 16.5 0.647377C10.5774 0.647377 5.77612 7.87136 5.77612 16.7826C5.77612 25.6938 10.5774 32.9178 16.5 32.9178Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.2145 25.1219C34.2755 20.3612 30.1703 12.7683 22.0454 8.16273C13.9204 3.55712 4.85244 3.68281 1.79143 8.44347C-1.26957 13.2041 2.83557 20.797 10.9605 25.4026C19.0855 30.0082 28.1535 29.8825 31.2145 25.1219Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.0407 25.408C30.1657 20.8024 34.2708 13.2096 31.2098 8.44891C28.1488 3.68825 19.0808 3.56255 10.9558 8.16816C2.8309 12.7738 -1.27424 20.3666 1.78677 25.1273C4.84777 29.888 13.9158 30.0137 22.0407 25.408Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5001 21.4506C25.903 16.5684 21.868 13.0842 21.868 13.0842C19.2261 10.7953 16.5001 13.321 16.5001 13.321C16.5001 13.321 13.7741 10.8066 11.1322 13.0842C11.1322 13.0842 7.09719 16.5684 16.5001 21.4506Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

IntegrityIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(IntegrityIcon);
