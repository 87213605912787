import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import TextSection from "../../components/TextSection";
import InfoBox from "../../components/InfoBox";
import { ButtonTooltipIcon, CircleIcon } from "../../assets/icons";
import Tooltip from "../../components/Tooltip";
import BarChart from "../../components/BarCharts/BarChart";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";

const IntegrityPage = () => {
  const { t, ready } = useTranslation();

  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["integrity"].areas}
            description={areas["integrity"].description}
          />
          <TextSection
            customClasses={{ "mb-[12px] font line-height-h1-40": "h3" }}
            h3={t("IntegrityPage.TextSection1.title")}
            p4={t("IntegrityPage.TextSection1.paragraph")}
          />
          <TextSection
            classes="pt-10"
            h5={t("IntegrityPage.TextSection1.subCaption")}
            p4={t("IntegrityPage.TextSection1.subParagraph")}
          />

          <BarChart
            classes="mt-12 hidden md:block"
            right={({ classes }) => (
              <div
                className={`d-flex align-end ${classes || ""}`}
                style={{ width: 285 }}
              >
                <div className="ml-6">
                  <div className="pb-3">
                    <table style={{ border: "0" }} cellPadding="10">
                      <tbody>
                        <tr>
                          <td>
                            <CircleIcon size={47} />
                          </td>
                          <td>
                            <p
                              className="text bold"
                              style={{ lineHeight: "20px" }}
                            >
                              {t("IntegrityPage.BarChart1.text1")}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <CircleIcon size={47} color="#323232" />
                          </td>
                          <td>
                            <p
                              className="text bold"
                              style={{ lineHeight: "20px" }}
                            >
                              {t("IntegrityPage.BarChart1.text2")}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <Tooltip
                  classes="ml-2 mb-2.5"
                  icon={<ButtonTooltipIcon width="19px" height="19px" />}
                >
                  <TextSection
                    p5={t(
                      "IntegrityPage.BarChart1.Tooltip1.TextSection1.text1"
                    )}
                  />
                  <TextSection
                    p5={t(
                      "IntegrityPage.BarChart1.Tooltip1.TextSection1.text2"
                    )}
                  />
                </Tooltip>
              </div>
            )}
            options={{
              width: "100%",
              height: "450px",
              columns: {
                height: "288px",
                justifyContent: "center",
              },
              labels: {
                marginTop: 12,
                justifyContent: "center",
              },
            }}
            data={[
              {
                topLabel: t("IntegrityPage.BarChart1.Values.val1"),
                label: "2020*",
                height: 26,
                color: "#29851B",
              },
              {
                topLabel: t("IntegrityPage.BarChart1.Values.val2"),
                label: "",
                height: 125,
                color: "#323232",
                classes: "mr-5",
              },
              {
                topLabel: t("IntegrityPage.BarChart1.Values.val3"),
                label: "2021",
                height: 35,
                color: "#29851B",
              },
              {
                topLabel: t("IntegrityPage.BarChart1.Values.val4"),
                label: "",
                height: 249,
                color: "#323232",
              },
            ]}
          />

          <BarChart
            classes="mt-12 mobile-show"
            options={{
              width: "100%",
              height: "350px",
              columns: {
                height: "288px",
                justifyContent: "center",
              },
              labels: {
                marginTop: 12,
                justifyContent: "center",
              },
            }}
            data={[
              {
                topLabel: "63,5%",
                label: "2020*",
                height: 26,
                color: "#29851B",
              },
              {
                topLabel: "356.453",
                label: "",
                height: 125,
                color: "#323232",
              },
            ]}
          />
          <BarChart
            classes="mt-12 mobile-show"
            options={{
              width: "100%",
              height: "350px",
              columns: {
                height: "288px",
                justifyContent: "center",
              },
              labels: {
                marginTop: 12,
                justifyContent: "center",
              },
            }}
            data={[
              {
                topLabel: "76,9%",
                label: "2021",
                height: 35,
                color: "#29851B",
              },
              {
                topLabel: "698.573",
                label: "",
                height: 249,
                color: "#323232",
              },
            ]}
          />
          <BarChart
            classes="mt-12 mobile-show"
            right={({ classes }) => (
              <div
                className={`d-flex align-end pl-6 pb-3 w-max ${classes || ""}`}
              >
                <table style={{ border: "0" }} cellPadding="10">
                  <tbody>
                    <tr>
                      <td>
                        <CircleIcon size={47} />
                      </td>
                      <td>
                        <p
                          className="text bold"
                          style={{ lineHeight: "20px", width: 120 }}
                        >
                          {t("IntegrityPage.BarChart1.text1")}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CircleIcon size={47} color="#323232" />
                      </td>
                      <td>
                        <p className="text bold" style={{ lineHeight: "20px" }}>
                          {t("IntegrityPage.BarChart1.text2")}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Tooltip
                  classes="ml-2 mb-2.5"
                  icon={<ButtonTooltipIcon width="19px" height="19px" />}
                >
                  <TextSection
                    p5={t(
                      "IntegrityPage.BarChart1.Tooltip1.TextSection1.text1"
                    )}
                  />
                  <TextSection
                    p5={t(
                      "IntegrityPage.BarChart1.Tooltip1.TextSection1.text2"
                    )}
                  />
                </Tooltip>
              </div>
            )}
            options={{
              width: "0%",
              height: "250px",
              columns: {},
              labels: {},
            }}
            data={[]}
          />

          <TextSection
            classes="pt-16 mb-20"
            h5={t("IntegrityPage.TextSection2.caption")}
            p4={t("IntegrityPage.TextSection2.paragraph")}
          />

          <BarChart
            classes="mt-2.5 mb-1"
            left={({ classes }) => (
              <div
                className={`d-flex align-end ${classes || ""}`}
                style={{ width: 64 }}
              >
                <Tooltip
                  classes="mr-2 mb-2.5"
                  icon={<ButtonTooltipIcon width="19px" height="19px" />}
                >
                  <TextSection
                    p5={t(
                      "IntegrityPage.BarChart2.Tooltip1.TextSection1.text1"
                    )}
                  />
                </Tooltip>
              </div>
            )}
            right={({ classes }) => (
              <div
                className={`d-flex align-end ${classes || ""}`}
                style={{ width: 215 }}
              >
                <p className="text bold text-size22 text-black pl-10">
                  {t("IntegrityPage.BarChart2.text1")}
                </p>
              </div>
            )}
            options={{
              width: "100%",
              height: "300px",
              columns: {
                height: "212px",
                justifyContent: "center",
              },
              labels: {
                justifyContent: "center",
              },
            }}
            data={[
              {
                topLabel: t("IntegrityPage.BarChart2.Values.val1"),
                label: "2020*",
                height: 120,
                color: "#29851B",
              },
              {
                topLabel: t("IntegrityPage.BarChart2.Values.val2"),
                label: "2021",
                height: 148,
                color: "#2B7021",
              },
            ]}
          />

          <TextSection
            classes="pt-16 mt-12"
            h5={t("IntegrityPage.TextSection3.caption")}
          />
          <InfoBox classes="my-8 height200-infoBox mb-32">
            <TextSection
              customClasses={{ "text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              h1={t("IntegrityPage.InfoBox1.TextSection1.text1")}
              p1={t("IntegrityPage.InfoBox1.TextSection1.text2")}
            />
            <TextSection
              customClasses={{ "text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              h1={t("IntegrityPage.InfoBox1.TextSection2.text1")}
              p1={t("IntegrityPage.InfoBox1.TextSection2.text2")}
            />
            <TextSection
              customClasses={{ "text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1" }}
              h1={t("IntegrityPage.InfoBox1.TextSection3.text1")}
              p1={t("IntegrityPage.InfoBox1.TextSection3.text2")}
            />
          </InfoBox>
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

IntegrityPage.propTypes = {};

export default React.memo(IntegrityPage);
