import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TextSection from "../../components/TextSection";
import LottieFilePlayer from "../../components/LottieFilePlayer";
import InclusioneFinanziariaLottieFile from "../../assets/lotties/inclusione_finanziaria.json";
import InclusioneFinanziariaLottieFileEN from "../../assets/lotties/inclusione_finanziaria_EN.json";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import InfoBox from "../../components/InfoBox";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";

const FinancialInclusion = () => {
  const { t, ready } = useTranslation();
  const currentLang = i18next.language;

  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["financial-inclusion"].areas}
            description={areas["financial-inclusion"].description}
          />
          <TextSection
            customClasses={{ "font line-height-h1-40": "h3" }}
            h3={t("FinancialInclusion.TextSection1.title")}
          />

          <InfoBox classes="my-20" height="251px" size={3} sizes={[1, 2]}>
            <TextSection
              customClasses={{
                "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              h1={t("FinancialInclusion.InfoBox1.TextSection1.text1")}
              p1={t("FinancialInclusion.InfoBox1.TextSection1.text2")}
            />
            <TextSection
              customClasses={{
                "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              h1={t("FinancialInclusion.InfoBox1.TextSection2.text1")}
              p1={t("FinancialInclusion.InfoBox1.TextSection2.text2")}
            />
          </InfoBox>

          <TextSection
            classes="pt-8"
            h5={t("FinancialInclusion.TextSection2.caption")}
          />

          <InfoBox classes="my-16" height="251px" size={3} sizes={[1, 2]}>
            <TextSection
              customClasses={{
                "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              h1={t("FinancialInclusion.InfoBox2.TextSection1.text1")}
            />
            <TextSection
              customClasses={{
                "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              h1={t("FinancialInclusion.InfoBox2.TextSection2.text1")}
              p1={t("FinancialInclusion.InfoBox2.TextSection2.text2")}
            />
          </InfoBox>

          <LottieFilePlayer
            data={
              currentLang === "it"
                ? InclusioneFinanziariaLottieFile
                : InclusioneFinanziariaLottieFileEN
            }
            classes="w-full relative"
            lottieStyles={{
              transform: "translateX(-15%)",
            }}
            speed={0.9}
          >
            <div className="absolute w-full top-0 left-0 translate-x-[37%] sm:translate-x-[35%] translate-y-[20%]">
              <svg
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1012 763"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  width: "100%",
                  height: "100%",
                  contentVisibility: "visible",
                }}
                fill="none"
              >
                <g>
                  <path
                    stroke="#2B8804"
                    id="svg_1"
                    d="m253.235,1l343.765,0l0,534l-586,0"
                  />
                  <path id="svg_2" stroke="#2B8804" d="m12,531l-4,4l4,4" />
                </g>
              </svg>
            </div>

            <TextSection
              classes="absolute bottom-[13px] xs:bottom-[20px] sm:bottom-[25px] lg:bottom-[38px] xl:bottom-[50px] 2xl:bottom-[68px]"
              customClasses={{ "xs:text-xl sm:text-2xl xl:text-3xl": "h5" }}
              h5={t("FinancialInclusion.TextSection3.caption")}
            />
          </LottieFilePlayer>

          <TextSection
            classes="mt-[-13px] xs:mt-[-20px] sm:mt-[-25px] lg:mt-[-38px] xl:mt-[-50px] 2xl:mt-[-68px]"
            customClasses={{ "mt-5": "p4" }}
            p4={`${t("FinancialInclusion.TextSection3.paragraph")}\n\n${t(
              "FinancialInclusion.TextSection3.subParagraph"
            )}`}
          />

          <InfoBox
            classes="my-16 height180-infoBox"
            table
            sliceClasses="h-full"
          >
            <div className="pr-4">
              <TextSection
                customClasses={{ "font regular-imp line-height-30-imp": "p1" }}
                classes="pt-5"
                p1={t("FinancialInclusion.InfoBox3.TextSection1.text1")}
              />
              <TextSection
                customClasses={{ "font line-height-30-imp": "p1" }}
                classes="pt-5"
                p1={t(
                  "FinancialInclusion.InfoBox3.TextSection1.Tooltip1.TextSection1.text1"
                )}
              />
            </div>
            <div className="flex flex-row overflow-x-auto gap-3 lg:gap-0 h-full">
              <div className="w-max flex grid-cols-auto border-r-2 border-light">
                <TextSection
                  classes="min-w-[170px] md:min-w-[180px] lg:min-w-[290px] pr-4"
                  customClasses={{
                    "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                      "h1",
                  }}
                  h1={t("FinancialInclusion.InfoBox3.TextSection2.text1")}
                  p1={t("FinancialInclusion.InfoBox3.TextSection2.text2")}
                />
              </div>
              <div className="w-max flex grid-cols-auto">
                <TextSection
                  classes="px-4"
                  customClasses={{
                    "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                      "h1",
                  }}
                  h1={t("FinancialInclusion.InfoBox3.TextSection3.text1")}
                  p1={t("FinancialInclusion.InfoBox3.TextSection3.text2")}
                />
              </div>
            </div>
          </InfoBox>

          <InfoBox
            classes="my-16 height180-infoBox"
            table
            sliceClasses="h-full"
          >
            <div className="pr-4">
              <TextSection
                customClasses={{ "font regular-imp line-height-30-imp": "p1" }}
                classes="pt-5"
                p1={t("FinancialInclusion.InfoBox4.TextSection1.text1")}
              />
              <TextSection
                customClasses={{ "font line-height-30-imp": "p1" }}
                classes="pt-5"
                p1={t(
                  "FinancialInclusion.InfoBox4.TextSection1.Tooltip1.TextSection1.text1"
                )}
              />
            </div>
            <div className="flex flex-row overflow-x-auto gap-3 lg:gap-0 h-full">
              <div className="w-max flex grid-cols-auto border-r-2 border-light">
                <TextSection
                  classes="min-w-[170px] md:min-w-[180px] lg:min-w-[290px] pr-4"
                  customClasses={{
                    "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                      "h1",
                  }}
                  h1={t("FinancialInclusion.InfoBox4.TextSection2.text1")}
                  p1={t("FinancialInclusion.InfoBox4.TextSection2.text2")}
                />
              </div>
              <div className="w-max flex grid-cols-auto">
                <TextSection
                  classes="px-4"
                  customClasses={{
                    "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                      "h1",
                  }}
                  h1={t("FinancialInclusion.InfoBox4.TextSection3.text1")}
                  p1={t("FinancialInclusion.InfoBox4.TextSection3.text2")}
                />
              </div>
            </div>
          </InfoBox>

          <InfoBox
            classes="my-16 height180-infoBox"
            table
            sliceClasses="h-full"
          >
            <div className="pr-4">
              <TextSection
                customClasses={{ "font regular-imp line-height-30-imp": "p1" }}
                classes="pt-5"
                p1={t("FinancialInclusion.InfoBox5.TextSection1.text1")}
              />
              <TextSection
                customClasses={{ "font line-height-30-imp": "p1" }}
                classes="pt-5"
                p1={t(
                  "FinancialInclusion.InfoBox5.TextSection1.Tooltip1.TextSection1.text1"
                )}
              />
            </div>
            <div className="flex flex-row overflow-x-auto gap-3 lg:gap-0 h-full">
              <div className="w-max flex grid-cols-auto border-r-2 border-light">
                <TextSection
                  classes="min-w-[170px] md:min-w-[180px] lg:min-w-[290px] pr-4"
                  customClasses={{
                    "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                      "h1",
                  }}
                  h1={t("FinancialInclusion.InfoBox5.TextSection2.text1")}
                  p1={t("FinancialInclusion.InfoBox5.TextSection2.text2")}
                />
              </div>
              <div className="w-max flex grid-cols-auto">
                <TextSection
                  classes="px-4"
                  customClasses={{
                    "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                      "h1",
                  }}
                  h1={t("FinancialInclusion.InfoBox5.TextSection3.text1")}
                  p1={t("FinancialInclusion.InfoBox5.TextSection3.text2")}
                />
              </div>
            </div>
          </InfoBox>

          <TextSection
            classes="pt-16"
            h5={t("FinancialInclusion.TextSection4.text1")}
          />

          <InfoBox classes="mt-10 mb-20" height="426px" table>
            <div className="grid grid-cols-1 gap-3">
              <TextSection
                customClasses={{
                  "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                    "h1",
                }}
                h1={t("FinancialInclusion.InfoBox6.Column1.TextSection1.text1")}
                p1={t("FinancialInclusion.InfoBox6.Column1.TextSection1.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-3">
              <TextSection
                customClasses={{
                  "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                    "h1",
                }}
                h1={t("FinancialInclusion.InfoBox6.Column2.TextSection2.text1")}
                p1={t("FinancialInclusion.InfoBox6.Column2.TextSection2.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-3">
              <TextSection
                customClasses={{
                  "text-23px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                    "h1",
                }}
                h1={t("FinancialInclusion.InfoBox6.Column3.TextSection3.text1")}
                p1={t("FinancialInclusion.InfoBox6.Column3.TextSection3.text2")}
              />
            </div>
          </InfoBox>
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

FinancialInclusion.propTypes = {};

export default React.memo(FinancialInclusion);
