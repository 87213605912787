import React from "react";
import PropTypes from "prop-types";

const GroupTooltipIcon1 = ({
  width = "48",
  height = "48",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 48 48`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <g clip-path="url(#clip0_206_1350)">
    <path d="M31.6027 22.5623L34.1509 20.0644C38.9389 22.0715 42.8398 19.6492 42.8398 19.6492C48.6345 14.1502 46.9798 6.58116 46.9798 6.58116L40.1784 13.3197C40.1784 13.3197 37.9889 13.3197 36.2775 11.8977C34.8555 10.1801 34.8555 7.99681 34.8555 7.99681L41.6003 1.20169C41.6003 1.20169 34.0313 -0.453049 28.5323 5.34168C28.5323 5.34168 26.11 9.24259 28.117 14.0306L23.373 18.869" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    <path d="M19.1262 23.2103L2.1069 40.5756C2.1069 40.5756 -0.0826388 43.86 1.85523 46.2634L1.86781 46.2823C4.27757 48.2957 7.59963 46.0747 7.59963 46.0747L21.2465 32.6983" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    <path d="M24.1288 24.3744L26.4191 22.1534L46.1186 38.7574C46.1186 38.7574 49.5476 42.2682 46.2507 46.1313L46.2067 46.1691C42.3058 49.6107 38.7572 46.125 38.7572 46.125L22.1532 26.4255L24.1351 24.3744L8.68252 8.72671H4.32232L0.314453 2.49155L1.40293 1.40308" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    <path d="M1.40234 1.40305L2.49082 0.314575L8.72598 4.44828V8.80848" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_206_1350">
    <rect width="48" height="48" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
};

GroupTooltipIcon1.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(GroupTooltipIcon1);


