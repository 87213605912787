import React from "react";
import PropTypes from "prop-types";

const GroupTooltipIcon5 = ({
  width = "69",
  height = "69",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 69 69`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <g clip-path="url(#clip0_206_1421)">
    <path d="M44.6562 17.8903H24.6449C21.0807 17.8903 18.1914 20.7796 18.1914 24.3438V44.355C18.1914 47.9192 21.0807 50.8085 24.6449 50.8085H44.6562C48.2203 50.8085 51.1097 47.9192 51.1097 44.355V24.3438C51.1097 20.7796 48.2203 17.8903 44.6562 17.8903Z" 
      stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M38.9389 23.3074H30.3643C26.6304 23.3074 23.6035 26.3343 23.6035 30.0682V38.6427C23.6035 42.3766 26.6304 45.4035 30.3643 45.4035H38.9389C42.6728 45.4035 45.6997 42.3766 45.6997 38.6427V30.0682C45.6997 26.3343 42.6728 23.3074 38.9389 23.3074Z" 
      stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M26.2246 17.8903V8.89392" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M42.625 17.8903V8.89392" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M26.2246 0.30127H30.4787C32.6902 0.30127 34.4858 2.09692 34.4858 4.30834V17.8902" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M42.625 50.9591V59.9615" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M26.2246 50.9591V59.9615" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M42.6264 68.5541H38.3723C36.1609 68.5541 34.3652 66.7584 34.3652 64.547V50.9591" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.8909 42.5172H8.89453" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.8909 26.1093H8.89453" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M0.300781 42.5172V38.2631C0.300781 36.0516 2.09643 34.256 4.30786 34.256H17.8897" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M51.1094 26.1093H60.1057" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M51.1094 42.5172H60.1057" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M68.6985 26.1093V30.3634C68.6985 32.5748 66.9028 34.3705 64.6914 34.3705H51.1035" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_206_1421">
    <rect width="69" height="68.8554" fill="white"/>
    </clipPath>
    </defs>
</svg>
  );
};

GroupTooltipIcon5.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(GroupTooltipIcon5);

