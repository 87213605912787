import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";
import { useFirstRender } from "../helpers/CustomHooks";
import TextSection from "./TextSection";

const AreaSquares = ({ areas, description }) => {
  const { t, ready } = useTranslation();
  const [areaDescription, setAreaDescription] = useState(description);
  const isFirstRender = useFirstRender();
  return (
    !!ready && (
      <>
        {areas?.length && (
          <div className="flex flex-col xl:flex-row items-start justify-start m-0 pb-12 pt-16">
            <div className="h-11 xl:h-12 w-auto flex justify-start items-center">
              {areas.map((area, index) => {
                const AreaIcon = area.icon;
                return (
                  <div key={area.id} className="overflow-hidden">
                    <Animated
                      animationIn="slideInLeft"
                      animationInDelay={250 * index}
                    >
                      <div
                        className="w-11 h-11 xl:w-12 xl:h-12 flex justify-center items-center cursor-help decoration-white"
                        onMouseOver={() =>
                          setAreaDescription(`${t(`Generic.SDG.${area.id}`)}`)
                        }
                        onMouseLeave={() => setAreaDescription(description)}
                      >
                        <AreaIcon />
                      </div>
                    </Animated>
                  </div>
                );
              })}
            </div>
            <Animated
              animationIn="fadeInUp"
              animationInDelay={isFirstRender ? 250 * (areas.length + 2) : 0}
            >
              {t(areaDescription) && (
                <TextSection
                  p1={t(areaDescription)}
                  customClasses={{ "xl:ml-4 mb-0": "p1" }}
                />
              )}
            </Animated>
          </div>
        )}
      </>
    )
  );
};

AreaSquares.propTypes = {
  areas: PropTypes.array,
  description: PropTypes.string,
};

export default React.memo(AreaSquares);
