import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TextSection from "../../components/TextSection";
import Menu from "../../components/Menu";
import CardImage from "../../components/CardImage";
import ContainerWrapper from "../../components/ContainerWrapper";
import LottieFilePlayer from "../../components/LottieFilePlayer";
import SupportoAllaComunita from "../../assets/lotties/supporto_alla_comunita.json";
import SupportoAllaComunitaEN from "../../assets/lotties/supporto_alla_comunita_EN.json";
import Timeline from "../../components/Timeline";
import { ButtonTooltipIcon } from "../../assets/icons";
import Tooltip from "../../components/Tooltip";
import GallerieItalia from "../../assets/images/gallerieitalia.png";
import PTech from "../../assets/images/ptech.png";
import ProgrammaGiovani from "../../assets/images/programmagiovani.png";
import GenerationUniversities from "../../assets/images/generationuniversities.png";
import {
  TimelineIcon1,
  TimelineIcon2,
  TimelineIcon3,
  TimelineIcon4,
} from "../../assets/images";
import InfoCard from "../../components/InfoCard";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";

const TimelineItems = [
  {
    Icon: TimelineIcon1,
    title: "CommunitySupport.Timeline1.title",
    titleClasses: "positionTitle30",
    left: { title: "CommunitySupport.Timeline1.leftTitle", caption: "" },
    right: { title: "CommunitySupport.Timeline1.rightTitle", caption: "" },
    timelineTop: {
      sx: "CommunitySupport.Timeline1.timelineTopSX",
      dx: "CommunitySupport.Timeline1.timelineTopDX",
    },
    timelineBottom: { sx: "", dx: "" },
  },
  {
    Icon: TimelineIcon2,
    title: "CommunitySupport.Timeline2.title",
    titleClasses: "",
    left: { title: "CommunitySupport.Timeline2.leftTitle", caption: "" },
    right: { title: "CommunitySupport.Timeline2.rightTitle", caption: "" },
    timelineTop: { sx: "", dx: "" },
    timelineBottom: { sx: "", dx: "" },
  },
  {
    Icon: TimelineIcon3,
    title: "CommunitySupport.Timeline3.title",
    titleClasses: "positionTitle80",
    left: { title: "CommunitySupport.Timeline3.leftTitle", caption: "" },
    right: { title: "CommunitySupport.Timeline3.rightTitle", caption: "" },
    timelineTop: { sx: "", dx: "" },
    timelineBottom: { sx: "", dx: "" },
  },
  {
    Icon: TimelineIcon4,
    title: "CommunitySupport.Timeline4.title",
    titleClasses: "positionTitle30",
    left: { title: "CommunitySupport.Timeline4.leftTitle", caption: "" },
    right: { title: "CommunitySupport.Timeline4.rightTitle", caption: "" },
    timelineTop: { sx: "", dx: "" },
    timelineBottom: {
      sx: "CommunitySupport.Timeline4.timelineBottomSX",
      dx: "CommunitySupport.Timeline4.timelineBottomDX",
    },
  },
];

const CommunitySupport = () => {
  const { t, ready } = useTranslation();
  const currentLang = i18next.language;

  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["community-support"].areas}
            description={areas["community-support"].description}
          />
          <TextSection
            customClasses={{
              "mb-[37px] font line-height-h1-40": "h3",
              "mb-6": "h5",
            }}
            h3={t("CommunitySupport.TextSection1.title")}
            h5={t("CommunitySupport.TextSection1.caption")}
            p4={t("CommunitySupport.TextSection1.text3")}
          />

          <LottieFilePlayer
            data={
              currentLang === "it"
                ? SupportoAllaComunita
                : SupportoAllaComunitaEN
            }
            classes="w-full overflow-auto"
            speed={0.9}
          />

          <TextSection
            customClasses={{ "mb-8": "h5" }}
            classes="pt-16"
            h5={t("CommunitySupport.TextSection2.title")}
            p4={t("CommunitySupport.TextSection2.paragraph")}
          />

          <TextSection
            customClasses={{ "font-black": "p4" }}
            classes="pt-16 flex justify-center"
            p4={t("CommunitySupport.TextSection3.paragraph")}
          />
          <Timeline
            TimelineItems={TimelineItems}
            classes="pt-3.5"
            lineClasses="h-[785px] sm:h-[1170px] md:h-[1200px] xl:h-[1440px]"
          ></Timeline>
        </ContainerWrapper>

        <CardImage
          backgroundImage={`url(${GallerieItalia}`}
          classes="grid gap-[30px]"
        >
          <InfoCard
            title={t("CommunitySupport.TextSection4.title")}
            buttonTitle={t("CommunitySupport.CardButton.title")}
            buttonLink={t("CommunitySupport.CardButton.linkGallerie")}
            wrapperClass="bg-white my-14 py-16 p-6 lg:p-16 md:w-[534px] lg:w-[740px] mx-[15px] md:ml-[max(12%,64px)] md:mr-auto"
          >
            <div className="grid grid-cols-2 mt-[33px]">
              <TextSection
                customClasses={{
                  "flex items-center": "h4",
                  "font-normal": "p4",
                }}
                classes="pr-4"
                h4={
                  <>
                    <div>
                      {t("CommunitySupport.InfoBox1.TextSection1.text1")}
                    </div>
                    <Tooltip
                      classes="ml-2"
                      tooltipLink={""}
                      icon={<ButtonTooltipIcon width="19px" height="19px" />}
                    >
                      <TextSection
                        p5={t(
                          "CommunitySupport.InfoBox1.TextSection1.Tooltip1.TextSection1.text1"
                        )}
                        customClasses={{ "font-normal": "p5" }}
                      />
                    </Tooltip>
                  </>
                }
                p4={t("CommunitySupport.InfoBox1.TextSection1.text2")}
              />
              <TextSection
                customClasses={{
                  "flex items-center": "h4",
                  "font-normal": "p4",
                }}
                classes="px-4"
                h4={
                  <>
                    <div>
                      {t("CommunitySupport.InfoBox1.TextSection2.text1")}
                    </div>
                    <Tooltip
                      classes="ml-2"
                      tooltipLink={""}
                      icon={<ButtonTooltipIcon width="19px" height="19px" />}
                    >
                      <TextSection
                        p5={t(
                          "CommunitySupport.InfoBox1.TextSection2.Tooltip1.TextSection1.text1"
                        )}
                      />
                    </Tooltip>
                  </>
                }
                p4={t("CommunitySupport.InfoBox1.TextSection2.text2")}
              />
            </div>
            <div className="grid grid-cols-2 mt-6">
              <TextSection
                h4={t("CommunitySupport.InfoBox1.TextSection3.text1")}
                p4={t("CommunitySupport.InfoBox1.TextSection3.text2")}
                classes="pr-4"
                customClasses={{ "font-normal grid grid-cols-2": "p4" }}
              />
              <TextSection
                h4={t("CommunitySupport.InfoBox1.TextSection4.text1")}
                p4={t("CommunitySupport.InfoBox1.TextSection4.text2")}
                classes="px-4"
                customClasses={{ "font-normal grid grid-cols-2": "p4" }}
              />
            </div>
          </InfoCard>
        </CardImage>

        <CardImage
          backgroundImage={`url(${ProgrammaGiovani}`}
          classes="grid gap-[30px] mt-[100px]"
        >
          <InfoCard
            title={t("CommunitySupport.TextSection5.caption")}
            wrapperClass="bg-white my-14 py-16 p-6 lg:p-16 md:w-[534px] lg:w-[740px] mx-[15px] md:mr-[max(12%,64px)] md:ml-auto"
          >
            <TextSection
              customClasses={{ "mt-5 font-normal": "p4" }}
              p4={t("CommunitySupport.TextSection5.paragraph")}
            />
            <div className="grid grid-cols-3 mt-[71px]">
              <TextSection
                classes="pr-4"
                h4={t("CommunitySupport.InfoBox2.TextSection1.text1")}
                p4={t("CommunitySupport.InfoBox2.TextSection1.text2")}
              />
              <TextSection
                customClasses={{ "flex items-center": "h4" }}
                classes="px-4"
                h4={
                  <>
                    <div>
                      {t("CommunitySupport.InfoBox2.TextSection2.text1")}
                    </div>
                    <Tooltip
                      classes="ml-2"
                      tooltipLink={""}
                      icon={<ButtonTooltipIcon width="19px" height="19px" />}
                    >
                      <TextSection
                        p5={t(
                          "CommunitySupport.InfoBox2.TextSection2.Tooltip1.TextSection1.text1"
                        )}
                      />
                    </Tooltip>
                  </>
                }
                p4={t("CommunitySupport.InfoBox2.TextSection2.text2")}
              />
              <TextSection
                classes="pl-4"
                h4={t("CommunitySupport.InfoBox2.TextSection3.text1")}
                p4={t("CommunitySupport.InfoBox2.TextSection3.text2")}
              />
            </div>
          </InfoCard>
        </CardImage>

        <CardImage
          backgroundImage={`url(${GenerationUniversities}`}
          classes="grid gap-[30px] mt-[100px]"
        >
          <InfoCard
            title={t("CommunitySupport.TextSection6.caption")}
            wrapperClass="bg-white my-14 py-16 p-6 lg:p-16 md:w-[534px] lg:w-[740px] mx-[15px] md:ml-[max(12%,64px)] md:mr-auto"
          >
            <TextSection
              customClasses={{ "mt-5": "p4" }}
              p4={t("CommunitySupport.TextSection6.paragraph")}
            />
            <div className="grid grid-cols-3 mt-[71px]">
              <TextSection
                classes="pr-4"
                h4={t("CommunitySupport.InfoBox3.TextSection1.text1")}
                p4={t("CommunitySupport.InfoBox3.TextSection1.text2")}
              />
              <TextSection
                classes="px-4"
                h4={t("CommunitySupport.InfoBox3.TextSection2.text1")}
                p4={t("CommunitySupport.InfoBox3.TextSection2.text2")}
              />
              <TextSection
                classes="pl-4"
                h4={t("CommunitySupport.InfoBox3.TextSection3.text1")}
                p4={t("CommunitySupport.InfoBox3.TextSection3.text2")}
              />
            </div>
          </InfoCard>
        </CardImage>

        <CardImage
          backgroundImage={`url(${PTech})`}
          classes="grid gap-[30px] mt-[100px]"
        >
          <InfoCard
            title={t("CommunitySupport.TextSection7.caption")}
            buttonTitle={t("CommunitySupport.CardButton.title")}
            buttonLink={t("CommunitySupport.CardButton.linkPTech")}
            wrapperClass="bg-white my-14 py-16 p-6 lg:p-16 md:w-[534px] lg:w-[740px] mx-[15px] md:mr-[max(12%,64px)] md:ml-auto"
          >
            <TextSection
              customClasses={{ "mt-5": "p4" }}
              p4={t("CommunitySupport.TextSection7.paragraph")}
            />
            <div className="grid grid-cols-3 mt-[71px]">
              <TextSection
                classes="pr-4 pt-4"
                p4={t("CommunitySupport.InfoBox4.TextSection1.text2")}
              />
              <TextSection
                classes="px-4"
                h4={t("CommunitySupport.InfoBox4.TextSection2.text1")}
                p4={t("CommunitySupport.InfoBox4.TextSection2.text2")}
              />
              <TextSection
                classes="pl-4"
                h4={t("CommunitySupport.InfoBox4.TextSection3.text1")}
                p4={t("CommunitySupport.InfoBox4.TextSection3.text2")}
              />
            </div>
          </InfoCard>
        </CardImage>
        <Menu />
      </>
    )
  );
};

CommunitySupport.propTypes = {};

export default React.memo(CommunitySupport);
