import React from "react";
import PropTypes from "prop-types";

const MenuItem = ({ title, Icon, isActive, classes, iconProps, isMobile }) => {
  return (
    <>
      {isMobile ? (
        <div
          className={`${classes || ""} box-border h-[33px] p-2 bg-white  ${
            isActive ? "text-green" : "text-gray"
          } text-center flex content-center`}
        >
          <label className="text-xs">{title}</label>
          <div
            style={{
              width: 32,
              height: 32,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon
              width={iconProps && iconProps.width}
              height={iconProps && iconProps.height}
              classes={`scale-down-75 ${
                isActive ? "stroke-green" : "stroke-gray"
              }`}
            />
          </div>
        </div>
      ) : (
        <>
          <div
            className={`menu-icon w-14 h-14 ${
              isActive ? "bg-white" : "bg-green"
            } flex items-center justify-center hover:stroke-green`}
          >
            <Icon
              width={iconProps && iconProps.width}
              classes={`${
                isActive ? "stroke-green" : "stroke-white hover:stroke-green"
              }`}
              height={iconProps && iconProps.height}
            />
          </div>
        </>
      )}
    </>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string,
  isMobile: PropTypes.bool,
  classes: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
};

export default React.memo(MenuItem);
