import React from "react";

const Bar = ({
  animate,
  topLabel,
  topLabelStyle = {},
  height = "0",
  color = "#29851B",
  delay = "0",
  duration = "2s",
  classes = "",
  inner = "",
}) => {
  const barStyle = {
    height: animate ? height : 0,
    backgroundColor: color,
    transitionProperty: "height",
    transitionDelay: delay,
    transitionDuration: duration,
    transitionTimingFunction: "easy-out",
  };
  const labelStyle = {
    bottom: animate ? height : 0,
    transitionProperty: "bottom",
    transitionDelay: delay,
    transitionDuration: duration,
    transitionTimingFunction: "easy-out",
  };
  return (
    <div className={`element ${classes || ""}`}>
      {topLabel && (
        <p
          className="top-label text bold text-size22 text-black mt-2"
          style={{ ...labelStyle, ...(topLabelStyle || {}) }}
        >
          {topLabel}
        </p>
      )}
      <div className="bar" style={barStyle}>
        {inner || ""}
      </div>
    </div>
  );
};

export default React.memo(Bar);
