import React from "react";
import PropTypes from "prop-types";

const CommunitySupportIcon = ({
  width = "30",
  height = "28",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 30 28`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_363_756)">
        <path
          d="M18.1027 9.68305C18.5015 8.29164 19.3828 7.56811 20.7927 7.51245C22.685 7.44752 24.5866 7.42896 26.479 7.52172C28.3156 7.61449 29.3638 8.82038 29.3731 10.6478C29.3916 12.4844 29.3731 14.3118 29.3824 16.1485C29.3916 17.2059 28.9928 18.0037 28.1858 18.6808C27.9167 18.9127 27.6941 19.4322 27.7405 19.7847C28.0188 22.0202 28.3806 24.2372 28.7145 26.4727"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.53924 26.547C3.53924 24.432 3.52996 22.3356 3.53924 20.23C3.53924 19.6085 3.50213 19.1632 2.7786 18.8571C1.65619 18.3747 1.15528 17.41 1.17384 16.1949C1.19239 14.3582 1.17384 12.5215 1.17384 10.6941C1.18311 8.83893 2.24058 7.63304 4.15146 7.53101C6.15509 7.42897 8.17727 7.43825 10.1902 7.53101C11.4517 7.58666 12.4814 8.17106 13.0101 9.55319"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.81357 6.31585C5.26447 6.31585 4.02147 5.10069 4.00292 3.57013C3.98437 1.98392 5.23664 0.703827 6.80429 0.703827C8.36268 0.703827 9.6335 1.9932 9.61495 3.56086C9.59639 5.09141 8.3534 6.31585 6.80429 6.31585"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.292 14.9982C13.9284 14.9982 12.8338 13.9315 12.8153 12.5772C12.7967 11.1765 13.9006 10.0448 15.292 10.0448C16.6741 10.0448 17.7873 11.1858 17.778 12.5679C17.7594 13.9129 16.6649 14.9982 15.3013 14.9982"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.1581 6.31584C21.609 6.31584 20.366 5.10067 20.3475 3.57012C20.3289 1.98391 21.5812 0.703812 23.1488 0.703812C24.7072 0.703812 25.978 1.99319 25.9595 3.56084C25.9409 5.09139 24.6979 6.31584 23.1488 6.31584"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.83212 19.3023V26.5469"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.62433 12.5215V15.3507"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3568 12.5308V15.36"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.53922 26.38V12.7534"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.7404 19.7939V12.5401"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.7405 16.4639H21.9244"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.2956 20.8422V26.7603H17.5554C18.0192 26.1945 18.5479 25.6657 18.9282 25.0535C19.2807 24.4877 19.6796 23.8476 19.726 23.2168C19.8373 21.6399 19.7816 20.0537 19.7631 18.4675C19.7445 17.0204 18.8262 16.0279 17.3698 15.9722C16.5814 15.9351 15.7929 15.9258 15.0044 15.9258C14.216 15.9258 13.4275 15.9444 12.639 15.9722C11.1734 16.0372 10.2644 17.0204 10.2458 18.4675C10.218 20.0537 10.1623 21.6399 10.2829 23.2168C10.3293 23.8476 10.7189 24.4877 11.0807 25.0535C11.461 25.6657 11.9897 26.1945 12.4535 26.7603H12.7411V20.8422"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

CommunitySupportIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(CommunitySupportIcon);
