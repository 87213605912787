import React from "react";
import PropTypes from "prop-types";

const InvestmentIcon = ({
  width = "29",
  height = "33",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 29 33`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_358_634)">
        <path
          d="M14.556 32.0644C-3.74262 20.4401 1.60215 5.03772 1.60215 5.03772C8.09223 5.14304 13.1869 1.82559 14.556 0.838257C15.9251 1.82559 21.0197 5.14304 27.5098 5.03772C27.5098 5.03772 32.8546 20.4401 14.556 32.0775V32.0644Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.76413 16.4118C9.85628 16.4513 13.8451 20.4928 13.8451 20.4928L21.8623 12.2255"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

InvestmentIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(InvestmentIcon);
