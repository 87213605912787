import React from "react";
import PropTypes from "prop-types";

const HealthIcon = ({
  width = "25",
  height = "37",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 25 37`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_707_2016)">
        <path
          d="M1.26233 25.0624C1.26233 31.2824 6.39365 36.3354 12.7343 36.3354C13.0175 36.3354 13.2899 36.314 13.5623 36.2926"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.72906 20.9943C7.28698 21.1656 8.91027 22.1505 10.0978 23.1033C11.2853 24.0561 11.9934 25.8546 12.9303 29.3554"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.86841 6.19928C4.86841 6.19928 4.86841 6.24279 4.86841 6.25366C4.86841 6.26454 4.86841 6.29717 4.86841 6.30805"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.94526 6.12435C9.869 4.80757 8.76865 3.75842 7.40683 3.75842C6.04502 3.75842 4.94467 4.80757 4.86841 6.12435"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.86841 6.20046C4.86841 6.20046 4.86841 6.14608 4.86841 6.12433"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.9563 6.26551C9.9563 6.26551 9.94525 6.17863 9.94525 6.12433"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.033 3.33021C15.033 3.33021 15.0439 3.23386 15.0439 3.18033C15.0439 1.79931 13.9 0.675232 12.4946 0.675232C11.0892 0.675232 9.94525 1.79931 9.94525 3.18033C9.94525 3.23386 9.95615 3.27668 9.95615 3.33021"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.7612 3.33023C19.5542 2.23827 18.5955 1.41394 17.4189 1.41394C16.2423 1.41394 15.2835 2.23827 15.0765 3.33023"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.7612 3.3302C19.783 3.46937 19.8048 3.60854 19.8048 3.75842"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0438 3.75842C15.0438 3.60854 15.0656 3.46937 15.0873 3.3302"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.55476 16.1983C5.51119 15.6202 5.27151 15.0956 4.86841 14.7102"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9411 20.4055C12.9411 18.9067 11.71 17.697 10.1848 17.697C8.65955 17.697 7.42847 18.9067 7.42847 20.4055C7.42847 20.8766 7.48294 21.1656 7.70083 21.551"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.86841 6.31892V6.19928"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.86841 14.7102V6.31708"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.95742 6.12433H9.94525"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.86841 6.12433V6.20046"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.96701 3.3302H9.95612V6.12435"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0331 3.3302H15.0453"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.95612 14.7102V14.1321"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.95612 6.26551V6.12433"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.95612 14.1321V6.26355"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.8048 3.75842V11.3915"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.8048 3.75842V3.3302H19.7612"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.088 3.3302H15.0438"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0438 3.75842V3.3302"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0438 14.7102V14.025"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0438 3.75848V14.0251"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.8048 11.3915V14.7102"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5623 36.2819C19.5107 35.8644 24.1953 31.004 24.1953 25.0518C24.1953 24.206 24.0973 23.3817 23.923 22.5895L23.7595 21.7545V11.3915C23.7595 10.3209 22.8771 9.4538 21.7876 9.4538C20.6982 9.4538 19.8157 10.3209 19.8157 11.3915"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.48021 27.2571L0.804749 19.8702L0.826538 16.1982C0.913694 14.9885 1.93778 14.025 3.19065 14.025C3.84432 14.025 4.44352 14.282 4.86841 14.7102"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.72909 20.9944V19.9559L5.54388 16.209C5.54388 16.209 5.54388 16.2518 5.54388 16.2839"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9412 29.3554C16.3403 25.116 18.4539 22.7072 18.4539 20.5447C18.4539 18.3822 17.2555 17.8362 15.9481 17.7078C14.3139 17.5686 12.9412 18.8318 12.9412 20.4056"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

HealthIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(HealthIcon);
