import React from "react";
import PropTypes from "prop-types";

const CollapseIcon = ({
  width = "22",
  height = "13",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 22 13`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M1.00001 1.28429L11.2843 11.5686L21.5686 1.28429" />
    </svg>
  );
};

CollapseIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
  color: PropTypes.string,
};
export default React.memo(CollapseIcon);


