import React from "react";
import PropTypes from "prop-types";

const CloseIcon = ({
  width = "22",
  height = "22",  
  classes="stroke-white"
}) => {
  return (  
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 22 22`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >                  
     <path d="M1.84744 1.84741L20.1525 20.1525" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
     <path d="M20.1525 1.84741L1.84744 20.1525" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>  
    
  );
};

CloseIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,  
  color: PropTypes.string  
};
export default React.memo(CloseIcon);



