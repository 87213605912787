import React from "react";
import PropTypes from "prop-types";

const SaluteBenessere = ({
  width = "54",
  height = "50",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox="0 0 54 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
            <path d="M54 0H0V50H54V0Z" fill="#289438"/>
            <path d="M45.1366 24.1754C45.1366 22.7683 44.1612 21.6277 42.6848 21.6277C41.9472 21.6277 41.0563 22.3771 40.5722 22.8367C40.0893 22.3758 39.2564 21.6264 38.52 21.6264C37.0424 21.6264 36.0078 22.7671 36.0078 24.1742C36.0092 24.5129 36.0794 24.8478 36.2142 25.1578C36.349 25.4678 36.5455 25.7464 36.7913 25.9762L40.577 29.4873L44.3567 25.9798C44.6025 25.7501 44.799 25.4715 44.9338 25.1615C45.0686 24.8514 45.1388 24.5166 45.1402 24.1778" fill="white"/>
            <path d="M29.021 35.2197H28.9824C28.848 35.2162 28.7187 35.1674 28.6148 35.081C28.5109 34.9947 28.4385 34.8757 28.409 34.7429L26.4859 24.3734L23.573 29.3736C23.5049 29.4829 23.4062 29.5692 23.2894 29.6215C23.1726 29.6737 23.0432 29.6896 22.9175 29.667C22.7933 29.6489 22.6777 29.5922 22.5868 29.5045C22.4959 29.4169 22.4343 29.3027 22.4105 29.178L21.1743 21.1802L18.912 25.8845C18.8583 25.9893 18.7766 26.0767 18.6764 26.137C18.5761 26.1973 18.4612 26.2279 18.3446 26.2256H9.55268C9.47428 26.2319 9.39543 26.2218 9.3211 26.1957C9.24676 26.1697 9.17856 26.1284 9.12077 26.0743C9.06298 26.0203 9.01687 25.9547 8.98532 25.8818C8.95378 25.8088 8.9375 25.73 8.9375 25.6504C8.9375 25.5707 8.95378 25.4919 8.98532 25.419C9.01687 25.346 9.06298 25.2804 9.12077 25.2264C9.17856 25.1724 9.24676 25.131 9.3211 25.105C9.39543 25.079 9.47428 25.0688 9.55268 25.0752H17.9402L20.916 18.8868C20.9773 18.7691 21.0731 18.6735 21.1903 18.6133C21.3075 18.5531 21.4402 18.5312 21.5703 18.5506C21.6986 18.564 21.8193 18.6188 21.9147 18.7068C22.0101 18.7948 22.0751 18.9114 22.1002 19.0396L23.375 27.2904L26.2469 22.3587C26.3138 22.2502 26.411 22.1643 26.5262 22.1118C26.6414 22.0594 26.7694 22.0428 26.894 22.0641C27.0168 22.0798 27.132 22.1334 27.2237 22.2175C27.3155 22.3017 27.3795 22.4125 27.407 22.5348L29.1864 32.1377L35.6763 12.1788C35.7174 12.0636 35.7913 11.9634 35.8886 11.8907C35.9859 11.818 36.1023 11.7762 36.2231 11.7705C36.3438 11.7606 36.4647 11.7858 36.5716 11.8432C36.6785 11.9006 36.7671 11.9878 36.8267 12.0944L40.2407 18.6484C40.2754 18.7174 40.2955 18.793 40.2997 18.8704C40.3038 18.9478 40.2919 19.0252 40.2647 19.0976C40.2375 19.1701 40.1957 19.2359 40.1418 19.291C40.088 19.3461 40.0234 19.3891 39.9521 19.4173C39.8054 19.4848 39.6392 19.4947 39.4857 19.445C39.3322 19.3953 39.2024 19.2896 39.1216 19.1484L36.4018 13.927L29.6162 34.8101C29.5718 34.9329 29.4904 35.0384 29.3837 35.1119C29.2769 35.1853 29.1501 35.223 29.021 35.2197Z" fill="white"/>
    </svg>
  );
};

SaluteBenessere.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};

export default React.memo(SaluteBenessere);
