import React from "react";
import PropTypes from "prop-types";

const CardImage = ({ classes, backgroundImage, children, id, onClick, overridingStyle }) => {
  // TODO NICETOHAVE Integrare suspense React
  return (
    <div
      id={id}
      className={`relative ${classes || ""}`}
      onClick={onClick}
      style={{
        backgroundImage,
        /* Background image is centered vertically and horizontally at all times */
        backgroundPosition: "center center",
        /* Background image doesn't tile */
        backgroundRepeat: "no-repeat",
        /* Background image is fixed in the viewport so that it doesn't move when the content's height is greater than the image's height */
        //backgroundAttachment: "fixed",
        /* This is what makes the background image rescale based on the container's size */
        backgroundSize: "cover",
        ...overridingStyle
      }}
    >
      {children}
    </div>
  );
};

CardImage.propTypes = {
  classes: PropTypes.string,
  backgroundImage: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default React.memo(CardImage);
