import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CopyIcon, ArrowBottomIcon } from "../../assets/icons";
import TextSection from "../TextSection";

const DownloadSection = ({ classes }) => {
  const { t, ready } = useTranslation();

  return (
    !!ready && (
      <a
        href={t("Generic.DownloadSection1.link")}
        download
        className="hover:stroke-2"
      >
        <footer
          className={`px-[12%] w-full bg-litegray h-[134px] md:h-[134px] ${
            classes || ""
          }`}
        >
          <div className="flex items-center h-full">
            <div className="flex-none pr-8 h-full flex items-center">
              <CopyIcon
                classes="stroke-green hover:stroke-1 mt-[10px]"
                width="36px"
                height="43px"
              />
            </div>
            <div className="anchor">
              <TextSection
                customClasses={{
                  download: ["p3", "p1"],
                  "font line-height-download-section regular text-base": "p1",
                  "mt-0-imp font line-height-28-imp normal text-size20": "p3",
                }}
                p1={t("Generic.DownloadSection1.title")}
                p3={t("Generic.DownloadSection1.caption")}
              />
            </div>
            <div className="flex-none pl-8 h-full flex items-center">
              <ArrowBottomIcon
                classes="stroke-green mt-[18px]"
                width="32px"
                height="43px"
              />
            </div>
          </div>
        </footer>
      </a>
    )
  );
};

DownloadSection.propTypes = {
  classes: PropTypes.string,
  icon: PropTypes.shape({
    element: PropTypes.element,
    classes: PropTypes.string,
  }),
  title: PropTypes.shape({
    element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    classes: PropTypes.string,
  }),
  caption: PropTypes.shape({
    element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    classes: PropTypes.string,
  }),
};

export default React.memo(DownloadSection);
