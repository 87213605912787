import React from "react";
import PropTypes from "prop-types";

const SolidityIcon = ({
  width = "36",
  height = "35",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 36 35`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_399_3504)">
        <path
          d="M30.2813 5.81962C30.2813 6.52276 30.8465 7.09126 31.5455 7.09126C32.4156 7.09126 33.1221 6.38063 33.1221 5.50545C33.1221 4.40585 32.2371 3.51571 31.144 3.51571C29.7831 3.51571 28.6751 4.63026 28.6751 5.99914C28.6751 7.71212 30.0583 9.10344 31.7612 9.10344C33.8955 9.10344 35.6207 7.36802 35.6207 5.2212C35.6207 2.54328 33.4642 0.374008 30.8019 0.374008H5.19066C2.52841 0.374008 0.371826 2.54328 0.371826 5.2212C0.371826 7.36802 2.09709 9.10344 4.23136 9.10344C5.93431 9.10344 7.3175 7.71212 7.3175 5.99914C7.3175 4.63026 6.20946 3.51571 4.84859 3.51571C3.75542 3.51571 2.87048 4.40585 2.87048 5.50545C2.87048 6.38063 3.57695 7.09126 4.44702 7.09126C5.14605 7.09126 5.71122 6.52276 5.71122 5.81962"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.6751 6.26843V25.9564H7.30263V6.26843"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.0512 30.2949H4.5065L7.30262 25.9564H28.6751L32.0512 30.2949Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.1969 30.2949H1.11548V34.626H35.1969V30.2949Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7684 25.9564V10.1133"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1971 25.9564V10.1133"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.6331 25.9564V10.1133"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99463 6.26843H26.0054"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

SolidityIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(SolidityIcon);
