import React from "react";
import PropTypes from "prop-types";

const ArrowRightIcon = ({
    width = "39",
    height = "30",
    classes = "stroke-white",
}) => {
    return (
        <svg
            className={classes}
            style={{ width, height }}
            viewBox={`0 0 39 30`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_1601_3651)">
                <path d="M23.1555 0.902588L33.1069 10.854L23.1555 20.8053" strokeWidth="2"
                      strokeLinejoin="round"/>
                <path d="M33.1069 10.8542H4.24792"  strokeWidth="2" strokeLinejoin="round"/>
            </g>
            <defs>
                <filter id="filter0_d_1601_3651" x="0.247925" y="0.195557" width="37.859" height="29.3169"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1601_3651"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1601_3651" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
};

ArrowRightIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    classes: PropTypes.string,
};
export default React.memo(ArrowRightIcon);


