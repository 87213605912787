import React from "react";
import PropTypes from "prop-types";

const EnhancementIcon = ({
  width = "33",
  height = "31",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 33 31`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_707_487)">
        <path
          d="M16.5601 6.1069C18.0589 6.1069 19.2738 4.89193 19.2738 3.39317C19.2738 1.89442 18.0589 0.679443 16.5601 0.679443C15.0614 0.679443 13.8464 1.89442 13.8464 3.39317C13.8464 4.89193 15.0614 6.1069 16.5601 6.1069Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8779 7.36309C13.8943 6.56719 15.1697 6.09732 16.5601 6.09732C19.8684 6.09732 22.5437 8.77269 22.5437 12.081C22.5437 13.4522 22.0835 14.718 21.3067 15.7344"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.8831 15.3125C5.38186 15.3125 6.59683 14.0975 6.59683 12.5987C6.59683 11.1 5.38186 9.88501 3.8831 9.88501C2.38435 9.88501 1.16937 11.1 1.16937 12.5987C1.16937 14.0975 2.38435 15.3125 3.8831 15.3125Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.52023 17.3358C6.07913 16.1276 6.0216 14.7659 6.45311 13.4426C7.47915 10.2974 10.8545 8.5809 13.9902 9.59735C15.2943 10.0193 16.3587 10.8535 17.0779 11.9083"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.72563 30.2236C10.2244 30.2236 11.4394 29.0086 11.4394 27.5099C11.4394 26.0111 10.2244 24.7961 8.72563 24.7961C7.22688 24.7961 6.0119 26.0111 6.0119 27.5099C6.0119 29.0086 7.22688 30.2236 8.72563 30.2236Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.038 26.4551C12.7531 26.503 11.4394 26.129 10.3174 25.314C7.64207 23.3674 7.04754 19.6276 8.99414 16.9522C9.79963 15.8399 10.9216 15.0919 12.149 14.7275"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.4039 30.2236C25.9027 30.2236 27.1176 29.0086 27.1176 27.5099C27.1176 26.0111 25.9027 24.7961 24.4039 24.7961C22.9052 24.7961 21.6902 26.0111 21.6902 27.5099C21.6902 29.0086 22.9052 30.2236 24.4039 30.2236Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.0368 22.1304C24.682 23.3674 23.9245 24.4989 22.8025 25.314C20.1271 27.2606 16.3874 26.6661 14.4408 23.9907C13.6353 22.8783 13.2613 21.5838 13.2997 20.3084"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2466 15.3125C30.7453 15.3125 31.9603 14.0975 31.9603 12.5988C31.9603 11.1 30.7453 9.88504 29.2466 9.88504C27.7478 9.88504 26.5328 11.1 26.5328 12.5988C26.5328 14.0975 27.7478 15.3125 29.2466 15.3125Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3273 10.3357C25.3917 11.0549 26.2356 12.1289 26.6671 13.4426C27.6931 16.5879 25.9671 19.9632 22.8218 20.9797C21.5177 21.4016 20.1656 21.3537 18.967 20.9222"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

EnhancementIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(EnhancementIcon);
