
import React from "react";
import PropTypes from "prop-types";

const LineIcon = ({
  width = "79",
  height = "1",
  classes="stroke-white"
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 79 1`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <line y1="0.5" x2="79" y2="0.5"/>
    </svg>

  );
};

LineIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(LineIcon);

