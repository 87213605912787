import React from "react";
import PropTypes from "prop-types";

const GroupTooltipIcon2 = ({
  width = "41",
  height = "56",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 41 56`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <g clip-path="url(#clip0_206_1358)">
    <path d="M39.8384 35.5468C39.7176 46.5405 31.1516 55.7853 19.6459 55.561C9.0319 55.3539 0.212795 46.3104 0.287582 35.3742C0.310593 31.5716 1.3346 28.0278 3.40562 24.8408C8.50839 16.9766 13.6514 9.13551 18.783 1.29439C19.6689 -0.0575318 20.5318 -0.0460261 21.4293 1.3174C26.5148 9.09524 31.6118 16.8731 36.6743 24.6624C38.8144 27.9588 39.8384 31.6061 39.8441 35.5411L39.8384 35.5468Z" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    <path d="M26.7959 27.9243C29.7241 30.0241 31.634 33.4528 31.634 37.3359C31.634 43.7273 26.4565 48.9049 20.0651 48.9049C13.6737 48.9049 8.49609 43.7273 8.49609 37.3359C8.49609 30.9445 13.6737 25.767 20.0651 25.767C21.0718 25.767 22.044 25.8935 22.9702 26.1351" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    <path d="M22.9707 26.1351C23.2641 26.2099 23.5575 26.302 23.8451 26.3998" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    <path d="M18.8799 19.6172L24.2645 26.5321L17.3496 31.9168" 
        stroke="white" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_206_1358">
    <rect width="40.1261" height="55.8486" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
};

GroupTooltipIcon2.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(GroupTooltipIcon2);

