import React from "react";
import { useTranslation } from "react-i18next";

const DiversityInclusionPointsGraphIcon = ({ classes }) => {
  const { t, ready } = useTranslation();
  return (
    !!ready && (
      <svg
        className={classes}
        width="918"
        height="547"
        viewBox="0 0 918 547"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 136">
          <line
            id="Line 7"
            x1="41"
            y1="41"
            x2="41"
            y2="506"
            stroke="#C4C4C4"
            strokeWidth="2"
          ></line>
          <line
            id="Line 8"
            x1="402"
            y1="41"
            x2="402"
            y2="506"
            stroke="#C4C4C4"
            strokeWidth="2"
          ></line>
          <line
            id="Line 9"
            x1="715"
            y1="41"
            x2="715"
            y2="506"
            stroke="#C4C4C4"
            strokeWidth="2"
          ></line>
          <path
            id="Vector 1"
            d="M42 295L405.078 154.278L713 42"
            stroke="#29851B"
            strokeWidth="2"
            strokeLinecap="round"
            opacity="0"
          >
            <animate
              attributeType="CSS"
              attributeName="opacity"
              from="0"
              to="1"
              dur="2s"
              repeatCount="1"
              fill="freeze"
            />
          </path>
          <path
            id="Vector 2"
            d="M41.4107 420.192C40.9645 420.518 40.8666 421.143 41.1921 421.589C41.5175 422.035 42.1431 422.133 42.5893 421.808L41.4107 420.192ZM403.953 157V156H403.627L403.364 156.192L403.953 157ZM42.5893 421.808L404.543 157.808L403.364 156.192L41.4107 420.192L42.5893 421.808ZM403.953 158H713V156H403.953V158Z"
            fill="#323232"
            opacity="0"
          >
            <animate
              attributeType="CSS"
              attributeName="opacity"
              from="0"
              to="1"
              dur="1s"
              repeatCount="1"
              fill="freeze"
            />
          </path>
          <text
            id="8.315 42,7%"
            fill="#232323"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="22"
            fontWeight="300"
            letterSpacing="0px"
          >
            <tspan x="541.367" y="540.535">
              {t("GroupValue.Graph.areeProfessionali")}
            </tspan>
          </text>
          <text
            id="8.315 42,7%_2"
            fill="#232323"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="22"
            fontWeight="300"
            letterSpacing="0px"
          >
            <tspan x="251.189" y="540.535">
              {t("GroupValue.Graph.quadri")}
            </tspan>
          </text>
          <text
            id="8.315 42,7%_3"
            fill="#232323"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="22"
            fontWeight="300"
            letterSpacing="0px"
          >
            <tspan x="40" y="540.535">
              {t("GroupValue.Graph.dirigenti")}
            </tspan>
          </text>
          <g id="Group 30">
            <circle
              id="Ellipse 4"
              cx="40"
              cy="295"
              r="40"
              fill="#29851B"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="2s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="dipendenti e collabo"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0px"
            >
              <tspan x="23.7112" y="303.856">
                {t("GroupValue.Graph.values.08")}
              </tspan>
            </text>
          </g>
          <g id="Group 33">
            <circle
              id="Ellipse 4_2"
              cx="714"
              cy="40"
              r="40"
              fill="#29851B"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="2s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="dipendenti e collabo_2"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0px"
            >
              <tspan x="697.405" y="48.3105">
                {t("GroupValue.Graph.values.1")}
              </tspan>
            </text>
          </g>
          <g id="Group 31">
            <circle
              id="Ellipse 4_3"
              cx="40"
              cy="418"
              r="40"
              fill="#323232"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="1s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="dipendenti e collabo_3"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0px"
            >
              <tspan x="23.4382" y="426.22">
                {t("GroupValue.Graph.values.07")}
              </tspan>
            </text>
          </g>
          <g id="Group 32">
            <circle
              id="Ellipse 4_4"
              cx="404"
              cy="156"
              r="40"
              fill="#323232"
              stroke="#2B8804"
              strokeWidth="8"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="2s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="dipendenti e collabo_4"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0px"
            >
              <tspan x="387.711" y="166.311">
                {t("GroupValue.Graph.values.09")}
              </tspan>
            </text>
          </g>
          <g id="Group 34">
            <circle
              id="Ellipse 4_5"
              cx="719"
              cy="160"
              r="40"
              fill="#323232"
              opacity="0"
            >
              <animate
                attributeType="CSS"
                attributeName="opacity"
                from="0"
                to="1"
                dur="1s"
                repeatCount="1"
                fill="freeze"
              />
            </circle>
            <text
              id="dipendenti e collabo_5"
              fill="white"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="24"
              fontWeight="300"
              letterSpacing="0px"
            >
              <tspan x="702.516" y="171.22">
                {t("GroupValue.Graph.values.09")}
              </tspan>
            </text>
          </g>
          <text
            id="dipendenti e collabo_6"
            fill="#29851B"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="18"
            fontWeight="bold"
            letterSpacing="0px"
          >
            <tspan x="770" y="38.9829">
              {t("GroupValue.Graph.italy")}
            </tspan>
          </text>
          <text
            id="dipendenti e collabo_7"
            fill="#323232"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="18"
            fontWeight="bold"
            letterSpacing="0px"
          >
            <tspan x="769" y="163.983">
              {t("GroupValue.Graph.group")}
            </tspan>
          </text>
        </g>
      </svg>
    )
  );
};

export default React.memo(DiversityInclusionPointsGraphIcon);
