import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Timeline = ({ TimelineItems, classes, lineClasses }) => {
  const { t, ready } = useTranslation();
  return (
    !!ready && (
      <>
        <div className={classes}>
          <div className="timeline-container relative d-none d-sm-block">
            <div className={`line ml-auto mr-auto ${lineClasses}`}>
              <div className="absolute timeline-absolute">
                {TimelineItems.map((item, index) => {
                  const Icon = item.Icon;
                  return (
                    <div key={index} className="tl-elements-container">
                      <div
                        className={`containerTime text-black h5 flex justify-around md:mb-10 ${
                          index === 0 ? "mt-10" : ""
                        }`}
                      >
                        {item.timelineTop.sx && (
                          <p className="text-size22 text-gray mb-6">
                            {t(item.timelineTop.sx)}
                          </p>
                        )}
                        {item.timelineTop.dx && (
                          <p className="text-size22 text-gray mb-6">
                            {t(item.timelineTop.dx)}
                          </p>
                        )}
                      </div>

                      <div className="flex justify-center items-center w-[100%]">
                        <div className=" tl-text-left animated fadeInRight mr-5 text-right">
                          {item.left.title && (
                            <h1 className="leading-6 text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl lg:line-height-62-imp text-green">
                              {t(item.left.title)}
                            </h1>
                          )}
                          {item.left.caption && (
                            <p className="font line-height-62-imp text-size22 text-green">
                              {t(item.left.caption)}
                            </p>
                          )}
                        </div>

                        <div className="grid justify-items-center animated fadeIn animationDelay">
                          {Icon && (
                            <div className="mx-5 justify-center tl-image">
                              <div className="rounded-lg bg-white max-w-sm">
                                {/* <Icon width="100%" height="auto" /> */}

                                <img
                                  className="svg-img "
                                  src={Icon}
                                  alt=""
                                  width="100%"
                                  height="auto"
                                />
                              </div>
                            </div>
                          )}
                          {item.title && (
                            <div className="justify-center tl-image">
                              <div
                                className={`${item.titleClasses} rounded-lg bg-white max-w-sm`}
                              >
                                <p className="text-sm xs:text-base sm:text-lg md:text-xl text-green py-2">
                                  {t(item.title)}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="tl-text-right animated fadeInLeft animationDelay  ml-5">
                          {item.right.title && (
                            <h1 className="leading-6 text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl lg:line-height-62-imp text-green">
                              {t(item.right.title)}
                            </h1>
                          )}
                          {item.right.caption && (
                            <p className="font line-height-30-imp text-size22 text-green">
                              {t(item.right.caption)}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="containerTime text-black h5 flex justify-around mt-20">
                        {item.timelineBottom.sx && (
                          <p className="text-size22 text-gray">
                            {t(item.timelineBottom.sx)}
                          </p>
                        )}
                        {item.timelineBottom.dx && (
                          <p className="text-size22 text-gray">
                            {t(item.timelineBottom.dx)}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

Timeline.propTypes = {
  tl: PropTypes.object,
  classes: PropTypes.string,
};

export default React.memo(Timeline);
