import React from "react";
import PropTypes from "prop-types";

const ButtonTooltipIcon = ({
  width = "19",
  height = "19",   
}) => { 
  return (  
    <svg      
      style={{ width, height }}
      viewBox={`0 0 19 19`}      
      xmlns="http://www.w3.org/2000/svg"        
    >         

    <circle className="fillButton hover:fill-[#323232]" fill="#2b8804" cx="9.5" cy="9.5" r="9.5"/>
      <path fill="#fff" d="M9.72,5.35h0c.37,.02,.73-.12,.98-.39,.24-.26,.36-.6,.35-.96,0-.26-.09-.51-.27-.7-.19-.2-.47-.31-.75-.29-.35-.01-.7,.13-.94,.38-.25,.25-.38,.59-.37,.94,0,.27,.11,.53,.3,.72,.18,.2,.43,.31,.7,.31Zm.38,9.23h0c-.09,.05-.19,.09-.3,.09-.17,0-.27-.12-.27-.36,0-.06,0-.12,0-.18l.97-6.89-.36-.17-2.58,.36-.1,.59,.89,.42-.24,1.75c-.36,2.52-.56,4.04-.62,4.56v.15c0,.26,.12,.5,.33,.64,.28,.17,.6,.25,.92,.23,.44,.01,.87-.11,1.23-.35,.29-.19,.55-.42,.76-.7l-.21-.47c-.13,.12-.27,.22-.42,.31Z"/>
    </svg>       
  );
};

ButtonTooltipIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string   
};
export default React.memo(ButtonTooltipIcon);



