import React from "react";

const CircleIcon = ({ size = "100px", color = "#29851B", classes = "" }) => {
  const style = {
    background: color,
    borderRadius: "50%",
    width: size,
    height: size,
  };
  return <div className={classes || ""} style={style}></div>;
};

export default React.memo(CircleIcon);
