import React from "react";
import PropTypes from "prop-types";

const CopyIcon = ({
  width = "66",
  height = "78",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 66 78`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.68735 9.81543H0.928833V76.4656H54.5387V71.8588"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M65.0898 1.10352H11.4799V67.7537H65.0898V1.10352Z"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M20.7307 19.4565H54.5388"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M20.7307 29.4688H54.5388"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M20.7307 39.4814H54.5388"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M20.7307 49.4751H54.5388"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
  );
};

CopyIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(CopyIcon);


