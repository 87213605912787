import React from "react";
import PropTypes from "prop-types";

const LavoroDignitoso = ({
  width = "55",
  height = "50",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox="0 0 55 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M54.6859 0H0V50H54.6859V0Z" fill="#931831"/>
        <path d="M37.7197 17.5273L32.1609 22.3612C31.9506 22.5531 31.843 22.6925 31.843 23.0874V36.6366C31.8471 36.717 31.8828 36.7926 31.9424 36.8468C32.0019 36.9011 32.0804 36.9297 32.1609 36.9263H37.9067C37.9871 36.9296 38.0655 36.901 38.1248 36.8468C38.1841 36.7925 38.2196 36.7169 38.2234 36.6366V17.6496C38.2234 17.4894 38.0864 17.1972 37.7246 17.5273" fill="white"/>
        <path d="M21.706 23.8258L16.1472 28.6596C15.9381 28.8503 15.8306 28.9909 15.8306 29.3846V36.6366C15.8347 36.7167 15.8703 36.792 15.9295 36.846C15.9888 36.9 16.0671 36.9284 16.1472 36.9251H21.8931C21.9732 36.9284 22.0514 36.9 22.1107 36.846C22.17 36.792 22.2056 36.7167 22.2097 36.6366V23.9468C22.2097 23.7867 22.0716 23.4945 21.7097 23.8245" fill="white"/>
        <path d="M40.2454 11.6641C40.2464 11.6434 40.2428 11.6228 40.2348 11.6038C40.2267 11.5847 40.2145 11.5677 40.1989 11.5541C40.1696 11.5296 40.1331 11.5155 40.095 11.5137H40.0657H40.0192L35.7636 12.3915L35.6866 12.3988C35.6556 12.4042 35.6268 12.4182 35.6034 12.4392C35.5884 12.4522 35.5764 12.4682 35.5681 12.4862C35.5599 12.5043 35.5556 12.5239 35.5556 12.5437C35.5556 12.5635 35.5599 12.5831 35.5681 12.6012C35.5764 12.6192 35.5884 12.6353 35.6034 12.6482L35.6511 12.6922L36.623 13.581L26.9785 22.3979L22.5249 18.3256C22.5001 18.3031 22.4679 18.2907 22.4344 18.2907C22.401 18.2907 22.3687 18.3031 22.3439 18.3256L21.1349 19.4259L13.9953 25.953C13.9836 25.9634 13.9742 25.9762 13.9678 25.9905C13.9613 26.0049 13.958 26.0204 13.958 26.0361C13.958 26.0518 13.9613 26.0673 13.9678 26.0817C13.9742 26.096 13.9836 26.1088 13.9953 26.1192L15.2056 27.2195C15.2302 27.2419 15.2622 27.2544 15.2955 27.2544C15.3287 27.2544 15.3608 27.2419 15.3853 27.2195L22.4369 20.7744L25.6729 23.7451C25.6756 23.7499 25.6789 23.7544 25.6827 23.7585L26.8917 24.8588C26.9165 24.8813 26.9487 24.8938 26.9822 24.8938C27.0157 24.8938 27.0479 24.8813 27.0727 24.8588L38.0179 14.8512L39.0387 15.784C39.0698 15.8124 39.1104 15.8281 39.1524 15.8281C39.1945 15.8281 39.235 15.8124 39.2661 15.784C39.2936 15.7596 39.3103 15.7253 39.3126 15.6887V15.6764L40.2466 11.7032L40.2454 11.6641Z" fill="white"/>
        <path d="M29.744 24.6742L27.0545 27.1535C27.0293 27.1722 26.9986 27.1817 26.9672 27.1806C26.9359 27.1795 26.9059 27.1678 26.8821 27.1474L24.4505 24.8759C24.0043 24.47 23.9077 24.8001 23.9077 24.959V36.6366C23.9112 36.7165 23.9462 36.7917 24.0051 36.8458C24.0641 36.8999 24.142 36.9284 24.2219 36.9251H29.9678C30.0479 36.9284 30.1261 36.9 30.1854 36.846C30.2447 36.792 30.2803 36.7167 30.2844 36.6366V24.7659C30.2844 24.6057 30.1463 24.3136 29.7514 24.6742" fill="white"/>

    </svg>
  );
};

LavoroDignitoso.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};

export default React.memo(LavoroDignitoso);
