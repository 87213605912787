import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./containers/Home";
import GroupValue from "./containers/GroupValue";
import IntegrityPage from "./containers/IntegrityPage";
import ServiceQuality from "./containers/ServiceQuality";
import InnovationTransformation from "./containers/InnovationTransformation";
import FinancialInclusion from "./containers/FinancialInclusion";
import InvestmentInsurance from "./containers/InvestmentInsurance";
import CommunitySupport from "./containers/CommunitySupport";
import JobProtection from "./containers/JobProtection";
import DiversityInclusion from "./containers/DiversityInclusion";
import HealthSafety from "./containers/HealthSafety";
import GreenTransition from "./containers/GreenTransition";
import ClimateChange from "./containers/ClimateChange";
import Covid19 from "./containers/Covid19";
import DownloadSection from "./components/DownloadSection";

import Simulator from "./pages/Simulator";
import Main from "./pages/Main";

import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop smoothly />
        <Routes>
          <Route path=":lang/simulator" element={<Simulator />} />
          <Route
            path=":lang/home"
            element={
              <Main>
                <Home />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/group-value"
            element={
              <Main>
                <GroupValue />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/integrity"
            element={
              <Main>
                <IntegrityPage />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/service-quality"
            element={
              <Main>
                <ServiceQuality />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/innovation-transformation"
            element={
              <Main>
                <InnovationTransformation />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/financial-inclusion"
            element={
              <Main>
                <FinancialInclusion />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/investment-insurance"
            element={
              <Main>
                <InvestmentInsurance />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/community-support"
            element={
              <Main>
                <CommunitySupport />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/job-protection"
            element={
              <Main>
                <JobProtection />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/diversity-inclusion"
            element={
              <Main>
                <DiversityInclusion />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/health-safety"
            element={
              <Main>
                <HealthSafety />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/green-transition"
            element={
              <Main>
                <GreenTransition />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/climate-change"
            element={
              <Main>
                <ClimateChange />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/covid19"
            element={
              <Main>
                <Covid19 />
                <DownloadSection />
              </Main>
            }
          />
          <Route
            path=":lang/*"
            element={
              <Main>
                <Home />
                <DownloadSection />
              </Main>
            }
          />
          <Route path="*" element={<Navigate to="/en/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
