import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
// import Fetch from "i18next-fetch-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const languages = ["en", "it"];

const options = {
  react: {
    useSuspense: false,
    wait: false,
  },
  detection: {
    order: ["path", "querystring"],
    lookupFromPathIndex: 0,
    lookupQuerystring: "lng",
    caches: false,
  },
  ns: ["translation"],
  defaultNS: "translation",
  fallbackLng: "en",
  supportedLngs: languages,
  preload: languages,
  lowerCaseLng: true,
  cleanCode: true,
  ignoreJSONStructure: false,
  backend: {
    // init option for fetch, for example
    requestOptions: {
      mode: "cors",
      credentials: "same-origin",
      cache: "default",
    },
  },
  debug: true,
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // .use(Fetch)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options, (err, t) => {
    if (err) return console.log("something went wrong loading", err);
    i18n.languages = languages;
    // i18n.changeLanguage("it"); // t -> same as i18next.t
  });

export default i18n;
