import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextSection from "../../components/TextSection";
import HealthIcon from "../../assets/icons/HealthIcon";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import {PcIcon} from "../../assets/icons";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";


const HealthSafety = () => {
  const { t, ready } = useTranslation();

  return (
    !!ready && (
      <>
        <ContainerWrapper>
            <AreaSquares areas={areas["health-safety"].areas} description={areas["health-safety"].description}/>
            <TextSection
            customClasses={{ "mb-3 font line-height-h1-40": "h3"}}
            h3={t("HealthSafety.TextSection1.title")}
            h5={t("HealthSafety.TextSection1.caption")}
            p4={t("HealthSafety.TextSection1.paragraph")}
          />
          <div className="flex justify-center">
            <TextSection
              customClasses={{ "font line-height-82-imp mb-0-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1", "font line-height-30-imp": "p1"}}
              classes="pt-16 w-60"
              h1={t("HealthSafety.TextSection2.text1")}
              p1={t("HealthSafety.TextSection2.text2")}
            />
          </div>
          <TextSection
            classes="pt-16"
            p4={t("HealthSafety.TextSection3.paragraph")}
          />
          <div className="flex justify-between items-start mb-20 pt-16 overflow-x-scroll sm:overflow-x-hidden">
              <TextSection
                customClasses={{ "font line-height-62-imp mb-3 text-right text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1", "text-right": "p1"}}
                classes="lg:max-w-[320px] lg:mr-8"
                h1={t("HealthSafety.TextSection4.text1")}
                p1={t("HealthSafety.TextSection4.text2")}
              />
              
              <div className="scale-[0.4] sm:scale-[0.6] md:scale-[0.6] lg:scale-[1]">
                <PcIcon width="340px" height="205px"  />
              </div>          

              <TextSection
                customClasses={{ "mb-1": "p1","font line-height-62-imp text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl": "h1"}}
                classes="lg:max-w-[320px] lg:ml-8"
                h1={t("HealthSafety.TextSection5.text1")}
                p1={`${t("HealthSafety.TextSection5.text2")}\n${t("HealthSafety.TextSection5.text3")}`}
              />
           </div>
        </ContainerWrapper>
      <Menu />
      </>
    )
  );
};

HealthSafety.propTypes = {};

export default React.memo(HealthSafety);
