import React from "react";
import PropTypes from "prop-types";

const GreenTransitionIcon = ({
  width = "30",
  height = "30",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 30 30`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_358_724)">
        <path
          d="M3.85724 18.3456C3.85724 19.5393 4.82573 20.5191 6.03071 20.5191C7.23569 20.5191 8.20418 19.5506 8.20418 18.3456C8.20418 17.1406 7.23569 16.1721 6.03071 16.1721C4.82573 16.1721 3.85724 17.1406 3.85724 18.3456V18.3456Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.55339 12.3883C10.1525 12.3883 11.4476 11.0932 11.4476 9.49406C11.4476 7.89493 10.1525 6.59985 8.55339 6.59985C6.95425 6.59985 5.65918 7.89493 5.65918 9.49406C5.65918 11.0932 6.95425 12.3883 8.55339 12.3883Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0535 11.4536C16.2337 10.8342 16.3238 10.1697 16.3238 9.48279C16.3238 5.47369 13.0692 2.21912 9.06015 2.21912C5.05105 2.21912 1.79648 5.48495 1.79648 9.49405C1.79648 11.4423 2.56226 13.2103 3.81229 14.5054"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.81218 14.5054C2.03286 15.2712 0.782837 17.028 0.782837 19.0889C0.782837 21.8367 3.01261 24.0777 5.77168 24.0777C8.53075 24.0777 10.7605 21.8479 10.7605 19.0889"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9588 21.7916C12.9227 22.3997 12.5736 23.7173 13.1705 24.7534C13.7786 25.7894 15.0962 26.1385 16.1323 25.5417C17.1683 24.9336 17.5174 23.616 16.9206 22.5799C16.3124 21.5439 14.9948 21.1948 13.9588 21.7916Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.8892 19.5281C24.2743 18.7285 24.736 16.9492 23.9365 15.5753C23.1369 14.1901 21.3576 13.7284 19.9837 14.528C18.5985 15.3275 18.1368 17.1068 18.9364 18.4807C19.7359 19.8659 21.5153 20.3276 22.8892 19.5281V19.5281Z"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0534 11.4535C16.4814 11.0256 16.9769 10.6315 17.5287 10.3161C20.9972 8.30032 25.4455 9.47152 27.4613 12.9401C29.4771 16.4086 28.306 20.8569 24.8374 22.8727C23.1594 23.8525 21.245 24.0777 19.4882 23.6498"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0218 19.956C9.64564 21.3412 8.83481 24.393 10.22 26.7692C11.6051 29.1454 14.657 29.9562 17.0332 28.571C18.8125 27.535 19.7134 25.5642 19.4769 23.6498"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5063 17.7487L18.9141 6.59985"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.914 6.59982L17.2248 1.27313"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3307 5.18091L18.9139 6.59986"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

GreenTransitionIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(GreenTransitionIcon);
