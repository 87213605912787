import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  PLogo1w2,
  PLogo3,
  PLogo4,
  PLogo5,
  PLogo6,
  PLogo7,
  PLogo8,
  PLogo9,
  PLogo10,
  PLogo11,
  PLogo12,
  PLogo13,
  MibEsg,
} from "../assets/images";
import Tooltip from "./Tooltip";
import { LineIcon } from "../assets/icons";

const PartnerLogos = () => {
  const { t } = useTranslation();
  const [selectedPartner, setSelectedPartner] = useState(null);
  const modalRef = useRef();

  const openModal = (partner) => {
    if (modalRef.current) {
      setSelectedPartner(partner);
      modalRef.current.openModal();
    }
  };

  const logos = [
    { name: "SandPGlobal", asset: PLogo1w2, height: "77px" },
    { name: "FTSE4Good", asset: PLogo3, height: "78px" },
    { name: "MSCI", asset: PLogo4, height: "67px" },
    { name: "CDP", asset: PLogo5, height: "55px" },
    { name: "Global100", asset: PLogo6, height: "39px" },
    { name: "Moodys", asset: PLogo7, height: "19px" },
    { name: "MibEsg", asset: MibEsg, height: "18px" },
    { name: "Solactive", asset: PLogo8, height: "58px" },
    { name: "Stoxx", asset: PLogo9, height: "47px" },
    { name: "StandardEthics", asset: PLogo10, height: "50px" },
    { name: "Ecpi", asset: PLogo11, height: "52px" },
    { name: "Bloomberg", asset: PLogo12, height: "97px" },
    { name: "Refinitiv", asset: PLogo13, height: "78px" },
  ];

  return (
    <>
      <Tooltip
        hideButton
        ref={modalRef}
        tooltipLink={t(`Home.Partners.${selectedPartner}.Tooltip.url`)}
      >
        <p className={`text-base text-white mt-2 mb-5`}>
          {t(`Home.Partners.${selectedPartner}.Tooltip.text1`)}
        </p>
        <LineIcon width="79px" height="1px" />
        <p className={`text-xs mt-5 text-white`}>
          {t(`Home.Partners.${selectedPartner}.Tooltip.text2`)}
        </p>
      </Tooltip>
      <div className="flex columns-5 overflow-x-scroll md:overflow-x-hidden md:flex-wrap justify-start  items-center gap-8 gap-y-12 my-8 md:my-16">
        {logos.map((logo, i) => {
          return (
            <img
              key={logo.name}
              className="w-auto h-full transition-all grayscale hover:grayscale-0 cursor-pointer"
              src={logo.asset}
              alt={logo.name}
              title={logo.name}
              height={logo.height}
              width={"100%"}
              onClick={() => openModal(logo.name)}
            ></img>
          );
        })}
      </div>
    </>
  );
};

export default PartnerLogos;
