import React from "react";
import PropTypes from "prop-types";

const ExpandIcon = ({
  width = "22",
  height = "12",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 22 12`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M21.5686 11.2843L11.2843 1L1.00002 11.2843" />
    </svg>
  );
};

ExpandIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(ExpandIcon);


