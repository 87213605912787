import React from "react";
import Bar from "./Bar";
import { useInView } from "react-intersection-observer";

const BarChart = ({
  classes,
  left: Left = () => null,
  right: Right = () => null,
  options: { width = "100%", height = "100%", columns, labels, ...rest },
  data = [],
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`chart ${classes || ""}`}
      style={{ width, height, ...rest }}
    >
      <div className="columns d-flex" style={columns}>
        <Left classes="z-10" />
        {data.map((element, i) => (
          <Bar key={`bar-${i}`} {...element} animate={inView} />
        ))}
        <Right classes="z-10" />
      </div>
      <div className="labels d-flex" style={labels}>
        <Left classes="overflow-y-clip opacity-0 z-0" />
        {data.map((element, i) => (
          <div
            key={`label-${i}`}
            className={`element ${element.classes || ""}`}
            style={element.style || {}}
          >
            <p className="label text-size22 text-black mt-2">
              {element.label || ""}
            </p>
          </div>
        ))}
        <Right classes="overflow-y-clip opacity-0 z-0" />
      </div>
    </div>
  );
};

export default React.memo(BarChart);
