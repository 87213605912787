import React, { Children } from "react";
import PropTypes from "prop-types";
import { Animated } from "react-animated-css";
import { useInView } from "react-intersection-observer";

const InfoBox = ({
  children,
  height,
  classes,
  table,
  size,
  sizes,
  border = true,
  sliceClasses = '',
}) => {
  const slices = Children.toArray(children);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });
  const customSizes = sizes && sizes.length > 0;
  const n = customSizes ? size : slices.length;
  const style = {
    gridTemplateColumns: `repeat(${n}, minmax(0, 1fr))`,
  };
  if (height) style.height = height;
  return (
    n > 0 && (
      <div ref={ref} className={`grid gap-3 ${classes || ""}`} style={style}>
        {Children.map(slices, (slice, index) => {
          const delay = 250 * index;
          const classes = ["info-slice"];
          if (border) {
            classes.push("bordered");
          }
          switch (index) {
            case 0:
              classes.push("first");
              break;
            case slices.length - 1:
              classes.push("last");
              break;
            default:
              break;
          }
          const style = customSizes
            ? { gridColumn: `span ${sizes[index]} / span ${sizes[index]}` }
            : {};

          return (
            <div className={`${classes.join(" ")}`} style={style}>
              {inView ? (
                <Animated
                  animationIn="slideInLeft"
                  animationInDelay={delay}
                  className={`${table ? "info-slice--cell" : "info-slice--inner"} ${sliceClasses}`}
                  // animateOnMount={false}
                >
                  {slice}
                </Animated>
              ) : (
                slice
              )}
            </div>
          );
        })}
      </div>
    )
  );
};

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  classes: PropTypes.string,
  table: PropTypes.bool,
  size: PropTypes.number,
  sizes: PropTypes.arrayOf(PropTypes.number),
};

export default React.memo(InfoBox);
