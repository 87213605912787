import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextSection from "../../components/TextSection";
import i18next from "i18next";
import {
  DownloadPdfIcon,
  ButtonTooltipIcon,
  ShowMoreIcon,
  CircleIcon,
  GroupTooltipIcon1,
  GroupTooltipIcon2,
  GroupTooltipIcon3,
  GroupTooltipIcon4,
  GroupTooltipIcon5,
  GroupTooltipIcon6,
  GroupTooltipIcon7,
  GroupTooltipIcon8,
  LineIcon,
} from "../../assets/icons";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import Tooltip from "../../components/Tooltip";
import InfoBox from "../../components/InfoBox";
import LottieFilePlayer from "../../components/LottieFilePlayer";
import TransizioneEconomiaSostenibile from "../../assets/lotties/transizione_economia_sostenibile.json";
import TransizioneEconomiaSostenibileEN from "../../assets/lotties/transizione_economia_sostenibile_EN.json";
import ShowMore from "../../components/ShowMore";
import BarChart from "../../components/BarCharts/BarChart";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";

const GreenTransition = () => {
  const { t, ready } = useTranslation();
  const currentLang = i18next.language;

  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["green-transition"].areas}
            description={areas["green-transition"].description}
          />
          <TextSection
            customClasses={{ "mb-[41px] font line-height-h1-40": "h3" }}
            h3={t("GreenTransition.TextSection1.title")}
            h5={t("GreenTransition.TextSection1.caption")}
          />

          <div className="pt-10 w-full mt-3.5 sm:mb-20 relative mobile-hide h-[408px]">
            <div className="w-full absolute" style={{ zIndex: 10 }}>
              <BarChart
                classes=""
                right={({ classes }) => (
                  <div
                    className={`d-flex align-end ${classes || ""}`}
                    style={{ width: 365 }}
                  ></div>
                )}
                options={{
                  width: "100%",
                  height: "408px",
                  columns: {
                    height: "408px",
                    justifyContent: "center",
                  },
                  labels: {
                    justifyContent: "center",
                  },
                }}
                data={[
                  {
                    topLabel: (
                      <>
                        {t("GreenTransition.BarChart2.Values.val1")}
                        <br />
                        <span>
                          {t("GreenTransition.BarChart2.Values.val2")}
                        </span>
                      </>
                    ),
                    label: "",
                    height: 101,
                    color: "#2B7021",
                    inner: (
                      <div
                        className="text-white m-1.5"
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "22px",
                        }}
                      >
                        {t("GreenTransition.BarChart2.Values.val3")}
                      </div>
                    ),
                    style: { textAlign: "right" },
                  },
                  {
                    topLabel: (
                      <>
                        {t("GreenTransition.BarChart2.Values.val4")}
                        <br />
                        <span>
                          {t("GreenTransition.BarChart2.Values.val5")}
                        </span>
                      </>
                    ),
                    label: "",
                    height: 303,
                    color: "#2B7021",
                    inner: (
                      <div
                        className="text-white m-1.5"
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "22px",
                        }}
                      >
                        {t("GreenTransition.BarChart2.Values.val6")}
                      </div>
                    ),
                    style: { textAlign: "right" },
                  },
                ]}
              />
            </div>

            <div className="w-full absolute" style={{ zIndex: 20 }}>
              <BarChart
                classes=""
                right={({ classes }) => (
                  <div
                    className={`d-flex col content-end ${classes || ""}`}
                    style={{ width: 365, height: 303, alignSelf: "end" }}
                  >
                    <table
                      className="align-top"
                      style={{
                        border: "0",
                        marginLeft: 47,
                        width: 267,
                      }}
                    >
                      <colgroup>
                        <col width="66px" />
                        <col />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td>
                            <CircleIcon size={47} />
                          </td>
                          <td>
                            <p
                              className="text bold"
                              style={{ lineHeight: "20px" }}
                            >
                              {t("GreenTransition.BarChart2.text2")}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <CircleIcon size={47} color="#323232" />
                          </td>
                          <td>
                            <p
                              className="text bold"
                              style={{ lineHeight: "20px" }}
                            >
                              {t("GreenTransition.BarChart2.text3")}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      className="d-flex align-end"
                      style={{ marginLeft: 30, marginTop: 30, marginRight: 30 }}
                    >
                      <Tooltip
                        classes="ml-2 mb-2"
                        icon={<ButtonTooltipIcon width="19px" height="19px" />}
                      >
                        <TextSection
                          p5={t(
                            "GreenTransition.BarChart2.Tooltip1.TextSection1.text1"
                          )}
                        />
                      </Tooltip>
                      <div
                        className="ml-auto"
                        style={{ textAlign: "right", marginTop: 30 }}
                      >
                        <p
                          style={{
                            fontWeight: 700,
                            fontSize: "22px",
                            lineHeight: "30px",
                          }}
                        >
                          € {t("GreenTransition.BarChart2.text4")}
                        </p>
                        <span>{t("GreenTransition.BarChart2.text1")}</span>
                      </div>
                    </div>
                  </div>
                )}
                options={{
                  width: "100%",
                  height: "490px",
                  columns: {
                    height: "408px",
                    justifyContent: "center",
                  },
                  labels: {
                    justifyContent: "center",
                  },
                }}
                data={[
                  {
                    label: "2020*",
                    height: 21,
                    color: "#323231",
                    inner: (
                      <div
                        className="text-white mx-1.5"
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "22px",
                        }}
                      >
                        {t("GreenTransition.BarChart2.Values.val7")}
                      </div>
                    ),
                    style: { textAlign: "right" },
                  },
                  {
                    label: "2021",
                    height: 82,
                    color: "#323231",
                    inner: (
                      <div
                        className="text-white m-1.5"
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "22px",
                        }}
                      >
                        {t("GreenTransition.BarChart2.Values.val8")}
                      </div>
                    ),
                    style: { textAlign: "right" },
                  },
                ]}
              />
            </div>
          </div>

          <div className="w-full mt-3.5 sm:mb-10 mobile-show">
            <BarChart
              classes=""
              options={{
                width: "100%",
                height: "408px",
                columns: {
                  height: "408px",
                  justifyContent: "center",
                },
                labels: {
                  justifyContent: "center",
                },
              }}
              data={[
                {
                  topLabel: (
                    <>
                      2.778
                      <br />
                      <span>2,9%</span>
                    </>
                  ),
                  label: "2020*",
                  height: 101,
                  color: "#2B7021",
                  inner: (
                    <div
                      className="text-white m-1.5"
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      2.189
                    </div>
                  ),
                  style: { textAlign: "right" },
                },
                {
                  topLabel: (
                    <>
                      8.699
                      <br />
                      <span>11,2%</span>
                    </>
                  ),
                  label: "2021",
                  height: 303,
                  color: "#2B7021",
                  inner: (
                    <div
                      className="text-white m-1.5"
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      6.139
                    </div>
                  ),
                  style: { textAlign: "right" },
                },
              ]}
            />

            <BarChart
              classes=""
              options={{
                width: "100%",
                height: "250px",
                columns: {
                  height: "250px",
                  justifyContent: "center",
                },
                labels: {
                  justifyContent: "center",
                },
              }}
              data={[
                {
                  label: "2020*",
                  height: 21,
                  color: "#323231",
                  inner: (
                    <div
                      className="text-white mx-1.5"
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      589
                    </div>
                  ),
                  style: { textAlign: "right" },
                },
                {
                  label: "2021",
                  height: 82,
                  color: "#323231",
                  inner: (
                    <div
                      className="text-white m-1.5"
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      2.560
                    </div>
                  ),
                  style: { textAlign: "right" },
                },
              ]}
            />

            <BarChart
              classes=""
              right={({ classes }) => (
                <div
                  className={`d-flex col content-end ${classes || ""}`}
                  style={{ width: 365, height: 303, alignSelf: "end" }}
                >
                  <table
                    className="align-top"
                    style={{
                      border: "0",
                      marginLeft: 47,
                      width: 267,
                    }}
                  >
                    <colgroup>
                      <col width="66px" />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <CircleIcon size={47} />
                        </td>
                        <td>
                          <p
                            className="text bold"
                            style={{ lineHeight: "20px" }}
                          >
                            {t("GreenTransition.BarChart2.text2")}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CircleIcon size={47} color="#323232" />
                        </td>
                        <td>
                          <p
                            className="text bold"
                            style={{ lineHeight: "20px" }}
                          >
                            {t("GreenTransition.BarChart2.text3")}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    className="d-flex col align-end"
                    style={{ marginLeft: 30 }}
                  >
                    <Tooltip
                      classes="ml-2 mb-2"
                      icon={<ButtonTooltipIcon width="19px" height="19px" />}
                    >
                      <TextSection
                        p5={t(
                          "GreenTransition.BarChart2.Tooltip1.TextSection1.text1"
                        )}
                      />
                    </Tooltip>
                    <div className="ml-auto" style={{ textAlign: "right" }}>
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: "22px",
                          lineHeight: "30px",
                        }}
                      >
                        € {t("GreenTransition.BarChart2.text4")}
                      </p>
                      <span>{t("GreenTransition.BarChart2.text1")}</span>
                    </div>
                  </div>
                </div>
              )}
              options={{
                width: "100%",
                height: "490px",
                columns: {
                  height: "408px",
                  justifyContent: "center",
                },
                labels: {
                  justifyContent: "center",
                },
              }}
              data={[]}
            />
          </div>

          <TextSection
            customClasses={{ "flex items-center": "h5" }}
            classes="pt-16"
            h5={
              <>
                <div>{t("GreenTransition.TextSection2.text1")}</div>
                <Tooltip
                  classes="ml-2"
                  tooltipLink={""}
                  icon={<ButtonTooltipIcon width="19px" height="19px" />}
                >
                  <TextSection
                    customClasses={{ "mb-1": "p5" }}
                    p5={t(
                      "GreenTransition.TextSection2.Tooltip1.TextSection1.text1"
                    )}
                  />
                  <LineIcon classes="stroke-white" width="51px" height="1px" />

                  <div className="mt-[75px] grid grid-cols-2 sm:grid-cols-4 gap-4 items-start">
                    <div>
                      <div className="iconInsideTooltip flex justify-center">
                        <GroupTooltipIcon1 width="48px" height="48px" />
                      </div>
                      <TextSection
                        customClasses={{ "text-center p-5": "p5" }}
                        p5={
                          <>
                            <div>
                              {t(
                                "GreenTransition.TextSection2.Tooltip1.InfoBox1.TextSection1.text1"
                              )}
                            </div>
                          </>
                        }
                      />
                    </div>
                    <div>
                      <div className="iconInsideTooltip flex justify-center">
                        <GroupTooltipIcon2 width="41px" height="56px" />
                      </div>
                      <TextSection
                        customClasses={{ "text-center p-5": "p5" }}
                        p5={
                          <>
                            <div>
                              {t(
                                "GreenTransition.TextSection2.Tooltip1.InfoBox1.TextSection2.text1"
                              )}
                            </div>
                          </>
                        }
                      />
                    </div>
                    <div>
                      <div className="iconInsideTooltip flex justify-center">
                        <GroupTooltipIcon3 width="79px" height="42px" />
                      </div>
                      <TextSection
                        customClasses={{ "text-center p-5": "p5" }}
                        p5={
                          <>
                            <div>
                              {t(
                                "GreenTransition.TextSection2.Tooltip1.InfoBox1.TextSection3.text1"
                              )}
                            </div>
                          </>
                        }
                      />
                    </div>
                    <div>
                      <div className="iconInsideTooltip flex justify-center">
                        <GroupTooltipIcon4 width="61px" height="63px" />
                      </div>
                      <TextSection
                        customClasses={{ "text-center p-5": "p5" }}
                        p5={
                          <>
                            <div>
                              {t(
                                "GreenTransition.TextSection2.Tooltip1.InfoBox1.TextSection4.text1"
                              )}
                            </div>
                          </>
                        }
                      />
                    </div>
                    <div>
                      <div className="iconInsideTooltip flex justify-center">
                        <GroupTooltipIcon5 width="69px" height="69px" />
                      </div>
                      <TextSection
                        customClasses={{ "text-center p-5": "p5" }}
                        p5={
                          <>
                            <div>
                              {t(
                                "GreenTransition.TextSection2.Tooltip1.InfoBox1.TextSection5.text1"
                              )}
                            </div>
                          </>
                        }
                      />
                    </div>
                    <div>
                      <div className="iconInsideTooltip flex justify-center">
                        <GroupTooltipIcon6 width="59px" height="59px" />
                      </div>
                      <TextSection
                        customClasses={{ "text-center p-5": "p5" }}
                        p5={
                          <>
                            <div>
                              {t(
                                "GreenTransition.TextSection2.Tooltip1.InfoBox1.TextSection6.text1"
                              )}
                            </div>
                          </>
                        }
                      />
                    </div>
                    <div>
                      <div className="iconInsideTooltip flex justify-center">
                        <GroupTooltipIcon7 width="46px" height="67px" />
                      </div>
                      <TextSection
                        customClasses={{ "text-center p-5": "p5" }}
                        p5={
                          <>
                            <div>
                              {t(
                                "GreenTransition.TextSection2.Tooltip1.InfoBox1.TextSection7.text1"
                              )}
                            </div>
                          </>
                        }
                      />
                    </div>
                    <div>
                      <div className="iconInsideTooltip flex justify-center">
                        <GroupTooltipIcon8 width="62px" height="46px" />
                      </div>
                      <TextSection
                        customClasses={{ "text-center p-5": "p5" }}
                        p5={
                          <>
                            <div>
                              {t(
                                "GreenTransition.TextSection2.Tooltip1.InfoBox1.TextSection8.text1"
                              )}
                            </div>
                          </>
                        }
                      />
                    </div>
                  </div>
                </Tooltip>
              </>
            }
            p4={t("GreenTransition.TextSection2.paragraph")}
          />

          <InfoBox classes="pt-16" height="240px" table>
            <TextSection
              customClasses={{
                "font line-height-62-imp text-21px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              h1={t("GreenTransition.InfoBox1.TextSection1.text1")}
              p1={t("GreenTransition.InfoBox1.TextSection1.text2")}
            />
            <TextSection
              customClasses={{
                "font line-height-62-imp text-21px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              h1={t("GreenTransition.InfoBox1.TextSection2.text1")}
              p1={t("GreenTransition.InfoBox1.TextSection2.text2")}
            />
            <TextSection
              customClasses={{
                "font line-height-62-imp text-21px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              h1={t("GreenTransition.InfoBox1.TextSection3.text1")}
              p1={t("GreenTransition.InfoBox1.TextSection3.text2")}
            />
          </InfoBox>
          <TextSection
            classes="pt-16"
            h6={t("GreenTransition.TextSection3.caption")}
          />
          <LottieFilePlayer
            speed={0.9}
            data={
              currentLang === "it"
                ? TransizioneEconomiaSostenibile
                : TransizioneEconomiaSostenibileEN
            }
            classes="overflow-auto w-full"
          />

          <div className={"flex justify-end mt-[72px]"}>
            <ShowMore
              iconPosition={"self-end pb-[7px]"}
              url={t("GreenTransition.TextSection9.url")}
              title={t("GreenTransition.TextSection9.text1")}
              width={432}
              height={121}
              target="_blank"
              icon={
                <a href={t("GreenTransition.TextSection9.url")} target="_blank">
                  <ShowMoreIcon
                    width="21px"
                    height="15px"
                    classes="stroke-green hover:stroke-gray"
                  />
                </a>
              }
            />
          </div>

          <TextSection
            customClasses={{ "flex items-center": "h5" }}
            classes="pt-16"
            h5={
              <>
                <div>{t("GreenTransition.TextSection4.caption")}</div>
                <Tooltip
                  classes="ml-2"
                  tooltipLink={""}
                  icon={<ButtonTooltipIcon width="19px" height="19px" />}
                >
                  <TextSection
                    p5={t(
                      "GreenTransition.TextSection4.Tooltip1.TextSection1.text1"
                    )}
                  />
                </Tooltip>
              </>
            }
          />

          <InfoBox classes="mt-7" height="207px" table size={3} sizes={[1, 2]}>
            <div className="grid grid-cols-1 gap-3 pt-4">
              <TextSection
                customClasses={{
                  "mb-0-imp text-22px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                    "h1",
                }}
                classes="pr-4"
                h1={t("GreenTransition.InfoBox2.TextSection1.text1")}
                p1={t("GreenTransition.InfoBox2.TextSection1.text2")}
              />
            </div>
            <div className="grid grid-cols-1 gap-3 pt-4">
              <TextSection
                customClasses={{
                  "mt-0-imp mb-0-imp text-22px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                    "h1",
                }}
                classes="px-4 pt-4"
                h1={t("GreenTransition.InfoBox2.TextSection2.text1")}
                p1={t("GreenTransition.InfoBox2.TextSection2.text2")}
              />
            </div>
          </InfoBox>

          {t("GreenTransition.TextSection10.text1") && (
            <div className={"flex justify-end mt-[28px]"}>
              <ShowMore
                iconPosition={"self-end pb-[7px]"}
                url={t("GreenTransition.TextSection10.url")}
                title={t("GreenTransition.TextSection10.text1")}
                width={432}
                height={121}
                target="_blank"
                icon={
                  <a
                    href={t("GreenTransition.TextSection10.url")}
                    target="_blank"
                  >
                    <ShowMoreIcon
                      width="21px"
                      height="15px"
                      classes="stroke-green hover:stroke-gray"
                    />
                  </a>
                }
              />
            </div>
          )}

          <TextSection
            classes="pt-16"
            h5={t("GreenTransition.TextSection5.text1")}
          />

          <TextSection
            customClasses={{ "flex items-center": "p1" }}
            classes="mt-4"
            p1={
              <>
                <div>{t("GreenTransition.TextSection7.text1")}</div>
                <Tooltip
                  classes="ml-2"
                  tooltipLink={""}
                  icon={<ButtonTooltipIcon width="19px" height="19px" />}
                >
                  <TextSection
                    p5={t(
                      "GreenTransition.TextSection7.Tooltip1.TextSection1.text1"
                    )}
                  />
                </Tooltip>
              </>
            }
            p4={t("GreenTransition.TextSection7.text2")}
          />

          <TextSection
            classes="pt-16"
            p1={t("GreenTransition.TextSection8.text1")}
            p4={t("GreenTransition.TextSection8.text2")}
          />

          {t("GreenTransition.TextSection11.text1") && (
            <div className={"flex justify-end mt-[60px]"}>
              <ShowMore
                iconPosition={"self-end pb-[7px]"}
                url={t("GreenTransition.TextSection11.url")}
                title={t("GreenTransition.TextSection11.text1")}
                width={432}
                height={121}
                target="_blank"
                icon={
                  <a
                    href={t("GreenTransition.TextSection11.url")}
                    target="_blank"
                  >
                    <ShowMoreIcon
                      width="21px"
                      height="15px"
                      classes="stroke-green hover:stroke-gray"
                    />
                  </a>
                }
              />
            </div>
          )}
          <TextSection
            classes="pt-16"
            p1={t("GreenTransition.TextSection6.caption")}
            p4={t("GreenTransition.TextSection6.paragraph")}
          />

          <InfoBox classes="pt-16 mb-20 height273-infoBox" table>
            <TextSection
              customClasses={{
                "text-22px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
                "font light-imp": "p3",
              }}
              h1={t("GreenTransition.InfoBox3.TextSection1.text1")}
              p1={t("GreenTransition.InfoBox3.TextSection1.text2")}
              p3={t("GreenTransition.InfoBox3.TextSection1.text3")}
            />
            <TextSection
              customClasses={{
                "text-22px-imp xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl":
                  "h1",
              }}
              h1={t("GreenTransition.InfoBox3.TextSection2.text1")}
              p1={t("GreenTransition.InfoBox3.TextSection2.text2")}
            />
          </InfoBox>

          <div className={"flex justify-end mt-[46px] mb-[68px]"}>
            <ShowMore
              iconPosition={"self-end pb-[7px]"}
              url="/pdf/Green Bond Report 2021.pdf"
              title={t("GreenTransition.TextSection12.text1")}
              width={432}
              height={121}
              download
              icon={
                <a href="/pdf/Green Bond Report 2021.pdf" download>
                  <DownloadPdfIcon
                    width="32px"
                    height="29px"
                    classes="stroke-green hover:stroke-gray"
                  />
                </a>
              }
            />
          </div>
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

GreenTransition.propTypes = {};

export default React.memo(GreenTransition);
