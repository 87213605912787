import React from "react";
import PropTypes from "prop-types";

const GroupTooltipIcon4 = ({
  width = "61",
  height = "63",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 61 63`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <g clip-path="url(#clip0_206_1405)">
    <path d="M9.27734 20.4122C13.2695 12.7484 21.286 7.51636 30.5263 7.51636C32.6053 7.51636 34.6201 7.78357 36.544 8.28059" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.4591 52.1414C11.3565 47.9888 6.58398 40.277 6.58398 31.4589C6.58398 28.8348 7.00619 26.3123 7.78646 23.9554" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M51.0059 43.8738C46.8053 50.784 39.2057 55.4015 30.5265 55.4015C27.8811 55.4015 25.3372 54.9739 22.959 54.183" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M44.4648 11.9843C50.5253 16.3292 54.4747 23.4318 54.4747 31.4589C54.4747 34.1311 54.0365 36.7017 53.2295 39.1013" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M31.0556 0.798584L36.9343 8.3501L29.3828 14.2288" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M0.267578 29.4388L7.81909 23.5601L13.6978 31.1116" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M28.6678 61.8039L22.7891 54.2524L30.3406 48.3737" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M60.733 33.6341L53.1868 39.5128L47.3027 31.9613" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.5664 25.687L21.58 27.6911L34.5132 34.1578L43.0428 29.9197L26.315 21.2299L17.5664 25.687Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M38.9714 31.9453V38.5509L30.3083 42.6927L21.7734 38.5509V31.9774" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M43.1131 25.687L39.0995 27.6911L26.1716 34.1578L17.6367 29.9197L34.3644 21.2299L43.1131 25.687Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M30.3711 42.6927V32.1804" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_206_1405">
    <rect width="61" height="61.5398" fill="white" transform="translate(0 0.531372)"/>
    </clipPath>
    </defs>    
</svg>
  );
};

GroupTooltipIcon4.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(GroupTooltipIcon4);

