import React from "react";
import PropTypes from "prop-types";

const GreenEnergy = ({
  width = "56",
  height = "50",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox="0 0 55 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M55.186 0H0.5V50H55.186V0Z" fill="#FBB800"/>
        <path d="M27.8647 28.2403C25.5627 28.2403 23.6959 26.5288 23.6959 24.4285C23.6959 22.7169 24.9282 21.2707 26.63 20.7878V22.2071C25.7461 22.6204 25.1397 23.459 25.1397 24.4285C25.1397 25.8063 26.3622 26.9236 27.8684 26.9236C29.3745 26.9236 30.5971 25.8063 30.5971 24.4285C30.5971 23.4504 29.9858 22.6155 29.1007 22.2035V20.7829C30.8012 21.2634 32.0347 22.712 32.0347 24.4248C32.0347 26.53 30.1692 28.2366 27.8659 28.2366L27.8647 28.2403ZM27.2742 20.0494C27.2742 19.8049 27.542 19.5371 27.8708 19.5371C28.1997 19.5371 28.4601 19.8049 28.4601 20.0494V22.5653C28.4601 22.8098 28.1948 23.0739 27.8672 23.0739C27.5395 23.0739 27.2706 22.8086 27.2706 22.5653L27.2742 20.0494ZM27.8672 17.1545C23.4416 17.1545 19.8535 20.4357 19.8535 24.4823C19.8535 28.5288 23.4416 31.8113 27.8672 31.8113C32.2927 31.8113 35.8808 28.5313 35.8808 24.4823C35.8808 20.4333 32.2866 17.1532 27.8635 17.1532" fill="white"/>
        <path d="M18.6611 24.4175C18.6518 24.2593 18.5842 24.1102 18.4712 23.999C18.3583 23.8879 18.2081 23.8227 18.0498 23.816H14.5363C14.3765 23.816 14.2232 23.8795 14.1101 23.9925C13.9971 24.1055 13.9336 24.2588 13.9336 24.4187C13.9336 24.5785 13.9971 24.7318 14.1101 24.8449C14.2232 24.9579 14.3765 25.0214 14.5363 25.0214H18.0462C18.2047 25.0141 18.3549 24.9483 18.4678 24.8368C18.5807 24.7253 18.6482 24.5759 18.6574 24.4175" fill="white"/>
        <path d="M41.8125 24.4175C41.8032 24.2593 41.7356 24.1102 41.6226 23.999C41.5097 23.8879 41.3595 23.8227 41.2012 23.816H37.6926C37.5327 23.816 37.3794 23.8795 37.2664 23.9925C37.1533 24.1055 37.0898 24.2588 37.0898 24.4187C37.0898 24.5785 37.1533 24.7318 37.2664 24.8449C37.3794 24.9579 37.5327 25.0214 37.6926 25.0214H41.2012C41.3597 25.0141 41.5099 24.9483 41.6228 24.8368C41.7357 24.7253 41.8033 24.5759 41.8125 24.4175Z" fill="white"/>
        <path d="M27.8665 37.1708C28.2332 37.1708 28.5242 36.8762 28.5242 36.6072V33.3993C28.5242 33.1292 28.2283 32.8321 27.8665 32.8321C27.5046 32.8321 27.2051 33.1292 27.2051 33.3993V36.6072C27.2051 36.8762 27.5022 37.1708 27.8665 37.1708Z" fill="white"/>
        <path d="M39.9713 30.7514C40.0105 30.6852 40.0362 30.612 40.047 30.5358C40.0577 30.4597 40.0533 30.3822 40.034 30.3078C40.0147 30.2333 39.9808 30.1635 39.9344 30.1022C39.888 30.0409 39.8299 29.9894 39.7635 29.9506L36.717 28.3613C36.5668 28.2952 36.3982 28.2835 36.2403 28.3281C36.0823 28.3727 35.9449 28.4709 35.8514 28.6058C35.8119 28.6723 35.7861 28.746 35.7753 28.8226C35.7645 28.8992 35.769 28.9771 35.7885 29.052C35.8081 29.1268 35.8423 29.197 35.8892 29.2585C35.9361 29.32 35.9947 29.3716 36.0617 29.4103L39.1082 30.9995C39.2582 31.0648 39.4261 31.076 39.5835 31.0315C39.7408 30.9869 39.8779 30.8892 39.9713 30.755" fill="white"/>
        <path d="M20.9375 35.4874C21.2529 35.6525 21.671 35.5302 21.8141 35.2967L23.5574 32.5118C23.5924 32.4427 23.6134 32.3673 23.6191 32.29C23.6249 32.2127 23.6152 32.1351 23.5908 32.0615C23.5664 31.988 23.5276 31.92 23.4768 31.8615C23.4259 31.8031 23.364 31.7552 23.2945 31.7208C22.9791 31.5558 22.561 31.6793 22.4143 31.914L20.6722 34.6989C20.5255 34.9312 20.6233 35.3224 20.9387 35.4874" fill="white"/>
        <path d="M34.8194 35.4727C35.1348 35.3077 35.2289 34.9177 35.0834 34.6854L33.3328 31.9079C33.1848 31.6731 32.768 31.5509 32.4538 31.7159C32.1396 31.881 32.043 32.2746 32.1909 32.5093L33.9404 35.2906C34.0871 35.5241 34.5039 35.6439 34.8194 35.4776" fill="white"/>
        <path d="M15.7729 30.777C15.8657 30.9099 16.0015 31.0068 16.1573 31.0513C16.3132 31.0958 16.4796 31.0853 16.6286 31.0215L19.6715 29.4225C19.7385 29.3838 19.7971 29.3322 19.844 29.2707C19.8909 29.2092 19.9251 29.139 19.9447 29.0642C19.9642 28.9894 19.9687 28.9114 19.9579 28.8348C19.9471 28.7582 19.9212 28.6846 19.8818 28.6181C19.7878 28.4834 19.6501 28.3855 19.4921 28.3409C19.3341 28.2963 19.1655 28.3079 19.015 28.3736L15.9721 29.9738C15.9056 30.0129 15.8474 30.0648 15.801 30.1265C15.7546 30.1882 15.7209 30.2584 15.7018 30.3332C15.6827 30.408 15.6786 30.4859 15.6897 30.5623C15.7009 30.6386 15.7271 30.712 15.7668 30.7783" fill="white"/>
        <path d="M27.8665 16.0053C28.2332 16.0053 28.5242 15.7107 28.5242 15.4417V12.2326C28.5242 11.9624 28.2283 11.6653 27.8665 11.6653C27.5046 11.6653 27.2051 11.9624 27.2051 12.2326V15.4417C27.2051 15.7107 27.5022 16.0053 27.8665 16.0053Z" fill="white"/>
        <path d="M19.8784 20.2377C19.9179 20.1716 19.9439 20.0983 19.9548 20.0221C19.9657 19.9459 19.9614 19.8683 19.9421 19.7938C19.9227 19.7193 19.8888 19.6493 19.8422 19.5881C19.7956 19.5268 19.7372 19.4754 19.6706 19.4369L16.624 17.8476C16.474 17.7818 16.3057 17.7703 16.148 17.8149C15.9904 17.8595 15.8531 17.9575 15.7597 18.0921C15.7202 18.1587 15.6943 18.2325 15.6835 18.3092C15.6727 18.3858 15.6772 18.4639 15.6967 18.5388C15.7163 18.6138 15.7505 18.6841 15.7974 18.7457C15.8443 18.8073 15.903 18.859 15.97 18.8978L19.0165 20.487C19.1655 20.5508 19.332 20.5613 19.4878 20.5168C19.6437 20.4723 19.7795 20.3754 19.8723 20.2425" fill="white"/>
        <path d="M32.438 17.1153C32.7522 17.2804 33.1715 17.1581 33.3133 16.9259L35.0566 14.1409C35.0919 14.0718 35.1131 13.9964 35.119 13.9191C35.1249 13.8417 35.1154 13.7639 35.0911 13.6903C35.0668 13.6166 35.0281 13.5485 34.9773 13.4899C34.9264 13.4312 34.8645 13.3833 34.795 13.3488C34.4796 13.1837 34.0615 13.3072 33.9136 13.5419L32.1702 16.3268C32.0235 16.5603 32.1213 16.9503 32.438 17.1153Z" fill="white"/>
        <path d="M23.2781 17.1239C23.5935 16.9589 23.6877 16.5689 23.541 16.3366L21.7915 13.5541C21.6497 13.3255 21.2279 13.2032 20.9162 13.3622C20.6044 13.5211 20.5054 13.9209 20.6533 14.1556L22.4028 16.9368C22.5495 17.1691 22.9651 17.2902 23.2818 17.1239" fill="white"/>
        <path d="M35.8435 20.2254C35.936 20.3587 36.0718 20.4558 36.2277 20.5004C36.3837 20.5449 36.5503 20.5342 36.6992 20.4699L39.7433 18.866C39.8104 18.8274 39.8692 18.7759 39.9162 18.7144C39.9632 18.6529 39.9974 18.5827 40.017 18.5078C40.0366 18.433 40.0411 18.355 40.0302 18.2783C40.0193 18.2017 39.9933 18.128 39.9536 18.0616C39.86 17.9266 39.7223 17.8283 39.5641 17.7837C39.406 17.7391 39.2372 17.7509 39.0868 17.8171L36.044 19.4161C35.9774 19.4552 35.9192 19.5071 35.8728 19.5688C35.8264 19.6305 35.7928 19.7008 35.7738 19.7757C35.7548 19.8505 35.7508 19.9284 35.7622 20.0047C35.7735 20.0811 35.7999 20.1545 35.8398 20.2206" fill="white"/>

    </svg>
  );
};

GreenEnergy.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};

export default React.memo(GreenEnergy);
