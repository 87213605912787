import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { CloseIcon, LinkIcon } from "../../assets/icons";
import {
  Retry,
  iFrameResizerListener,
  autoResizerRegistrar,
} from "../../utils";
import configuration from "../../configuration.json";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.55)",
    zIndex: 90,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#232323",
    maxWidth: "70%",
    border: "0px",
    maxHeight: "800px",
    overflow: "clip",
    transitionProperty: "top",
    transitionDuration: ".5s",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const Tooltip = forwardRef((props, ref) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [maxHeight, setMaxHeight] = React.useState(800);
  const [styles, setStyles] = useState(customStyles);

  const setModalPosition = (sizes) => {
    const { elements, members } = sizes;
    const ispHeader = elements["page-header"]?.[0];
    const ispBreadcrumb = elements["header-main"]?.[0];

    const offset = members.visualViewport?.height / 2; //- (maxHeight) / 2;
    const position =
      members.visualViewport?.height +
      members?.scrollY +
      2 -
      (ispHeader?.clientHeight + ispBreadcrumb?.clientHeight) -
      offset;

    if (isNaN(position)) {
      setStyles(customStyles);
    } else {
      setStyles({
        ...customStyles,
        content: { ...customStyles.content, top: position },
      });
    }
  };

  const configureModal = (parentSizes) => {
    updateTooltipHeight();
    setModalPosition(parentSizes);
  };

  function openModal() {
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        retry.start({
          condition: () => window.autoResizer,
          onSuccess: () => {
            window.autoResizer.addOnParentSizesResponse(configureModal);
            window.autoResizer.requestParentSizes({
              byClassNames: ["page-header", "header-main"],
              byMemberNames: ["visualViewport", "scrollY"],
            });
          },
          onFailure: () =>
            console.debug("autoResizer not found in Menu Tooltip index.jsx:82"),
        });
        break;

      case configuration.menu.positioning.algorithms.Infer:
        iFrameResizerListener.listen();
        break;
    }
    setIsOpen(true);
  }

  const updateTooltipHeight = () => {
    // We execute the same script as before
    const maxHeight = window.screen.availHeight * 0.7;
    setMaxHeight(maxHeight);
    setStyles({
      ...customStyles,
      content: { ...customStyles.content, maxHeight },
    });
  };

  const retry = new Retry("Tooltip", true);

  useEffect(() => {
    window.autoResizerTooltipTimeout = [];
    window.addEventListener("resize", updateTooltipHeight);
    updateTooltipHeight();
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        retry.register();
        autoResizerRegistrar.register(updateTooltipHeight);
        return () => {
          retry.unregister();
          autoResizerRegistrar.unregister(updateTooltipHeight);
        };

      default:
        iFrameResizerListener.register(retry.name, configureModal);
        return () => {
          iFrameResizerListener.unregister(retry.name);
        };
    }
  }, []);

  useImperativeHandle(ref, () => ({
    // Allow parent to open tooltip modal
    openModal() {
      openModal();
    },
  }));

  function closeModal() {
    setIsOpen(false);
  }

  let container;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    container.style.maxHeight = maxHeight + "px";
  }

  const Tag = props.tag || "div";
  return (
    <Tag
      style={{
        marginTop: "0.375rem",
      }}
      className={props.classes || ""}
    >
      {!props.hideButton && <button onClick={openModal}>{props.icon}</button>}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={styles}
        contentLabel="Example Modal"
        onAfterOpen={afterOpenModal}
      >
        <div ref={(_) => (container = _)}>
          <div className="textPadding">{props.children}</div>
          <button onClick={closeModal} className="closeButton">
            <CloseIcon color="white" width="22px" height="22px" />
          </button>
          {props.tooltipLink && (
            <a className="linkButton" href={props.tooltipLink} target="_blank">
              <LinkIcon width="23px" height="24px" />
            </a>
          )}
        </div>
      </Modal>
    </Tag>
  );
});

Tooltip.propTypes = {
  classes: PropTypes.string,
};

export default React.memo(Tooltip);
