import React, { useCallback, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  menuItems,
  autoResizerRegistrar,
  Retry,
  iFrameResizerListener,
} from "../../utils";
import MenuItem from "../MenuItem";
import { ExpandIcon, CollapseIcon } from "../../assets/icons";
import configuration from "./../../configuration.json";

const Menu = () => {
  const ref = useRef(true);
  const { t, ready } = useTranslation();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [sizes, setSizes] = useState({ elements: {}, members: {} });
  const [desktopPosition, setDesktopPosition] = useState(0);
  const [mobilePosition, setMobilePosition] = useState(0);
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);

  useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);

  const showMobileMenu = useCallback(() => {
    if (ref.current) {
      const collapsedMenu = document.getElementById("mobile-collapsed-menu");
      collapsedMenu.classList.toggle("hidden");
      const collapsed = !isCollapsed;
      setIsCollapsed(collapsed);
    }
  }, [isCollapsed]);

  const setMenuPosition = (
    elementId,
    sizes,
    menuHeight,
    navigationItemsHeight
  ) => {
    const { elements, members } = sizes;
    const ispHeader = elements["page-header"]?.[0];
    const ispBreadcrumb = elements["header-main"]?.[0];

    const menuPosition =
      members.visualViewport?.height -
      (ispHeader?.clientHeight + ispBreadcrumb?.clientHeight + menuHeight) +
      members?.scrollY +
      2 -
      navigationItemsHeight;

    if (isNaN(menuPosition)) return;

    const isDesktop = elementId === "desktop-menu";
    const previousPosition = isDesktop ? desktopPosition : mobilePosition;
    if (ref.current) {
      if (previousPosition !== menuPosition) {
        if (isDesktop) setDesktopPosition(menuPosition);
        else setMobilePosition(menuPosition);
        const menu = document.getElementById(elementId);
        const footer = document.getElementsByTagName("footer")?.[0];
        if (menu && footer) {
          //const threshold = window.visualViewport?.height - menuHeight;
          const threshold =
            footer.getBoundingClientRect().top -
            menuHeight -
            navigationItemsHeight;
          if (menuPosition >= threshold) {
            //menu.style.display = "none";
            menu.style.position = "fixed";
            menu.style.top = threshold + "px";
          } else {
            //menu.style.display = "block";
            menu.style.position = "fixed";
            menu.style.top = menuPosition + "px";
          }
          menu.style.maxHeight = menuHeight + navigationItemsHeight + "px";
        }
      }
    }
  };

  useEffect(() => {
    const desktopMenuHeight = 55;
    const desktopNavigationItemsHeight = 29;
    setMenuPosition(
      "desktop-menu",
      sizes,
      desktopMenuHeight,
      desktopNavigationItemsHeight
    );

    const mobileMenuHeight = 48;
    const collaspedMobileNavigationItemsHeight = isCollapsed
      ? 0
      : 33 * menuItems.length;
    setMenuPosition(
      "mobile-menu",
      sizes,
      mobileMenuHeight,
      collaspedMobileNavigationItemsHeight
    );
  }, [isCollapsed, sizes]);

  const onDocumentLoaded = () => {
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        retry.start({
          condition: () => window.autoResizer,
          onSuccess: () => {
            if (ref.current) {
              window.autoResizer.setOnParentScrollRequest({
                byClassNames: ["page-header", "header-main"],
                byMemberNames: ["visualViewport", "scrollY"],
              });
              window.autoResizer.addOnParentSizesResponse(setSizes);
            }
          },
          onRetry: onDocumentLoaded,
          onFailure: () => {
            console.debug("autoResizer not found in Menu index.jsx:115");
          },
        });
        break;

      case configuration.menu.positioning.algorithms.Infer:
        iFrameResizerListener.listen();
        break;
    }
  };

  const retry = new Retry("Menu");

  // Register BEFORE document.load:
  iFrameResizerListener.register(retry.name, setSizes);

  useEffect(() => {
    console.info("configuration version " + configuration.version);
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        retry.register();
        autoResizerRegistrar.register(onDocumentLoaded);
        return () => {
          retry.unregister();
          autoResizerRegistrar.unregister(onDocumentLoaded);
        };

      default:
        return () => {
          // NEVER un-register Menu! MUST be always positioned!
          // iFrameResizerListener.unregister(retry.name);
        };
    }
  }, []);

  useEffect(() => {
    switch (configuration.menu.positioning.algorithm) {
      case configuration.menu.positioning.algorithms.AutoResizer:
        retry.register();
        autoResizerRegistrar.register(onDocumentLoaded);
        return () => {
          retry.unregister();
          autoResizerRegistrar.unregister(onDocumentLoaded);
        };

      default:
        iFrameResizerListener.listen();
        return () => {
          // NEVER un-register Menu! MUST be always positioned!
          // iFrameResizerListener.unregister(retry.name);
        };
    }
  }, [location]);

  const onClick = (e) => {
    setTimeout(() => {
      switch (configuration.menu.positioning.algorithm) {
        case configuration.menu.positioning.algorithms.AutoResizer:
          if (window.autoResizer) {
            window.autoResizer.onChildResized(e);
          } else {
            console.debug("autoResizer not found in Menu index.jsx:105");
          }
          break;

        default:
          iFrameResizerListener.scrollParentToTop();
          break;
      }
    }, 500);
  };

  return (
    ready && (
      <>
        <div
          className="bg-modal"
          style={{
            position: "absolute",
            height: isCollapsed ? 0 : mobilePosition,
          }}
        ></div>
        <nav
          id="desktop-menu"
          className="fixed w-full hidden md:block bottom-0 z-40 md:z-50"
          style={{
            transitionProperty: "top",
            transitionDuration: "0.5s",
          }}
        >
          <div
            className={`bg-white h-[23px] border-t border-light flex color-green justify-center ${
              hoveredMenuItem ? "visible" : "invisible"
            }`}
          >
            {menuItems.map((menuItem, i) => (
              <div
                key={menuItem.title}
                className={`text-green text-xs w-14 relative ${
                  menuItem.id !== hoveredMenuItem ? "invisible" : "visible" //"visible w-full text-center"
                }`}
              >
                <div
                  className="w-max mt-1.5 absolute"
                  style={i > 6 ? { right: 0 } : {}}
                >
                  {t(menuItem.title)}
                </div>
              </div>
            ))}
          </div>
          <div className="h-[6px] hidden md:block bg-white"></div>
          <div className="hidden md:flex items-center justify-center bg-green h-14">
            {menuItems.map((item) => (
              <Link
                key={item.id}
                className="menu-link flex items-center justify-end"
                to={t(item.url)}
                onMouseEnter={() => setHoveredMenuItem(item.id)}
                onMouseLeave={() => setHoveredMenuItem(null)}
                onClick={onClick}
              >
                <MenuItem
                  classes="justify-center w-full"
                  title={t(item.title)}
                  Icon={item.icon}
                  isActive={location.pathname === t(item.url)}
                />
              </Link>
            ))}
          </div>
        </nav>

        <nav
          id="mobile-menu"
          className="fixed w-full bottom-0 block md:hidden z-50 md:z-40"
          style={{
            transitionProperty: "top",
            transitionDuration: "0.5s",
            maxHeight: "48px",
          }}
        >
          <div
            id="mobile-collapsed-menu"
            className="collapse w-full hidden md:hidden bg-green"
          >
            {menuItems.map((item) => (
              <Link
                key={item.id}
                className={`flex space-x-4 ${
                  location.pathname === t(item.url) && "bg-gray"
                }`}
                to={t(item.url)}
                onClick={onClick}
              >
                <MenuItem
                  classes={`w-full justify-end items-center space-x-4`}
                  Icon={item.icon}
                  iconProps={item.iconProps}
                  title={t(item.title)}
                  isActive={location.pathname === t(item.url)}
                  isMobile
                />
              </Link>
            ))}
          </div>
          <div
            className="navbar navbar-expand-lg block md:hidden h-12 p-2 bg-green relative flex items-center w-full justify-end z-[120]"
            data-bs-toggle="collapse"
            data-bs-target="#mobileMenu"
            aria-expanded="false"
            aria-controls="mobileMenu"
            onClick={showMobileMenu}
          >
            <div className="flex space-x-4 items-center mr-2.5">
              <p className="text-white">{t("Generic.Menu.label")}</p>
              {isCollapsed ? (
                <CollapseIcon
                  classes="stroke-white stroke-2"
                  width="22px"
                  height="13px"
                />
              ) : (
                <ExpandIcon
                  classes="stroke-white stroke-2"
                  width="22px"
                  height="12px"
                />
              )}
            </div>
          </div>
        </nav>
      </>
    )
  );
};

Menu.propTypes = {};

export default React.memo(Menu);
