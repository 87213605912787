import React from "react";
import PropTypes from "prop-types";

const SatisfactionIcon = ({
  width = "33",
  height = "38",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 33 38`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_707_1633)">
        <path
          d="M21.0419 9.87378L14.6655 16.4397C14.6655 16.4397 11.4976 13.2312 11.4164 13.1906"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.3957 20.5958L0.856873 31.3179L7.32801 31.4804L10.4282 37.1528L16.5067 27.1888"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.476 23.872L16.5066 27.1888"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3469 20.1762L32.143 31.3179L25.6854 31.4804L22.5717 37.1528L16.5067 27.1888"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4761 23.872L12.6485 24.8197L10.523 21.0697L7.39575 20.5958"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.476 23.872L16.466 22.8567L18.5644 23.8043"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.347 20.1762L22.3281 20.7854L20.3921 24.6302L18.5645 23.8043"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.347 20.1762L26.5383 19.9325L25.8479 15.6816L28.745 12.5137L25.699 9.4812L26.1863 5.21674L21.9354 4.55338L19.8099 0.81691L15.9922 2.77991L12.0798 1.00644L10.1438 4.83768L5.92 5.70411L6.61044 9.94149L3.71332 13.1229L6.75936 16.1419L6.28553 20.4199L7.39564 20.5959"

          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

SatisfactionIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(SatisfactionIcon);
