import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextSection from "../../components/TextSection";
import Tooltip from "../../components/Tooltip";
import { SolidityIcon, ButtonTooltipIcon } from "../../assets/icons";
import ContainerWrapper from "../../components/ContainerWrapper";
import Menu from "../../components/Menu";
import InfoBox from "../../components/InfoBox";
import LottieFilePlayer from "../../components/LottieFilePlayer";
import BarChart from "../../components/BarCharts/BarChart";
import RipartizioneValoreEconomicoGenerato from "../../assets/lotties/ripartizione_valore_economico_generato.json";
import RipartizioneValoreEconomicoGeneratoEN from "../../assets/lotties/ripartizione_valore_economico_generato_EN.json";
import AreaSquares from "../../components/AreaSquares";
import { areas } from "../../utils";
import i18next from "i18next";
const GroupValue = () => {
  const { t, ready } = useTranslation();
  const currentLang = i18next.language;
  return (
    !!ready && (
      <>
        <ContainerWrapper>
          <AreaSquares
            areas={areas["group-value"].areas}
            description={areas["group-value"].description}
          />

          <TextSection
            customClasses={{ "mb-[12px] font line-height-h1-40": "h3" }}
            h3={t("GroupValue.TextSection1.title")}
            p4={t("GroupValue.TextSection1.paragraph")}
          />

          <div className="grid lg:hidden grid-cols-2 gap-3 mt-4 h-auto">
            <div className="flex px-4 border-r border-light flex-col">
              <TextSection
                classes="py-6"
                customClasses={{
                  "uppercase font text-sm lg:line-height-22 bold": "p3",
                }}
                p3={t("GroupValue.InfoBox1.Column1.TextSection1.text1")}
              />
              <TextSection
                customClasses={{ "font light line-height-22": "p4" }}
                classes="py-4"
                p4={t("GroupValue.InfoBox1.Column1.TextSection2.text1")}
              />
              <TextSection
                customClasses={{ "font light line-height-22": "p4" }}
                classes="py-4"
                p4={t("GroupValue.InfoBox1.Column1.TextSection3.text1")}
              />
              <TextSection
                customClasses={{ "font light line-height-22": "p4" }}
                classes="py-4"
                p4={t("GroupValue.InfoBox1.Column1.TextSection4.text1")}
              />
              <TextSection
                customClasses={{ "font light line-height-22": "p4" }}
                classes="py-4"
                p4={t("GroupValue.InfoBox1.Column1.TextSection5.text1")}
              />
              <TextSection
                customClasses={{ "font light line-height-22": "p4" }}
                classes="py-4"
                p4={t("GroupValue.InfoBox1.Column1.TextSection6.text1")}
              />
            </div>
            <div className="flex flex-row overflow-x-scroll">
              <div className="w-max flex grid-cols-auto">
                <div className="flex flex-col px-4 border-r border-light w-max">
                  <TextSection
                    classes="py-6"
                    customClasses={{
                      "uppercase font text-sm lg:line-height-22 bold": "p3",
                    }}
                    p3={t("GroupValue.InfoBox1.Column2.TextSection1.text1")}
                  />
                  <TextSection
                    customClasses={{ "font light line-height-22": "p4" }}
                    classes="py-4"
                    p4={t("GroupValue.InfoBox1.Column2.TextSection2.text1")}
                  />
                  <TextSection
                    customClasses={{ "font light line-height-22": "p4" }}
                    classes="py-4"
                    p4={t("GroupValue.InfoBox1.Column2.TextSection3.text1")}
                  />
                  <TextSection
                    customClasses={{ "font light line-height-22": "p4" }}
                    classes="py-4"
                    p4={t("GroupValue.InfoBox1.Column2.TextSection4.text1")}
                  />
                  <TextSection
                    customClasses={{ "font light line-height-22": "p4" }}
                    classes="py-4"
                    p4={t("GroupValue.InfoBox1.Column2.TextSection5.text1")}
                  />
                  <TextSection
                    customClasses={{ "font light line-height-22": "p4" }}
                    classes="py-4"
                    p4={t("GroupValue.InfoBox1.Column2.TextSection6.text1")}
                  />
                </div>
                <div className="flex flex-col px-4 border-r border-light w-max">
                  <TextSection
                    classes="py-6"
                    customClasses={{
                      "uppercase font text-sm lg:line-height-22 bold": "p3",
                    }}
                    p3={t("GroupValue.InfoBox1.Column3.TextSection1.text1")}
                  />
                  <div className="py-4">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock1.text1")}
                    </span>
                    <br />
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock1.text2")}
                    </span>
                  </div>
                  <div className="py-4">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock2.text1")}
                    </span>
                    <br />
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock2.text2")}
                    </span>
                  </div>
                  <div className="py-4">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock3.text1")}
                    </span>
                    <br />
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock3.text2")}
                    </span>
                  </div>
                  <div className="py-4">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock4.text1")}
                    </span>
                    <br />
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock4.text2")}
                    </span>
                  </div>
                  <div className="py-4">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock5.text1")}
                    </span>
                    <br />
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock5.text2")}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col px-4 border-r border-light w-max">
                  <TextSection
                    classes="py-6"
                    customClasses={{
                      "uppercase font text-sm lg:line-height-22 bold": "p3",
                    }}
                    p3={t("GroupValue.InfoBox1.Column4.TextSection1.text1")}
                  />
                  <div className="py-4">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text1")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text2")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text3")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text4")}
                    </span>
                    <br />
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text5")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text6")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text7")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text8")}
                    </span>
                  </div>
                  <div className="py-4">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock2.text1")}
                    </span>
                  </div>
                  <div className="py-4">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock3.text1")}
                    </span>
                  </div>
                  <div className="py-4">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock4.text1")}
                    </span>
                  </div>
                  <div className="py-4">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock5.text1")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <InfoBox
            classes="my-16 hidden lg:grid"
            height="615px"
            table
            border={false}
          >
            <div className="grid grid-cols-4 gap-3 md:gap-5 lg:gap-7">
              <div className="col-span-1 grid grid-cols-1 border-r-2 border-light mr-3 md:mr-4 lg:mr-7">
                <TextSection
                  classes="h-28"
                  customClasses={{ "uppercase font line-height-22 bold": "p3" }}
                  p3={t("GroupValue.InfoBox1.Column1.TextSection1.text1")}
                />
                <TextSection
                  customClasses={{ "font light line-height-22": "p4" }}
                  classes="h-40 mr-3 md:mr-4 lg:mr-7"
                  p4={t("GroupValue.InfoBox1.Column1.TextSection2.text1")}
                />
                <TextSection
                  customClasses={{ "font light line-height-22": "p4" }}
                  classes="h-24 mr-3 md:mr-4 lg:mr-7"
                  p4={t("GroupValue.InfoBox1.Column1.TextSection3.text1")}
                />
                <TextSection
                  customClasses={{ "font light line-height-22": "p4" }}
                  classes="h-24 mr-3 md:mr-4 lg:mr-7"
                  p4={t("GroupValue.InfoBox1.Column1.TextSection4.text1")}
                />
                <TextSection
                  customClasses={{ "font light line-height-22": "p4" }}
                  classes="h-24 mr-3 md:mr-4 lg:mr-7"
                  p4={t("GroupValue.InfoBox1.Column1.TextSection5.text1")}
                />
                <TextSection
                  customClasses={{ "font light line-height-22": "p4" }}
                  classes="h-24 mr-3 md:mr-4 lg:mr-7"
                  p4={t("GroupValue.InfoBox1.Column1.TextSection6.text1")}
                />
              </div>
              <div className="col-span-3 grid grid-cols-3 gap-3 md:gap-5 gap-7 overflow-x-scroll lg:overflow-x-hidden w-[800px]">
                <div className="grid grid-cols-1 border-r-2 border-light mr-3 md:mr-4 lg:mr-7">
                  <TextSection
                    classes="h-28"
                    customClasses={{
                      "uppercase font line-height-22 bold": "p3",
                    }}
                    p3={t("GroupValue.InfoBox1.Column2.TextSection1.text1")}
                  />
                  <TextSection
                    customClasses={{ "font light line-height-22": "p4" }}
                    classes="h-40 mr-3 md:mr-4 lg:mr-7"
                    p4={t("GroupValue.InfoBox1.Column2.TextSection2.text1")}
                  />
                  <TextSection
                    customClasses={{ "font light line-height-22": "p4" }}
                    classes="h-24 mr-3 md:mr-4 lg:mr-7"
                    p4={t("GroupValue.InfoBox1.Column2.TextSection3.text1")}
                  />
                  <TextSection
                    customClasses={{ "font light line-height-22": "p4" }}
                    classes="h-24 mr-3 md:mr-4 lg:mr-7"
                    p4={t("GroupValue.InfoBox1.Column2.TextSection4.text1")}
                  />
                  <TextSection
                    customClasses={{ "font light line-height-22": "p4" }}
                    classes="h-24 mr-3 md:mr-4 lg:mr-7"
                    p4={t("GroupValue.InfoBox1.Column2.TextSection5.text1")}
                  />
                  <TextSection
                    customClasses={{ "font light line-height-22": "p4" }}
                    classes="h-24 mr-3 md:mr-4 lg:mr-7"
                    p4={t("GroupValue.InfoBox1.Column2.TextSection6.text1")}
                  />
                </div>
                <div className="grid grid-cols-1 border-r-2 border-light mr-3 md:mr-4 lg:mr-7">
                  <TextSection
                    classes="h-28"
                    customClasses={{
                      "uppercase font line-height-22 bold": "p3",
                    }}
                    p3={t("GroupValue.InfoBox1.Column3.TextSection1.text1")}
                  />
                  <div className="h-40 mr-3 md:mr-4 lg:mr-7">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock1.text1")}
                    </span>
                    <br />
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock1.text2")}
                    </span>
                  </div>
                  <div className="h-24 mr-3 md:mr-4 lg:mr-7">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock2.text1")}
                    </span>
                    <br />
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock2.text2")}
                    </span>
                  </div>
                  <div className="h-24 mr-3 md:mr-4 lg:mr-7">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock3.text1")}
                    </span>
                    <br />
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock3.text2")}
                    </span>
                  </div>
                  <div className="h-24 mr-3 md:mr-4 lg:mr-7">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock4.text1")}
                    </span>
                    <br />
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock4.text2")}
                    </span>
                  </div>
                  <div className="h-24 mr-3 md:mr-4 lg:mr-7">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock5.text1")}
                    </span>
                    <br />
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column3.TextBlock5.text2")}
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-1">
                  <TextSection
                    classes="h-28"
                    customClasses={{
                      "uppercase font line-height-22 bold": "p3",
                    }}
                    p3={t("GroupValue.InfoBox1.Column4.TextSection1.text1")}
                  />
                  <div className="h-40 mr-3 md:mr-4 lg:mr-7">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text1")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text2")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text3")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text4")}
                    </span>
                    <br />
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text5")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text6")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text7")}
                    </span>
                    &nbsp;
                    <span className="font light line-height-22 text-base text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock1.text8")}
                    </span>
                  </div>
                  <div className="h-24 mr-3 md:mr-4 lg:mr-7">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock2.text1")}
                    </span>
                  </div>
                  <div className="h-24 mr-3 md:mr-4 lg:mr-7">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock3.text1")}
                    </span>
                  </div>
                  <div className="h-24 mr-3 md:mr-4 lg:mr-7">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock4.text1")}
                    </span>
                  </div>
                  <div className="h-24 mr-3 md:mr-4 lg:mr-7">
                    <span className="font light line-height-30 text-size22 text-gray">
                      {t("GroupValue.InfoBox1.Column4.TextBlock5.text1")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </InfoBox>

          <TextSection
            customClasses={{ "mb-3": "h5" }}
            classes="pt-16 mb-3.5"
            h5={t("GroupValue.TextSection2.caption")}
            p4={t("GroupValue.TextSection2.paragraph")}
          />

          <BarChart
            classes="mt-12"
            right={({ classes }) => (
              <div className={`d-flex align-end ${classes || ""}`}>
                <div className="text bold text-size22 text-black pl-10">
                  &euro; {currentLang === "it" ? "mld" : "bn"}
                </div>
                <Tooltip
                  classes="ml-2 mb-2.5"
                  icon={<ButtonTooltipIcon width="19px" height="19px" />}
                >
                  <TextSection
                    p5={t(
                      "GroupValue.TextSection2.Tooltip1.TextSection1.text1"
                    )}
                  />
                  <TextSection
                    p5={t(
                      "GroupValue.TextSection2.Tooltip1.TextSection1.text2"
                    )}
                  />
                </Tooltip>
              </div>
            )}
            options={{
              width: "100%",
              height: "300px",
              columns: {
                height: "212px",
                justifyContent: "center",
              },
              labels: {
                justifyContent: "center",
              },
            }}
            data={[
              {
                topLabel: "3.5**",
                label: "2020*",
                height: 152,
                color: "#29851B",
              },
              {
                topLabel: "4.2",
                label: "2021",
                height: 173,
                color: "#2B7021",
              },
            ]}
          />

          <TextSection
            customClasses={{ "flex items-center mb-2.5": "h5" }}
            classes="pt-16"
            h5={
              <>
                <div>{t("GroupValue.TextSection3.caption")}</div>
                <Tooltip
                  classes="ml-2"
                  tooltipLink={""}
                  icon={<ButtonTooltipIcon width="19px" height="19px" />}
                >
                  <TextSection
                    p5={t(
                      "GroupValue.TextSection3.Tooltip1.TextSection1.text1"
                    )}
                  />
                  <TextSection
                    p5={t(
                      "GroupValue.TextSection3.Tooltip1.TextSection1.text2"
                    )}
                  />
                </Tooltip>
              </>
            }
            p4={t("GroupValue.TextSection3.paragraph")}
          />

          <LottieFilePlayer
            speed={0.9}
            data={
              currentLang === "it"
                ? RipartizioneValoreEconomicoGenerato
                : RipartizioneValoreEconomicoGeneratoEN
            }
            lottieStyles={{ transform: "translateX(-15%)" }}
            classes="px-0 overflow-auto mt-12 mb-24 w-full"
          />
        </ContainerWrapper>
        <Menu />
      </>
    )
  );
};

GroupValue.propTypes = {};

export default React.memo(GroupValue);
