import React from "react";
import PropTypes from "prop-types";

const GroupTooltipIcon8 = ({
  width = "62",
  height = "46",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox={`0 0 62 46`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <g clip-path="url(#clip0_206_1445)">
    <path d="M18.1406 7.1613C18.1406 7.1613 33.0462 -1.02652 44.1043 0.365491C55.1624 1.7575 55.2445 12.7663 53.9305 17.0491C52.6165 21.3319 47.377 26.4934 40.9097 25.5901C34.4424 24.6867 27.6424 15.189 27.6424 15.189C27.6424 15.189 21.7459 8.31105 18.1406 7.1613V7.1613Z" 
      stroke="white" 
      strokeLinecap="round" 
      strokeLinejoin="round"/>
    <path d="M41.8105 12.951L61.7956 20.5188" 
      stroke="white" 
      strokeLinecap="round" 
      strokeLinejoin="round"/>
    <path d="M0.205078 19.365C0.205078 19.365 4.05262 19.0365 6.0195 15.2711C6.0195 15.2711 9.1115 8.18784 18.1411 11.4235C18.1411 11.4235 26.0045 17.3201 29.1992 23.0524C29.1992 23.0524 34.7672 31.9793 48.1166 29.0311C48.1166 29.0311 40.0889 46.15 21.6642 44.7538C21.6642 44.7538 7.49364 44.1009 4.3031 28.0045L3.56398 23.1304C3.56398 23.1304 4.3031 20.4285 0.205078 19.365V19.365Z" 
      stroke="white" 
      strokeLinecap="round" 
      strokeLinejoin="round"/>
    <path d="M11.5351 20.6543C12.5306 20.6543 13.3377 19.8473 13.3377 18.8517C13.3377 17.8561 12.5306 17.0491 11.5351 17.0491C10.5395 17.0491 9.73242 17.8561 9.73242 18.8517C9.73242 19.8473 10.5395 20.6543 11.5351 20.6543Z" 
      stroke="white" 
      strokeLinecap="round" 
      strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_206_1445">
    <rect width="62" height="45.0413" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
};

GroupTooltipIcon8.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};
export default React.memo(GroupTooltipIcon8);

