import React from "react";
import PropTypes from "prop-types";

const Istruzione = ({
  width = "55",
  height = "50",
  classes = "stroke-white",
}) => {
  return (
    <svg
      className={classes}
      style={{ width, height }}
      viewBox="0 0 55 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M54.5962 0.0805511H0.157227V49.9988H54.5962V0.0805511Z" fill="#C11728"/>
        <path d="M24.2982 19.7871L14.5342 14.617V31.3406L24.2982 34.0416V19.7871Z" fill="white"/>
        <path d="M25.3711 19.8213V34.0478L35.1984 31.3382V14.6182L25.3711 19.8213Z" fill="white"/>
        <path d="M40.839 31.044H40.5518L41.7079 34.7459L42.8616 31.044H42.5744H42.8616V16.1658H42.5744H42.2884H41.9842H41.4097H41.1262H40.839H40.5518V31.044H40.839Z" fill="white"/>
        <path d="M37.2953 15.3359L36.2487 14.5779V31.7934L25.4003 35.0083H24.8977H24.7991H24.2977L13.4968 32.2414V14.5779L12.4502 15.3359V32.9151L24.2478 35.909H24.7991H24.8977H25.449L36.3339 32.7406L37.2953 32.4818V15.3359Z" fill="white"/>
        <path d="M42.8628 14.1947C42.8628 13.6088 42.3444 13.1341 41.7079 13.1341C41.0714 13.1341 40.5518 13.6088 40.5518 14.1947V15.3359H42.8641L42.8628 14.1947Z" fill="white"/>
    </svg>
  );
};

Istruzione.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};

export default React.memo(Istruzione);
